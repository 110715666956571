import { EWidgetType } from '@/components/FormCreator';
import type { FieldItem } from '@/store/types';
import { useState, useEffect } from 'react';

interface Custom {
  [propName: string]: {};
}
// 动态创建自定义输入框
export function useCustom(fieldInfo: FieldItem, property: keyof FieldItem, defaultValue?: any) {
  const [Custom, setCustom] = useState({});
  const customInput: Custom = {};
  customInput[`${property}Custom`] = {
    label: '自定义',
    defaultValue: defaultValue || '',
    fieldType: EWidgetType.Input,
  };
  const ifCustom = (value: string) => {

    if (value === 'custom') {
      setCustom(customInput);
    } else {
      setCustom({});
    }
  };
  useEffect(() => {
    if (fieldInfo[`${property}`] === 'custom') {
      setCustom(customInput);
    }
  }, []);
  return [Custom, ifCustom];
}
