import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Typography, Space, Row, Image, Popconfirm } from 'antd';
import { default as ProTable } from '@ant-design/pro-table';
import Text from '@/components/Column/Text';
import { SORT_TYPES } from '@/constant';
import { value2Label } from '@lizhife/schema-shared';
import useFormGeneratorStore from '@/store/form-generator-list';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import HelpText from '@/components/HelpText';
import { LeftFixedName } from '../help';
import { getButtonContents } from '@/components/PageParser/List/ToolbarButton';
import './Table.less';
import { cssToStyleObject } from '@/utils/css';
import { sandbox } from '@/utils/sandbox';

export const Name = 'list_table';

export const DEFAULT_CONFIIG = {
  version: '0.0.1',
  used: true,
  bindParam: {
    fieldName: '',
    fieldId: '',
  },
  addButton: {
    text: '新增',
    hide: false,
    shapeType: 'default',
    styleType: 'primary',
    hasTip: false,
    tipContent: '',
  },
  fields: [],
};

const MOCK_DATA = {
  video: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
  enums: '1',
  text: '示例数据',
  'date-time': Date.now(),
  image: 'https://cdnimg101.gzlzfm.com/audio_cover/2013/08/09/5112668945392263_300x300.jpg',
};

const Table = memo(() => {
  const { bindEvents, isFocus } = useEvents(Name);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const fgStore = useFormGeneratorStore((state) => state);
  // const [tempHelp, setTempHelp] = useState(false)
  const { listTable } = fgStore;

  const getColumns = useCallback(() => {
    const { fields } = listTable;
    const fieldColumns: any[] = fields
      .filter((m: any) => Boolean(m.used))
      .map((o: any) => {
        /* 排序用到的参数 */
        const sortProps = o.sortable
          ? {
              sorter: true,
              // dataIndex: o.dataIndex,
              showSorterTooltip: true,
              sortDirections: SORT_TYPES.map((sort) => sort.value),
            }
          : {};

        return {
          ...(o.fixed
            ? {
                fixed: o.fixed,
              }
            : {}),
          ...sortProps,
          dataIndex: o.dataIndex,
          title: (
            <Row style={{ margin: 0, padding: 0 }} justify="start" align="middle">
              {o.hasTip && o.tipContent ? (
                <>
                  <HelpText toolTip={o.tipContent}>{o.title}</HelpText>
                </>
              ) : (
                <span>{o.title}</span>
              )}
            </Row>
          ),
          onHeaderCell: () => ({ style: { position: 'relative' } }),
          width: o.width,
          render(_: any, record: any) {
            if (o.fixed) {
              const { buttons = [] } = o;
              return (
                <Space>
                  {buttons.map((buttonConfig: any) => {
                    const { hide, hideExpression } = buttonConfig;
                    if (hide) return null;
                    if (hideExpression) {
                      if (sandbox(`return ${hideExpression}`, record)) {
                        return null;
                      }
                    }
                    const node = (
                      <Typography.Link type={buttonConfig.shapeType}>
                        {buttonConfig.text ?? '-'}
                      </Typography.Link>
                    );
                    if (buttonConfig.confirmMessage) {
                      return <Popconfirm title={buttonConfig.confirmMessage}>{node}</Popconfirm>;
                    }
                    return node;
                  })}
                </Space>
              );
            }
            let value = record[o.dataIndex] ?? '';
            const { valueExpression, inlineStyle } = o;
            let style: React.CSSProperties | undefined;
            if (inlineStyle) {
              style = cssToStyleObject(inlineStyle);
            }
            if (valueExpression) {
              value = sandbox(`return ${valueExpression}`, record);
            }
            if (o.displayType === 'video') {
              return (
                <video width={o.width - 20} controls poster={o.videoPoster} style={style}>
                  <source src={value} type="video/mp4" />
                </video>
              );
            }
            if (o.displayType === 'image') {
              return <Image src={value} width="90%" style={style} />;
            }
            if (o.displayType === 'button') {
              return <Button>{value}</Button>;
            }

            // 枚举或文本
            return (
              <Text
                linkStyle={o.linkToDetail}
                copyable={!!o.copyable}
                isTime={o.displayType === 'date-time'}
                format={o.formatDateTime ? o.formatDateTime : undefined}
                tooltip={!!o.ellipsisTooltip}
                value={
                  o.displayType === 'enums' && o?.options?.length
                    ? value2Label(o.options, String(value))
                    : value
                }
                style={style}
              />
            );
          },
        };
      });
    return fieldColumns;
  }, [listTable]);

  useEffect(() => {
    const mockData: any = {};
    listTable.fields.forEach((field: any) => {
      mockData[field.dataIndex] = ((MOCK_DATA as any)[field.displayType] as any) || '示例数据';
      if (field.displayType === 'enums' && field.options?.length) {
        mockData[field.dataIndex] = field.options[0].value;
      }
    });
    setDataSource([mockData]);
  }, [listTable.fields]);

  const columns = getColumns();

  return (
    <div {...bindEvents('common-area')}>
      {isFocus && <LeftFixedName name="内容区域" />}
      <ProTable
        search={false}
        bordered
        columns={columns}
        pagination={false}
        className="fg-list-table"
        scroll={{
          x: 800,
        }}
        locale={{
          emptyText: '暂无数据。请检查是否有绑定参数！',
        }}
        rowKey={(row) => JSON.stringify(row)}
        dataSource={columns?.length ? dataSource : []}
        toolBarRender={() =>
          listTable.toolbarButtons.map((buttonConfig: any) => {
            const { properties } = buttonConfig;
            if (properties?.hide) {
              return null;
            }
            return (
              <Button key="add" type="primary">
                {getButtonContents(properties)}
              </Button>
            );
          })
        }
      />
    </div>
  );
});

export default Table;
