import { memo, useCallback, useState } from 'react';
import { Upload, message, Table, Button, Alert } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { read, utils } from 'xlsx';
import { exportJSONToExcelFile } from '@/utils/excle';
import { EModeType } from '@/store/form-generator-parse';

const MAX_COUNT = 1; // 最大上传个数
const ACCEPT = '.xls, .xlsx, .csv'; // 文件格式白名单
const PREVIEW_DATA_NUMBER = 10; // 预览多少条数据

interface TConfigInfo {
  buttonLabel?: string;
  transformKeys?: Record<string, Record<string, any>>;
  limitPrompt?: string;
}
interface ExcelFileUploadProps {
  configInfo?: TConfigInfo;
  value?: any;
  mode: EModeType;
  onChange?: (data: any) => void;
  onValidate?: (errMsg: string) => void;
}

const ExcelFileUpload = memo<ExcelFileUploadProps>(({ configInfo = {}, mode, onChange }) => {
  const {
    buttonLabel = '上传表格',
    transformKeys,
    limitPrompt = '上传的表格字段不符合要求',
  } = configInfo;
  const isPreview = mode === EModeType.preview;

  const [tableHeader, setTableHeader] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  // excel文件上传解析
  const onBeforeUploadFile = useCallback(
    (file) => {
      if (isPreview) {
        message.warn('预览模式不支持上传表格');
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = (e as any).target.result;
        const workbook = read(data, { type: 'binary' });
        const jsonData: any = {};
        let firstSheetName;

        // 遍历工作表
        for (const sheetName of Object.keys(workbook.Sheets)) {
          // esline-disable-next-line
          if (workbook.Sheets[sheetName]) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            jsonData[sheetName] = utils.sheet_to_json(workbook.Sheets[sheetName]);
            if (typeof firstSheetName === 'undefined') {
              firstSheetName = sheetName;
            }
          }
        }
        if (typeof firstSheetName === 'undefined') {
          message.error('识别数据失败！');
          return;
        }

        const excelData = jsonData[firstSheetName];
        const excelHeader = [] as any[];

        if (excelData.length < 1) {
          message.error(`工作表-${firstSheetName}内容为空！`);
          return;
        }
        // 获取表头
        for (const headerAttr of Object.keys(excelData[0])) {
          const header = {
            title: headerAttr,
            dataIndex: headerAttr,
            key: headerAttr,
          };
          excelHeader.push(header);
        }

        // 检测是否配置了映射字段
        if (!(typeof onChange === 'function' && typeof transformKeys === 'object')) {
          message.warn('转换表格数据出错，未配置表格映射接口字段');
          return;
        }

        // 检测excel中的字段，是否符合要求
        let checkFlag = true;
        for (const { excelKey } of Object.values(transformKeys)) {
          let hasKey = false;
          for (const excelColName of Object.keys(excelData[0])) {
            if (excelColName === excelKey) {
              hasKey = true;
              break;
            }
          }
          if (!hasKey) {
            checkFlag = false;
            break;
          }
        }
        if (!checkFlag) {
          message.error(limitPrompt);
          return;
        }

        message.success('上传成功！');

        // 显示在表格中
        setTableHeader(excelHeader);
        setTableData(excelData.slice(0, PREVIEW_DATA_NUMBER));

        // 将上传的数据，转换成接口定义的格式
        const changeValue: any[] = [];
        excelData.forEach((item: any) => {
          const linData: any = []; // 一行数据
          for (const [apiProp, paramInfo] of Object.entries(
            transformKeys as Record<string, Record<string, any>>,
          )) {
            const newItem: any = {};
            const { excelKey } = paramInfo;
            if (typeof item[excelKey] !== 'undefined') {
              newItem.fieldId = paramInfo.id;
              newItem.dataType = paramInfo.dataType;
              newItem.propName = apiProp;
              newItem.value = item[excelKey];
            }
            linData.push(newItem);
          }
          if (linData.length > 0) {
            changeValue.push(linData);
          }
        });
        console.log('上传表格后，转换的接口待存储数据', changeValue);
        onChange(changeValue);
      };
      reader.readAsBinaryString(file);

      return false;
    },
    [onChange, transformKeys, isPreview, limitPrompt],
  );

  // 导出模板文件
  const exportFile = useCallback(() => {
    if (typeof transformKeys !== 'object') {
      message.error('表头字段为空');
      return;
    }
    const excelTemplate: Record<string, string> = {};
    for (const paramInfo of Object.values(transformKeys as Record<string, Record<string, any>>)) {
      const { excelKey } = paramInfo;
      excelTemplate[excelKey] = '';
    }
    exportJSONToExcelFile({
      jsonDataArray: [excelTemplate],
      sheetName: 'Sheet1',
      fileName: '模板文件',
    });
  }, [transformKeys]);

  const hasUpload = tableData.length > 0;

  return (
    <>
      <Upload
        accept={ACCEPT}
        maxCount={MAX_COUNT}
        beforeUpload={onBeforeUploadFile}
        disabled={isPreview}
      >
        <Button icon={<UploadOutlined />}>{hasUpload ? '重新上传' : buttonLabel}</Button>
      </Upload>
      <Button type="link" icon={<DownloadOutlined />} onClick={exportFile}>
        下载模板
      </Button>
      {tableData.length > 0 ? (
        <>
          <Alert
            message="仅展示上传表格的前10行数据，作为预览参考"
            type="warning"
            showIcon
            closable
          />
          <Table pagination={false} columns={tableHeader} dataSource={tableData} />
        </>
      ) : null}
    </>
  );
});

export default ExcelFileUpload;
