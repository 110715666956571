import deepmerge from 'deepmerge';
import create from 'zustand';

interface StoreType {
  // 联动组件数据
  linkageComponents: {
    // 组件id为属性名
    [key: string]: {
      // 组件自身数据
      data: any;
      // 推送数据到此处
      originalData: any;
    };
  };
  uLinkageComponents: (...args: any) => void;

  // 数据重置
  reset: () => void;

  // 回滚的时候 重置
  rollback: () => void;
}

const useLinkageStore = create<StoreType>((set) => ({
  linkageComponents: {},
  uLinkageComponents: (value: any) =>
    set((state) => ({
      linkageComponents: deepmerge(state.linkageComponents, value, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    })),

  rollback: () =>
    set(() => ({
      linkageComponents: {},
    })),

  reset: () =>
    set(() => ({
      linkageComponents: {},
    })),
}));

export default useLinkageStore;
