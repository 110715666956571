import { memo } from 'react';
import { Table } from 'antd';
import type { TableColumnType } from 'antd';

interface TConfigInfo {
  bindParam?: any;
  transformKeys?: any;
}
interface TableViewPorps {
  configInfo?: TConfigInfo;
  value?: any;
}

const TableUpload = memo<TableViewPorps>(({ configInfo = {}, value = [] }) => {
  const { transformKeys = [] } = configInfo; // 获取table数据转换的配置和绑定参数
  // const loadMethod = bindParam.find((item: any) => item.type === 'loadMethod');
  // const childKeys = loadMethod?.bindVar?.childKeys;

  // 拼装表格列
  // const columns: Array<TableColumnType<any>> = [];
  // childKeys.forEach((child: any) => {
  //   columns.push({
  //     title: child.title,
  //     dataIndex: child.key.split('.').slice(-1)[0],
  //   });
  // });

  // 拼装表格列
  const columns: Array<TableColumnType<any>> = [];
  for (const key of Object.keys(transformKeys)) {
    const { excelKey } = transformKeys[key];
    columns.push({
      title: excelKey,
      dataIndex: key,
    });
  }

  return <Table<any> dataSource={value} bordered columns={columns} pagination={false} />;
});

export default TableUpload;
