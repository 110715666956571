import { useCallback } from 'react';
import { executeMethod } from '@/services/method';
import { message } from 'antd';
import useMethodDetail from '@/hooks/useMethodDetail';
import { eachTree } from '@/utils/utils';
import _ from 'lodash';
import { isRequestSuccess, makeTargetMethodParamValues } from './helper';

const useHandleCallAPI = () => {
  const { load: loadMethod } = useMethodDetail();
  const handleCallAPI = useCallback(
    async (config: any, record: any, listOutputParamsMap: Record<string, any>) => {
      const methodinParamMap: any = {};
      try {
        const methodId = config?.callMethod?.method?.id;
        if (!methodId) return;
        // 获取删除方法
        const method = await loadMethod(methodId);
        // log('删除方法的配置', method);
        // 入参建立 hash
        eachTree(
          method?.inputParams || [],
          (param) => {
            methodinParamMap[param.id] = param;
          },
          0,
          'fields',
        );
        // log('删除方法入参数 map', delMethodinParamMap);
        const paramValues = await makeTargetMethodParamValues(
          // callMethod
          _.omit(config?.callMethod, ['method']),
          // row 值
          record,
          // 列表方法出参的 map
          listOutputParamsMap,
          // 删除方法入参的 map
          methodinParamMap,
        );
        // 调用方法
        const executeRes = await executeMethod({
          methodId,
          // 构造入参
          paramValues,
        });
        if (executeRes?.rCode !== 0) {
          return message.error(executeRes.msg);
        }
        const responseData = executeRes.data;
        const { isSuccess } = isRequestSuccess({
          responseData,
          outputParams: method?.outputParams,
        });
        if (isSuccess) {
          message.success('操作成功');
        }
        return isSuccess;
      } catch (error) {
        console.log(error);
      }
    },
    [loadMethod],
  );

  return {
    handleCallAPI,
  };
};

export default useHandleCallAPI;
