import { useState } from 'react';
import { getRegionBusinessEnv } from '@/services/method';

// 获取方法列表
export default function useRegioEnv() {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const load = async (): Promise<any> => {
    try {
      if (loading) {
        return [];
      }
      setLoading(true);
      const res = await getRegionBusinessEnv();
      if (res.rCode === 0) {
        setData(res.data?.regions || []);
        return res.data?.regions || [];
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    load,
  };
}
