/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { Row, Typography, Popconfirm, Space, Image, message } from 'antd';
import Text from '@/components/Column/Text';
import HelpText from '@/components/HelpText';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import { default as ProTable } from '@ant-design/pro-table';
import _ from 'lodash';
import { value2Label } from '@/components/SchemaView/utils/help';
import { parseString2Json } from '@/components/FormGeneractor/help';
import { DEFAULT_CONFIIG as DEFAULT_CONFIIG_PAGINATION } from '@/components/FormGeneractor/ListContent/Pagination';
import { DEFAULT_CONFIIG as DEFAULT_CONFIIG_TITLE } from '@/components/FormGeneractor/ListContent/Header';
import useMethodDetail from '@/hooks/useMethodDetail';
import { EPageType, SORT_TYPES, EPageOpenMode, EPageSourceType } from '@/constant';
import Title from './Title';
import Search from './Search';
import {
  // isRequestSuccess,
  findTableListResponseByConfig,
  findTotalResByConfig,
  validateRequest,
} from '../helper';
import { executeMethod } from '@/services/method';
import type { ExecuteMethodParam, MethodParam, TMethodsValidationConfig } from '@/types';
import type { ActionType } from '@ant-design/pro-table/lib/typing';
import { findTree, eachTree } from '@/utils/utils';
import useFormGeneratorStore, { EModeType } from '@/store/form-generator-parse';
import useCurPageStore from '../useCurPageStore';
import useHandleCallAPI from '../useHandleCallAPI';
import DyEnumText from './DyEnumText';
import IframeDrawer from '../Widgets/IframeDrawer';
import IframeModal from '../Widgets/IframeModal';

import './index.less';
import ToolbarButton from './ToolbarButton';
import { getListPageConfig } from '@/services/page';
import { sandbox } from '@/utils/sandbox';
import { fromARGBtoRGBA, cssToStyleObject } from '@/utils/css';
import { makeParamvalues } from '@/utils/tool';
import { transferValueFromInAndOutParams } from '@/utils/helper';

const cacheDyOps: Record<string, any[]> = {};

function getKeyMap(outputKeyMap: Map<string, string>, outputParams: any[]) {
  outputParams.forEach(({ id, name, fields = [] }: { id: string; name: string; fields: any[] }) => {
    outputKeyMap.set(id, name);
    if (fields.length > 0) {
      getKeyMap(outputKeyMap, fields);
    }
  });
}

function getRealResponseData(responseData: any[], outputKeyMap: Map<string, string>) {
  const realData: Record<string, any> = {};
  responseData.forEach(
    ({
      paramId,
      value,
      listObjectValue,
      objectValue,
    }: {
      paramId: string;
      value: any;
      listObjectValue: any;
      objectValue: any;
    }) => {
      const keyName = outputKeyMap.get(paramId);
      console.log('"paramId"', paramId, 'keyName', keyName);
      if (typeof keyName !== 'undefined') {
        if (listObjectValue === null && objectValue === null) {
          console.log('keyname ==', keyName);
          realData[keyName] = value;
        } else if (Array.isArray(objectValue) && objectValue.length > 0) {
          console.log('keyname ==', keyName, 'objectValue', objectValue);
          realData[keyName] = getRealResponseData(objectValue, outputKeyMap);
        } else if (Array.isArray(listObjectValue) && listObjectValue.length > 0) {
          console.log('keyname ==', keyName);
          realData[keyName] = [];

          listObjectValue.forEach((inner) => {
            realData[keyName].push(getRealResponseData(inner, outputKeyMap));
          });
        }
      }
    },
  );

  return realData;
}

interface ListProps {
  pageModeId: string;
  pageId: string;
  onSelect?: (record: any, uncheck?: Set<string>) => void;
  rowSelectionType?: 'checkbox' | 'radio';
  defaultSet?: Set<string>;
  dataMainKeys?: string[];
}

// 解析列表
const List = memo<ListProps>(
  ({ pageModeId, pageId, onSelect, rowSelectionType, defaultSet, dataMainKeys }) => {
    const { curPageStore, store } = useCurPageStore(pageModeId);
    const { pageList, listOutputParamsMap, mode } = curPageStore;
    const {
      uListMethodDetail,
      uEditorVisible,
      uCurrentDrawerServiceTarget,
      uRowInfo,
      uListInputParamsMap,
      uListOutputParamsMap,
      uConfigForDetailInit,
    } = store;
    const { load: loadMethodDetail, data: listMethodDetail } = useMethodDetail();
    const [searchParams, setSearchParams] = useState<any>({});
    const [fields, setFields] = useState<any[]>([]);
    const ref = useRef<ActionType>();
    const editPageId =
      pageList?.find((item) => String(item.type) === EPageType.编辑页面)?.id ?? null;
    const viewPageId =
      pageList?.find((item) => String(item.type) === EPageType.查看页面)?.id ?? null;
    const [listPageInfo, setPageInfo] = useState<Record<string, any> | null>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { handleCallAPI } = useHandleCallAPI();
    const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState<any[]>(
      Array.from(defaultSet ?? new Set()),
    );
    const uncheck = useRef<Set<string>>(new Set());
    const checked = useRef<Set<string>>(new Set());
    const listRefresh = useFormGeneratorStore((state) => state.listRefresh);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('');
    const [iframeTitle, setIframeTitle] = useState('');
    const [loadData, setLoadData] = useState<Record<string, any> | undefined>(undefined);
    const [externalPageConfig, setExternalPageConfig] = useState<Record<string, any> | undefined>(
      undefined,
    );

    useEffect(() => {
      (async () => {
        if (pageId) {
          // 5. 根据列表页面 ID, 获取相关信息
          const res = await getListPageConfig(pageId, mode === EModeType.production);
          if (res.rCode !== 0) {
            return;
          }
          const pageInfo = res.data;

          if (pageInfo?.loadMethodId) {
            const methodDetail = await loadMethodDetail(String(pageInfo?.loadMethodId));

            uListMethodDetail(pageModeId, methodDetail);
            // 参数建立 map 关系
            if (methodDetail) {
              const mapInput: any = {};
              const mapOutput: any = {};
              eachTree(
                methodDetail.inputParams,
                (item) => {
                  mapInput[item.id] = item;
                },
                0,
                'fields',
              );
              uListInputParamsMap(pageModeId, mapInput);
              eachTree(
                methodDetail.outputParams,
                (item) => {
                  mapOutput[item.id] = item;
                },
                0,
                'fields',
              );
              uListOutputParamsMap(pageModeId, mapOutput);
              setPageInfo(res.data);
            }
          }
        }
      })();
    }, [pageId, listRefresh]);

    // 顶层配置
    const topConfig: any = useMemo(() => {
      return parseString2Json(listPageInfo?.properties || '');
    }, [listPageInfo]);

    // 点击查询按钮，组装查询参数
    const onSearchFinish = useCallback((values: any) => {
      setCurrentPage(1);
      setSearchParams(values);
      ref.current?.reload();
    }, []);

    // 绑定 list 的配置
    const listFieldConfig: any = useMemo(() => {
      const { contentProperties } = listPageInfo || {};
      return parseString2Json(contentProperties);
    }, [listPageInfo]);

    // 分页
    const pagination: any = useMemo(() => {
      if (!listPageInfo?.paginationProperties) {
        return null;
      }
      return Object.assign(
        DEFAULT_CONFIIG_PAGINATION,
        parseString2Json(listPageInfo?.paginationProperties),
      );
    }, [listPageInfo]);

    // pageSize is only init for the first time
    const [pageSize, setPageSize] = useState<number>(10);

    // listen the "pagination?.pageSize",can be able to keep up the latest values.
    useEffect(() => {
      setPageSize(pagination?.pageSize ?? 10);
    }, [pagination?.pageSize]);

    // 请求
    const request = useCallback(
      async (params: any) => {
        const emptyData = {
          data: [],
          total: 0,
          success: true,
        };
        if (listMethodDetail?.id) {
          // 分页的数据插入
          const { pageNumberField, pageSizeField, totalField } = pagination || {};
          const searchVals = searchParams;
          // 更新分页数据值
          if (topConfig?.usedPagination) {
            _.set(searchVals, pageNumberField.fieldName, params.current || 1);
            _.set(searchVals, pageSizeField.fieldName, params.pageSize);
          }

          const res = await executeMethod({
            methodId: listMethodDetail.id,
            // 构造出 list 方法的入参
            paramValues: makeExecuteMethodParam(searchVals, listMethodDetail?.inputParams || [], {
              config: topConfig?.usedPagination ? pagination : undefined,
              value: params,
            }),
          });

          if (res.rCode === 0) {
            // 校验方法是否成功

            // 通过页面配置信息的contentProperties，查找到当前绑定的列表字段id
            const properties = JSON.parse(listPageInfo?.contentProperties ?? '{}');
            const bindFieldId = (
              properties?.bindParam?.find((item: any) => item.type === 'loadMethod') ?? {
                bindVar: { fieldId: '' },
              }
            ).bindVar.fieldId;
            const methodsValidationConfig: TMethodsValidationConfig = JSON.parse(
              listPageInfo?.properties ?? '{}',
            )?.methodsValidationConfig;
            // console.log('methodsValidationConfig',methodsValidationConfig)
            const { isSuccess, dataTarget } = await validateRequest({
              responseData: res.data,
              methodsConfig: methodsValidationConfig?.loadMethods,
              bindFieldId,
            });

            console.log('列表请求是否成功', isSuccess);

            if (!isSuccess || !dataTarget) return emptyData;
            const bindInfo = listFieldConfig?.bindParam?.find((o: any) => o.type === 'loadMethod');

            // 响应回来的 list
            const listResponse = findTableListResponseByConfig(dataTarget, bindInfo);
            const totalRes = findTotalResByConfig(res.data?.paramValues, totalField);

            const listData = listResponse
              .map((item: any) => {
                const result: any = {};
                if (Array.isArray(item)) {
                  item.forEach((sub) => {
                    const fieldName = listOutputParamsMap[sub.paramId]?.name;
                    if (fieldName) {
                      result[fieldName] = sub.value;
                    }
                  });
                  if (dataMainKeys && defaultSet) {
                    let mainKey = '';
                    for (const key of dataMainKeys) {
                      mainKey = String(result[key]) + mainKey;
                    }
                    result[`_${dataMainKeys.join('')}`] = mainKey;
                  }
                  return result;
                } else {
                  return null;
                }
              })
              .filter((o: any) => Boolean(o));

            return {
              data: listData,
              total: totalRes || 0,
              success: true,
            };
          } else {
            message.error(res.msg || res.message || '查询失败');
            return emptyData;
          }
        } else {
          return emptyData;
        }
      },
      [
        searchParams,
        listFieldConfig,
        listOutputParamsMap,
        pagination,
        topConfig?.usedPagination,
        dataMainKeys,
        defaultSet,
      ],
    );

    // Processing external page logic
    const excuteExternalPage = useCallback(async (configInfo, record, isInTable = true) => {
      const url = configInfo?.externalInfo?.url ?? '';
      const pageName = configInfo?.externalInfo?.name ?? '';
      // excute loadEvents config
      const loadEvents: Record<string, any> = configInfo?.loadEvents ?? {};

      const loadResult: Array<Record<string, any>> = [];
      for (const [key, info] of Object.entries(loadEvents)) {
        const { getTransformParamType } = info;
        if (getTransformParamType === 'function') {
          const methodId = info?.bindFunction?.id ?? '';
          const inputParams = info?.bindFunction?.inputParams ?? [];
          const outputParams = info?.bindFunction?.outputParams ?? [];
          const rowId = record?.id;
          const paramValues = makeParamvalues(inputParams, { id: rowId });

          const result = await executeMethod({ methodId, paramValues }).then((res) => {
            const responParamValues: Array<Record<string, any>> = res?.data?.paramValues ?? [];
            try {
              responParamValues.forEach((item) => {
                if (['rCode', 'code'].includes(item?.path)) {
                  if (item.value !== 0) {
                    message.error('执行绑定方法出错！');
                    throw new Error('执行绑定方法出错！');
                  }
                }
              });
            } catch (e) {
              console.log(e);
            }
            return res?.data?.paramValues;
          });

          const outputKeyMap = new Map();
          getKeyMap(outputKeyMap, outputParams);
          const realData = getRealResponseData(result, outputKeyMap);

          const {
            transformInAndOutParamsWithFunction,
          }: { transformInAndOutParamsWithFunction: Record<string, any> } = info;

          let newTransferValue: Record<string, any> = {};
          for (const [rowDataKey, transferInfo] of Object.entries(
            transformInAndOutParamsWithFunction,
          )) {
            const sourceValue = sandbox(`return this.${rowDataKey}`, realData);
            const { writeKey } = transferInfo;
            newTransferValue = transferValueFromInAndOutParams(
              newTransferValue,
              writeKey,
              sourceValue,
            );
          }

          loadResult.push({
            actionKey: key,
            value: newTransferValue,
          });
        } else if (getTransformParamType === 'table' && isInTable) {
          const sourceData = record;
          const {
            transformInAndOutParamsWithTableRowData,
          }: { transformInAndOutParamsWithTableRowData: Record<string, any> } = info;

          let newTransferValue: Record<string, any> = {};
          for (const [rowDataKey, transferInfo] of Object.entries(
            transformInAndOutParamsWithTableRowData,
          )) {
            const sourceValue = sandbox(`return this.${rowDataKey}`, sourceData);
            const { writeKey } = transferInfo;
            newTransferValue = transferValueFromInAndOutParams(
              newTransferValue,
              writeKey,
              sourceValue,
            );
          }

          loadResult.push({
            actionKey: key,
            value: newTransferValue,
          });
        }
      }

      setLoadData(loadResult);
      setExternalPageConfig(configInfo);
      setIframeTitle(pageName);

      if (configInfo.pageOpenMode === EPageOpenMode.DRAWER) {
        setIframeSrc(url);
        setDrawerVisible(true);
      } else if (configInfo.pageOpenMode === EPageOpenMode.MODAL) {
        setIframeSrc(url);
        setModalVisible(true);
      }

      return Promise.resolve(true);
    }, []);

    // 标题
    const title: any = useMemo(() => {
      if (!listPageInfo?.titleProperties) {
        return null;
      }
      return Object.assign(DEFAULT_CONFIIG_TITLE, parseString2Json(listPageInfo?.titleProperties));
    }, [listPageInfo]);

    // 新增按钮
    const buttonProperties: any = useMemo(() => {
      return (listPageInfo?.toolbarButtons ?? []).map((button: any) =>
        parseString2Json(button.properties),
      );
    }, [listPageInfo?.toolbarButtons]);

    // 字段
    const columns: any[] = useMemo(() => {
      if (!listPageInfo?.contentColumns?.length) return [];
      // TODO 优化这种字段名的方式
      const getFieldName = (field: any) => {
        const bindParamItem = (field?.bindParam || []).find((x: any) => x.type === 'loadMethod');
        const fieldNames = (bindParamItem?.bindVar?.fieldName || '').split('.');
        const fieldName = fieldNames.length ? fieldNames[fieldNames.length - 1] : '';
        return fieldName;
      };
      const contentColumns = listPageInfo?.contentColumns;
      const _nameCache: Record<string, any> = {};
      const name2index = (data: any) => {
        const re: Record<string, any> = {};
        // eslint-disable-next-line guard-for-in
        for (const key in data) {
          re[_nameCache[key]] = data[key];
        }
        return re;
      };
      // 配置信息
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const fields = contentColumns
        .map((param: any) => {
          const field: any = parseString2Json(param.properties);
          if (field.dataIndex) {
            const name = getFieldName(field);
            _nameCache[name] = field.dataIndex;
          }
          return field;
        })
        .filter((o: any) => !!o.used)
        .sort((a: any, b: any) => a.orderNum - b.orderNum);

      const buttons = listPageInfo?.operationButtons?.map((x: any) => {
        if (typeof x.properties === 'string') {
          x.properties = parseString2Json(x.properties);
        } else {
          // 兼容旧配置
          const { properties, frontComponentCode } = x;
          if (frontComponentCode === 'edit_button') {
            properties.action = 'openPage';
          }
          if (frontComponentCode === 'del_button') {
            properties.action = 'callAPI';
          }
        }

        return x.properties;
      });
      const fixedRightConfig = listFieldConfig?.fixedRightConfig || {};

      const fixedConfig = {
        title: '操作',
        fixed: 'right',
        width: fixedRightConfig.width || 100,
        buttons,
      };

      setFields(fields);
      const source = [...fields];
      if (fixedRightConfig?.used && !onSelect) {
        source.push(fixedConfig);
      }
      const result = source.map((field: any) => {
        const sortProps = field.sortable
          ? {
              sorter: true,
              showSorterTooltip: true,
              sortDirections: SORT_TYPES.map((sort) => sort.value),
            }
          : {};
        const fieldName = getFieldName(field);
        const info: any = {
          ...(field.fixed
            ? {
                fixed: field.fixed,
              }
            : {}),
          ...sortProps,
          // 标题
          title: (
            <Row style={{ margin: 0, padding: 0 }} justify="start" align="middle">
              {field.hasTip && field.tipContent ? (
                <>
                  <HelpText toolTip={field.tipContent}>{field.title}</HelpText>
                </>
              ) : (
                <span>{field.title}</span>
              )}
            </Row>
          ),
          dataIndex: fieldName,
          width: field.width,
          render(text: any, record: any) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { buttons = [] } = field;

            if (field.fixed) {
              return (
                <Space>
                  {buttons.map((buttonConfig: any) => {
                    const { hide, hideExpression } = buttonConfig;
                    if (hide) return null;
                    if (hideExpression) {
                      if (sandbox(`return ${hideExpression}`, record)) {
                        return null;
                      }
                    }

                    const onClick = async () => {
                      // 处理打开页面的动作
                      if (buttonConfig.action === 'openPage') {
                        // open external page by drawer or modal.
                        if (buttonConfig.pageSourceType === EPageSourceType.EXTERNAL) {
                          await excuteExternalPage(buttonConfig, record);
                          return;
                        }

                        if (!buttonConfig?.callMethod) {
                          message.error('请先配置详情方法入参绑定关系');
                          return;
                        }
                        uCurrentDrawerServiceTarget(pageModeId, buttonConfig.page);
                        uRowInfo(pageModeId, record);
                        uEditorVisible(pageModeId, true);
                        uConfigForDetailInit(pageModeId, {
                          callMethod: buttonConfig.callMethod,
                        });
                      }
                      // 处理调用 API 的动作
                      if (buttonConfig.action === 'callAPI') {
                        if (mode === EModeType.production) {
                          const ok = await handleCallAPI(buttonConfig, record, listOutputParamsMap);
                          if (ok) {
                            ref.current?.reload();
                          }
                        }
                      }
                    };

                    const node = (
                      <Typography.Link
                        onClick={buttonConfig.confirmMessage ? undefined : onClick}
                        type={buttonConfig.shapeType}
                      >
                        {buttonConfig.text ?? '-'}
                      </Typography.Link>
                    );
                    if (buttonConfig.confirmMessage) {
                      return (
                        <Popconfirm title={buttonConfig.confirmMessage} onConfirm={onClick}>
                          {node}
                        </Popconfirm>
                      );
                    }
                    return node;
                  })}
                </Space>
              );
            }
            let value = record[fieldName] ?? '';
            const { valueExpression, inlineStyle } = field;
            let style: React.CSSProperties | undefined;
            if (inlineStyle) {
              const code = sandbox(`return \`${inlineStyle}\``, {
                ...name2index(record),
                fromARGBtoRGBA,
              });
              style = cssToStyleObject(code);
            }
            if (valueExpression) {
              value = sandbox(`return ${valueExpression}`, name2index(record));
            }

            if (field.displayType === 'video') {
              return (
                <video width={field.width - 20} controls poster={field.videoPoster} style={style}>
                  <source src={value} type="video/mp4" />
                </video>
              );
            }
            if (field.displayType === 'image') {
              let urls = value;
              if (Array.isArray(urls)) {
                urls.map((item: string) => {
                  if (
                    item.indexOf('http://') === -1 &&
                    item.indexOf('https://') === -1 &&
                    field.preFileLink
                  ) {
                    return (field.preFileLink as string) + item;
                  }
                  return item;
                });
              } else if (
                urls.indexOf('http://') === -1 &&
                urls.indexOf('https://') === -1 &&
                field.preFileLink
              ) {
                urls = (field.preFileLink as string) + urls;
              }
              return (
                <Image
                  src={Array.isArray(urls) && urls.length ? urls[0] : urls}
                  width="90%"
                  style={style}
                />
              );
            }

            // 动态枚举额外处理
            if (field.displayType === 'enums' && field.dyOptionsInfo) {
              if (cacheDyOps[JSON.stringify(field.dyOptionsInfo)]) {
                field.options = cacheDyOps[JSON.stringify(field.dyOptionsInfo)];
              } else {
                return <DyEnumText mode={mode} value={value} dyOptionsInfo={field.dyOptionsInfo} />;
              }
            }
            if (field.displayType === 'button') {
              return (
                <Text
                  linkStyle
                  onClick={async () => {
                    await excuteExternalPage(field, record);
                  }}
                  value={value}
                />
              );
            }

            let transferFormat;
            if (field?.transferFormat === 'custom') {
              transferFormat = field.transferFormatCustom;
            } else {
              transferFormat = field?.transferFormat;
            }

            return (
              <Text
                linkStyle={field.linkToDetail}
                copyable={!!field.copyable}
                isTime={['date-time', 'date'].includes(field.displayType)}
                format={field.formatDateTime ? field.formatDateTime : undefined}
                transferFormat={transferFormat}
                tooltip={field.copyable ? true : !!field.ellipsisTooltip}
                value={
                  field.displayType === 'enums' && field?.options?.length
                    ? `${value2Label(field.options, String(value))}`
                    : `${value}`
                }
                onClick={() => {
                  if (field.linkToDetail) {
                    if (!field.callMethod) {
                      message.error('请先配置详情方法入参绑定关系');
                      return;
                    }
                    uCurrentDrawerServiceTarget(pageModeId, viewPageId);
                    uRowInfo(pageModeId, record);
                    uEditorVisible(pageModeId, true);
                    uConfigForDetailInit(pageModeId, {
                      callMethod: field.callMethod,
                    });
                  }
                }}
                style={style}
              />
            );
          },
        };
        return info;
      });
      return [...result];
    }, [
      listPageInfo,
      pageModeId,
      editPageId,
      viewPageId,
      mode,
      listOutputParamsMap,
      listPageInfo?.operationButtons,
      listFieldConfig,
    ]);

    // 模拟数据
    const Mocks: any[] = useMemo(() => {
      const record: any = {};
      fields.forEach((field: any) => {
        const bindParamItem = (field?.bindParam || []).find((x: any) => x.type === 'loadMethod');
        const fieldNames = (bindParamItem?.bindVar?.fieldName || '').split('.');
        const fieldName = fieldNames.length ? fieldNames[fieldNames.length - 1] : '';
        record[fieldName] =
          field.displayType === 'text'
            ? '示例数据'
            : field.displayType === 'image'
            ? 'https://cdnimg101.gzlzfm.com/audio_cover/2013/08/09/5112668945392263_300x300.jpg'
            : field.displayType === 'video'
            ? 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
            : field.displayType === 'date-time'
            ? Date.now()
            : field.displayType === 'enums'
            ? field?.options?.length
              ? field?.options[0].value
              : '1'
            : '-';
      });
      return [record];
    }, [fields]);

    // 关闭弹窗重新加载列表
    useEffect(() => {
      if (!curPageStore.editorVisible) {
        ref.current?.reload();
      }
    }, [curPageStore.editorVisible]);

    return (
      <>
        {topConfig?.usedTitle && <Title config={title} />}
        <Search
          hasCondition={!!topConfig.usedSearch}
          pageModeId={pageModeId}
          onFinish={onSearchFinish}
          onUpdateParams={setSearchParams}
          listPageInfo={listPageInfo}
        />
        <ProTable<any>
          bordered
          rowSelection={
            onSelect
              ? {
                  type: rowSelectionType ?? 'radio',
                  onSelect(record, selected) {
                    if (rowSelectionType === 'checkbox') {
                      if (dataMainKeys) {
                        if (selected) {
                          checked.current.add(JSON.stringify(record));
                          uncheck.current.delete(record[`_${dataMainKeys.join('')}`]);
                        } else {
                          checked.current.delete(JSON.stringify(record));
                          uncheck.current.add(record[`_${dataMainKeys.join('')}`]);
                        }
                      }
                      onSelect?.(
                        Array.from(checked.current).map((item) => JSON.parse(item)),
                        uncheck.current,
                      );
                    } else {
                      onSelect?.(record);
                    }
                  },
                  onSelectAll(selected, selectedRows, changeRows) {
                    if (dataMainKeys) {
                      if (selected) {
                        changeRows.forEach((record) => {
                          checked.current.add(JSON.stringify(record));
                          uncheck.current.delete(record[`_${dataMainKeys.join('')}`]);
                        });
                      } else {
                        changeRows.forEach((record) => {
                          checked.current.delete(JSON.stringify(record));
                          uncheck.current.add(record[`_${dataMainKeys.join('')}`]);
                        });
                      }
                      onSelect?.(
                        Array.from(checked.current).map((item) => JSON.parse(item)),
                        uncheck.current,
                      );
                    }
                  },
                  selectedRowKeys: defaultSelectedRowKeys,
                  onChange(selectedRowKeys) {
                    setDefaultSelectedRowKeys(selectedRowKeys);
                  },
                  preserveSelectedRowKeys: true,
                }
              : undefined
          }
          rowKey={(record) => {
            return dataMainKeys && defaultSet
              ? record[`_${dataMainKeys.join('')}`]
              : JSON.stringify(record);
          }}
          search={false}
          columns={columns}
          scroll={{
            x: 1000,
          }}
          dataSource={curPageStore?.mode === EModeType.preview ? Mocks : undefined}
          toolBarRender={() =>
            buttonProperties.map((properties: any) => (
              <ToolbarButton
                fields={fields}
                searchParams={searchParams}
                pageModeId={pageModeId}
                properties={properties}
                request={curPageStore?.mode === EModeType.preview ? undefined : request}
                openExternalPage={async (config) => await excuteExternalPage(config, null, false)}
              />
            ))
          }
          request={curPageStore?.mode === EModeType.preview ? undefined : request}
          pagination={
            topConfig?.usedPagination
              ? {
                  current: currentPage,
                  onChange(page, size) {
                    setCurrentPage(page);
                    setPageSize(size);
                  },
                  pageSize,
                  onShowSizeChange(current, size) {
                    setPageSize(size);
                  },
                  showSizeChanger: true,
                }
              : false
          }
          actionRef={ref}
        />
        {!modalVisible ? null : (
          <IframeModal
            title={iframeTitle}
            src={iframeSrc}
            isVisible={modalVisible}
            loadData={loadData}
            config={externalPageConfig}
            handleCancel={() => {
              setModalVisible(false);
            }}
            handleOk={() => {
              setModalVisible(false);
              ref.current?.reload();
            }}
          />
        )}
        {!drawerVisible ? null : (
          <IframeDrawer
            title={iframeTitle}
            src={iframeSrc}
            isVisible={drawerVisible}
            loadData={loadData}
            config={externalPageConfig}
            handleCancel={() => {
              setDrawerVisible(false);
            }}
            handleOk={() => {
              setDrawerVisible(false);
              ref.current?.reload();
            }}
          />
        )}
      </>
    );
  },
);

export default List;

/**
 * 构造自定义执行方法入参 ExecuteMethodParam 结构
 * @param info
 * @returns
 */
function makeExecuteMethodParam(
  info: Record<string, any>,
  params: MethodParam[],
  pageInfo: {
    config?: Record<string, any>;
    value?: Record<string, any>;
  },
) {
  const hasPage = typeof pageInfo.config !== 'undefined';
  const { pageNumberField, pageSizeField } = pageInfo.config || {};

  // 递归处理
  const doRecursion = (data: any, parents: string[] = []) => {
    const results: ExecuteMethodParam[] = [];
    // eslint-disable-next-line guard-for-in
    for (const paramName in data) {
      // 从参数 tree 中根据 paths 对比找到当前参数，目的为了找到参数对应的 paramId
      const targetParam = findTree(
        params || [],
        (item, key, level, paths) => {
          // 要路径对比才是准确的，不能单单 name 对比
          const a = [...paths.map((x) => x.name), item.name].join('.');
          const b = parents.concat(paramName).join('.');
          return a === b;
        },
        'fields',
      );

      const value = data[paramName];
      let item: ExecuteMethodParam | null = {
        paramId: targetParam?.id,
      };
      // List/Object
      if (_.isArray(value) && value.length && _.isObject(value[0])) {
        item.listObjectValue = value.map((valItem: any) => {
          return doRecursion(valItem, parents.concat(paramName));
        });
      }
      // List/基础类型
      else if (!_.isArray(value) && _.isObject(value)) {
        item.objectValue = doRecursion(value, parents.concat(paramName));
      }
      // 其他基础类型
      else {
        // 约定：long类型，直接转换为 string； 避免精度丢失问题
        item.value = targetParam?.dataType === EFieldType.Long ? String(value) : value;

        // 分页的值独立处理
        if (hasPage && pageInfo.value) {
          if (pageNumberField?.fieldId === item.paramId && pageInfo?.value?.current) {
            item.value = pageInfo?.value?.current;
          }
          if (pageSizeField?.fieldId === item.paramId && pageInfo?.value?.pageSize) {
            item.value = pageInfo?.value?.pageSize;
          }
        }
        // 搜素值是 '' 时  不要传这个值
        if (
          item.value === '' ||
          item.value === null ||
          item.value === 'NaN' ||
          item.value === 'undefined'
        ) {
          item = null;
        }
      }
      if (item && item.paramId) {
        results.push(item);
      }
    }
    return results;
  };
  return doRecursion(info);
}
