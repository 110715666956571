import React, { useCallback, useEffect, useState } from 'react';
import { Select, Dropdown, Menu, Button, message, Modal, Tag, Form, Input } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { copyPage, removePage, setDefaultPage } from '@/services/pageGroup';
import useFormGeneratorStore from '@/store/form-generator';
import useListStore from '@/store/form-generator-list';

const PageMenu: React.FC<{
  id: string;
  onDefault?: (id: string) => void;
  onRemove?: (id: string) => void;
  onCreate?: (id: string) => void;
  onEditPageOpen: () => void;
  isDefault?: boolean;
}> = ({ id, onDefault, onRemove, onCreate, onEditPageOpen, isDefault }) => {
  const handleDefault = useCallback(async () => {
    const res = await setDefaultPage(id);
    if (res.rCode === 0) {
      message.success('首页设置成功');
      onDefault?.(id);
    } else {
      message.error(res.msg ?? res.message ?? '设置失败，请稍后再试');
    }
  }, [id, onDefault]);
  // const handleCopy = useCallback(() => {}, [id]);
  const handleRemove = useCallback(async () => {
    if (isDefault) {
      message.error('当前页面被设置为首页，无法删除');
      return;
    }
    Modal.confirm({
      title: '是否要删除当前页面？',
      content: '删除后，该页面将无法恢复，生成的功能页面也将无法访问。',
      async onOk() {
        const res = await removePage(id);
        if (res.rCode === 0) {
          message.success('页面删除成功');
          onRemove?.(id);
        } else {
          message.error(res.msg ?? res.message ?? '删除失败，请稍后再试');
        }
      },
    });
  }, [id, onRemove, isDefault]);

  const handleCopy = useCallback(async () => {
    Modal.confirm({
      title: '是否要复制当前页面？',
      // content: '删除后，该页面将无法恢复，生成的功能页面也将无法访问。',
      async onOk() {
        const res = await copyPage(id);
        if (res.rCode === 0) {
          message.success('页面复制成功');
          onCreate?.(res.data.id);
        } else {
          message.error(res.msg ?? res.message ?? '复制失败，请稍后再试');
        }
      },
    });
  }, [id, onCreate]);

  const items = [
    {
      key: '2',
      label: (
        <Button type="text" onClick={handleCopy}>
          复制该页
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          type="text"
          onClick={() => {
            onEditPageOpen();
          }}
        >
          编辑该页
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button type="text" onClick={handleRemove}>
          删除该页
        </Button>
      ),
    },
  ];
  if (!isDefault) {
    items.unshift({
      key: '1',
      label: (
        <Button type="text" onClick={handleDefault}>
          设为首页
        </Button>
      ),
    });
  }

  return <Menu items={items} />;
};

const SelectPage: React.FC<{
  id: string;
  pages: Array<{ name: string; id: string; firstPage: boolean }>;
  onChange?: (id: string) => Promise<void>;
  onDefault?: (id: string) => void;
  onRemove?: (id: string) => void;
  onCreate?: (id: string) => void;
  onEditPage?: (data: any) => void;
}> = ({ id, pages, onChange, onDefault, onRemove, onCreate, onEditPage }) => {
  const currentPage = useFormGeneratorStore((state) => state.currentPage);
  const loading = useFormGeneratorStore((state) => state.loading);
  const [editPageForm] = Form.useForm();
  const [editPageModal, setEditPageModal] = useState<boolean>(false);

  const listStore = useListStore((state) => state);
  const { listPageServiceConfig } = listStore;
  useEffect(() => {
    editPageForm.setFieldsValue(currentPage);
  }, [currentPage, editPageForm, listPageServiceConfig, listStore]);

  return (
    <>
      <Select
        value={id}
        style={{ width: 240, left: 10 }}
        onChange={async (option: any) => {
          await onChange?.(option.value);
        }}
        className="fg-tabs-box"
        optionLabelProp="label"
        labelInValue
      >
        {pages.map((page) => {
          return (
            <Select.Option key={page.id} value={page.id} label={page.name}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ flex: 1 }}>
                  {page.name}
                  &nbsp;&nbsp;
                  {page.firstPage ? <Tag color="blue">首页</Tag> : null}
                </div>
                <Dropdown
                  overlay={
                    <PageMenu
                      id={page.id}
                      isDefault={page.firstPage}
                      onDefault={onDefault}
                      onRemove={onRemove}
                      onCreate={onCreate}
                      onEditPageOpen={() => {
                        setEditPageModal(true);
                      }}
                    />
                  }
                >
                  <Button type="text">
                    <MoreOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Select.Option>
          );
        })}
      </Select>
      <Modal
        title="编辑页面"
        visible={editPageModal && !loading}
        onCancel={() => {
          setEditPageModal(false);
        }}
        onOk={async () => {
          const data = await editPageForm.validateFields();
          if (onEditPage) {
            await onEditPage(data);
          }
          setEditPageModal(false);
        }}
      >
        <Form form={editPageForm}>
          <Form.Item name="name" label="页面名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SelectPage;
