import { memo, useEffect, useRef } from 'react';
import useListStore from '@/store/form-generator-list';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import type { ProFormInstance } from '@ant-design/pro-form';
import PickMethodVar from '@/components/PickMethodVar';
import { FieldCmpType } from '@/constant';
import WithCollapse from '../../WithCollapse';

interface IListPaginationProps {
  visible?: boolean;
}

let debounceTimer: any;
const ListPagination = memo<IListPaginationProps>(() => {
  const formRef = useRef<ProFormInstance<any>>();
  const { listPagination, uListPagination, bindedMethods } = useListStore((state) => state);

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        pageSize: String(listPagination?.pageSize || 5),
        pageSizeField: listPagination?.pageSizeField,
        pageNumberField: listPagination?.pageNumberField,
        totalField: listPagination?.totalField,
      });
    }, 20);
  }, [JSON.stringify(listPagination)]);

  const Content = (
    <>
      <FormCreator
        submitter={{ render: () => null }}
        layout="horizontal"
        debug
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 15 }}
        onMounted={(form) => {
          formRef.current = form;
        }}
        onValuesChange={(changedValues: any) => {
          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
          const newValue = {
            pageSize: changedValues?.pageSize ?? listPagination.pageSize,
            pageSizeField: changedValues?.pageSizeField ?? listPagination.pageSizeField,
            pageNumberField: changedValues?.pageNumberField ?? listPagination.pageNumberField,
            totalField: changedValues?.totalField ?? listPagination?.totalField,
          };
          debounceTimer = setTimeout(() => {
            uListPagination(newValue);
          }, 500);
        }}
        config={{
          pageSize: {
            label: '默认条数',
            defaultValue: String(listPagination.pageSize || 5),
            fieldType: EWidgetType.Select,
            fieldProps: {
              options: [
                {
                  label: '5条/页',
                  value: '5',
                },
                {
                  label: '10条/页',
                  value: '10',
                },
                {
                  label: '20条/页',
                  value: '20',
                },
                {
                  label: '100条/页',
                  value: '100',
                },
              ],
            },
          },
          pageSizeField: {
            label: '每页X条',
            fieldType: EWidgetType.CustomWidget,
            defaultValue: listPagination?.pageSizeField ? listPagination?.pageSizeField : undefined,
            custom: (props: any) => (
              <PickMethodVar
                {...props}
                value={listPagination?.pageSizeField || props.value}
                contextField="inputParams"
                methodId={bindedMethods?.loadMethod?.id}
                targetFieldType={FieldCmpType.数字输入框}
                onSelected={(val) => {
                  props?.onChange?.(val);
                }}
              />
            ),
          },
          pageNumberField: {
            label: '第X页',
            fieldType: EWidgetType.CustomWidget,
            defaultValue: listPagination?.pageNumberField
              ? listPagination?.pageNumberField
              : undefined,
            custom: (props: any) => (
              <PickMethodVar
                {...props}
                value={listPagination?.pageNumberField || props.value}
                contextField="inputParams"
                methodId={bindedMethods?.loadMethod?.id}
                targetFieldType={FieldCmpType.数字输入框}
                onSelected={(val) => {
                  props?.onChange?.(val);
                }}
              />
            ),
          },
          totalField: {
            label: '总条数字段',
            fieldType: EWidgetType.CustomWidget,
            defaultValue: listPagination?.totalField ? listPagination?.totalField : undefined,
            custom: (props: any) => (
              <PickMethodVar
                {...props}
                value={listPagination?.totalField || props.value}
                contextField="outputParams"
                methodId={bindedMethods?.loadMethod?.id}
                targetFieldType={FieldCmpType.数字输入框}
                onSelected={(val) => {
                  props?.onChange?.(val);
                }}
              />
            ),
          },
        }}
      />
    </>
  );
  return (
    <WithCollapse
      collapseDatas={[
        {
          key: 'base',
          title: '基础属性',
          configComponent: Content,
        },
      ]}
    />
  );
});
export default ListPagination;
