import React from 'react';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { debounce } from 'lodash';

const ListCheckboxConfigItem: React.FC<{
  // 用于过滤自身，不用于回填
  value: any;
  onChange: (value: any) => void;
}> = ({ value, onChange }) => {
  return (
    <FormCreator
      submitter={{ render: () => null }}
      labelAlign="right"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 15 }}
      debug
      layout="horizontal"
      onValuesChange={debounce((_, values) => {
        onChange?.(values);
      }, 500)}
      config={{
        isBackfills: {
          label: '回填表格',
          tooltip: '是否回填到表格组件',
          defaultValue: value?.isBackfills ?? false,
          fieldType: EWidgetType.Switch,
        },
        backfills: {
          label: '表格列名',
          tooltip: '配置该字段回填到表格时的列命名。',
          fieldType: EWidgetType.Input,
          defaultValue: value.backfills ?? '',
          whenHidden: (formData) => !formData?.isBackfills,
        },
        isMainKey: {
          label: '是否作为主键',
          tooltip: '仅生效设置的第一个',
          defaultValue: value?.isMainKey,
          fieldType: EWidgetType.Switch,
        },
      }}
    />
  );
};

export default ListCheckboxConfigItem;
