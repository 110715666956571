import { useCallback, useState } from 'react';
import Form from './Form';

export default function useJsonParamsModal() {
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    show,
    hide,
    visible,
    Form,
  };
}
