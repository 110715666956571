import React from 'react';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { debounce } from 'lodash';
import { FieldCmpType } from '@/constant';
import { newTransferOptions, transferDefault } from '../helper';
import { showOptions } from '@/types';

// 暂时只有这3种类型，后期待补充
// Temporarily only these 3 types, after looking forward to adding
const TABLEUPLOAD_COLUMN_TYPES = [
  {
    label: '文本类型',
    value: FieldCmpType.输入框,
  },
  {
    label: '日期类型',
    value: FieldCmpType.日期选择器,
  },
  {
    label: '图片类型',
    value: 'image',
  },
];

const TableUploadConfigItem: React.FC<{
  // 用于过滤自身，不用于回填
  value: any;
  onChange: (value: any) => void;
  transformKeysItem: Record<string, any>;
}> = ({ value, onChange, transformKeysItem }) => {
  return (
    <FormCreator
      submitter={{ render: () => null }}
      labelAlign="right"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 15 }}
      debug
      layout="horizontal"
      onValuesChange={debounce((_, values) => {
        onChange?.(values);
      }, 500)}
      config={{
        editing: {
          label: '编辑',
          tooltip: '是否可编辑',
          defaultValue: value?.editing ?? false,
          fieldType: EWidgetType.Switch,
        },
        type: {
          label: '组件类型',
          fieldType: EWidgetType.Select,
          defaultValue: value?.type || '',
          fieldProps: {
            options: TABLEUPLOAD_COLUMN_TYPES,
            allowClear: false,
          },
        },
        showTime: {
          label: '展示格式',
          defaultValue: value?.showTime || 'YYYY-MM-DD',
          whenHidden: (fromData: any) => !['date'].includes(fromData.type),
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: showOptions,
          },
        },
        transferFormat: {
          label: '传值格式',
          tooltip: '数据类型为Date时，请选择时间戳，其余均不生效',
          defaultValue:
            value.transferFormat ||
            transferDefault(value, () => transformKeysItem.dataType === 'Data'),
          fieldType: EWidgetType.Select,
          fieldProps: {
            value:
              transformKeysItem.dataType === 'Data' ? 'x' : value.transferFormat || 'YYYY-MM-DD',
            options: newTransferOptions(value, () => transformKeysItem.dataType === 'Data'),
          },
          whenHidden: (formData: any) => ![FieldCmpType.日期选择器].includes(formData.type),
        },
        transferFormatCustom: {
          label: '自定义',
          defaultValue: value.transferFormatCustom || '',
          whenHidden: (formData: any) => formData.transferFormat !== 'custom',
          fieldType: EWidgetType.Input,
        },
        preFileLink: {
          label: 'cdn前缀',
          defaultValue: value.preFileLink || '',
          whenHidden: (formData: any) => formData.type !== 'image',
          fieldType: EWidgetType.Input,
        },
      }}
    />
  );
};

export default TableUploadConfigItem;
