import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { showOptions } from '@/types';
import { useCustom } from '../../../../hooks/useCustom';

import omit from 'lodash/omit';

import { isDateBindParam, newTransferOptions, transferDefault } from '../helper';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function SingleDatePickerConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const [isCustom, ifCustom] = useCustom(
    fieldInfo,
    'transferFormat',
    fieldInfo?.transferFormatCustom,
  );
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...omit(commonConfig.base.formConfig(fieldInfo, store), ['placeholder']),
            defaultValue: {
              label: '默认值',
              defaultValue: fieldInfo?.defaultValue,
              fieldType: EWidgetType.DatePicker,
              fieldProps: {
                showTime: { format: 'HH:mm:ss' },
                format: 'YYYY-MM-DD HH:mm:ss',
              },
            },
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.({
                defaultValue: undefined,
                ...allVals,
              });
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            showFormat: {
              label: '展示格式',
              defaultValue: fieldInfo?.showFormat || 'YYYY-MM-DD',
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: showOptions,
              },
            },
            transferFormat: {
              label: '传值格式',
              defaultValue: fieldInfo?.transferFormat || transferDefault(fieldInfo),
              fieldType: EWidgetType.Select,
              fieldProps: {
                value: isDateBindParam(fieldInfo) ? 'x' : fieldInfo?.transferFormat || 'YYYY-MM-DD',
                options: newTransferOptions(fieldInfo),
              },
              onChange: ifCustom,
            },
            ...isCustom,
            ...omit(commonConfig.interaction.formConfig(fieldInfo), ['rules']),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
