import React, { memo, useCallback, useState } from 'react';
import { Button, Form, Input, message, Space, Modal, Radio } from 'antd';

import { EJSONType } from '@/types';

const { TextArea } = Input;
const PLACEHOLDER = `请输入json样例数据，比如输入
{
  "a": "A",
  "b": {
    "c": 1,
    "d": true
  }
}
将会解析出以下的字段
-- String：a
-- Object：b
---- Integer: c
---- Boolean: d`;

// 判断是否是json
function isJsonString(str: string) {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true;
    }
  } catch (e) {
    //
  }
  return false;
}

interface TProps {
  visible: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  isCopy?: boolean;
  value?: string;
}

/* JSON推断弹窗 */
const JsonParamsModal = memo<TProps>(
  ({ onClose, onSave, visible = false, isCopy = false, value }) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    if (value) {
      form.setFieldValue('json', value);
    }

    // 保存数据
    const onFinish = useCallback(
      async (data) => {
        if (submitting) {
          return;
        }
        setSubmitting(true);

        if (!isJsonString(data.json)) {
          message.error('JSON格式有误');
          setSubmitting(false);
          return;
        }

        onSave(data);
        setSubmitting(false);
        onClose();
      },
      [onClose, onSave, submitting],
    );

    return (
      <Modal
        title={!isCopy ? '使用JSON推断' : '复制字段'}
        visible={visible}
        footer={null}
        onCancel={onClose}
        destroyOnClose
      >
        <Form
          preserve={false}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          colon={false}
          labelAlign="right"
          initialValues={{
            jsonType: EJSONType.IN_PARAM,
            json: '',
          }}
        >
          {!isCopy && (
            <Form.Item noStyle name="jsonType">
              <Radio.Group>
                <Radio value={EJSONType.IN_PARAM}>入参字段</Radio>
                <Radio value={EJSONType.OUT_PARAM}>出参字段</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            noStyle
            name="json"
            rules={[
              {
                required: true,
                message: `请输入JSON`,
              },
            ]}
          >
            <TextArea
              placeholder={!isCopy ? `${PLACEHOLDER}` : ''}
              autoSize={{ minRows: 5, maxRows: 20 }}
              style={{ marginTop: '20px' }}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Space align="center" size={20}>
              <Button type="primary" htmlType="submit" loading={submitting}>
                {!isCopy ? '立即推断' : '保存'}
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </div>
        </Form>
      </Modal>
    );
  },
);

export default JsonParamsModal;
