import { useEffect, useCallback } from 'react';

enum CONNECT_TYPE {
  RECEIVE_WINDOW_READY = 'receive_window_ready', // the receive data window instance is ready
  DEFINE_WINDOW_ID = 'define_window_id', // define window instance id
  RECEIVE_WINDOW_ID = 'receive_window_id', // receive window instance id
  EXCHANGE_DATA = 'exchange_data', // exchange data
  EXCHANGE_STATE = 'exchange_state', // exchange state
  LOAD = 'load', // window instance load
  UNLOAD = 'unload', // window instance unload
}

const windowInstanceMap = new Map(); // save window instance by windowId
const AUTO_CLEAN_TIMEOUT = 3000;
let windowId: number | undefined;

export function useIframeWindow({
  listenData,
  load,
  unload,
}: {
  listenData?: (data: any, sendMessage: (data: any) => Promise<any>) => void;
  load?: (data: any, sendMessage: (data: any) => Promise<any>) => void;
  unload?: (data: any, sendMessage: (data: any) => Promise<any>) => void;
}) {
  // send message to the window by id
  const sendMessage = useCallback((data) => {
    return new Promise((resolve, reject) => {
      const sourceWindow = windowInstanceMap.get(windowId);

      if (typeof sourceWindow === 'undefined') {
        return reject(new Error('窗体不存在!'));
      }

      const messageId = `m_${Math.floor(Math.random() * 10000)}`;
      function listenFn(event: MessageEvent) {
        const { type, messageId: mid, data: rdata } = event.data;
        if (type === CONNECT_TYPE.EXCHANGE_STATE && mid === messageId) {
          resolve(rdata);
          window.removeEventListener('message', listenFn);
        }
      }
      window.addEventListener('message', listenFn, false);

      setTimeout(() => {
        window.removeEventListener('message', listenFn);
        reject(new Error('窗体反应超时'));
      }, AUTO_CLEAN_TIMEOUT);

      sourceWindow.postMessage(
        {
          type: CONNECT_TYPE.EXCHANGE_DATA,
          windowId,
          messageId,
          data,
        },
        '*',
      );
    });
  }, []);

  useEffect(() => {
    const listen = async (event: MessageEvent) => {
      const { type, windowId: wid } = event.data;
      const sourceWindow = (event?.source ?? null) as Window;

      // the window unload, event.source is null now
      if (type === CONNECT_TYPE.UNLOAD) {
        if (typeof unload === 'function') {
          unload(event.data, sendMessage);
        }
        const deleteWindowId = event.data;
        windowInstanceMap.delete(deleteWindowId);
      }

      if (sourceWindow === null) {
        return;
      }

      // create a new window ID for the window
      if (type === CONNECT_TYPE.RECEIVE_WINDOW_READY) {
        if (windowInstanceMap.get(windowId) !== sourceWindow) {
          windowId = Math.floor(Math.random() * 1000);
          windowInstanceMap.set(windowId, sourceWindow);
        }

        sourceWindow.postMessage(
          {
            type: CONNECT_TYPE.DEFINE_WINDOW_ID,
            data: windowId,
          },
          '*',
        );
      }

      // exchange data for each other
      if (
        type === CONNECT_TYPE.EXCHANGE_DATA &&
        wid === windowId &&
        typeof listenData === 'function'
      ) {
        const result = await listenData(event.data, sendMessage);
        sourceWindow.postMessage(
          {
            type: CONNECT_TYPE.EXCHANGE_STATE,
            windowId: wid,
            messageId: event.data.messageId,
            data: result,
          },
          '*',
        );
      }

      // listen load
      if (type === CONNECT_TYPE.LOAD && typeof load === 'function') {
        load(event.data, sendMessage);
      }
    };
    window.addEventListener('message', listen, false);

    return () => {
      windowInstanceMap.delete(windowId);
      window.removeEventListener('message', listen);
    };
  }, [listenData, load, unload, sendMessage]);

  // send message to all the window instance in windowInstanceMap
  const sendMessageToAll = useCallback((data) => {
    windowInstanceMap.forEach((wid, sourceWindow) => {
      sourceWindow.postMessage(
        {
          type: CONNECT_TYPE.EXCHANGE_DATA,
          windowId: wid,
          data,
        },
        '*',
      );
    });
  }, []);

  return {
    sendMessage,
    sendMessageToAll,
  };
}
