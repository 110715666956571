import { memo, useMemo, useRef, useState, useEffect } from 'react';
import FormCreator, { FCRules } from '@/components/FormCreator';
import type { ProFormInstance } from '@ant-design/pro-form';
import { Row, Space, Button, message, Spin } from 'antd';
import moment from 'moment';
import { EModeType } from '@/store/form-generator-parse';
import { FooterToolbar } from '@ant-design/pro-layout';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import useGlobalStore from '@/store/global';
import _, { cloneDeep, isArray, isString } from 'lodash';
import { parseString2Json } from '@/components/FormGeneractor/help';
import { executeMethod } from '@/services/method';
import { EOptionsFromType, ERuleType, FieldCmpType } from '@/constant';
import { log } from '@/utils/utils';
import useEnumRequest from '../useEnumRequest';
import { formatControlType, validateRequest, checkValueByControlType } from '../helper';
import MultiUpload from '../Widgets/MultiUpload';
import VideoUpload from '../Widgets/VideoUpload';
import ExcelFileUpload from '../Widgets/ExcelFileUpload';
import TableUpload from '../Widgets/TableUpload';
import useCurPageStore from '../useCurPageStore';
import { EPassValueType } from '@/components/ConfigFieldEditor/types';
import SingleFileUpload from '@/components/FormGeneractor/Controls/SingleFileUpload';
import ListSelector from '@/components/FormGeneractor/Controls/ListSelector';
import type { FormCreatorHandle } from '@/components/FormCreator';

import './index.less';
import { Operators } from '@/components/FormGeneractor/ConfigComponent/HiddensConfig';
import { sandbox } from '@/utils/sandbox';
import DisplayComponent from './DisplayComponent';
import { EWidgetType } from '@/components/SchemaView/Widgets/Form/type';
import InputFillControl from '@/components/FormGeneractor/Controls/InputFill';
import ListCheckbox from '@/components/FormGeneractor/Controls/ListCheckbox';
import TextArea from 'antd/lib/input/TextArea';
import useLinkageStore from '@/store/form-generator-linkage';

interface AddEditType {
  pageModeId: string;
}

const AddEdit = memo<AddEditType>(({ pageModeId }) => {
  const fcHelpRef = useRef<FormCreatorHandle | null>(null);
  const [submiting, setSubmiting] = useState(false);
  const [fields, setFields] = useState<any[]>([]);
  const [uploadImgError, setUploadImgError] = useState('');
  const [uploadVideoError, setUploadVideoError] = useState('');
  const globalState = useGlobalStore((state) => state);
  // const [formVals, setFormVals] = useState<Record<string, any>>({})
  const { curPageStore, store } = useCurPageStore(pageModeId);
  const { uEditorVisible, uCurrentDrawerServiceTarget } = store;
  const formRef = useRef<
    ProFormInstance<Record<string, any>> & { setFormData?: (values: Record<string, any>) => void }
  >();
  const { fetchOptions } = useEnumRequest();
  const { addEditViewConfigInfo, addEditViewMethodDetail, configForDetailInit, mode } =
    curPageStore;

  const { cancelButtonConfig, submitButtonConfig, labelColWidth, wrapperColWidth } = useMemo(() => {
    const result: any = {};
    if (!addEditViewConfigInfo?.properties) return result;
    const config: any = parseString2Json(addEditViewConfigInfo?.properties);
    return {
      cancelButtonConfig: config.cancelButtonConfig || {},
      submitButtonConfig: config.submitButtonConfig || {},
      labelColWidth: config.labelColWidth || 200,
      wrapperColWidth: config.wrapperColWidth,
    };
  }, [addEditViewConfigInfo]);

  const resetLinkageStore = useLinkageStore((state) => state.reset);

  // 点击提交
  const handleSubmit = async (vals: any) => {
    const avabileList: any[] = [];
    // 过滤收集有效的数据录入
    // eslint-disable-next-line guard-for-in

    // 将 fields 转为可以通过 controName 取值的对象
    const values: Record<string, any> = {};
    fields.forEach((field) => {
      values[field.controlName] = vals[field.controlId];
    });

    // TODO 这种处理值的模式需要优化
    // eslint-disable-next-line guard-for-in
    for (const contrlId in vals) {
      let value = vals[contrlId];
      const target = fields.find((o: any) => o.controlId === contrlId);

      let defaultFormat = 'YYYY-MM-DD';
      let isDate = false;
      if (Object.prototype.toString.call(target.bindParam).toLowerCase() === '[object array]') {
        isDate =
          target.bindParam[0].bindVar.fieldType === 'Date' ||
          (target.bindParam.length > 1 && target.bindParam[1].bindVar.fieldType === 'Date');
      }

      if (isDate) {
        defaultFormat = 'x';
      }
      if (value && target.controlType === FieldCmpType.多值文本) {
        value = (value as string).split(new RegExp(target.separator ?? '')).filter((o) => o !== '');
      }
      // 日期组件需要对moment格式的时间进行转换
      if (value && target.controlType === FieldCmpType.日期时间) {
        const transferFormat =
          target.transferFormat === 'custom' ? target.transferFormatCustom : target.transferFormat;

        value = moment(
          typeof value === 'string' ? value : value.format(target.showFormat || defaultFormat),
        ).format(transferFormat || defaultFormat);
      }

      // 日期组件需要对moment格式的时间进行转换
      if (value && target.controlType === FieldCmpType.日期区间) {
        const transferFormat =
          target.transferFormat === 'custom' ? target.transferFormatCustom : target.transferFormat;
        for (let i = 0; i < value.length; i++) {
          value[i] = moment(
            typeof value[i] === 'string'
              ? value[i]
              : value[i].format(target.showFormat || defaultFormat),
          ).format(transferFormat || defaultFormat);
        }
      }

      // 多图上传需要额外处理值
      if (typeof value !== 'undefined' && target.controlType === FieldCmpType.多图上传) {
        if (target.isSingle && value.length !== 0) {
          value = value[0];
        }
        if (typeof target.preFileLink !== 'undefined' && target.isFilterPrefix) {
          if (typeof value === 'string') {
            value = value.replace(target.preFileLink, '');
          } else {
            for (let i = 0; i < value.length; i++) {
              value[i] = value[i].replace(target.preFileLink, '');
            }
          }
        }
      }
      if (typeof value !== 'undefined' && target.controlType === FieldCmpType.列表) {
        console.log('列表数据转换target', target);
      }

      // 对值进行表达式转换
      if (target.valueExpression) {
        value = sandbox(`return JSON.stringify(${target.valueExpression})`, { ...values, JSON });
      }

      const bindSbmitMethod = target.bindParam?.find((x: any) => x.type === 'submitMethod');
      if (bindSbmitMethod) {
        avabileList.push({
          bindSbmitMethod,
          fieldId: bindSbmitMethod?.bindVar?.fieldId,
          fieldName: '',
          value,
          controlType: target.controlType,
        });
      }
    }

    if (!addEditViewMethodDetail?.inputParams?.length) {
      return;
    }
    const paramValues = makeParamvalues(
      addEditViewMethodDetail?.inputParams,
      avabileList,
      (passValueConfig) => {
        return _.get(globalState, passValueConfig, '');
      },
    );
    // log('avabileList', avabileList);
    // log('addEditViewMethodDetail', addEditViewMethodDetail);
    // log('paramValues', paramValues);

    // 执行方法
    try {
      setSubmiting(true);
      const res = await executeMethod({
        methodId: addEditViewMethodDetail.id,
        paramValues,
      });
      const properties: any = parseString2Json(addEditViewConfigInfo?.properties ?? '{}');
      if (res.rCode === 0) {
        const resInfo = await validateRequest({
          responseData: res.data,
          methodsConfig: properties?.methodsValidationConfig?.updateMethods,
        });
        log('结果 resInfo：', resInfo);
        if (resInfo.isSuccess) {
          message.success('提交成功');
          uEditorVisible(pageModeId, false);
          uCurrentDrawerServiceTarget(pageModeId, null);
          resetLinkageStore();
        }
      } else {
        message.error(res.msg || '提交失败');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmiting(false);
    }
  };

  const submitter = {
    render: () => (
      <FooterToolbar style={{ width: '100%' }}>
        <Row justify="center" align="middle" className="submit-view">
          <Space>
            <Button
              onClick={() => {
                uCurrentDrawerServiceTarget(pageModeId, null);
                uEditorVisible(pageModeId, false);
                resetLinkageStore();
              }}
            >
              {cancelButtonConfig?.text}
            </Button>
            <Button
              type="primary"
              loading={submiting}
              onClick={() => {
                formRef.current?.validateFields().then(async (vals) => {
                  if (mode === EModeType.production) {
                    handleSubmit(vals);
                  }
                });
              }}
            >
              {submitButtonConfig?.text}
            </Button>
          </Space>
        </Row>
      </FooterToolbar>
    ),
  };

  // 表单配置信息
  const fcConfig = useMemo(() => {
    let result: any = {};
    if (!addEditViewConfigInfo?.components?.length) return result;
    const { components } = cloneDeep(addEditViewConfigInfo);

    // 缓存 id 到 name 的映射，方便快速找到 name
    const idCache: Record<string, string> = {};
    const nameCache: Record<string, string> = {};
    const id2name = (data: any) => {
      return Object.keys(data ?? {}).reduce((re, key) => {
        re[idCache[key]] = data[key];
        return re;
      }, {} as any);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const fields = (components || []).map((o: any) => {
      o.properties = parseString2Json(o.properties);
      const info = {
        ...o.properties,
      };
      idCache[info.controlId] = info.controlName;
      nameCache[info.controlName] = info.controlId;
      return info;
    });
    setFields(fields);

    result = fields.reduce((calc: any, cur: any) => {
      const otherProps = checkValueByControlType(cur, cur.controlType);
      const fieldType = formatControlType(cur.controlType);

      const configInfo: any = {
        label: cur.label,
        fieldType: fieldType ?? EWidgetType.CustomWidget,
        rules: [],
        fieldProps: {
          placeholder: cur.placeholder || '请输入',
          disabled: !!cur.disabled,
        },
        onFill(data: any) {
          formRef.current?.setFieldsValue(data);
        },
        ...otherProps,
        // 纯展示类型
        custom:
          fieldType === null
            ? (props: any) => {
                return (
                  <DisplayComponent
                    item={{ ...cur, value: props.value }}
                    values={id2name(props.formData)}
                  />
                );
              }
            : undefined,
      };
      // tooltip 提示
      if (cur.hasTip && cur.tipContent) {
        configInfo.tooltip = cur.tipContent;
      }

      // 下拉、单选框、复选框 静态options
      if (cur.options?.length && cur.optionsFromType !== EOptionsFromType.动态数据) {
        configInfo.fieldProps.options = cur.options;
      }
      if (mode === EModeType.production && cur.optionsFromType === EOptionsFromType.动态数据) {
        configInfo.request = async () => {
          return await fetchOptions(cur?.dyOptionsInfo);
        };
      }
      if (cur.controlType === FieldCmpType.复选框 && typeof cur.multiSelectMaxCount === 'number') {
        configInfo.rules.push(FCRules.MaxSelectedCount(cur.multiSelectMaxCount));
      }

      // 下拉多选
      if (cur.controlType === FieldCmpType.下拉多选框) {
        configInfo.fieldProps.mode = 'multiple';
        // 最多可选数量控制
        if (typeof cur.multiSelectMaxCount === 'number') {
          configInfo.rules.push(FCRules.MaxSelectedCount(cur.multiSelectMaxCount));
        }
      }

      if (cur.controlType === FieldCmpType.弹窗选择) {
        configInfo.custom = (props: any) => {
          return (
            <ListSelector
              {...props}
              configInfo={cur}
              onFill={(data) => {
                formRef.current?.setFieldsValue(data);
              }}
            />
          );
        };
      }

      if (cur.controlType === FieldCmpType.弹窗多选) {
        configInfo.custom = (props: any) => {
          return <ListCheckbox {...props} configInfo={cur} />;
        };
      }
      if (cur.controlType === FieldCmpType.多值文本) {
        configInfo.custom = (props: any) => {
          return <TextArea {...props} />;
        };
      }

      // 图片上传组件，自定义
      if (cur.controlType === FieldCmpType.多图上传) {
        if (cur.isSingle) {
          cur.maxCount = 1;
        }
        configInfo.custom = (props: any) => (
          <MultiUpload
            {...props}
            configInfo={cur}
            onValidate={(msg) => {
              setUploadImgError(msg);
            }}
          />
        );
        configInfo.validateStatus = uploadImgError ? 'error' : undefined;
        configInfo.help = uploadImgError || undefined;
      }

      // 联动输入
      if (cur.controlType === FieldCmpType.联动输入) {
        configInfo.custom = (props: any) => (
          <InputFillControl
            {...props}
            configInfo={cur}
            values={id2name(props.formData)}
            onFill={(filledData: any) => {
              // 将 name 转为 id 然后设置表单值
              const t = Object.keys(filledData).reduce((target, current) => {
                target[nameCache[current]] = filledData[current];
                return target;
              }, {} as Record<string, string>);
              formRef.current?.setFieldsValue(t);
            }}
          />
        );
      }

      // 单文件上传
      if (cur.controlType === FieldCmpType.单文件上传) {
        configInfo.custom = (props: any) => (
          <SingleFileUpload
            {...props}
            configInfo={cur}
            onValidate={(msg) => {
              setUploadVideoError(msg);
            }}
          />
        );
        configInfo.validateStatus = uploadVideoError ? 'error' : undefined;
        configInfo.help = uploadVideoError || undefined;
      }

      // 表格文件上传
      if (cur.controlType === FieldCmpType.表格文件上传) {
        configInfo.custom = (props: any) => (
          <ExcelFileUpload
            {...props}
            mode={mode}
            configInfo={cur}
            onValidate={(msg) => {
              setUploadImgError(msg);
            }}
          />
        );
        configInfo.validateStatus = uploadImgError ? 'error' : undefined;
        configInfo.help = uploadImgError || undefined;
      }

      // 列表数据上传
      if (cur.controlType === FieldCmpType.列表) {
        configInfo.custom = (props: any) => (
          <TableUpload
            {...props}
            fields={fields}
            mode={mode}
            configInfo={cur}
            onValidate={(msg) => {
              setUploadImgError(msg);
            }}
          />
        );
        configInfo.validateStatus = uploadImgError ? 'error' : undefined;
        configInfo.help = uploadImgError || undefined;
      }

      // 视频
      if (cur.controlType === FieldCmpType.视频上传) {
        configInfo.custom = (props: any) => (
          <VideoUpload
            {...props}
            configInfo={cur}
            onValidate={(msg) => {
              setUploadVideoError(msg);
            }}
          />
        );
        configInfo.validateStatus = uploadVideoError ? 'error' : undefined;
        configInfo.help = uploadVideoError || undefined;
      }

      if (cur.controlType === FieldCmpType.日期时间 || cur.controlType === FieldCmpType.日期区间) {
        configInfo.format = cur.showFormat || 'YYYY-MM-DD';
        // configInfo.format = 'YYYY-MM-DD';
        if (cur.showFormat === 'YYYY-MM-DD HH:mm:ss') {
          configInfo.showTime = { format: 'HH:mm:ss' };
        } else {
          configInfo.showTime = false;
        }
      }

      // 联动显示隐藏
      if (cur.whenHiddens?.targetControlId) {
        configInfo.whenHidden = (formData: any) => {
          // 默认为等于
          const operator = cur.whenHiddens?.triggerTargetControlOperator ?? Operators.Equal;
          const dataString = JSON.stringify(formData[cur.whenHiddens?.targetControlId]);
          const otherIf = dataString === JSON.stringify([]) || dataString === JSON.stringify({});
          if (
            (formData[cur.whenHiddens?.targetControlId] ||
              formData[cur.whenHiddens?.targetControlId] === 0) &&
            cur.whenHiddens?.triggerTargetControlValue &&
            !otherIf
          ) {
            if (operator === Operators.Equal) {
              return (
                formData[cur.whenHiddens?.targetControlId] ===
                cur.whenHiddens?.triggerTargetControlValue
              );
            }
            if (operator === Operators.NotEqual) {
              return (
                formData[cur.whenHiddens?.targetControlId] !==
                cur.whenHiddens?.triggerTargetControlValue
              );
            }
            return false;
          } else {
            const ifNull =
              formData[cur.whenHiddens?.targetControlId] === null ||
              formData[cur.whenHiddens?.targetControlId] === undefined ||
              formData[cur.whenHiddens?.targetControlId] === '' ||
              otherIf;
            if (operator === Operators.EqualNull) {
              return ifNull;
            } else if (operator === Operators.NotEqualNull) {
              return !ifNull;
            }
            return true;
          }
        };
      }

      // 必填
      if (cur.required) {
        configInfo.rules.push(FCRules.Required(cur.requiredMsg));
      }

      // 不可见
      if (cur.noVisible) {
        configInfo.label = undefined;
        configInfo.fieldType = undefined;
        configInfo.whenHidden = () => true;
      }

      // 校验规则
      if (cur.rules?.length) {
        cur.rules.forEach((rule: any) => {
          switch (rule.ruleType) {
            case ERuleType.字符串最大长度:
              configInfo.rules.push(FCRules.Count(rule.maxLength, rule.message));
              break;
            case ERuleType.数值大小范围:
              if (typeof rule.max !== 'undefined') {
                configInfo.rules.push(FCRules.MaxNum(rule.max, rule.message));
                configInfo.fieldProps.max = rule.max;
              }
              if (typeof rule.min !== 'undefined') {
                configInfo.rules.push(FCRules.MinNum(rule.min, rule.message));
                configInfo.fieldProps.min = rule.min;
              }
              break;
            case ERuleType.整数:
              configInfo.rules.push(FCRules.Int(rule.message));
              break;
            case ERuleType.正整数:
              configInfo.rules.push(FCRules.PosInt(rule.message));
              break;
            case ERuleType.不允许特殊字符:
              configInfo.rules.push(FCRules.SpecialStr(rule.message));
              break;
            case ERuleType.小数:
              configInfo.rules.push(FCRules.Decimal(rule.precision, rule.message));
              break;
            case ERuleType.正则:
              configInfo.rules.push(FCRules.Reg(rule.pattern, rule.message));
              break;
            default:
              break;
          }
        });
      }

      if (cur.controlId) {
        calc[cur.controlId] = configInfo;
      }
      return calc;
    }, {});

    return result;
  }, [addEditViewConfigInfo, mode, uploadImgError, uploadVideoError]);

  log('fcConfig', fcConfig);
  log('fields', fields);

  // 编辑，数据回选
  useEffect(() => {
    const { detailHashMap } = configForDetailInit || {};
    if (!detailHashMap) return;
    const result: Record<string, any> = {};
    const nameCache: Record<string, any> = {};
    fields.forEach((n) => {
      nameCache[n.controlName] = n.controlId;
    });
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (!field?.bindParam?.length) continue;
      const bindInfo = field.bindParam.find((o: any) => o.type === 'loadMethod');
      if (!bindInfo) continue;
      const fieldId = bindInfo?.bindVar?.fieldId;
      if (!fieldId) continue;
      let value = detailHashMap[fieldId];
      // 一个组件绑定多个参数值的情况
      // 当前暂时只有日期区间
      // 通过 startTime & endTime 作为 key，但不通用
      // @TODO 通用化需要确定值顺序的方法
      if (bindInfo?.bindVar?.mix) {
        const { startTime, endTime } = bindInfo?.bindVar?.mix;
        value = [detailHashMap[startTime?.fieldId], detailHashMap[endTime?.fieldId]];
      }
      if (typeof value === 'undefined') continue;
      if (Array.isArray(value) && value?.length) {
        value = value.map((o: any) => {
          return typeof o === 'number' ? String(o) : o;
        });
      }

      // 解决：返回 0，没匹配上的问题
      if (
        [FieldCmpType.单选框].includes(field.controlType) &&
        field?.options?.length &&
        !field?.options
          ?.map((x: any) => x.value)
          .includes(typeof value === 'number' ? String(value) : value)
      ) {
        value = field?.options[0].value;
      }

      // 处理单日期 value 为 '' 时显示 Invalid Date 的问题
      if ([FieldCmpType.日期时间].includes(field.controlType) && !value) {
        value = undefined;
      }
      if ([FieldCmpType.日期时间].includes(field.controlType)) {
        value = moment(value, field.transferFormat);
      }
      if (field.controlType === FieldCmpType.日期区间) {
        value = [moment(value[0], field.transferFormat), moment(value[1], field.transferFormat)];
      }
      if ([FieldCmpType.多图上传].includes(field.controlType)) {
        if (isArray(value)) {
          value = value.map((item: string) => {
            if (
              item.indexOf('http://') === -1 &&
              item.indexOf('https://') === -1 &&
              field.preFileLink
            ) {
              return (field.preFileLink as string) + item;
            }
            return item;
          });
        } else if (
          isString(value) &&
          value.indexOf('http://') === -1 &&
          value.indexOf('https://') === -1 &&
          field.preFileLink
        ) {
          value = (field.preFileLink as string) + value;
        }
      }
      result[field.controlId] = typeof value === 'number' ? String(value) : value;

      // 如果有值转换表达式，且值为 JSON，将表达式中的值提取出来，并赋值给对应的输入框
      // 临时的解决方案，后续重新梳理这种解决方案
      if (field.controlType === FieldCmpType.联动输入 && field.valueExpression) {
        //
        try {
          const valueExpression = field.valueExpression.trim();
          const jsonValue: string = value.replace(/'/g, '"');
          if (valueExpression && JSON.parse(jsonValue)) {
            result[field.controlId] = undefined;
            const valueNames: string[] = [];
            const RE = new RegExp(
              valueExpression
                .replace(/\[/g, '\\[')
                .replace(/\]/g, '\\]')
                .replace(/'/g, '"')
                .replace(/:\s*([A-Za-z_$][\d\w_$]*)\s*(?:,|\})/g, (match: string, n: string) => {
                  valueNames.push(n.trim());
                  return ':\\s*(.*?)\\s*(?:,|\\})';
                }),
            );
            const match = jsonValue.match(RE);
            if (match) {
              // 根据匹配出来的值和对应 index 的 key 组装数据
              valueNames.forEach((key, index) => {
                const expression = match[index + 1];
                const expressionValue = sandbox(`return ${expression}`, {});
                if (expressionValue !== undefined || expression !== null) {
                  const id = nameCache[key];
                  if (id) {
                    result[id] = expressionValue;
                  }
                }
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
    log('回选数据 result', result);
    log('回选数据 fields0', fields);
    log('detailHashMap', detailHashMap);

    formRef.current?.setFieldsValue(result);
    fcHelpRef.current?.setFormData?.(result);
  }, [configForDetailInit, fields]);

  return (
    <Spin spinning={submiting}>
      <FormCreator
        debug
        ref={fcHelpRef}
        onMounted={(form) => {
          formRef.current = form;
        }}
        submitter={submitter}
        layout="horizontal"
        validateTrigger={['onBlur', 'onChange']}
        labelCol={{ style: { width: labelColWidth } }}
        wrapperCol={{ style: { flex: wrapperColWidth ? `0 0 ${wrapperColWidth}px` : '1' } }}
        config={fcConfig}
      />
    </Spin>
  );
});

export default AddEdit;

/**
 * 构造 update 接口入参
 * @param inputParams 入参
 * @param avaibleList 收集的表单数据
 * @param getValueByPassValueConfig 前端传值获取
 * @returns
 */
function makeParamvalues(
  inputParams: any[],
  avaibleList: any[],
  getValueByPassValueConfig: (passValueConfig: any) => void,
) {
  const result = [];

  const doRev = (params: any[], excelDataItem?: any): any => {
    const toAdd: any[] = [];
    return params
      .map((param) => {
        if (['Object'].includes(param.dataType)) {
          return {
            paramId: param.id,
            objectValue: doRev(param.fields || []),
          };
        } else if (param.dataType === 'List' && param.genericType === 'Object') {
          const avaibleObj = avaibleList.find((o) => o.fieldId === param.id);
          // 没匹配到的直接返回 null, 后面过滤掉
          if (!avaibleObj) return null;

          // 如果是表格上传的数据，则需要对 avaibleObj.value 逐个解析
          return {
            paramId: param.id,
            listObjectValue: (avaibleObj?.value || []).map((objValue: any) => {
              return doRev(param.fields || [], objValue);
            }),
          };
        } else {
          // 弹窗多选组件，不支持绑定参数字段，所以需要特殊过滤
          let avaibleObj: any = avaibleList.find(
            (o) => o.fieldId === param.id && o.controlType !== FieldCmpType.弹窗多选,
          );
          if (typeof avaibleObj === 'undefined' && typeof excelDataItem !== 'undefined') {
            // excel表格上传的数据，需要特殊处理
            avaibleObj = excelDataItem.find((o: any) => o.fieldId === param.id);
          }
          const mix: Record<string, Record<string, any>> = avaibleObj?.bindSbmitMethod?.bindVar
            ?.mix;
          let value =
            param.dataType === EFieldType.Long
              ? String(avaibleObj?.value || '') || null
              : avaibleObj?.value;

          if (typeof mix !== 'undefined') {
            // 1个组件包含多个参数
            value = avaibleObj.value;
            const other: Array<Record<string, any>> = [];

            // 时间区间的提交逻辑
            if (Array.isArray(value)) {
              for (const [key, obj] of Object.entries(mix)) {
                if (key === 'startTime') {
                  other.push({
                    paramId: obj.fieldId,
                    value: value[0],
                  });
                } else if (key === 'endTime') {
                  other.push({
                    paramId: obj.fieldId,
                    value: value[1],
                  });
                }
              }
            }
            toAdd.push(other[0]);
            return other[1];
          }

          // 前端传值
          if (String(param.passValueType) === EPassValueType.前端传值) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const value = getValueByPassValueConfig(param.passValueConfig);
            return {
              paramId: param.id,
              value: [EFieldType.Long, EFieldType.String].includes(param.dataType as EFieldType)
                ? String(value)
                : value,
            };
          }

          // 布尔值
          if (param.dataType === EFieldType.Boolean && typeof value === 'undefined') {
            value = false;
          }

          // 没匹配到的直接返回 null, 后面过滤掉
          if (!avaibleObj) return null;

          // 【视频上传】 是一个字符串， 要定义一个 List/String 去兼容它
          if (
            param.dataType === EFieldType.List &&
            param.genericType === EFieldType.String &&
            typeof value === 'string'
          ) {
            value = [value];
          }

          //  inputNumber 组件，定义的类型却是 string
          if (param.dataType === EFieldType.String && typeof value === 'number') {
            value = String(value);
          }
          return {
            paramId: param.id,
            value: typeof value === 'undefined' ? null : value,
          };
        }
      })
      .filter((o) => Boolean(o))
      .concat(toAdd);
  };
  console.log('inputParams', inputParams);
  const tmp = doRev(inputParams);

  for (const item of tmp) {
    if (_.isArray(item)) {
      // 将mix值对应的参数平展开
      result.push(...item);
    } else {
      result.push(item);
    }
  }
  return result;
}
