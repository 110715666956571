import React, { memo, useEffect } from 'react';
import { Card, Form, Row, Popconfirm } from 'antd';
import { FieldCmpType, EPageLayoutCmpType, PLACEHOLDER_OPTIONS } from '@/constant';
import DeleteIconButton from '@/components/DeleteIconButton';
import useAddStore from '@/store/form-generator-add';
import useStore from '@/store/form-generator';
import { nanoid } from 'nanoid';
import pick from 'lodash/pick';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import {
  getDefaultLabelByCmpType,
  LeftFixedName,
  formatAddEditDetailConfig,
  IconPlusButton,
} from '../help';
import SubmitView from './SubmitView';
import { findCmpViewByType } from '../PickCmps/helper';

import './index.less';

export const Name = 'add_box';

/**  集中处理 form item */
const FormItem = ({ children, info }: any) => {
  const fgStore = useStore((state) => state);
  const { bindEvents, isFocus } = useEvents(info.controlId);
  const { fields, uFields } = useAddStore((state) => state);
  // 添加组件
  const handlePushField = (fieldCmpType: any, postion: number) => {
    const curControlIndex = fields.findIndex((o) => o.controlId === info.controlId);
    const newField = {
      hide: false,
      label: getDefaultLabelByCmpType(fieldCmpType),
      controlType: fieldCmpType,
      controlId: `${nanoid()}`,
      options: [
        FieldCmpType.下拉多选框,
        FieldCmpType.下拉框,
        FieldCmpType.复选框,
        FieldCmpType.单选框,
      ].includes(fieldCmpType)
        ? PLACEHOLDER_OPTIONS
        : undefined,
    };
    fields.splice(curControlIndex + postion, 0, newField);
    uFields([...fields]);
  };

  return (
    <div {...bindEvents('fg-field-item common-area')} key={info.controlId}>
      {isFocus && (
        <LeftFixedName
          name={`${getDefaultLabelByCmpType(info.controlType)}：${
            info.paramName || info.controlId
          }`}
        />
      )}

      {/* 处理添加组件 删除组件 */}
      {isFocus && (
        <>
          <Row justify="start" align="middle" className="fg-field-toolbar">
            <Popconfirm
              title="确定移除？"
              onConfirm={() => {
                const targetIdx = fields.findIndex((o) => o.controlId === info.controlId);
                if (targetIdx !== -1) {
                  fields.splice(targetIdx, 1);
                  uFields([...fields]);
                }
              }}
            >
              <div className="fg-field-delete">
                <DeleteIconButton color="#fff" />
              </div>
            </Popconfirm>
          </Row>
          <IconPlusButton
            position="top"
            onClick={() => {
              new Promise((resolve) => {
                fgStore.uCmpListPanel({
                  visible: true,
                  resolve,
                });
              }).then((cmp) => handlePushField(cmp, 0));
            }}
          />
          <IconPlusButton
            position="bottom"
            onClick={() => {
              new Promise((resolve) => {
                fgStore.uCmpListPanel({
                  visible: true,
                  resolve,
                });
              }).then((cmp) => handlePushField(cmp, 1));
            }}
          />
        </>
      )}

      {info.isFormItemControl ? (
        <Form.Item
          {...pick(info, ['name', 'hidden', 'label', 'required'])}
          tooltip={info.hasTip && info.tipContent ? info.tipContent : undefined}
        >
          {React.cloneElement(children, {
            onChange(data: any) {
              // 存 value
              let result = data;
              if (data?.target) {
                result = data.target.value;
              }
              fields.forEach((x) => {
                if (x.controlId === info.controlId) {
                  x.value = result;
                }
              });
              uFields([...fields]);
            },
          })}
        </Form.Item>
      ) : (
        children
      )}
    </div>
  );
};

/* 【新增表单】内容区组件 */
const AddBox = memo(() => {
  const { bindEvents, isFocus } = useEvents(Name);
  const [form] = Form.useForm();
  // const { load: loadMethod } = useMethodDetail()
  const addStore = useAddStore((state) => state);
  const fgStore = useStore((state) => state);
  const usedFields = addStore.fields;
  const { uCmpListPanel } = fgStore;

  useEffect(() => {
    if (!addStore.serviceConfig) {
      return;
    }
    // 加载数据
    const initWork = async () => {
      fgStore.uLoading(true);
      const info = await formatAddEditDetailConfig({
        serviceConfig: addStore.serviceConfig,
        method: null,
      });
      addStore.uTitle(info.title || info.name);
      addStore.uLabelColWidth(info.labelColWidth);
      addStore.uWrapperColWidth(info.wrapperColWidth);
      addStore.uCancelButtonConfig(info.cancelButtonConfig);
      addStore.uSubmitButtonConfig(info.submitButtonConfig);
      // 第一次默认
      if (!info.fields.length) {
        info.fields = (addStore.bindedMethods?.submitMethod?.inputParams || []).map((o: any) => ({
          controlType: FieldCmpType.输入框,
          controlId: nanoid(),
          label: o.name,
          used: true,
          bindParam: [
            {
              type: 'submitMethod',
              bindMethod: {
                id: addStore.bindedMethods?.submitMethod?.id,
                name: addStore.bindedMethods?.submitMethod?.name,
              },
              bindVar: {
                fieldName: o.name,
                fieldId: o.id,
              },
              methodParamType: 'inputParams',
            },
          ],
        }));
      }
      addStore.uFields(info.fields);
      addStore.uReady(true);
      fgStore.uCurrentFocusCmp(Name);
      fgStore.uLoading(false);
    };
    initWork();
  }, [addStore.serviceConfig]);

  useEffect(() => {
    form.validateFields();
  }, [JSON.stringify(usedFields)]);

  return (
    <div className="add-box">
      <Card
        title={addStore.title}
        bodyStyle={{ height: 800, overflowY: 'scroll' }}
        {...bindEvents('fg-add-box common-area')}
      >
        {isFocus && <LeftFixedName name="新增页面" />}
        <Form
          labelCol={{ style: { width: addStore.labelColWidth } }}
          form={form}
          validateTrigger={['onChange', 'onBlur']}
          wrapperCol={{
            style: { flex: addStore.wrapperColWidth ? `0 0 ${addStore.wrapperColWidth}px` : '1' },
          }}
        >
          {usedFields.map((item, index) => {
            // const rules: any[] = []

            /* 联动效果 */
            const calcHidden = () => {
              const bool = false;
              if (!item.whenHiddens) return bool;
              const { used, targetControlId, triggerTargetControlValue } = item.whenHiddens;
              if (used && targetControlId && triggerTargetControlValue) {
                const target = usedFields.find((o) => o.controlId === targetControlId);
                if (target?.value === triggerTargetControlValue) {
                  return true;
                }
              }
              return bool;
            };

            const info = {
              ...item,
              name: item.controlId,
              hidden: calcHidden(),
              index,
              isFormItemControl: true,
            };
            /* 根据控件类型展示对应组件 */
            const ControlComponent: any = findCmpViewByType(item.controlType);
            const DisplayControlComponent = (
              <FormItem
                key={info.name}
                info={{
                  ...info,
                  ...(item.controlType === EPageLayoutCmpType.标题
                    ? { isFormItemControl: false }
                    : {}),
                }}
              >
                <ControlComponent
                  key={item.controlId}
                  {...item}
                  configInfo={item}
                  formData={usedFields}
                />
              </FormItem>
            );
            if (!DisplayControlComponent) {
              return null;
            }
            return DisplayControlComponent;
          })}
        </Form>

        {!usedFields.length && (
          <Row style={{ position: 'relative', marginTop: 10 }}>
            <IconPlusButton position="top" onClick={() => uCmpListPanel({ visible: true })} />
          </Row>
        )}
        <div style={{ height: 500 }} />
      </Card>
      <SubmitView isFocus={isFocus} />
    </div>
  );
});

export default AddBox;
