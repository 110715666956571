import React, { useEffect } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { HashRouter } from 'react-router-dom';
import Router from './routers/router';
import GlobalDndContext from '@/components/SortableTable/GlobalDndContext';
import './App.less';

import { securityCheck } from '@/utils/utils';

function App() {
  useEffect(() => {
    if (!securityCheck()) {
      window.location.href = `//${location.host}${location.pathname}`;
    }
    const listenHashChange = () => {
      if (!securityCheck()) {
        window.location.href = `//${location.host}${location.pathname}`;
      }
    };
    window.addEventListener('hashchange', listenHashChange, false);

    return () => {
      window.removeEventListener('hashchange', listenHashChange);
    };
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      {/* <Helmet> */}
      <title>Orca</title>
      {/* </Helmet> */}
      <GlobalDndContext>
        <HashRouter>
          <Router />
        </HashRouter>
      </GlobalDndContext>
    </ConfigProvider>
  );
}

export default App;
