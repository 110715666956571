import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Upload, message, Button } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { token } from '@/utils/utils';
import { HOST } from '@/constant';
import { prefixSystem } from '@/services/common';

interface SingleFileUploadProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
  onValidate?: (errMsg: string) => void;
  onFill?: (values: Record<string, any>) => void;
}

const uploadButton = <Button icon={<UploadOutlined />}>点击上传</Button>;

const fillFileByString = (url: string, idx: number) => ({
  thumbUrl: url,
  name: url,
  uid: url + idx,
  status: 'done',
  response: {
    rCode: 0,
    data: url,
  },
});

const SingleFileUpload = memo<SingleFileUploadProps>(
  ({ onChange, value, configInfo, onValidate, onFill }: SingleFileUploadProps) => {
    const {
      preFileLink = '',
      fileType,
      fileTypeLimit = ['zip'],
      maxCount = 1,
      sizeLimit = false,
      singleFileSize = 2,
      md5Digest,
      sha1Digest,
    } = configInfo;

    const accept = fileTypeLimit
      .map((type: string) => {
        return `.${type}`;
      })
      .join(',');

    const [fileList, setFileList] = useState<any[]>(value ? [value].map(fillFileByString) : []);
    const hasFillRef = useRef(false);

    useEffect(() => {
      // 编辑时第一次进入需从 value 填充值
      // 当 fileList 有值时无需再填充，为了避免没有值但是第一次上传时修改 fileList 导致的异常
      // https://github.com/ant-design/ant-design/issues/2423
      if (!hasFillRef.current && value !== undefined && fileList.length <= 0) {
        hasFillRef.current = true;
        setFileList(value ? [value].map(fillFileByString) : []);
      }
    }, [value]);

    useEffect(() => {
      if (fileList.length > 0) {
        onChange?.(fileList.map((o: any) => o?.thumbUrl || '')[0]);
      }
    }, [fileList]);

    // 监听图片上传
    const onFileChange = useCallback(
      ({ fileList: newFileList, file }) => {
        newFileList.forEach((item: UploadFile) => {
          if (
            item &&
            item.status == 'done' &&
            item.response &&
            item.response.data &&
            preFileLink.length > 0
          ) {
            const { path, md5, sha1 } = item.response.data;
            item.thumbUrl = `${preFileLink}${path}`;

            if (onFill) {
              const filledData: Record<string, string> = {};
              if (md5Digest && md5) {
                (md5Digest || []).forEach((key: string) => {
                  filledData[key] = md5;
                });
              }
              if (sha1Digest && sha1) {
                (sha1Digest || []).forEach((key: string) => {
                  filledData[key] = sha1;
                });
              }
              onFill(filledData);
            }
          }
        });

        const list = newFileList.filter((item: UploadFile) => {
          if (['uploading', 'success', 'removed'].includes(item.status as string)) {
            return true;
          }
          if (item.status == 'done' && item.response.rCode == 0) {
            return true;
          }
          return false;
        });

        setFileList([...list]); // 阻止上传的图片，不存入fileList

        const { status, response } = file;

        switch (status) {
          case 'error':
            message.error('上传失败');
            break;
          case 'done':
            if (response.rCode !== 0) {
              message.error('上传失败');
              return;
            }
            message.success('上传成功');
            break;
        }
      },
      [preFileLink],
    );

    // 限制图片 格式、size、分辨率
    const handleBeforeUpload = useCallback(
      (file) => {
        if (preFileLink.length < 1) {
          onValidate?.('请在交互设置中填写“图片资源的url前缀”');
          return false;
        }

        if (typeof fileType === 'undefined') {
          onValidate?.('请在交互设置中填写“内部上传编码”');
          return false;
        }

        const isLowerLimit = file.size / 1024 / 1024 < singleFileSize; // 图片*m大小的限制
        if (sizeLimit && !isLowerLimit) {
          onValidate?.(`超过${singleFileSize}M限制，不允许上传~`);
          return false;
        }
        return true;
      },
      [preFileLink, fileType, singleFileSize, sizeLimit],
    );

    return (
      <Upload
        accept={accept}
        action={HOST + prefixSystem('upload/file')}
        data={{
          fileType,
        }}
        headers={{
          'X-SSO-Authorization': token as string,
        }}
        fileList={fileList}
        maxCount={maxCount}
        onChange={onFileChange}
        beforeUpload={handleBeforeUpload}
        withCredentials
      >
        {uploadButton}
      </Upload>
    );
  },
);

export default SingleFileUpload;
