import { Typography } from 'antd';
import HelpText from '@/components/HelpText';

interface TitleProps {
  config: any;
}
const Title = ({ config }: TitleProps) => {
  const { titleName, hasTip, tipContent } = config;
  return (
    <Typography.Title level={4}>
      {titleName}
      {hasTip && tipContent && <HelpText toolTip={tipContent} />}
    </Typography.Title>
  );
};

export default Title;
