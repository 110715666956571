import request from '@/utils/request';
import type { TReturn, ListPageConfig } from '@/types';
import { prefixListPage, prefixFormPage } from './common';
import type { EPageType } from '@/constant';

// 列表页面配置 - 新建
export const createListPageConfig = (data: ListPageConfig) => {
  return request.post<TReturn<any>>(prefixListPage('create'), {
    data,
  });
};

// 列表页面配置  - 更新
export const updateListPageConfig = (data: ListPageConfig & { id: string }) => {
  return request.post<TReturn<any>>(prefixListPage('update'), {
    data,
  });
};

// 列表页面配置  - 更新
export const getListPageConfig = (id: string, published = false) => {
  return request.get<TReturn<ListPageConfig>>(prefixListPage('get'), {
    params: { id, published },
  });
};

// 新增/编辑/查询页面配置 - 新建
export const createFormPageConfig = (data: any, type: EPageType) => {
  return request.post<TReturn<any>>(prefixFormPage('create'), {
    data: {
      ...data,
      type,
    },
  });
};

// 新增/编辑/查询页面配置 - 查询
export const getFormPageConfig = (id: any, published = false) => {
  return request.get<TReturn<any>>(prefixFormPage('get'), {
    params: { id, published },
  });
};

// 新增/编辑/查询页面配置 - 更新
export const updateFormPageConfig = (data: any, type: EPageType) => {
  return request.post<TReturn<any>>(prefixFormPage('update'), {
    data: {
      ...data,
      type,
    },
  });
};
