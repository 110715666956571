import React, { memo, useCallback, useState } from 'react';
import { Button, Form, Input, message, Space, Modal } from 'antd';

import { getDCParams } from '@/services/method';
import type { TGetDCParamsReturn } from '@/services/method';

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 7,
    offset: 0,
  },
};

export type DCParams = TGetDCParamsReturn & {
  serviceName: string;
  methodName: string;
  groupId: string;
  artifactId: string;
  version: string;
};

interface TInitValue {
  serviceName?: string;
  methodName?: string;
  groupId?: string;
  artifactId?: string;
  version?: string;
  protocolType?: string;
}
export interface TProps {
  visible: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  initValue: TInitValue;
}

/* 坐标推断弹窗 */
const DCParamsModal = memo<TProps>(({ onClose, onSave, visible = false, initValue }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const initialValues = {
    serviceName: initValue?.serviceName ?? '',
    methodName: initValue?.methodName ?? '',
    groupId: initValue?.groupId ?? '',
    artifactId: initValue?.artifactId ?? '',
    version: initValue?.version ?? '',
  };

  // 保存数据
  const onFinish = useCallback(
    async (data) => {
      if (submitting) {
        return;
      }
      data.protocolType = initValue?.protocolType;
      setSubmitting(true);
      const result = await getDCParams(data).catch((reason) => {
        message.error(reason || '接口错误');
        setSubmitting(false);
      });
      if (typeof result === 'undefined') {
        return;
      }

      if (result.rCode !== 0) {
        setSubmitting(false);
        message.error(result.msg);
        return;
      }

      const values = form.getFieldsValue();

      onSave({
        ...result.data,
        methodName: values.methodName,
        serviceName: values.serviceName,
        groupId: values.groupId,
        artifactId: values.artifactId,
        version: values.version,
      });

      setSubmitting(false);
      onClose();
    },
    [onClose, onSave, submitting, form, initValue],
  );

  return (
    <Modal title="使用坐标推断" visible={visible} footer={null} onCancel={onClose}>
      <Form
        {...formItemLayout}
        name="basic"
        layout="horizontal"
        onFinish={onFinish}
        form={form}
        colon={false}
        labelAlign="right"
        initialValues={initialValues}
      >
        <Form.Item
          label="DC接口全类名"
          name="serviceName"
          rules={[
            {
              required: true,
              message: `请输入DC接口全类名`,
            },
          ]}
        >
          <TextArea placeholder="请输入DC接口全类名" autoSize={{ minRows: 1, maxRows: 15 }} />
        </Form.Item>
        <Form.Item
          label="DC接口方法"
          name="methodName"
          rules={[
            {
              required: true,
              message: `请输入DC接口方法`,
            },
          ]}
        >
          <TextArea placeholder="请输入DC接口方法" autoSize={{ minRows: 1, maxRows: 15 }} />
        </Form.Item>
        <Form.Item
          label="groupId"
          name="groupId"
          rules={[
            {
              required: true,
              message: `请输入DC接口所属jar包的maven groupId`,
            },
          ]}
        >
          <TextArea
            placeholder="请输入DC接口所属jar包的maven groupId"
            autoSize={{ minRows: 1, maxRows: 15 }}
          />
        </Form.Item>
        <Form.Item
          label="artifactId"
          name="artifactId"
          rules={[
            {
              required: true,
              message: `请输入DC接口所属jar包的maven artifactId`,
            },
          ]}
        >
          <TextArea
            placeholder="请输入DC接口所属jar包的maven artifactId"
            autoSize={{ minRows: 1, maxRows: 15 }}
          />
        </Form.Item>
        <Form.Item
          label="version"
          name="version"
          rules={[
            {
              required: true,
              message: `请输入DC接口所属jar包的maven版本号`,
            },
          ]}
        >
          <TextArea
            placeholder="请输入DC接口所属jar包的maven版本号"
            autoSize={{ minRows: 1, maxRows: 15 }}
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Space align="center" size={20}>
            <Button type="primary" htmlType="submit" loading={submitting}>
              立即推断
            </Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
});

export default DCParamsModal;
