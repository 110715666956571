import create from 'zustand';

interface StoreType {
  // SSO 登录用户信息
  user?: {
    useId: string;
    account: string;
    realName: string;
    email: string;
    userType: string;
  };
  uUser: (...args: any[]) => void;
}

const useStore = create<StoreType>((set) => ({
  uUser: (val) =>
    set(() => ({
      user: val,
    })),
}));

export default useStore;
