import useMethodDetail from '@/hooks/useMethodDetail';
import { executeMethod } from '@/services/method';
import { findInExecuteMethodParam, isRequestSuccess } from './helper';
import { message } from 'antd';

interface FiledItemType {
  fieldName: string;
  fieldId: string;
}

const cacheResultOptions: Record<string, any> = {};

// 处理动态枚举的请求
export default function useEnumRequest() {
  const { load: loadDetail } = useMethodDetail();

  const fetchOptions = async (dyOptionsInfo: {
    method: {
      id: string;
      name: string;
    };
    listField: FiledItemType;
    labelField: FiledItemType;
    valueField: FiledItemType;
  }) => {
    try {
      // 避免重复加载
      if (cacheResultOptions[JSON.stringify(dyOptionsInfo)]) {
        return cacheResultOptions[JSON.stringify(dyOptionsInfo)];
      }
      const methodId = dyOptionsInfo?.method?.id;
      if (
        !methodId ||
        !dyOptionsInfo.listField ||
        !dyOptionsInfo.labelField ||
        !dyOptionsInfo.valueField
      )
        return [];
      const method = await loadDetail(methodId);
      const executeRes = await executeMethod({
        methodId,
        // NOTE: 以后可能要支持入参
        paramValues: [],
      });
      if (executeRes.rCode !== 0) {
        message.error(executeRes.msg || executeRes.message || '查询失败');
        return;
      }
      const responseData = executeRes.data;

      const validateRes = isRequestSuccess({
        responseData,
        outputParams: method?.outputParams || [],
      });
      console.log('useEnumRequest', validateRes);
      let options: Array<{ label: string; value: string }> = [];

      // 获取数据成功后的处理
      if (validateRes.isSuccess && validateRes.dataTarget) {
        const { objectValue } = validateRes.dataTarget;
        if (objectValue?.length) {
          // 匹配到 list 层级的绑定字段
          const listFieldLevel = findInExecuteMethodParam(objectValue, (o: any) => {
            return o.paramId === dyOptionsInfo.listField.fieldId;
          });
          if (listFieldLevel && listFieldLevel?.listObjectValue?.length) {
            // 匹配 label 、 value 并返回值
            options = listFieldLevel?.listObjectValue
              .map((item: any) => {
                const labelValue = Array.isArray(item)
                  ? item.find((x) => x.paramId === dyOptionsInfo.labelField.fieldId)?.value
                  : null;
                const valueValue = Array.isArray(item)
                  ? item.find((x) => x.paramId === dyOptionsInfo.valueField.fieldId)?.value
                  : null;
                if (valueValue && labelValue) {
                  return {
                    label: String(labelValue),
                    value: String(valueValue),
                  };
                } else {
                  return null;
                }
              })
              .filter((y: any) => Boolean(y));
          }
        }
      }
      if (options.length) {
        cacheResultOptions[JSON.stringify(dyOptionsInfo)] = options;
      }
      return options;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    fetchOptions,
  };
}
