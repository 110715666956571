import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Table,
  Row,
  Col,
  message,
  Button,
  Card,
  Collapse,
  Form,
  Input,
  Space,
  Popconfirm,
} from 'antd';
import FormCreator, { FCRules, EWidgetType } from '@/components/FormCreator';
import { defaultColumns } from '../helper';
import type { ProFormInstance } from '@ant-design/pro-form';
import RegioEnvSelector from '@/components/RegioEnvSelector';
import { clone } from 'lodash';
import { getBusinessTables, getTableStructs } from '@/services/method';
import { TStructFields } from '@/types';

const { Panel } = Collapse;
const { TextArea } = Input;
const FormItem = Form.Item;

const cardstyle = { height: '600px', overflow: 'scroll' };

interface Tprops {
  visible: boolean;
  isEditor: boolean;
  submit?: (data: TReturnData) => void;
  handleCancel?: () => void;
  formRef: any;
}

interface TReturnData {
  mysqlConfig: Object;
}

type Tables = Array<{
  tableName: string;
  structFields: TStructFields[];
}>;

const HandEditingModal: React.FC<Tprops> = ({
  visible,
  isEditor,
  submit,
  formRef,
  handleCancel,
}) => {
  const oneClickFormRef = useRef<ProFormInstance<Record<string, any>>>();
  const [dataSource, setDataSource] = useState<Tables>([]);
  const [sqlForm] = Form.useForm();

  const isFirstReturn = useRef(true);
  const isSecond = useRef(false);

  useEffect(() => {
    // 表单渲染
    if (visible) {
      const { mysqlConfig } = formRef?.current?.getFieldsValue();
      if (mysqlConfig.generateSql) {
        isSecond.current = true;
        const newData = {
          mysqlConfig: {
            mysqlNamespace: mysqlConfig.mysqlNamespace,
            region_businessEnv: mysqlConfig.region_businessEnv,
            tableData: mysqlConfig.tableData,
          },
        };
        oneClickFormRef.current?.setFieldsValue(clone(newData));
        sqlForm.setFieldsValue({ text: mysqlConfig.generateSql });
        if (isFirstReturn.current) {
          getTableDatas();
          getTableStructsData();
          isFirstReturn.current = false;
        }
      } else {
        isSecond.current = false;
      }
    }
  }, [visible]);

  const [tableOptions, setTableOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const getTableDatas = async () => {
    const params = await oneClickFormRef.current
      ?.validateFields([
        ['mysqlConfig', 'region_businessEnv'],
        ['mysqlConfig', 'mysqlNamespace'],
      ])
      .then((formData) => {
        setSearchLoading(true);
        const { mysqlConfig } = clone(formData);
        const { region_businessEnv, mysqlNamespace } = mysqlConfig;
        const data = {
          regionCode: region_businessEnv.region,
          businessEnvCode: region_businessEnv.businessEnv,
          namespace: mysqlNamespace,
        };
        return data;
      });
    if (params === undefined) return;
    const res = await getBusinessTables(params);
    if (res.rCode === 0) {
      if (res.data?.tableNames === undefined) return;
      const arr = [...res.data?.tableNames];

      setTableOptions(
        arr.map((item) => {
          return {
            label: item,
            value: item,
          };
        }),
      );
    } else {
      message.error(res.msg || '获取失败');
    }
    setSearchLoading(false);
  };
  const getTableStructsData = async () => {
    const params = await oneClickFormRef.current?.validateFields().then((formData) => {
      const { mysqlConfig } = clone(formData);
      const { region_businessEnv, mysqlNamespace, tableData } = mysqlConfig;
      const data = {
        regionCode: region_businessEnv.region,
        businessEnvCode: region_businessEnv.businessEnv,
        namespace: mysqlNamespace,
        tables: tableData,
      };
      return data;
    });
    if (params === undefined) return;
    const res = await getTableStructs(params);
    if (res.rCode === 0) {
      if (res.data?.tables) {
        setDataSource([...res.data?.tables]);
      }
    } else {
      message.error(res.msg || '获取失败');
    }
  };

  const config = {
    'mysqlConfig.region_businessEnv': {
      label: '区域/业务环境',
      fieldType: EWidgetType.CustomWidget,
      rules: [FCRules.Required()],
      custom: (props: any) => <RegioEnvSelector {...props} />,
    },
    'mysqlConfig.mysqlNamespace': {
      label: 'namespace',
      fieldType: EWidgetType.Input,
      rules: [FCRules.Required()],
      fieldProps: {
        onChange() {
          oneClickFormRef.current?.setFieldValue(['mysqlConfig', 'tableData'], undefined);
          setTableOptions([]);
        },
        addonAfter: (
          <Button type="link" loading={searchLoading} onClick={getTableDatas}>
            获取数据表
          </Button>
        ),
      },
    },
    'mysqlConfig.tableData': {
      label: '数据表',
      fieldType: EWidgetType.Select,
      rules: [FCRules.Required()],
      mode: 'multiple',
      fieldProps: {
        options: tableOptions,
        showSearch: true,
        onChange() {
          getTableStructsData();
        },
        getPopupContainer(triggerNode: any) {
          return triggerNode.parentNode;
        },
      },
    },
  };

  const clear = () => {
    oneClickFormRef.current?.resetFields();
    sqlForm.resetFields();
    setDataSource([]);
    setTableOptions([]);
    isFirstReturn.current = true;
  };
  const onOk = async () => {
    const formData = await oneClickFormRef.current?.validateFields().then((data) => data);
    if (formData === undefined) return;
    const configData = formRef.current?.getFieldsValue();
    const { text: sql } = await sqlForm.validateFields();
    if (!sql) return;
    const newData = {
      ...configData.mysqlConfig,
      ...formData.mysqlConfig,
      generateSql: sql,
    };
    if (submit) {
      submit({ mysqlConfig: clone(newData) });
    }
    clear();
  };
  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    }
    clear();
  };

  return (
    <Modal
      title={isEditor ? '查看脚本' : '手工编辑SQL脚本'}
      width="100%"
      visible={visible}
      footer={
        <Row justify="end">
          <Space>
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              取消
            </Button>
            {isSecond ? (
              <Button
                hidden={isEditor}
                type="primary"
                onClick={() => {
                  onOk();
                }}
              >
                确定
              </Button>
            ) : (
              <Popconfirm title="请问是否保存" onConfirm={onOk} okText="确认" cancelText="取消">
                <Button type="primary" hidden={isEditor}>
                  确定
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Row>
      }
      onCancel={onCancel}
    >
      <Row>
        <Col span={10}>
          <Card style={cardstyle}>
            <FormCreator
              debug
              onMounted={(form) => {
                oneClickFormRef.current = form;
              }}
              submitter={false}
              labelCol={{ span: 6 }}
              layout="horizontal"
              config={config}
              readonly={isEditor}
            />
            <Collapse>
              {dataSource.map((item) => {
                return (
                  <Panel header={item.tableName} key={item.tableName}>
                    <Table
                      key={item.tableName}
                      columns={defaultColumns}
                      dataSource={item.structFields}
                      pagination={false}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={cardstyle}>
            <Form form={sqlForm} name="sql" layout="vertical" disabled={isEditor}>
              <FormItem
                name="text"
                label={isEditor ? '' : '请参考左侧数据表，编写脚本'}
                rules={[{ required: true, message: '请编写脚本' }]}
              >
                <TextArea rows={23} />
              </FormItem>
            </Form>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default HandEditingModal;
