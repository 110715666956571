import { memo, useMemo, useCallback } from 'react';
import useFormGeneratorStore from '@/store/form-generator';
import { Empty } from 'antd';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useDetailStore from '@/store/form-generator-detail';
import { EPageType, NoNeedBindParam } from '@/constant';
import { findCmpConfigViewByType } from './PickCmps/helper';

// 列表
import ListContent, { Name as ListBoxName } from './ListContent';
import { Name as ListHeaderName } from './ListContent/Header';
import { Name as ListSearchName } from './ListContent/Search';
import { Name as ListPaginationName } from './ListContent/Pagination';
import { Name as ListTableName } from './ListContent/Table';

import ListBoxConfig from './ConfigComponent/ListBox';
import ListHeaderConfig from './ConfigComponent/ListHeader';
import ListSearchConfig from './ConfigComponent/ListSearch';
import ListPagination from './ConfigComponent/ListPagination';
import ListTable from './ConfigComponent/ListTable/index';

// 添加
import AddContent from './AddContent';
import { Name as AddBoxName } from './AddContent/index';
import AddBoxConfig from './ConfigComponent/AddBox';

// 编辑
import EditContent from './EditContent';
import { Name as EditBoxName } from './EditContent/index';
import EidtBoxConfig from './ConfigComponent/EditBox';

// 查看
import ViewContent from './ViewContent';
import { Name as ViewBoxName } from './ViewContent/index';
import ViewBoxConfig from './ConfigComponent/ViewBox';

import './Content.less';
import { omit } from 'lodash';

const Content = memo(() => {
  const { currentPage, currentFocusCmp } = useFormGeneratorStore((state) => state);
  const addStore = useAddStore((state) => state);
  const editStore = useEditStore((state) => state);
  const detailStore = useDetailStore((state) => state);

  const ContentView = useMemo(() => {
    switch (String(currentPage?.type)) {
      case EPageType.列表页面:
        return <ListContent />;
      case EPageType.新增页面:
        return <AddContent />;
      case EPageType.编辑页面:
        return <EditContent />;
      case EPageType.查看页面:
        return <ViewContent />;
      default:
        return null;
    }
  }, [currentPage]);

  const getStyleByName = useCallback(
    (name: string) => {
      return {
        display: currentFocusCmp === name ? 'block' : 'none',
      };
    },
    [currentFocusCmp],
  );

  const pageType = String(currentPage?.type);

  const storeMap = {
    [`${EPageType.新增页面}`]: addStore,
    [`${EPageType.编辑页面}`]: editStore,
    [`${EPageType.查看页面}`]: detailStore,
  };

  const handleFieldItem = useCallback(
    (field: any, index: number) => {
      const EmptyPlaeholder = <Empty description="待开发" style={{ marginTop: 50 }} />;
      const commonProps = {
        fieldInfo: field,
        index,
        pageType,
        store: storeMap?.[pageType],
        onChange(data: any) {
          if (storeMap?.[pageType]?.fields?.[index]) {
            const oldData = NoNeedBindParam.includes(storeMap[pageType].fields[index].controlType)
              ? omit(storeMap?.[pageType]?.fields?.[index], 'bindParam')
              : storeMap?.[pageType]?.fields?.[index];
            storeMap[pageType].fields[index] = {
              ...oldData,
              ...(data || {}),
            };
            storeMap[pageType].uFields([...storeMap[pageType].fields]);
          }
        },
      };
      const ConfigView = findCmpConfigViewByType(field?.controlType);
      const ConfigViewCmp = ConfigView ? <ConfigView {...commonProps} /> : null;
      return currentFocusCmp === field.controlId ? (
        <div key={field.controlId}>{ConfigViewCmp || EmptyPlaeholder}</div>
      ) : null;
    },
    [storeMap, pageType, currentFocusCmp],
  );

  const ConfigView = useMemo(() => {
    /* 列表页的配置 */
    const ListWrapper = (
      <>
        <div style={getStyleByName(ListBoxName)} key="ListBoxConfig">
          {/* 整体配置 */}
          <ListBoxConfig />
        </div>
        <div style={getStyleByName(ListHeaderName)} key="ListHeaderConfig">
          {/* 头部配置 */}
          <ListHeaderConfig />
        </div>
        <div style={getStyleByName(ListSearchName)} key="ListSearchConfig">
          {/* 搜索区配置 */}
          <ListSearchConfig />
        </div>
        <div style={getStyleByName(ListTableName)} key="ListTable">
          {/* 表格区配置 */}
          <ListTable />
        </div>
        <div style={getStyleByName(ListPaginationName)} key="ListPagination">
          {/* 分页配置 */}
          <ListPagination />
        </div>
      </>
    );

    /* 新增页的配置 */
    const AddWrapper = (
      <>
        <div style={getStyleByName(AddBoxName)} key="AddBoxConfig">
          {/* 新增表单 - 整体配置 */}
          <AddBoxConfig />
        </div>
        {/* 新增表单 - 每个控件配置 */}
        {(addStore?.fields || []).map(handleFieldItem)}
      </>
    );

    /* 编辑页的配置 */
    const EditWrapper = (
      <>
        <div style={getStyleByName(EditBoxName)} key="AddBoxConfig">
          {/* 新增表单 - 整体配置 */}
          <EidtBoxConfig />
        </div>
        {/* 编辑表单 - 每个控件配置 */}
        {(editStore?.fields || []).map(handleFieldItem)}
      </>
    );

    /* 查看页的配置 */
    const ViewWrapper = (
      <>
        <div style={getStyleByName(ViewBoxName)} key="ViewBoxConfig">
          {/* 新增表单 - 整体配置 */}
          <ViewBoxConfig />
        </div>
        {/* 查看项目 - 每个控件配置 */}
        {(detailStore?.fields || []).map(handleFieldItem)}
      </>
    );
    switch (pageType) {
      case EPageType.列表页面:
        return ListWrapper;
      case EPageType.新增页面:
        return AddWrapper;
      case EPageType.编辑页面:
        return EditWrapper;
      case EPageType.查看页面:
        return ViewWrapper;
      default:
        return null;
    }
  }, [addStore, getStyleByName, editStore, detailStore, pageType, handleFieldItem]);

  return (
    <div className="fg-content">
      <div
        className="fg-body"
        style={{
          padding: 24,
        }}
      >
        {/* 左侧组件展示区 */}
        {ContentView}
      </div>
      <div className="fg-config-area">
        {/* 右侧配置区域 */}
        {ConfigView}
        <div style={{ height: 50 }} />
      </div>
    </div>
  );
});

export default Content;
