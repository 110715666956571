import React, { memo, ReactNode } from 'react';
import { Form, Typography } from 'antd';
import { pickWidget } from './helper';
import omit from 'lodash/omit';
import { EWidgetType } from './type';
import type { FormCreatorConfigItem } from './type';
import HelpText from '../HelpText';
import WidgetItem from './WidgetItem';
import { useStore } from './FContext';

// 用于处理自定义渲染方法
const CustomWidget: React.FC<{ render: (props: any) => ReactNode } & any> = ({
  render,
  ...props
}) => {
  return render(props);
};

export default memo<FormCreatorConfigItem & { fieldName: string }>((props) => {
  const { formData, readonly } = useStore();
  const { fieldName, fieldType, defaultValue, custom, whenHidden, ...formItemProps } = props;
  const WidgetComponent: any = pickWidget(fieldType);
  const isHide = typeof whenHidden === 'function' ? whenHidden(formData) : false;
  const exclueSearchSelectkeysProps = omit(formItemProps, [
    'requestOptions',
    'onSearchSelected',
    'getOptionById',
    'tableProps',
    'selectKey',
    'selectName',
  ]);

  const name = fieldName.split('.');

  // 隐藏的组件，直接不渲染，不然，会存在有效值检测问题
  if (isHide) {
    return null;
  }

  if (WidgetComponent) {
    const widgetProps = isHide ? omit(formItemProps, ['rules']) : formItemProps;
    return (
      <WidgetComponent
        {...omit(widgetProps, [
          'getDynamicFieldProps',
          'requestOptions',
          'onSearchSelected',
          'getOptionById',
          'tableProps',
          'selectKey',
          'selectName',
        ])}
        key={fieldName}
        name={name}
        hidden={isHide}
      />
    );
  } else {
    if (fieldType === EWidgetType.Title) {
      return (
        <Typography.Title
          level={4}
          {...formItemProps.fieldProps}
          style={{
            borderBottom: '1px solid #ccc',
            padding: '10px 0',
            marginBottom: 20,
          }}
        >
          {formItemProps.label || '标题'}{' '}
          {formItemProps.tooltip && <HelpText toolTip={formItemProps.tooltip} />}
        </Typography.Title>
      );
    }
    if (fieldType === EWidgetType.Text) {
      return (
        <Form.Item {...omit(exclueSearchSelectkeysProps, ['fieldProps'])}>
          <Typography.Text {...formItemProps.fieldProps}>{defaultValue || '文案'}</Typography.Text>
        </Form.Item>
      );
    }
    let node: ReactNode = null;

    if ([EWidgetType.Title, EWidgetType.RichText].includes(fieldType)) {
      node = <WidgetItem key={fieldName} {...formItemProps} fieldType={fieldType} />;
    } else if (custom) {
      node = (
        <CustomWidget
          {...exclueSearchSelectkeysProps}
          readOnly={readonly}
          formData={formData}
          render={custom}
        />
      );
    }

    return (
      <Form.Item
        {...omit(exclueSearchSelectkeysProps, ['fieldProps'])}
        key={fieldName}
        name={name}
        hidden={isHide}
      >
        {node}
      </Form.Item>
    );
  }
});
