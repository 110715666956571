import { memo } from 'react';
import { DatePicker } from 'antd';
import pick from 'lodash/pick';

interface DatePickerControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const DatePickerControl = memo<DatePickerControlProps>(({ onChange, value, configInfo }) => {
  return (
    <DatePicker
      {...pick(configInfo, ['disabled', 'placeholder'])}
      value={value}
      defaultValue={configInfo.defaultValue}
      onChange={onChange}
    />
  );
});

export default DatePickerControl;
