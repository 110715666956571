import { EWidgetType } from '@/components/FormCreator';
import {
  FieldControls,
  DataDisplayCmpTypes,
  EDataDisplayCmpType,
  EOptionsFromType,
  OptionsFromTypes,
} from '@/constant';
import EnumsEditor from './common/EnumsEditor';
import DyEnumsEditor from './common/DyEnumsEditor';
import BindParam from './common/BindParam';

/* const getStoreDyMethodOptions = () => {
  return new Promise<any>(resolve => {
    unstable_batchedUpdates(() => {
      resolve(
        getDyEnumOptions(useStore.getState().dyMethods)
      )
    })
  })
} */

/** 表单项公共配置 */
export default () => ({
  base: {
    title: '基础属性',
    key: 'base-config',
    formProps: {
      submitter: { render: () => null },
      labelAlign: 'right',
      labelCol: { style: { width: 90 } },
      debug: true,
      layout: 'horizontal',
    },
    formConfig(fieldInfo: any, store?: any) {
      const result = {
        controlId: {
          label: '组件ID',
          defaultValue: fieldInfo?.controlId || '',
          fieldType: EWidgetType.Text,
        },
        bindParam: {
          label: '绑定参数',
          tooltip: '设置当前组件关联对应方法下的参数',
          defaultValue: fieldInfo?.bindParam,
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => <BindParam {...props} bindedMethods={store?.bindedMethods} />,
        },
        controlType: {
          label: '组件类型',
          defaultValue: fieldInfo?.controlType || '',
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [...FieldControls, ...DataDisplayCmpTypes],
            allowClear: false,
          },
        },
        label: {
          label: '组件标题',
          defaultValue: fieldInfo?.label || '',
          fieldType: EWidgetType.Input,
        },
        optionsFromType: {
          label: '枚举值',
          fieldType: EWidgetType.RadioGroup,
          whenHidden: (fromData: any) => fromData.controlType !== EDataDisplayCmpType.枚举,
          defaultValue: fieldInfo?.optionsFromType || EOptionsFromType.静态数据,
          fieldProps: {
            options: OptionsFromTypes,
          },
        },
        options: {
          label: '',
          fieldType: EWidgetType.CustomWidget,
          colon: false,
          defaultValue: fieldInfo?.options || [],
          whenHidden: (fromData: any) =>
            fromData.controlType !== EDataDisplayCmpType.枚举 ||
            fromData.optionsFromType !== EOptionsFromType.静态数据,
          custom: (props: any) => <EnumsEditor {...props} />,
        },
        dyOptionsInfo: {
          label: '',
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => <DyEnumsEditor {...props} style={{ marginLeft: 0 }} />,
          defaultValue: fieldInfo?.dyOptionsInfo || '',
          whenHidden: (fromData: any) =>
            fromData.controlType !== EDataDisplayCmpType.枚举 ||
            fromData.optionsFromType !== EOptionsFromType.动态数据,
        },
        hasTip: {
          label: '图标提示',
          defaultValue: fieldInfo?.hasTip || '',
          fieldType: EWidgetType.Switch,
        },
        tipContent: {
          label: '提示内容',
          defaultValue: fieldInfo?.tipContent || '',
          whenHidden: (formData: any) => !formData.hasTip,
          fieldType: EWidgetType.Input,
          fieldProps: {
            maxLength: 2000,
          },
        },
      };
      return result;
    },
  },
});
