import type { EPageType } from '../constant';

export enum TRankState {
  ENABLED = 1, // 启用
  DISABLED = 0, // 禁用
}

export interface TReturn<T = Record<string, any>> {
  code?: number;
  rCode?: number;
  message: string;
  msg: string;
  data?: T;
}

export interface Page {
  pageNumber?: number;
  pageNo?: number;
  pageSize?: number;
}

export interface FieldItem {
  // 数据源ID
  tableInformationId: string;
  fieldName: string;
  fieldIdentification: string;
  fieldLength: number;
  fieldDecimal: number;
  description: string;
  // 来源字段表
  sourceTableFieldName: string;
  // 来源表
  sourceTableName: string;
  // 外键。“表名.字段”
  fieldForeignKey: string;
  // ID生成策略： 0 无 1自增 2策略生成器生成
  generateStrategy: 0 | 1 | 2;
  fieldTypeChar: string;
  //  0 允许 1 不允许
  fieldNull: 0 | 1;
}

export interface PageGroup {
  name: string;
  // 页面组类型: 1, 标准模板
  type: string;
  description: string;
  // 页面组样式类型: 1, 传统列表模板
  styleType: string;
}

// 方法类型
// 透传方法 methodType 为 2，
export enum EMethodType {
  RPC = 1,
  LPC = 2, // 本地方法
  SQL = 3,
}
export const MethodTypes = [
  {
    label: 'RPC',
    value: EMethodType.RPC,
  },
  {
    label: 'SQL方法',
    value: EMethodType.SQL,
  },
  {
    label: '透传方法',
    value: EMethodType.LPC,
  },
];

// 方法协议
export enum EMethodPoolType {
  DC = 1,
  HTTP = 2,
  NEW_DC = 5,
}
export const MethodPoolTypes = [
  {
    label: 'DC2.0（pojo）',
    value: EMethodPoolType.NEW_DC,
  },
  {
    label: 'DC',
    value: EMethodPoolType.DC,
  },
  {
    label: 'HTTP',
    value: EMethodPoolType.HTTP,
  },
];
// 透传方法专用
export const TransmitMethod = {
  label: '-',
  value: 3,
};
// SQL方法
export const SQLMethods = [
  {
    label: 'MySQL',
    value: 4,
  },
];

export enum ESQLSubtypes {
  /** 列表查询 */
  listSearch = 1,
  /** 单行查询 */
  SingleLineQuery,
  /** 列表分页查询 */
  listPagingQuery,
  /** 新建 */
  newlyBuilt,
  /** 编辑 */
  update,
  /** 逻辑删除 */
  logicalDeletion,
  /** 物理删除 */
  physicalDeletion,
}

// SQL方法子类型
export const SQLSubtypes = [
  {
    label: '列表查询',
    value: ESQLSubtypes.listSearch,
  },
  {
    label: '单行查询',
    value: ESQLSubtypes.SingleLineQuery,
  },
  {
    label: '列表分页查询',
    value: ESQLSubtypes.listPagingQuery,
  },
  {
    label: '新建',
    value: ESQLSubtypes.newlyBuilt,
  },
  {
    label: '编辑',
    value: ESQLSubtypes.update,
  },
  {
    label: '逻辑删除',
    value: ESQLSubtypes.logicalDeletion,
  },
  {
    label: '物理删除',
    value: ESQLSubtypes.physicalDeletion,
  },
];

// http请求类型
export enum EMethodRequestType {
  GET = 1,
  POST = 2,
}
export const MethodRequestTypes = [
  {
    label: 'GET',
    value: EMethodRequestType.GET,
  },
  {
    label: 'POST',
    value: EMethodRequestType.POST,
  },
];

// 请求方法数据编码类型
export enum EMethodRequestEncode {
  APPLICATION_JSON = 'application/json',
  X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
}
export const PostMethodRequestEncode = [
  {
    label: 'application/json',
    value: EMethodRequestEncode.APPLICATION_JSON,
  },
];

export const GetMethodRequestEncode = [
  {
    label: 'application/x-www-form-urlencoded',
    value: EMethodRequestEncode.X_WWW_FORM_URLENCODED,
  },
];

// IDL方法配置,
export interface MethodIdlConfig {
  // 区域
  region: string;
  // 业务环境
  businessEnv: string;
  // DC接口domain值
  domain: string;
  // DC接口op值
  op: string;
  // IDL接口全类名
  serviceName: string;
  // IDL接口方法名
  methodName: string;
}
export interface MethodDcConfig {
  // 区域
  region: string;
  // 业务环境
  businessEnv: string;
  // IDL接口全类名
  serviceName: string;
  // IDL接口方法名
  methodName: string;
}

export interface MethodHttpConfig {
  // 链接
  url: string;
  // 请求类型
  requestMethodType: EMethodRequestType;
  // 编码类型
  contentType: string;
}

// 方法参数传值类型
export enum EMethodParamPassValueType {
  直接传值 = '1',
  前端变量 = '2',
  后端变量 = '3',
}

// 方法参数
export interface MethodParam {
  id: string;
  orderNum: number;
  // 参数名
  name: string;
  // 参数标题, 展示用
  title: string;
  // 数据类型: String,Boolean,Byte,Short,Integer,Long,Double,Object,List
  dataType: string;
  // 参数泛型类型, 当数据类型为List时有效: String,Boolean,Byte,Short,Integer,Long,Double,Object
  genericType?: string;
  // 传值类型
  passValueType: string | number; // EMethodParamPassValueType;
  // 传值配置。 当传值类型为: 前端变量, 存前端表达式; 后端变量, 存后端表达式
  passValueConfig?: string;
  // 参数子字段列表, 当参数数据类型为Object或泛型类型为Object时有值, 参数子字段与一级字段结构一致，递归嵌套
  fields?: MethodParam[];
  // 参数路径
  path?: string;
}

// 方法
export interface MethodInfo {
  id: string;
  name: string;
  description: string;
  type: EMethodType;
  protocolType: EMethodPoolType;
  // IDL方法配置,
  idlConfig?: MethodIdlConfig;
  dcConfig?: MethodDcConfig | null;
  httpConfig?: MethodHttpConfig | null;
  // 入参
  inputParams: MethodParam[];
  // 出参
  outputParams: MethodParam[];
}

// 页面组内的页面类型
export interface GroupPageListItemType {
  id: string;
  name: string;
  description: string;
  type: EPageType;
  orderNum: number;
  firstPage?: boolean;
}

export interface MethodReferences {
  methodId: string;
  type: string;
  frontComponentId: string;
}

// 列表页配置信息
export interface ListPageConfig {
  pageGroupId: string;
  name: string;
  description: string;
  loadMethodId?: string | number;
  submitMethodId?: string | number;
  properties: string;
  orderNum: number;
  titleProperties: string;
  paginationProperties: string;
  contentProperties: string;
  searchParams: any[];
  toolbarButtons: any[];
  operationButtons: any[];
  contentColumns: any[];
  methodReferences: MethodReferences[];
}

export interface ExecuteMethodParam {
  paramId: string;
  value?: any;
  // Object参数值, 当参数类型为Object时使用objectValue传值, 每一个元素代表一个字段值
  objectValue?: ExecuteMethodParam[];
  // List/Object参数值, 当参数类型为List/Object时使用listObjectValue传值, 二维数组, 第一维每个元素代表列表中的一个对象, 第二维每个元素代表列表对象中的一个子字段值
  listObjectValue?: ExecuteMethodParam[][];
}

// 参数类型
export enum EJSONType {
  IN_PARAM = 1, // 入参
  OUT_PARAM = 2, // 出参
}

// 分页数据
export interface PaginationType<T> {
  total: number;
  pageNumber: number;
  pageSize: number;
  totalPage: number;
  list: T[];
}

// 日期格式菜单选项
export const showOptions = [
  {
    label: '精确到日（YYYY-MM-DD）',
    value: 'YYYY-MM-DD',
  },
  {
    label: '精确到秒（YYYY-MM-DD HH:mm:ss）',
    value: 'YYYY-MM-DD HH:mm:ss',
  },
  // {
  //   label: '毫秒时间戳',
  //   value: 'x',
  // },
];

export const transferOptions = [
  {
    label: '精确到日（YYYY-MM-DD）',
    value: 'YYYY-MM-DD',
  },
  {
    label: '精确到秒（YYYY-MM-DD HH:mm:ss）',
    value: 'YYYY-MM-DD HH:mm:ss',
  },
  {
    label: '精确到毫秒（YYYY-MM-DD HH:mm:ss:SSS）',
    value: 'YYYY-MM-DD HH:mm:ss:SSS',
  },
  {
    label: '毫秒时间戳',
    value: 'x',
  },
  {
    label: '自定义',
    value: 'custom',
  },
];
// 时间日期范围选择 - 默认时间
export enum EDefaultTimeOptions {
  specifiedTime,
  nearlyXDays,
  noTime,
}

export const defaultTimeOptions = [
  {
    label: '指定时间',
    value: EDefaultTimeOptions.specifiedTime,
  },
  {
    label: '近X自然天',
    value: EDefaultTimeOptions.nearlyXDays,
  },
];

export interface TStructFields {
  position: string;
  name: string;
  columnIdentification: string;
  columnDesc: string;
  columnType: string;
}

export interface TMethodsParams {
  interbehaviorTypes: string;
  extractTypes: string;
  symbolEq: string;
  symbolValue: string | null | undefined;
  result: any;
  error: any;
}

export interface TMethodsValidationConfig {
  loadMethods?: TMethodsParams[];
  updateMethods?: TMethodsParams[];
}
