import { useState } from 'react';
import { getExternalPageDetail } from '@/services/pageGroup';

// get external page detail
export default function useExternalPageDetail() {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const load = async (id: string): Promise<any | null> => {
    try {
      if (loading || id === '0') {
        return null;
      }
      setLoading(true);
      const res = await getExternalPageDetail({ id });
      if (res.rCode === 0) {
        setData(res.data || null);
        return res.data || null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData,
    loading,
    load,
  };
}
