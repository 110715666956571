import { memo } from 'react';
import { value2Label } from '@lizhife/schema-shared';

interface EnumViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const EnumView = memo<EnumViewProps>(({ value, configInfo }) => {
  return (
    <>{value2Label(configInfo?.options || [], value || configInfo.defaultValue) || '示例数据'}</>
  );
});

export default EnumView;
