import { memo, useCallback, useState } from 'react';
import { Upload, message, Table, Button, Alert } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { read, utils } from 'xlsx';
import { exportJSONToExcelFile } from '@/utils/excle';

const MAX_COUNT = 1; // 最大上传个数
const ACCEPT = '.xls, .xlsx, .csv'; // 文件格式白名单
const PREVIEW_DATA_NUMBER = 10; // 预览多少条数据

interface ExcelFileUploadProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

// 编辑状态下的组件，不做value的上传
const ExcelFileUpload = memo<ExcelFileUploadProps>(({ configInfo, value = [] }) => {
  const { buttonLabel = '上传表格', bindParam = [], transformKeys = {} } = configInfo;

  const [tableHeader, setTableHeader] = useState(() => {
    if (value.length < 1) {
      return [];
    }
    const excelHeader = [];
    for (const headerAttr of Object.keys(value[0])) {
      const header = {
        title: headerAttr,
        dataIndex: headerAttr,
        key: headerAttr,
      };
      excelHeader.push(header);
    }
    return excelHeader;
  });
  const [tableData, setTableData] = useState(() => {
    if (value.length < 1) {
      return [];
    }

    return value;
  });
  const bindInfo = bindParam.find((item: any) => item.methodParamType === 'inputParams');
  const bindKeys: string[] = []; // 获取到可绑定的字段

  if (typeof bindInfo !== 'undefined') {
    const keys = bindInfo?.bindVar?.childKeys ?? [];
    keys.forEach((item: any) => {
      bindKeys.push(item.key.split('.').slice(-1)[0]);
    });
  }

  // excel文件上传解析
  const onBeforeUploadFile = useCallback((file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = (e as any).target.result;
      const workbook = read(data, { type: 'binary' });
      const jsonData: any = {};
      let firstSheetName;

      // 遍历工作表
      for (const sheetName of Object.keys(workbook.Sheets)) {
        // esline-disable-next-line
        if (workbook.Sheets[sheetName]) {
          // 利用 sheet_to_json 方法将 excel 转成 json 数据
          jsonData[sheetName] = utils.sheet_to_json(workbook.Sheets[sheetName]);
          if (typeof firstSheetName === 'undefined') {
            firstSheetName = sheetName;
          }
        }
      }
      if (typeof firstSheetName === 'undefined') {
        message.success('识别数据失败！');
        return;
      }

      const excelData = jsonData[firstSheetName];
      const excelHeader = [] as any[];

      if (excelData.length < 1) {
        message.success(`工作表-${firstSheetName}内容为空！`);
        return;
      }
      // 获取表头
      for (const headerAttr of Object.keys(excelData[0])) {
        const header = {
          title: headerAttr,
          dataIndex: headerAttr,
          key: headerAttr,
        };
        excelHeader.push(header);
      }

      message.success('上传成功！');

      // 显示在表格中
      setTableHeader(excelHeader);
      setTableData(excelData.slice(0, PREVIEW_DATA_NUMBER));
    };
    reader.readAsBinaryString(file);

    return false;
  }, []);

  // 导出模板文件
  const exportFile = useCallback(() => {
    if (typeof transformKeys !== 'object') {
      message.error('表头字段为空');
      return;
    }

    const excelTemplate: Record<string, string> = {};
    for (const paramInfo of Object.values(transformKeys as Record<string, Record<string, any>>)) {
      const { excelKey } = paramInfo;
      excelTemplate[excelKey] = '';
    }
    exportJSONToExcelFile({
      jsonDataArray: [excelTemplate],
      sheetName: 'Sheet1',
      fileName: '模板文件',
    });
  }, [transformKeys]);

  const hasUpload = tableData.length > 0;

  return (
    <>
      <Upload accept={ACCEPT} maxCount={MAX_COUNT} beforeUpload={onBeforeUploadFile} disabled>
        <Button icon={<UploadOutlined />}>{hasUpload ? '重新上传' : buttonLabel}</Button>
      </Upload>
      <Button type="link" icon={<DownloadOutlined />} onClick={exportFile}>
        下载模板
      </Button>
      {tableData.length > 0 ? (
        <>
          <Alert
            message="仅展示上传表格的前10行数据，作为预览参考"
            type="warning"
            showIcon
            closable
          />
          <Table pagination={false} columns={tableHeader} dataSource={tableData} />
        </>
      ) : null}
    </>
  );
});

export default ExcelFileUpload;
