import React from 'react';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { debounce } from 'lodash';
import { Select } from 'antd';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useFormGeneratorStore from '@/store/form-generator';
import { EPageType } from '@/constant';
import { FieldItem } from '@/store/types';

const ListSelectorConfigItem: React.FC<{
  // 用于过滤自身，不用于回填
  currentId: string;
  value: any;
  onChange: (value: any) => void;
}> = ({ currentId, value, onChange }) => {
  const fgStore = useFormGeneratorStore((state) => state);
  const { currentPage } = fgStore;
  const type = String(currentPage.type);
  const editStore = useEditStore((state) => state);
  const addStore = useAddStore((state) => state);

  let fields: FieldItem[] = [];
  if (type === EPageType.新增页面) {
    fields = addStore.fields;
  }
  if (type === EPageType.编辑页面) {
    fields = editStore.fields;
  }

  return (
    <FormCreator
      submitter={{ render: () => null }}
      labelAlign="right"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 15 }}
      debug
      layout="horizontal"
      onValuesChange={debounce((_, values) => {
        onChange?.(values);
      }, 500)}
      config={{
        displayField: {
          label: '回显字段',
          fieldType: EWidgetType.Switch,
          defaultValue: value.displayField ?? false,
        },
        backfills: {
          label: '回填字段',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: value.backfills ?? [],
          custom(props: any) {
            return (
              <Select
                mode="multiple"
                defaultValue={props.value}
                onChange={(values) => {
                  props?.onChange?.(values);
                }}
                options={fields
                  .filter((n) => n.controlId !== currentId)
                  .map((n) => ({
                    label: n.controlName || n.label || n.controlId,
                    value: n.controlId,
                  }))}
              />
            );
          },
        },
      }}
    />
  );
};

export default ListSelectorConfigItem;
