import { memo } from 'react';
import { Table, Space, Popconfirm, message } from 'antd';
import type { TableColumnType } from 'antd';
import DeleteIconButton from '@/components/DeleteIconButton';
import { dragComponents } from '@/components/SortableTable/dragable';

interface TableUploadProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

// 编辑状态下的组件，不做value的上传
const TableUpload = memo<TableUploadProps>(({ configInfo, value = [] }) => {
  const { bindParam = [], transformKeys = {} } = configInfo;
  let bindInfo = bindParam.find((item: any) => item.methodParamType === 'inputParams');
  const bindKeys: string[] = []; // 获取到可绑定的字段

  if (typeof bindInfo === 'undefined') {
    bindInfo = bindParam.find((item: any) => item.methodParamType === 'outputParams');
  }

  if (typeof bindInfo !== 'undefined') {
    const keys = bindInfo?.bindVar?.childKeys ?? [];
    keys.forEach((item: any) => {
      bindKeys.push(item.key.split('.').slice(-1)[0]);
    });
  }

  const isView = typeof bindInfo !== 'undefined' && bindInfo.type === 'loadMethod';

  const cols: Array<TableColumnType<any>> = [];
  for (const key of Object.keys(transformKeys)) {
    const { excelKey } = transformKeys[key];
    cols.push({
      title: excelKey,
      dataIndex: key,
    });
  }

  const columns: Array<TableColumnType<any>> = [...cols];
  if (!isView) {
    columns.push({
      title: '操作',
      fixed: 'right',
      width: 60,
      render: () => (
        <Space>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              message.warning('模拟操作');
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </Space>
      ),
    });
  }

  return (
    <Table<any>
      dataSource={value}
      bordered
      columns={columns}
      pagination={false}
      components={{ ...dragComponents() }}
    />
  );
});

export default TableUpload;
