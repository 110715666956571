import { memo, useRef, useState, useMemo, useEffect } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_LIST_SCROLL,
  PageStatus,
  PageGroupTypes,
  EPageStatus,
} from '@/constant';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { PlusOutlined } from '@ant-design/icons';
import { default as ProTable } from '@ant-design/pro-table';
import EditIconButton from '@/components/EditIconButton';
import DeleteIconButton from '@/components/DeleteIconButton';
import { Space, Typography, Button, Drawer, Popconfirm, message, Tag } from 'antd';
import { EModeType } from '@/store/form-generator-parse';
import Text from '@/components/Column/Text';
import useSetState from '@/hooks/useSetState';
import FormGeneractor from '@/components/FormGeneractor';
import { pageList, delPageGroup, disablePageGroup } from '@/services/pageGroup';
import useFormGenerator from '@/store/form-generator';
import PageParser from '@/components/PageParser';
import omit from 'lodash/omit';
import useVisible from '@/hooks/useVisible';
import { value2Label } from '@/components/SchemaView/utils/help';
import Editor from './Editor';

import './index.less';

const Page = memo(() => {
  const fgStore = useFormGenerator();
  const { visible, setVisible } = useVisible(false);
  const [editId, setEditId] = useState(''); // 5234082476764169855
  const [previewState, setPreviewState] = useSetState<{
    visbile: boolean;
    pageId: string;
  }>({
    visbile: false,
    pageId: '',
  });
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const actionRef = useRef<ActionType>();
  const columns: Array<ProColumns<any>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      formItemProps: {
        name: 'id',
      },
      width: 200,
      search: false,
      render: (_, record) => <Text copyable value={record.id} />,
    },
    {
      title: '名称',
      ellipsis: true,
      width: 150,
      dataIndex: 'name',
      render: (_, record) => <Text ellipsis value={record.name} />,
    },
    {
      title: '描述',
      ellipsis: true,
      dataIndex: 'description',
      width: 150,
      search: false,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: true,
      width: 150,
      valueType: 'select',
      fieldProps: {
        options: PageGroupTypes,
      },
      render: (_, record) => `${value2Label(PageGroupTypes, record.type)}`,
    },
    {
      title: 'SSO配置URL',
      search: false,
      ellipsis: true,
      fieldProps: {
        options: [],
      },
      width: 200,
      render(_, record) {
        if (String(record.status) === EPageStatus.已发布) {
          return <Text copyable value={`/published/page/${record.id}`} />;
        } else {
          return '-';
        }
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: true,
      valueType: 'select',
      width: 200,
      fieldProps: {
        options: PageStatus,
      },
      render: (_, record) => (
        <>
          <Tag color={[undefined, undefined, '#87d068', '#f50'][record.status]}>
            {value2Label(PageStatus, record.status) || ''}
          </Tag>
          {record.draft && <Tag color="#2db7f5">有待发布的草稿</Tag>}
        </>
      ),
    },
    {
      title: '操作',
      fixed: 'right',
      search: false,
      width: 150,
      render(_, record) {
        const isPublished = String(record.status) === EPageStatus.已发布; //  && !record.draft
        const isDisabled = String(record.status) === EPageStatus.禁用;
        return (
          <Space>
            <Popconfirm
              title="请问是否禁用，禁用后该页面组生成的所有页面将无法被访问（无论是否已将ID配置到SSO的菜单下）"
              cancelText="取消"
              placement="left"
              okText="确定"
              onConfirm={async () => {
                try {
                  const api = disablePageGroup;
                  const res = await api(record.id);
                  if (res.rCode === 0) {
                    message.success('操作成功');
                    actionRef.current?.reload();
                  } else {
                    message.error(res.msg || '操作失败');
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <Typography.Link disabled={isDisabled}>禁用</Typography.Link>
            </Popconfirm>
            <Typography.Link
              onClick={() => {
                setPreviewState({
                  visbile: true,
                  pageId: record.id,
                });
              }}
            >
              预览
            </Typography.Link>
            <EditIconButton
              onClick={() => {
                fgStore.uCurrentPageGroupId(record.id);
                fgStore.uEditorVisible(true);
              }}
            />
            {isPublished ? (
              <DeleteIconButton disabled />
            ) : (
              <Popconfirm
                title="是否删除当前数据？"
                cancelText="取消"
                okText="确定"
                onConfirm={async () => {
                  try {
                    const res = await delPageGroup(record.id);
                    if (res.rCode === 0) {
                      message.success('操作成功');
                      actionRef.current?.reload();
                    } else {
                      message.error(res.msg);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <div>
                  <DeleteIconButton />
                </div>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const modeMap = useMemo(
    () => ({
      edit: {
        title: '编辑页面组',
        width: 1000,
      },
      add: {
        title: '新建页面组',
        width: 1000,
      },
    }),
    [],
  );

  useEffect(() => {
    actionRef.current?.reload();
  }, [fgStore.editorVisible]);

  return (
    <>
      <div style={{ display: previewState.visbile ? 'none' : 'block' }}>
        <ProTable<any>
          rowKey="id"
          search={{
            span: 6,
            defaultCollapsed: false,
          }}
          columns={columns}
          scroll={DEFAULT_LIST_SCROLL}
          actionRef={actionRef}
          pagination={{
            pageSize: DEFAULT_PAGE_SIZE,
          }}
          toolBarRender={() => [
            <Button
              key="add"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditId('');
                setMode('add');
                setVisible(true);
              }}
            >
              新建页面组
            </Button>,
          ]}
          request={async (params) => {
            params.pageNumber = params.current;
            const { data } = await pageList(omit(params, ['current']));
            return {
              data: data?.list || [],
              total: data?.total || 0,
              success: true,
            };
          }}
        />
        <Drawer
          placement="right"
          width={modeMap[mode].width}
          title={`${modeMap[mode].title}${editId || ''}`}
          destroyOnClose
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          bodyStyle={{
            ...(editId ? { paddingTop: 0 } : { padding: 0 }),
          }}
        >
          <Editor
            id={editId}
            onSubmitOk={(id) => {
              setVisible(false);
              actionRef.current?.reload();
              fgStore.uCurrentPageGroupId(id);
              fgStore.uEditorVisible(true);
            }}
          />
        </Drawer>
        {/* 表单编辑 */}
        {fgStore.editorVisible && <FormGeneractor />}
      </div>
      <div style={{ display: !previewState.visbile ? 'none' : 'block' }} className="preview-box">
        <div
          className="preview-toolbar"
          onClick={() => {
            setPreviewState({
              visbile: false,
              pageId: '',
            });
          }}
        >
          关闭预览
        </div>
        {previewState.pageId && (
          <PageParser mode={EModeType.preview} pageModelId={previewState.pageId} />
        )}
      </div>
    </>
  );
});

export default Page;
