import type { EFieldType } from '@/components/FieldTypeSelect/selector';

export enum EMode {
  编辑中 = 'editing',
  预览中 = 'preview',
}

export interface IStore {
  state: StoreState;
  doFold?: (idx: number) => void;
  updateCheckeds?: (arr: IRowItem[]) => void;
  doExpand?: (idx: number) => void;
  doAddRow?: (idx?: number) => void;
  setCheckBoxDisabledByIds?: (ids: string[], checked: boolean) => void;
  changeRowModeByIdx?: (idx: number, mode: EMode) => void;
  getChildsByRow?: (row: IRowItem, list: IRowItem[]) => IRowItem[];
  doCopy?: (idx: number) => void;
  doJSONSave?: (data: any) => void;
  // cacheSnapBeforeEdit?: (idx: number) => void;
  // restoreRowByCacheSnap?: (idx: number) => void;
}

export type IRowItem<T = Record<string, any>> = {
  // type: string;
  dataType: string;
  // 当前type为List/Object类型时，用于标识Object具体类型
  genericType?: EFieldType;
  sort?: number | string;
  // 不能改
  fixed?: boolean;
  // 默认值
  defaultValue?: any;
  // 绑定变量路径
  bindVarPath?: string;
  // 传值类型: 1, 直接传值; 2, 前端变量；3，后端变量
  passValueType?: EPassValueType;
  // 传值配置，当传值类型为: 前端变量, 存前端表达式; 后端变量, 存后端表达式
  passValueConfig?: string;
  // 当前模式
  _mode: EMode;
  // ID
  _id: string;
  // 父ID
  _parentId?: string | undefined;
  // 层级
  _level: number;
  // 折叠数据
  _folds?: Array<IRowItem<T>>;
  // 是否可以选择
  _aviableSelect?: boolean;
  // 操作保存的数据快照
  _savedRow?: Array<IRowItem<T>>;
  /** row未保存提示 */
  _rowSaveTip?: boolean;
} & T;

export interface IServerfield
  extends Pick<
    IRowItem,
    'fixed' | 'name' | 'defaultValue' | 'title' | 'genericType' | 'sort' | 'dataType'
  > {
  registered?: boolean;
  fields?: IServerfield[];
  passValueType?: string;
  passValueConfig?: string;
}

export type CFEMode = 'view' | 'opera';

export interface StoreState {
  datas: IRowItem[];
  checkeds: IRowItem[];
  mode: CFEMode;
  configFields?: IConfigFields;
  singleSelection?: boolean;
  showBindGlobal?: boolean;
  jsonCollect?: {
    visible?: boolean;
    value?: Record<string, any>;
  };
  copyNode?: IRowItem;
  copySet?: Set<String>;
  copyIndex?: number;
  copyJSON?: string;
}

export type Rule = (value: string, datas: IRowItem[], row: IRowItem) => Promise<string>;

export type IConfigFields = Record<
  string,
  {
    label: string;
    key?: string;
    style?: React.CSSProperties;
    customWidget?: (...args: any[]) => React.ReactNode;
    rules?: Rule[];
    whenShowInput?: (...args: any[]) => boolean;
  }
>;

export enum EPassValueType {
  直接传值 = '1',
  前端传值 = '2',
  后端传值 = '3',
}

export const EPassValueTypeText = {
  1: '直接传值',
  2: '前端变量',
  3: '后端变量',
};
