import { message } from 'antd';
import { parseUrl, stringifyUrl } from 'query-string';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Cookies from 'js-cookie';
import { SSO_URL } from '@/constant';

dayjs.extend(utc);

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

// protect sensitive data when iframe load with __vt_param__ token
export function securityCheck() {
  const ctoken = Cookies.get('token-v1');
  if (typeof ctoken === 'string' && location.href.indexOf('__vt_param__%3D') !== -1) {
    const len = ctoken.length;
    const vtoken = location.href.match(new RegExp(`__vt_param__%3D(.{${len}})`))?.[1] ?? '';

    if (vtoken !== ctoken) {
      return false;
    }
  }

  return true;
}

// remove url parameter
export function removeUrlParameter(url: string, param: string): string {
  return url.replace(new RegExp(`[?&]${param}=[^&#]*([&#]?)`), '$1');
}

export function getCookieDomain() {
  const { host } = window.location;

  if (host.indexOf('.') !== -1 && host.indexOf(':') === -1) {
    return host.match(/[^.]*(.*)/)?.[1] ?? '';
  }

  return host.match(/[^:]*/)?.[0] ?? '';
}
/*
  获取url search参数内容
*/
export function getQueryString(name: string) {
  try {
    const RE = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const r = window.location.search.substr(1).match(RE);
    // console.log('r---',r)
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  } catch (error) {
    console.log('getQueryString报错了~~', error);
    return null;
  }
}

export const token = Cookies.get('token-v1');

export async function getTokenFromSSO() {
  const ctoken = Cookies.get('token-v1');

  // first get token from sso
  if (localStorage.getItem('hasGetToken') && typeof ctoken === 'undefined') {
    const newToken = getQueryString('__vt_param__');
    if (typeof newToken === 'string') {
      localStorage.removeItem('hasGetToken');
      Cookies.set('token-v1', newToken, {
        path: '/',
        domain: getCookieDomain(),
      });

      window.location.href = `${removeUrlParameter(
        removeUrlParameter(window.location.href, '__vt_param__'),
        '_ac',
      )}`.replace(/#\/iframe[^&#]*([&#]?)/g, '$1');
      return Promise.resolve(newToken);
    }

    return Promise.reject(new Error('单点登录没有返回token'));
  }
  if (ctoken) {
    localStorage.removeItem('hasGetToken');
    return Promise.resolve(ctoken);
  }

  localStorage.setItem('hasGetToken', 'true');

  window.location.href = `//${SSO_URL}/login?backUrl=${removeUrlParameter(
    removeUrlParameter(window.location.href, '__vt_param__'),
    '_ac',
  )}`.replace(/#\/iframe[^&#]*([&#]?)/g, '$1'); // "open iframe page" is a special scenario that needs to be handled separately.
}

export function addTokenToUrl(url: string) {
  const t = { ...parseUrl(url, { parseFragmentIdentifier: true }) };
  if (token) {
    t.query = {
      ...t.query,
      __vt_param__: token,
    };
  }
  return stringifyUrl(t, { encode: false });
}

export function uuid() {
  const temp_url = URL.createObjectURL(new Blob());
  const t = temp_url.toString(); // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
  URL.revokeObjectURL(temp_url);
  return t.substr(t.lastIndexOf('/') + 1);
}

export function eventStop(e: any) {
  e.stopPropagation();
  e.preventDefault();
}

export function randomString(len: number) {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = len; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
  return result;
}

export function get(
  target: Record<string, any>,
  path: string | string[],
  defaultVal: any = null,
): any {
  let t = path;
  if (typeof t === 'string') {
    t = t.split('.');
  }
  return t.reduce((acc, cur) => {
    return acc && acc[cur] ? acc[cur] : defaultVal;
  }, target);
}
/**
 * 钱包地址
 * @param activityId 活动ID
 * @param deptId 部门ID
 * @param appId 支付AppId
 */
export function getPaymentPath(activityId: string, deptId: string, appId: string) {
  return `${PAYMENT_HOST}/static/__static/distribute/dept_activity/?activityId=${activityId}&deptId=${deptId}&appId=${appId}`;
}

export function checkTextEmoji(val: string) {
  const regRule = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g;
  let returnData = false;
  if (val.match(regRule)) {
    // param = param.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "");
    message.error('文案中存在表情，请将表情删除');
    returnData = true;
  }
  return returnData;
}

/**
 * 转换为东八区时间
 * @param time 待转换的Moment
 * @param zoneValue 待转换时间对应的时区，格式，eg: GMT+9
 * @param format 转换后输出格式， null时返回moment对象
 * @returns
 */
const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export function toEase8Time(
  time: Dayjs,
  zoneValue: string,
  format: string | null = DEFAULT_FORMAT,
) {
  const ZONE_NUM = 8;
  // 重新创建一个，避免影响time
  const cloneTime = time.clone();
  if (!zoneValue || !zoneValue.startsWith('GMT')) {
    return format !== null ? cloneTime.format(format) : cloneTime;
  }
  let zone = parseInt(zoneValue.slice(3).split(':')[0]);
  if (zone < 0) {
    // 西区比东区早
    zone = Math.abs(zone) + ZONE_NUM;
    cloneTime.hour(cloneTime.hour() + zone);
  } else {
    // 东区： 减京8区
    zone -= ZONE_NUM;
    cloneTime.hour(cloneTime.hour() - zone);
  }
  return format !== null ? cloneTime.format(format) : cloneTime;
}

/**
 * 东8区时间转换指定时区时间
 * @param timestamp 东8区时间戳
 * @param zone 指定时区， eg: GMT+9
 */
export function ease8TimeToZoneTime(timestamp: number, zoneValue?: string): Dayjs {
  const time = dayjs(timestamp).utcOffset(8);
  let ease8 = 8;
  if (!zoneValue || !zoneValue.startsWith('GMT')) {
    return time;
  }
  const zone = parseInt(zoneValue.slice(3).split(':')[0]);
  ease8 -= zone;
  const ntime = time.hour(time.hour() - ease8);
  return ntime;
}

/**
 * 服务端北京时间时间戳 => 字符串
 */
export function showEase8Time(timestamp: number, format: string | null = 'YYYY-MM-DD HH:mm:ss') {
  try {
    const time = dayjs(timestamp).utcOffset(8);
    return format === null ? time : time.format(format);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function serverEaseTime(timestamp: number) {
  return dayjs(timestamp).utcOffset(8);
}

export function formatNum(num: number | string) {
  const numStr = String(num);
  const [integer, decimal = ''] = numStr.split('.');
  return integer.replace(/\B(?=(\d{3})+$)/g, ',') + (decimal ? `.${decimal}` : '');
}

export function typeOf(obj: any) {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

export function log(title: string, ...infos: any[]) {
  if (process.env.NODE_ENV === 'production') return;
  const styles = [
    'color: #fff',
    'background: #000',
    'line-height: 20px',
    'font-size: 20px',
    'border-left: 2px solid red',
    'border-right: 2px solid red',
    'text-shadow: 2px 2px black',
    'padding: 10px',
  ].join(';');
  console.log();
  console.log(`%c${title}：`, styles, ...(Array.isArray(infos) ? infos : [infos]));
  console.log();
}

export function mapTree(
  tree: any[],
  iterator: (item: any, key: number, level: number, paths: any[]) => any,
  level = 1,
  depthFirst = false,
  paths: any[] = [],
  childKey = 'children',
) {
  return tree.map((item: any, index) => {
    if (depthFirst) {
      const children: any[] | undefined = item[childKey]
        ? mapTree(item[childKey], iterator, level + 1, depthFirst, paths.concat(item))
        : undefined;
      if (children) {
        // eslint-disable-next-line no-param-reassign
        item = { ...item, [childKey]: children };
      }
      // eslint-disable-next-line no-param-reassign
      item = iterator(item, index, level, paths) || { ...(item as any) };
      return item;
    }

    // eslint-disable-next-line no-param-reassign
    item = iterator(item, index, level, paths) || { ...(item as any) };

    if (item[childKey] && item[childKey].splice) {
      item[childKey] = mapTree(item[childKey], iterator, level + 1, depthFirst, paths.concat(item));
    }

    return item;
  });
}

/**
 * 遍历树
 * @param tree
 * @param iterator
 */
export function eachTree(
  tree: any[],
  iterator: (item: any, key: number, level: number) => any,
  level = 1,
  childKey = 'children',
) {
  tree.forEach((item, index) => {
    iterator(item, index, level);
    if (item[childKey] && item[childKey].splice) {
      eachTree(item[childKey] as any[], iterator, level + 1, childKey);
    }
  });
}

/**
 * 判断树中每个节点是否满足某个条件。
 * @param tree
 * @param iterator
 */
export function everyTree(
  tree: any[],
  iterator: (item: any, key: number, level: number, paths: any[], indexes: number[]) => boolean,
  level = 1,
  paths: any[] = [],
  indexes: number[] = [],
  childKey = 'children',
): boolean {
  return tree.every((item, index) => {
    const value: any = iterator(item, index, level, paths, indexes);
    if (value && item[childKey] && item[childKey].splice) {
      return everyTree(
        item[childKey] as any[],
        iterator,
        level + 1,
        paths.concat(item),
        indexes.concat(index),
      );
    }
    return value;
  });
}

/**
 * 在树中查找节点。
 * @param tree
 * @param iterator
 */
export function findTree(
  tree: any[],
  iterator: (item: any, key: number, level: number, paths: any[]) => any,
  childKey = 'children',
): any | null {
  let result: any = null;

  everyTree(
    tree,
    (item, key, level, paths) => {
      if (iterator(item, key, level, paths)) {
        result = item;
        return false;
      }
      return true;
    },
    1,
    [],
    [],
    childKey,
  );

  return result;
}
