import type { ForwardRefRenderFunction } from 'react';
import React, { useImperativeHandle, forwardRef } from 'react';
import type { ProColumns } from '@ant-design/pro-table';
import { default as ProTable } from '@ant-design/pro-table';
import { Row, Button } from 'antd';
import useSelection from '@/hooks/useSelection';
import Text from '@/components/Column/Text';
import { methodList } from '@/services/method';
import useMethodDetail from '@/hooks/useMethodDetail';
import { MethodPoolTypes, MethodTypes, SQLMethods } from '@/types';

type TableListItem = any;
interface IMethodsProps {
  onGetModel?: (daataa: TableListItem[]) => void;
  defaultCheckeds?: TableListItem[];
  selectType?: 'radio' | 'checkbox';
}

const Methods: ForwardRefRenderFunction<any, IMethodsProps> = (props, ref) => {
  const { load: loadDetail, loading: loadingMethod } = useMethodDetail();
  const { onGetModel, defaultCheckeds, selectType = 'radio' } = props;
  const { getRowSelection, checkedRows, getRowClickSelectRowKeys } = useSelection({
    type: selectType,
    idKey: 'id',
    checkedRows: defaultCheckeds,
  });

  useImperativeHandle(ref, () => ({}));

  const protocolTypeEnum = () => {
    const enumsMap = new Map();
    [...MethodPoolTypes, ...SQLMethods, { label: '-', value: 3 }].forEach((item) => {
      enumsMap.set(item.value, {
        text: item.label,
      });
    });
    return enumsMap;
  };

  const columns: Array<ProColumns<TableListItem>> = [
    {
      title: 'ID',
      ellipsis: true,
      search: false,
      dataIndex: 'id',
      render: (_, record) => <Text value={record.id} />,
      width: 200,
    },
    {
      title: '方法名称',
      dataIndex: 'name',
      search: false,
      render: (_, record) => <Text value={record.name} />,
    },
    {
      title: '方法类型',
      dataIndex: 'type',
      filtered: true,
      valueType: 'select',
      fieldProps: {
        options: MethodTypes,
      },
      width: 100,
    },
    {
      title: '协议类型',
      dataIndex: 'protocolType',
      // search: false,
      valueEnum: protocolTypeEnum() as any,
      width: 100,
    },
  ];

  return (
    <ProTable<TableListItem>
      columns={columns}
      // loading={loading}
      bordered
      size="small"
      rowKey="id"
      style={{ width: 700 }}
      // search={false}
      rowSelection={{
        ...getRowSelection(),
      }}
      onRow={(record) => getRowClickSelectRowKeys(record)}
      scroll={{
        y: 500,
      }}
      toolBarRender={false}
      request={async (params) => {
        params.pageNumber = params.current;
        delete params.current;
        const res = await methodList(params);
        return {
          data: res?.data?.list || [],
          total: res?.data?.total || 0,
          success: true,
        };
      }}
      footer={() => (
        <Row justify="end">
          <Button
            type="primary"
            disabled={!checkedRows.length}
            loading={loadingMethod}
            onClick={async () => {
              // 列表拿到的方法数据不齐全，输出时，查询详情输出
              Promise.all(
                checkedRows.map(async (row) => {
                  return await loadDetail(row.id);
                }),
              ).then((vals) => {
                onGetModel?.(vals);
              });
            }}
          >
            确定
          </Button>
        </Row>
      )}
    />
  );
};

export default forwardRef(Methods);
