import { memo, useRef } from 'react';
import type { ActionType } from '@ant-design/pro-table';
import Protable from '@ant-design/pro-table';
import _ from 'lodash';
import { Typography } from 'antd';
import { PageTypes, EPageType } from '@/constant';
import PickMethod from '@/components/PickMethod';
// import MethodSelector from '@/components/MethodSelector/index';

export const DefaultPages = PageTypes.map((o: any) => ({
  pageTypeLabel: o.label,
  pageTypeValue: o.value,
}));

interface PageCmpsProps {
  value?: any;
  onChange?: (val: any) => void;
  pageGroupId?: string;
}
const PageCmps = memo<PageCmpsProps>((props) => {
  const { value, onChange } = props;
  const actionRef = useRef<ActionType>();

  /*   const value = useMemo(() => {
    if (value?.length) {
      return value
    } else {
      return DefaultPages
    }
  }, [value])
 */
  /*   useEffect(() => {
    onChange?.(value)
  }, [value]) */

  return (
    <Protable
      size="small"
      rowKey="pageTypeValue"
      bordered
      pagination={false}
      actionRef={actionRef}
      columns={[
        {
          dataIndex: 'pageTypeLabel',
          title: '页面类型',
          width: 120,
        },
        {
          dataIndex: 'bb',
          title: '加载方法',
          render(__, record, index) {
            if (record.pageTypeValue === EPageType.新增页面) {
              return '无需配置';
            }
            return (
              <PickMethod
                value={record.loadMethod ? [record.loadMethod] : undefined}
                getLabelByValue={(methods) => (methods?.length ? methods[0].name : '请选择')}
                checkedList={[record.loadMethod].filter((o) => Boolean(o))}
                onChange={(methods) => {
                  const list = value?.length ? value : DefaultPages;
                  list[index].loadMethod = methods?.length ? methods[0] : null;
                  onChange?.(_.cloneDeep([...list]));
                }}
              />
            );
          },
        },
        {
          dataIndex: 'cc',
          title: '更新方法',
          render(__, record, index) {
            if ([EPageType.列表页面, EPageType.查看页面].includes(record.pageTypeValue)) {
              return '无需配置';
            }
            return (
              <PickMethod
                value={record.submitMethod ? [record.submitMethod] : undefined}
                getLabelByValue={(methods) => (methods?.length ? methods[0].name : '请选择')}
                onChange={(methods) => {
                  const list = value?.length ? value : DefaultPages;
                  list[index].submitMethod = methods?.length ? methods[0] : null;
                  onChange?.(_.cloneDeep([...list]));
                }}
              />
            );
          },
        },
      ]}
      toolBarRender={false}
      search={false}
      dataSource={value?.length ? value : DefaultPages}
      footer={() => (
        <Typography.Text type="danger">
          注：若不选择映射方法，则不做页组件初始化，可进入页面编辑器后自行添加组件
        </Typography.Text>
      )}
    />
  );
});

export default PageCmps;
