import { EWidgetType, FCRules } from '@/components/FormCreator';
import {
  FieldControls,
  FieldCmpType,
  EOptionsFromType,
  OptionsFromTypes,
  DataDisplayCmpTypes,
} from '@/constant';
import HiddensConfig from './HiddensConfig';
import EnumsEditor from './common/EnumsEditor';
import BindParam from './common/BindParam';
import RulesConfig from './RulesConfig';
import DyEnumsEditor from './common/DyEnumsEditor';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import { Input, Select } from 'antd';

/* const getStoreDyMethodOptions = () => {
  return new Promise<any>(resolve => {
    unstable_batchedUpdates(() => {
      resolve(
        getDyEnumOptions(useStore.getState().dyMethods)
      )
    })
  })
} */

/** 表单项公共配置 */
export default () => ({
  base: {
    title: '基础属性',
    key: 'base-config',
    formProps: {
      submitter: { render: () => null },
      labelAlign: 'right',
      labelCol: { style: { width: 90 } },
      debug: true,
      layout: 'horizontal',
    },
    formConfig(
      fieldInfo: any,
      store?: any,
      excluded: string[] = [],
      checkMethods?: 'loadMethod' | 'submitMethod',
    ) {
      const result: Record<string, any> = {
        controlId: {
          label: '组件ID',
          defaultValue: fieldInfo?.controlId || '',
          fieldType: EWidgetType.Text,
        },
        bindParam: {
          label: '绑定参数',
          tooltip: '设置当前组件关联对应方法下的参数',
          defaultValue: fieldInfo?.bindParam,
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => (
            <BindParam
              {...props}
              bindedMethods={store?.bindedMethods}
              checkMethods={checkMethods}
            />
          ),
        },
        controlType: {
          label: '组件类型',
          defaultValue: fieldInfo?.controlType || '',
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [...FieldControls, ...DataDisplayCmpTypes],
            allowClear: false,
          },
        },
        controlName: {
          label: '组件命名',
          defaultValue: fieldInfo?.controlName || fieldInfo?.label || '',
          fieldType: EWidgetType.Input,
        },
        label: {
          label: '组件标题',
          defaultValue: fieldInfo?.label || '',
          fieldType: EWidgetType.Input,
        },
        defaultValue: {
          label: '默认值',
          defaultValue: fieldInfo?.defaultValue || '',
          fieldType: EWidgetType.CustomWidget,
          whenHidden: (fromData: any) =>
            [FieldCmpType.表格文件上传, FieldCmpType.列表].includes(fromData.controlType),
          custom(props: any) {
            let isTypeBoolean = false;
            if (fieldInfo?.bindParam && fieldInfo?.bindParam.length !== 0) {
              isTypeBoolean =
                fieldInfo?.bindParam[0]?.bindVar.fieldType === EFieldType.Boolean ||
                fieldInfo?.bindParam[1]?.bindVar.fieldType === EFieldType.Boolean;
            }
            if (isTypeBoolean) {
              return (
                <Select
                  {...props}
                  options={[
                    { label: 'true', value: true },
                    { label: 'false', value: false },
                  ]}
                />
              );
            }
            return <Input {...props} />;
          },
        },
        placeholder: {
          label: '占位符号',
          defaultValue: fieldInfo?.placeholder || '请输入',
          fieldType: EWidgetType.Input,
          whenHidden: (fromData: any) =>
            [FieldCmpType.表格文件上传, FieldCmpType.列表].includes(fromData.controlType),
        },
        optionsFromType: {
          label: '枚举值',
          fieldType: EWidgetType.RadioGroup,
          whenHidden: (fromData: any) =>
            ![
              FieldCmpType.下拉框,
              FieldCmpType.下拉多选框,
              FieldCmpType.复选框,
              FieldCmpType.单选框,
            ].includes(fromData.controlType),
          defaultValue: fieldInfo?.optionsFromType || EOptionsFromType.静态数据,
          fieldProps: {
            options: OptionsFromTypes,
          },
        },
        options: {
          label: '',
          fieldType: EWidgetType.CustomWidget,
          colon: false,
          defaultValue: fieldInfo?.options,
          whenHidden: (fromData: any) =>
            fromData.optionsFromType !== EOptionsFromType.静态数据 ||
            ![
              FieldCmpType.下拉框,
              FieldCmpType.下拉多选框,
              FieldCmpType.复选框,
              FieldCmpType.单选框,
            ].includes(fromData.controlType),
          custom: (props: any) => <EnumsEditor {...props} />,
        },
        dyOptionsInfo: {
          label: '',
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => <DyEnumsEditor {...props} style={{ marginLeft: 0 }} />,
          defaultValue: fieldInfo?.dyOptionsInfo,
          whenHidden: (fromData: any) =>
            fromData.optionsFromType !== EOptionsFromType.动态数据 ||
            ![
              FieldCmpType.下拉框,
              FieldCmpType.下拉多选框,
              FieldCmpType.复选框,
              FieldCmpType.单选框,
            ].includes(fromData.controlType),
        },
      };

      const target = Object.keys(result).reduce<Record<string, any>>((value, key) => {
        if (excluded.includes(key)) return value;
        value[key] = result[key];
        return value;
      }, {});

      return target;
    },
  },
  interaction: {
    title: '交互配置',
    key: 'interaction-config',
    formProps: {
      submitter: { render: () => null },
      labelAlign: 'right',
      labelCol: { style: { width: 90 } },
      debug: true,
      layout: 'horizontal',
    },
    formConfig(fieldInfo: any) {
      const result = {
        disabled: {
          label: '是否只读',
          defaultValue: fieldInfo?.disabled || false,
          fieldType: EWidgetType.Switch,
        },
        required: {
          label: '是否必填',
          defaultValue: typeof fieldInfo?.required === 'undefined' ? false : fieldInfo?.required,
          fieldType: EWidgetType.Switch,
        },
        requiredMsg: {
          label: '必填校验文案',
          whenHidden: (formData: any) => !formData.required,
          defaultValue: fieldInfo?.requiredMsg || '必填',
          fieldType: EWidgetType.Input,
        },
        noVisible: {
          label: '是否隐藏',
          defaultValue: fieldInfo?.noVisible || false,
          fieldType: EWidgetType.Switch,
        },
        whenHiddens: {
          label: '联动显隐',
          defaultValue: fieldInfo?.whenHiddens || {},
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => <HiddensConfig {...props} curControlStore={fieldInfo} />,
        },
        rules: {
          label: '校验规则',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: fieldInfo?.rules || [],
          custom: (props: any) => <RulesConfig {...props} />,
        },
        hasTip: {
          label: '图标提示',
          defaultValue: fieldInfo?.hasTip || '',
          fieldType: EWidgetType.Switch,
        },
        tipContent: {
          label: '提示内容',
          defaultValue: fieldInfo?.tipContent || '',
          whenHidden: (formData: any) => !formData.hasTip,
          fieldType: EWidgetType.Input,
          fieldProps: {
            maxLength: 2000,
          },
          rules: [FCRules.Required('请输入提示内容')],
        },
      };
      return result;
    },
  },
});
