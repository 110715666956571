import { sandbox } from '@/utils/sandbox';
import { memo } from 'react';

interface PureTextProps {
  onChange?: (data: any) => void;
  value?: any;
  values?: any;
  configInfo?: any;
}

const PureText = memo<PureTextProps>(({ configInfo, values }) => {
  let text = configInfo.finalValue ?? '-';
  if (configInfo.textExpression) {
    text = sandbox(`return ${configInfo.textExpression}`, values);
  }
  return <div>{text}</div>;
});

export default PureText;
