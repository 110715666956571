import { memo, useState, useEffect, useCallback } from 'react';
import { Form, Space, Select, Alert } from 'antd';

interface Param {
  dataType: string;
  name: string;
  title: string;
  genericType: string;
  fields?: Param[];
}

interface FormBindKeys {
  readKey: string | undefined;
  writeKey: string | undefined;
  readInfo?: any;
  writeInfo?: any;
}

const { Option } = Select;

interface MapParam {
  dataType: string;
  name: string;
  path: string;
  genericType: string;
  title: string;
  fields?: Param[];
}

type KeyMap = Record<string, MapParam>;

// transform mix array param to "key-value pair"
function getKeyMap(param: Param[], prevPath = ''): KeyMap {
  let result: Record<string, MapParam> = {};

  param.forEach((item) => {
    const { name, fields = [] } = item;
    const path = `${prevPath}${name}`;
    result[path] = {
      path,
      ...item,
    };
    if (fields.length > 0) {
      const nextResult = getKeyMap(fields, `${path}.`);
      result = {
        ...result,
        ...nextResult,
      };
    }
  });

  return result;
}

// validate read-value and write-value is same dataType
const arrayValidator =
  (fields: any, formValue: any, readKeyMap: KeyMap, writeKeyMap: KeyMap) =>
  async (rule: any, value: any) => {
    if (value === 'undefined') {
      return Promise.resolve(true);
    }

    const { formBindKeys } = formValue;
    const readInfo = readKeyMap[formBindKeys[fields.key]?.readKey];
    const writeInfo = writeKeyMap[value];

    if (typeof writeInfo === 'undefined') {
      return Promise.reject(new Error('格式有误!'));
    }
    const { dataType: readDataType } = readInfo;
    const { dataType: writeDataType } = writeInfo;

    if (readDataType !== writeDataType) {
      return Promise.reject(new Error('数据类型不一致!'));
    }

    return Promise.resolve(true);
  };

export enum ReadOrWrite {
  READ_DISABLED = 'readDisabled',
  WRITE_DISABLED = 'writeDisabled',
}

const headerTitleStyle = {
  width: '130px',
  height: '30px',
  lineHeight: '30px',
  textAlign: 'center' as const,
  color: '#1890ff',
};

interface TransformParams {
  readTitle?: string;
  writeTitle?: string;
  readParams?: any[];
  writeParams?: any[];
  readOrWrite: ReadOrWrite;
  value?: Record<string, any>;
  onChange?: (data: any) => void;
}

export default memo<TransformParams>(
  ({
    readTitle = '',
    writeTitle = '',
    readParams = [],
    writeParams = [],
    readOrWrite = ReadOrWrite.READ_DISABLED,
    value,
    onChange,
  }) => {
    const [formValue, setFormValue] = useState<{
      formBindKeys: FormBindKeys[];
    }>({ formBindKeys: [] });
    const [form] = Form.useForm();

    const readKeyMap = getKeyMap(readParams);
    const writeKeyMap = getKeyMap(writeParams);

    useEffect(() => {
      const _readKeyMap = getKeyMap(readParams);
      const newFormBindKeys: FormBindKeys[] = [];
      for (const [key, info] of Object.entries(_readKeyMap)) {
        newFormBindKeys.push({
          readKey: key,
          writeKey: value?.[key]?.writeKey ?? '',
          writeInfo: value?.[key]?.writeInfo ?? undefined,
          readInfo: info,
        });
      }

      setFormValue({
        formBindKeys: newFormBindKeys,
      });
      form.setFieldsValue({
        formBindKeys: newFormBindKeys,
      });
    }, [readParams, value, form]);

    const onValuesChange = useCallback(
      (_: any, values: any) => {
        const newSaveValue: any = {};
        let isSafe = true;

        values.formBindKeys.every(
          (item: { readKey: string | undefined; writeKey: string | undefined }) => {
            if (typeof item === 'undefined') {
              return false;
            }
            const { readKey = '', writeKey = '' } = item;
            if (readKey.length < 1 || writeKey.length < 1) {
              return false;
            }

            const readInfo = readKeyMap[readKey];
            const writeInfo = writeKeyMap[writeKey];
            const { dataType: readDataType } = readInfo;
            const { dataType: writeDataType } = writeInfo;

            if (readDataType !== writeDataType) {
              isSafe = false;
              return false;
            }

            newSaveValue[readKey] = {
              writeKey,
              readKey,
              readInfo: readKeyMap[readKey],
              writeInfo: writeKeyMap[writeKey],
            };
            return true;
          },
        );

        if (typeof onChange === 'function' && isSafe) {
          onChange(newSaveValue);
        }
      },
      [onChange, readKeyMap, writeKeyMap],
    );

    if (formValue.formBindKeys.length < 1) {
      return <Alert message="请选择出入参" type="warning" showIcon />;
    }

    return (
      <>
        <Form
          form={form}
          initialValues={formValue}
          onValuesChange={onValuesChange}
          autoComplete="off"
        >
          <Space align="baseline">
            <div style={headerTitleStyle}>{readTitle}</div>
            <div style={headerTitleStyle}>{writeTitle}</div>
          </Space>
          <Form.List name="formBindKeys">
            {(fields) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.readKey !== curValues.readKey
                      }
                    >
                      {() => (
                        <Form.Item {...field} tooltip="入参字段" name={[field.name, 'readKey']}>
                          <Select
                            style={{ width: 130 }}
                            placeholder="请选择字段"
                            disabled={readOrWrite === ReadOrWrite.READ_DISABLED}
                          >
                            {Object.entries(readKeyMap).map(([key, item]) => (
                              <Option key={key} value={item.path}>
                                {`${item?.title ?? item.name}(${item.path})`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.writeKey !== curValues.writeKey
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          tooltip="出参字段"
                          name={[field.name, 'writeKey']}
                          rules={[
                            {
                              validator: arrayValidator(field, formValue, readKeyMap, writeKeyMap),
                            },
                          ]}
                        >
                          <Select
                            style={{ width: 130 }}
                            placeholder="请选择字段"
                            disabled={readOrWrite === ReadOrWrite.WRITE_DISABLED}
                          >
                            {Object.entries(writeKeyMap).map(([key, item]) => (
                              <Option key={key} value={item.path}>
                                {`${item.name}`}
                              </Option>
                            ))}
                            <Option key="undefined" value="undefined">
                              置空
                            </Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  },
);
