import React, { useContext } from 'react';
import { Tooltip, Select, Popover, Space, Typography } from 'antd';
import { StoreContext, PassValueTypes } from './constants';
import PickGlobalVar from '@/components/PickGlobalVar';
import { EPassValueType, EPassValueTypeText } from './types';

import './index.less';

interface TInfo {
  passValueType: EPassValueType;
  passValueConfig: string;
}
interface IRowProps {
  info: TInfo;
  // eslint-disable-next-line react/no-unused-prop-types
  onPassValueTypeChange: (value: string) => void;
  onPassValueConfigChange: (...args: any[]) => void;
}

// function ExpressIcon({ color = '#666' }: { color?: string } = {}) {
//   return (
//     <span style={{ color, position: 'relative', bottom: '-4px' }}>
//       <svg
//         fill="currentColor"
//         preserveAspectRatio="xMidYMid meet"
//         width="20"
//         height="20"
//         viewBox="0 0 1024 1024"
//         data-spm-anchor-id="0.0.0.i29.68b04b2c4jJnEp"
//       >
//         <path d="M596.32 263.392c18.048 6.56 27.328 26.496 20.8 44.512l-151.04 414.912a34.72 34.72 0 1 1-65.28-23.744l151.04-414.912a34.72 34.72 0 0 1 44.48-20.768zM220.64 192H273.6v55.488H233.024c-26.112 0-38.464 14.4-38.464 44.544v134.304c0 42.496-19.936 71.264-59.104 85.664 39.168 16.448 59.104 44.544 59.104 85.664v134.976c0 28.8 12.352 43.84 38.464 43.84H273.6V832H220.672c-30.24 0-53.6-10.272-70.08-29.44-15.136-17.856-22.72-42.496-22.72-72.64v-128.832c0-19.872-4.096-34.24-12.352-43.2-9.6-10.944-26.784-16.416-51.52-17.792v-56.192c24.736-1.376 41.92-7.52 51.52-17.824 8.256-9.6 12.384-24 12.384-43.168V294.784c0-30.848 7.552-55.488 22.688-73.312C167.04 201.6 190.4 192 220.672 192z m529.792 0h52.896c30.24 0 53.6 9.6 70.08 29.44 15.136 17.856 22.72 42.496 22.72 73.344v128.128c0 19.2 4.096 34.24 13.024 43.84 8.96 9.6 26.112 15.776 50.848 17.152v56.192c-24.736 1.376-41.92 6.848-51.52 17.824-8.256 8.896-12.384 23.296-12.384 43.168v128.8c0 30.176-7.552 54.816-22.688 72.64-16.48 19.2-39.84 29.472-70.08 29.472h-52.896v-55.488h40.544c25.408 0 38.464-15.104 38.464-43.84v-135.04c0-41.088 19.232-69.184 59.104-85.632-39.872-14.4-59.104-43.168-59.104-85.664V292.032c0-30.144-13.056-44.544-38.464-44.544H750.4V192z" />
//       </svg>
//     </span>
//   );
// }

const CustomPassValue: React.FC<IRowProps> = (props) => {
  const { info, onPassValueTypeChange, onPassValueConfigChange } = props;
  const context = useContext(StoreContext);
  const isView = context.state.mode === 'view';

  return (
    <Popover
      trigger="click"
      content={
        <Space direction="vertical">
          <span>请选择传值类型</span>
          <Select
            allowClear
            options={PassValueTypes}
            defaultValue="1"
            placeholder="请选择传值类型"
            disabled={isView}
            value={info?.passValueType ?? EPassValueType.直接传值}
            onChange={async (val) => {
              onPassValueTypeChange(val);
            }}
          />
          {info?.passValueType &&
          [EPassValueType.前端传值, EPassValueType.后端传值].includes(info?.passValueType) ? (
            <PickGlobalVar
              value={info?.passValueConfig ?? ''}
              onSelected={(...args: any) => onPassValueConfigChange(...args)}
              isBackend={info.passValueType === EPassValueType.后端传值}
            />
          ) : null}
        </Space>
      }
      title={null}
    >
      <Tooltip placement="left" title="绑定全局变量标识。 默认不绑定，即不标识">
        <Typography.Link>
          {info?.passValueType ? EPassValueTypeText[info?.passValueType] : EPassValueTypeText[1]}
          {info?.passValueConfig && `:${info?.passValueConfig}`}
        </Typography.Link>
      </Tooltip>
    </Popover>
  );
};

export default CustomPassValue;
