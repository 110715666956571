import React, { useCallback } from 'react';
import { Button, Space } from 'antd';

import useDCParamsModal from './DCParamsModal/index';
import useJsonParamsModal from './JsonParamsModal/index';

import { EMethodPoolType } from '@/types';

import type { DCParams } from './DCParamsModal/Form';

type Mode = 'view' | 'edit' | 'add' | 'copy';
interface TProps {
  formRef: any;
  formData: any;
  mode: Mode;
  onDCSave: (data: DCParams) => void;
  onJsonSave: (data: any) => void;
}
const ParamsEditor: React.FC<TProps> = (props) => {
  const { formRef, mode, onDCSave, onJsonSave } = props;
  const disabled = mode === 'view';
  const { protocolType } = props.formData;
  const dcConfig =
    formRef?.current?.getFieldValue('dcConfig') || formRef?.current?.getFieldValue('pojoDcConfig');

  const {
    serviceName: dcConfigServiceName,
    methodName: dcConfigMethodName,
    groupId: dcConfigGroupId,
    artifactId: dcConfigArtifactId,
    version: dcConfigVersion,
  } = dcConfig ?? { methodName: '', serviceName: '', groupId: '', artifactId: '', version: '' };
  const DCInitValue = {
    serviceName: dcConfigServiceName,
    methodName: dcConfigMethodName,
    groupId: dcConfigGroupId,
    artifactId: dcConfigArtifactId,
    version: dcConfigVersion,
    protocolType,
  };

  const {
    show: showDCParamsModal,
    hide: hideDCParamsModal,
    visible: DCParamsModalVisible,
    Form: DCParamsModal,
  } = useDCParamsModal();
  const {
    show: showJsonParamsModal,
    hide: hideJsonParamsModal,
    visible: JsonParamsModalVisible,
    Form: JsonParamsModal,
  } = useJsonParamsModal();

  const saveDCParams = useCallback(
    (data) => {
      onDCSave(data);
    },
    [onDCSave],
  );

  const saveJsonParams = useCallback(
    (data) => {
      onJsonSave(data);
    },
    [onJsonSave],
  );

  // 二次编辑，需要等等数据准备后，再回显
  if (mode === 'edit' && DCParamsModalVisible && typeof dcConfig === 'undefined') {
    // 展示坐标推断时，需要等dcConfig数据准备好
    return null;
  }

  return (
    <>
      <DCParamsModal
        visible={DCParamsModalVisible}
        onClose={hideDCParamsModal}
        onSave={saveDCParams}
        initValue={DCInitValue}
      />
      <JsonParamsModal
        visible={JsonParamsModalVisible}
        onClose={hideJsonParamsModal}
        onSave={saveJsonParams}
      />
      <Space>
        {protocolType === EMethodPoolType.DC || protocolType === EMethodPoolType.NEW_DC ? (
          <Button type="primary" disabled={disabled} onClick={showDCParamsModal}>
            使用坐标推断
          </Button>
        ) : null}
        <Button type="primary" disabled={disabled} onClick={showJsonParamsModal}>
          使用JSON推断
        </Button>
      </Space>
    </>
  );
};

export default ParamsEditor;
