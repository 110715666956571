import React, { useState, useRef } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useIframeWindow } from '../hooks/useIframeWindow';

const CustomPage: React.FC = () => {
  const params = useParams();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  useIframeWindow({
    listenData(data) {
      console.log('orca receive data: ', data);

      return {
        rCode: 0,
      };
    },
  });

  return (
    <div className="h-full" style={{ position: 'relative' }}>
      {loading && <Spin spinning />}
      <Tooltip title="刷新 Iframe 页面" placement="left">
        <Button
          style={{ position: 'absolute', right: -10, top: -10 }}
          size="small"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => {
            setRefresh(true);
            setLoading(true);
            setTimeout(() => {
              setRefresh(false);
            }, 100);
            // iframeRef.current?.contentWindow?.location?.reload()
          }}
        />
      </Tooltip>
      {!refresh && (
        <iframe
          ref={iframeRef}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          title="自定义页面"
          onLoad={() => {
            setLoading(false);
          }}
          src={params.url}
        />
      )}
    </div>
  );
};

export default CustomPage;
