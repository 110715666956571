import { memo } from 'react';
import { Typography, Row } from 'antd';
import useFormGeneratorStore from '@/store/form-generator-list';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { LeftFixedName } from '../help';
import HocToolTip from '../HocToolTip';
import './Header.less';

export const Name = 'list_header';

// 默认配置信息
export const DEFAULT_CONFIIG = {
  titleName: '标题',
  hasTip: false,
  tipContent: '提示内容',
  used: true,
  version: '0.0.1',
};

const Header = memo(() => {
  const { title } = useFormGeneratorStore((state) => state);
  const { bindEvents, isFocus } = useEvents(Name);
  const QuestionView = HocToolTip(QuestionCircleOutlined, title.tipContent || '提示内容', false);
  return (
    <Row {...bindEvents('fg-list-header')} data-cmpname="list-header">
      {isFocus && <LeftFixedName name="标题" />}
      <Typography.Title level={4}>
        {title?.titleName}
        {title.hasTip && <QuestionView />}
      </Typography.Title>
    </Row>
  );
});

export default Header;
