import { useState, useMemo, useEffect, useCallback } from 'react';
import uniq from 'lodash/uniq';
import pick from 'lodash/pick';

export default function useSelection(rowSelection: any) {
  const [checkedRows, setCheckedRows] = useState<any[]>(rowSelection?.checkedRows || []);
  const isRadio = rowSelection?.type === 'radio';
  const isCheckbox = rowSelection?.type === 'checkbox';
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const realRowSelection = useMemo(() => {
    if (!rowSelection) {
      return {};
    }
    // selectedRowKeys
    return {
      ...pick(rowSelection, [
        'checkStrictly',
        'columnTitle',
        'columnWidth',
        'fixed',
        'hideSelectAll',
        'preserveSelectedRowKeys',
        'defaultSelectedRowKeys',
        'selections',
        'type',
      ]),
      onChange(keys: string[], selectedRows: any[]) {
        console.log(keys, 'onchange', selectedRows);
        rowSelection?.onChange?.(keys, selectedRows);
      },
      onSelectAll(selected: boolean, selectedRows: any[], changeRows: any[]) {
        if (isCheckbox) {
          if (selected) {
            let list: any[] = selectedRows;
            if (checkedRows.length && selectedRows.length) {
              list = selectedRows.filter(
                (o) =>
                  o &&
                  !checkedRows
                    .map((c: any) => c[rowSelection.idKey])
                    .includes((o as any)[rowSelection.idKey]),
              );
            }
            setCheckedRows(uniq([...checkedRows, ...list]));
          } else {
            let list: any[] = [];
            if (checkedRows.length && changeRows.length) {
              list = checkedRows.filter(
                (o) =>
                  o &&
                  !changeRows
                    .map((c) => (c as any)[rowSelection.idKey])
                    .includes((o as any)[rowSelection.idKey]),
              );
            }
            setCheckedRows(uniq([...list]));
          }
        }
        (rowSelection as any)?.onSelectAll?.(selected, selectedRows, changeRows);
      },
      onSelect(record: any, selected: boolean) {
        if (isCheckbox) {
          const id = (record as any)[rowSelection.idKey];
          if (!selected) {
            const result = [...checkedRows.filter((o: any) => o[rowSelection.idKey] !== id)];
            setCheckedRows(result);
          } else {
            setCheckedRows(uniq([...checkedRows, record]));
          }
        } else if (isRadio) {
          setCheckedRows([record]);
        }
        (rowSelection as any)?.onSelect?.(record, selected);
      },
    } as any;
  }, [rowSelection, checkedRows, isCheckbox, isRadio]);

  useEffect(() => {
    if (!rowSelection && !(rowSelection as any)?.idKey) {
      return;
    }
    const ids = checkedRows
      ?.filter((o) => !!o)
      .map((o: any) => o[(rowSelection as any)?.idKey])
      .filter((id) => !!id);
    setSelectedRowKeys(Array.from(new Set(ids)));
  }, [checkedRows, rowSelection?.idKey]);

  const getRowSelection = () => {
    return rowSelection
      ? {
          ...realRowSelection,
          selectedRowKeys, // isRadio ? undefined : selectedRowKeys,
        }
      : undefined;
  };

  const getRowClickSelect = useCallback(
    (record: any, validate: (record: any) => boolean = () => true) => {
      return {
        onClick() {
          if (validate(record)) {
            setCheckedRows(uniq([...checkedRows, record]));
          }
        },
      };
    },
    [checkedRows],
  );

  const getRowClickSelectRowKeys = useCallback(
    (record: any) => {
      return {
        onClick() {
          if (isCheckbox) {
            setCheckedRows(uniq([...checkedRows, record]));
          } else if (isRadio) {
            setCheckedRows([record]);
          }
          (rowSelection as any)?.onSelect?.(record, true);
        },
      };
    },
    [isCheckbox, isRadio, checkedRows, rowSelection],
  );

  return {
    realRowSelection,
    checkedRows,
    selectedRowKeys,
    isRadio,
    getRowSelection,
    setSelectedRowKeys,
    getRowClickSelect,
    getRowClickSelectRowKeys,
  };
}
