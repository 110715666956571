import React, { memo } from 'react';
import type { TableProps } from 'antd';
import { Table } from 'antd';
import { dragComponents } from '@/components/SortableTable/dragable';

interface SortableTableProps extends TableProps<any> {
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const SortableTable = memo<SortableTableProps>(({ moveRow, ...rest }) => {
  return (
    <Table<any>
      {...rest}
      components={{ ...dragComponents() }}
      onRow={(record, index) => ({ index, moveRow } as any)}
    />
  );
});

export default SortableTable;
