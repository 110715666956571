import React from 'react';
import { Popconfirm, Row, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import FormCreator, { FCRules, EWidgetType } from '@/components/FormCreator';
import useFormGeneratorStore from '@/store/form-generator';
import WithCollapse from '../../WithCollapse';
import DeleteIconButton from '@/components/DeleteIconButton';
import ExportFileKeys from './ExportFileKeys';
import {
  EPageType,
  TRIGGER,
  ACTION,
  PAGE_SOURCE_TYPES,
  PAGE_RENDER_DATA_TYPES,
  EPageRenderDataType,
  EPageSourceType,
  PAGE_OPEN_MODES,
  EPageOpenMode,
} from '@/constant';
import PickExternalPage from '@/components/PickExternalPage';

import ExternalPageEventsBind from '@/components/FormGeneractor/ConfigComponent/ExternalPageEventsBind';

export const DEFAULT_BUTTON_PROPERTIES = {
  text: '新增',
  hide: false,
  buttonType: 'default',
  styleType: 'primary',
  hasTip: false,
  action: 'openPage',
};

export enum FILE_TYPE {
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
}

// 方法类型
export enum FUNCTION_TYPE {
  CUSTOM = '自定义方法',
  EXPORT = '导出方法',
}

export enum SEARCH_AREA {
  SEARCH = '搜索条件',
  CUSTOM = '自定义条件',
}

export interface ToolbarButtonProperties {
  text?: string;
  hide?: boolean;
  shapeType?: string;
  styleType?: string;
  hasTip?: boolean;
  tipContent?: string;
  buttonType?: 'default' | 'dropdown' | 'download';
  action?: string;
  children: ToolbarButtonProperties[];
  isChild?: boolean;
  page?: string;
  trigger?: string;
  fileType?: FILE_TYPE;
  bindMethod?: string;
  functionType?: FUNCTION_TYPE;
  searchArea?: SEARCH_AREA;
  exportKeys?: any;
  fileName?: string;
  pageSourceType?: EPageSourceType;
  pageOpenMode?: EPageOpenMode;
  pageRenderDataType?: EPageRenderDataType;
  externalInfo?: any;
  loadEvents?: any[];
  messageEvents?: any[];
}

const ConfigToolbarButton: React.FC<{
  properties?: ToolbarButtonProperties;
  onChange?: (values: ToolbarButtonProperties) => void;
  bindParam?: any[];
}> = ({ bindParam = [], properties, onChange }) => {
  const pageList = useFormGeneratorStore((state) => state.pageList);
  const loadMethod = bindParam.find((item) => item.type === 'loadMethod');
  let bindLoadMethodInfo: any = {};
  if (typeof loadMethod !== 'undefined') {
    bindLoadMethodInfo = loadMethod?.bindMethod ?? {};
  }

  return (
    <FormCreator
      submitter={{ render: () => null }}
      labelAlign="right"
      labelCol={{ flex: '100px' }}
      wrapperCol={{ flex: 1 }}
      // style={{ overflow: 'scroll' }}
      debug
      layout="horizontal"
      onValuesChange={debounce((_, values) => {
        // 切换按钮类型时，重新定义下默认执行动作
        let currentAction = DEFAULT_BUTTON_PROPERTIES.action;
        if (values.buttonType === 'download') {
          currentAction = ACTION.excuteFunction;
        }
        values.action = currentAction;

        // 切换按钮类型时，重新定义下执行方法
        if (values.buttonType === 'download' && typeof values.functionType === 'undefined') {
          values.functionType = FUNCTION_TYPE.EXPORT;
        }

        onChange?.(values);
      }, 500)}
      config={{
        hide: {
          label: '是否隐藏',
          fieldType: EWidgetType.Switch,
          defaultValue: properties?.hide || DEFAULT_BUTTON_PROPERTIES.hide,
        },
        text: {
          label: '按钮文案',
          // whenHidden: (formData: any) => !formData.used,
          fieldType: EWidgetType.Input,
          defaultValue: properties?.text || DEFAULT_BUTTON_PROPERTIES.text,
          rules: [FCRules.Required()],
        },
        buttonType: {
          label: '按钮类型',
          defaultValue: properties?.buttonType || DEFAULT_BUTTON_PROPERTIES.buttonType,
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [
              { label: '标准按钮', value: 'default' },
              { label: '下拉按钮', value: 'dropdown' },
              { label: '导出按钮', value: 'download' },
            ],
            allowClear: false,
          },
        },
        // styleType: {
        //   label: '样式类型',
        //   defaultValue: properties.styleType || DEFAULT_BUTTON_PROPERTIES.styleType,
        //   fieldType: EWidgetType.Select,
        //   fieldProps: {
        //     options: [{ label: '主要按钮', value: 'primary' }],
        //     allowClear: false,
        //   },
        // },
        hasTip: {
          label: '图标提示',
          defaultValue: properties?.hasTip || DEFAULT_BUTTON_PROPERTIES.hasTip,
          fieldType: EWidgetType.Switch,
        },
        tipContent: {
          label: '提示内容',
          defaultValue: properties?.tipContent || '',
          whenHidden: (formData: any) => !formData.hasTip,
          fieldType: EWidgetType.Input,
          fieldProps: {
            maxLength: 2000,
          },
        },
        trigger: {
          label: '触发条件',
          defaultValue: properties?.trigger || TRIGGER.click,
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [{ label: '点击按钮', value: TRIGGER.click }],
            allowClear: false,
          },
          whenHidden: (formData: any) => formData.buttonType !== 'download',
        },
        action: {
          label: '执行动作',
          defaultValue: 'openPage',
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [
              {
                label: '打开页面',
                value: 'openPage',
                disabled: properties?.buttonType === 'download',
              },
              {
                label: '执行方法',
                value: ACTION.excuteFunction,
                disabled: properties?.buttonType !== 'download',
              },
            ],
            allowClear: false,
          },
          getDynamicFieldProps(formData: any, form: any) {
            // 触发UI重新渲染
            form?.current?.setFieldsValue({
              action: formData.action,
            });

            return null;
          },
        },
        functionType: {
          label: '方法类型',
          defaultValue: properties?.functionType || FUNCTION_TYPE.CUSTOM,
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [
              { label: '自定义方法', value: FUNCTION_TYPE.CUSTOM },
              { label: '导出方法', value: FUNCTION_TYPE.EXPORT },
            ],
            allowClear: false,
          },
          getDynamicFieldProps(formData: any, form: any) {
            // 触发UI重新渲染
            form?.current?.setFieldsValue({
              functionType: formData.functionType,
            });

            return null;
          },
          whenHidden: (formData: any) => formData.buttonType !== 'download',
        },
        fileType: {
          label: '导出格式',
          defaultValue: properties?.fileType || FILE_TYPE.XLSX,
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [
              { label: 'xlsx', value: FILE_TYPE.XLSX },
              { label: 'csv', value: FILE_TYPE.CSV },
            ],
            allowClear: false,
          },
          whenHidden: (formData: any) =>
            formData.buttonType !== 'download' || formData.functionType !== FUNCTION_TYPE.EXPORT,
        },
        bindMethod: {
          label: '绑定方法',
          tooltip: '默认绑定该页面的全局加载方法，若需要调整绑定的方法，请在右上角切换加载方法',
          defaultValue: properties?.bindMethod || (bindLoadMethodInfo?.id ?? ''),
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: [
              {
                label: bindLoadMethodInfo.name,
                value: bindLoadMethodInfo.id,
              },
            ],
            disabled: true,
          },
          whenHidden: (formData: any) => formData.buttonType !== 'download',
        },
        searchArea: {
          label: '数据范围',
          tooltip: '配置导出的数据范围，按搜索条件导出表示按当前列表设置的搜索条件，导出相关的数据',
          defaultValue: properties?.searchArea || SEARCH_AREA.SEARCH,
          fieldType: EWidgetType.RadioGroup,
          fieldProps: {
            options: [
              { label: '按搜索条件', value: SEARCH_AREA.SEARCH },
              { label: '自定义搜索', value: SEARCH_AREA.CUSTOM },
            ],
            disabled: true,
          },
          whenHidden: (formData: any) =>
            formData.buttonType !== 'download' || formData.functionType !== FUNCTION_TYPE.EXPORT,
        },
        fileName: {
          label: '文件名称',
          tooltip: '配置导出的文件的默认命名，最终将以文件命名＋执行导出时间作为文件名称',
          defaultValue: properties?.fileName || '导出数据',
          fieldType: EWidgetType.Input,
          fieldProps: {
            maxLength: 200,
          },
          rules: [FCRules.Required()],
          whenHidden: (formData: any) =>
            formData.buttonType !== 'download' || formData.functionType !== FUNCTION_TYPE.EXPORT,
        },
        exportKeys: {
          label: '表头字段',
          tooltip: '以下字段将作为导出的报表表头字段',
          defaultValue: properties?.exportKeys,
          fieldType: EWidgetType.CustomWidget,
          custom: (props: any) => <ExportFileKeys {...props} bindParam={bindParam} />,
          whenHidden: (formData: any) =>
            formData.buttonType !== 'download' || formData.functionType !== FUNCTION_TYPE.EXPORT,
        },
        pageSourceType: {
          label: '绑定页面',
          defaultValue: properties?.pageSourceType || EPageSourceType.BUILT_IN,
          fieldType: EWidgetType.Select,
          rules: [FCRules.Required('请选择')],
          fieldProps: {
            options: PAGE_SOURCE_TYPES,
          },
          whenHidden: (formData: any) => formData.action !== 'openPage',
        },
        pageOpenMode: {
          label: '交互方式',
          defaultValue: properties?.pageOpenMode || EPageOpenMode.DRAWER,
          fieldType: EWidgetType.Select,
          rules: [FCRules.Required('请选择')],
          fieldProps: {
            options: PAGE_OPEN_MODES,
          },
          whenHidden: (formData: any) =>
            formData.action !== 'openPage' ||
            (formData.action === 'openPage' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL),
        },
        pageRenderDataType: {
          label: '页面类型',
          defaultValue: properties?.pageRenderDataType || EPageRenderDataType.REQUEST_DATA,
          fieldType: EWidgetType.Select,
          rules: [FCRules.Required('请选择')],
          fieldProps: {
            options: PAGE_RENDER_DATA_TYPES,
          },
          whenHidden: (formData: any) =>
            formData.action !== 'openPage' ||
            (formData.action === 'openPage' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL),
        },
        page: {
          label: '内部页面',
          defaultValue: properties?.page,
          whenHidden: (formData: any) =>
            formData.action !== 'openPage' ||
            (formData.action === 'openPage' &&
              formData.pageSourceType !== EPageSourceType.BUILT_IN),
          fieldType: EWidgetType.Select,
          fieldProps: {
            options: pageList
              .filter((page) => String(page.type) === EPageType.新增页面)
              .map((page) => ({
                label: page.name,
                value: page.id,
              })),
          },
        },
        externalInfo: {
          label: '外部页面',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: properties?.externalInfo ?? undefined,
          whenHidden(formData: any) {
            return (
              formData.action !== 'openPage' ||
              (formData.action === 'openPage' &&
                formData.pageSourceType !== EPageSourceType.EXTERNAL)
            );
          },
          custom(props: any) {
            return (
              <PickExternalPage
                {...props}
                value={props?.value ? [props?.value] : undefined}
                getLabelByValue={(row) => {
                  return row?.length ? row[0].name : '请选择外部页面';
                }}
                checkedList={[props?.value].filter((o) => Boolean(o))}
                onChange={(row) => {
                  const info = row?.length ? row[0] : null;
                  if (info) {
                    props?.onChange?.({
                      ...info,
                      id: info.id,
                      name: info.name,
                      url: info.url,
                      loadEvents: info.loadEvents,
                      messageEvents: info.messageEvents,
                    });
                  }
                }}
              />
            );
          },
        },
        loadEvents: {
          defaultValue: properties?.loadEvents ?? [],
          fieldType: EWidgetType.CustomWidget,
          fieldProps: {},
          whenHidden(formData: any) {
            return (
              formData.action !== 'openPage' ||
              (formData.action !== 'openPage' &&
                formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
              !Array.isArray(formData?.externalInfo?.loadEvents ?? null)
            );
          },
          custom(props: any) {
            const { value = [] } = props;
            const eventList = props?.formData?.externalInfo?.loadEvents ?? [];
            const safeValue: Record<string, any> = {};
            eventList.forEach(({ key }: { key: string }) => {
              safeValue[key] = value[key];
            });

            return (
              <ExternalPageEventsBind
                title="加载配置"
                value={safeValue}
                eventList={eventList}
                onChange={(data) => {
                  props?.onChange(data);
                }}
              />
            );
          },
        },
        messageEvents: {
          defaultValue: properties?.messageEvents ?? [],
          fieldType: EWidgetType.CustomWidget,
          fieldProps: {},
          whenHidden(formData: any) {
            return (
              formData.action !== 'openPage' ||
              (formData.action !== 'openPage' &&
                formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
              !Array.isArray(formData?.externalInfo?.messageEvents ?? null)
            );
          },
          custom(props: any) {
            const { value = [] } = props;
            const eventList = props?.formData?.externalInfo?.messageEvents ?? [];
            const safeValue: Record<string, any> = {};
            eventList.forEach(({ key }: { key: string }) => {
              safeValue[key] = value[key];
            });

            return (
              <ExternalPageEventsBind
                title="动作配置"
                value={safeValue}
                eventList={eventList}
                onChange={(data) => {
                  props?.onChange(data);
                }}
              />
            );
          },
        },
        // openMethod: {
        //   label: '打开方式',
        //   whenHidden: (formData: any) => formData.action === 'openPage',
        //   fieldType: EWidgetType.Text,
        //   defaultValue: '抽屉',
        // },
        children: {
          label: null,
          defaultValue: properties?.children || [],
          whenHidden: (formData: any) => formData.buttonType !== 'dropdown',
          fieldType: EWidgetType.CustomWidget,
          wrapperCol: { span: 24 },
          custom(props: any) {
            const children = props.value;
            return (
              <>
                <WithCollapse
                  collapseDatas={children.map(
                    (buttonProperty: ToolbarButtonProperties, index: number) => ({
                      title: (
                        <Row justify="space-between">
                          <span>{`子按钮配置 - ${buttonProperty.text || ''}`}</span>
                          <Popconfirm
                            title="确定要删除？"
                            cancelText="取消"
                            okText="确定"
                            onConfirm={() => {
                              children.splice(index, 1);
                              props?.onChange?.(children);
                            }}
                          >
                            <div style={{ width: 40 }}>
                              <DeleteIconButton />
                            </div>
                          </Popconfirm>
                        </Row>
                      ),
                      key: index,
                      configComponent: (
                        <ConfigToolbarButton
                          properties={buttonProperty}
                          onChange={(values) => {
                            children[index] = values;
                            props?.onChange?.(children);
                          }}
                        />
                      ),
                    }),
                  )}
                />
                <Row>
                  <Button
                    type="link"
                    onClick={() => {
                      props?.onChange?.([
                        ...children,
                        { ...DEFAULT_BUTTON_PROPERTIES, isChild: true },
                      ]);
                    }}
                    icon={<PlusCircleOutlined />}
                  >
                    添加子按钮
                  </Button>
                </Row>
              </>
            );
          },
        },
      }}
    />
  );
};

export default ConfigToolbarButton;
