import React from 'react';

// 将 background: green; 之类的 css 文本转为 React 节点可用的 style object
export function cssToStyleObject(css: string): React.CSSProperties | undefined {
  const obj: any = {};
  try {
    const s = css
      .toLowerCase()
      .replace(/-(.)/g, (m, g) => {
        return g.toUpperCase();
      })
      .replace(/;\s?$/g, '')
      .split(/:|;/g);
    for (let i = 0; i < s.length; i += 2)
      obj[s[i].replace(/\s/g, '')] = s[i + 1].replace(/^\s+|\s+$/g, '');
    return obj;
  } catch (e) {
    console.error('CSS 解析转换异常', e, css);
    return undefined;
  }
}

const HEX_RE = /^#(.{2})(.{2})(.{2})(.{2})$/;
// 仅支持 HEX 格式的互转
export function fromARGBtoRGBA(hex: string): string | null {
  const match = hex.trim().match(HEX_RE);
  if (!match) return null;
  return `#${match[2]}${match[3]}${match[4]}${match[1]}`;
}
