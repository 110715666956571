import { memo, useRef } from 'react';
import type { FC } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import { default as ProTable } from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import Text from '@/components/Column/Text';
import useSetState from '@/hooks/useSetState';
import type { Mode } from './Editor';
import Editor from './Editor';
// import Text from '@/components/Column/Text';
// import useSetState from '@/hooks/useSetState';
// import { MethodTypes, MethodPoolTypes, SQLMethods } from '@/types';
import omit from 'lodash/omit';
// import type { Mode } from './Editor';
import { DEFAULT_PAGE_SIZE, DEFAULT_LIST_SCROLL } from '@/constant';
// import SearchGroups from './SearchGroups';
// import { pageList } from '@/services/pageGroup';
// import type { ProColumns, ActionType } from '@ant-design/pro-table';
// import DeleteIconButton from '@/components/DeleteIconButton';
import EditIconButton from '@/components/EditIconButton';

import { getExternalPageList } from '@/services/pageGroup';

const modeMap = {
  edit: '编辑',
  add: '新建',
  view: '查看',
  copy: '新建',
};

const ExternalPage: FC = memo(() => {
  const actionRef = useRef<ActionType>();
  const [state, setState] = useSetState({
    editId: '',
    mode: 'add' as Mode,
    visible: false,
  });

  const columns: Array<ProColumns<any>> = [
    {
      title: '页面ID',
      dataIndex: 'id',
      width: 200,
      render: (_, record) => <Text copyable value={record.id} />,
    },
    {
      title: '页面名称',
      dataIndex: 'name',
      ellipsis: true,
      render: (_, record) => <Text copyable value={record.name} />,
    },
    {
      title: '页面URL',
      dataIndex: 'url',
      ellipsis: true,
      search: false,
      render: (_, record) => <Text copyable value={record.url} />,
    },
    {
      title: '操作',
      valueType: 'option',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Space>
          <EditIconButton
            onClick={() => {
              setState({
                visible: true,
                mode: 'edit',
                editId: record.id,
              });
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <ProTable<any>
        columns={columns}
        actionRef={actionRef}
        search={{
          defaultCollapsed: false,
        }}
        scroll={DEFAULT_LIST_SCROLL}
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
        }}
        request={async (params) => {
          params.pageNumber = params.current;
          const res = await getExternalPageList(omit(params, ['current']));
          return {
            data: res?.data?.list || [],
            total: res?.data?.total || 0,
            success: true,
          };
        }}
        rowKey="id"
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setState({
                visible: true,
                editId: '',
                mode: 'add',
              });
            }}
          >
            新建
          </Button>,
        ]}
      />

      <Drawer
        title={modeMap[state.mode]}
        width="100%"
        placement="right"
        onClose={() => setState({ visible: false })}
        visible={state.visible}
        destroyOnClose
      >
        <Editor
          onCancel={() => {
            setState({ visible: false });
            actionRef.current?.reload();
          }}
          onComplete={() => {
            setState({ visible: false });
            actionRef.current?.reload();
          }}
          mode={state.mode}
          id={state.editId}
        />
      </Drawer>
    </>
  );
});

export default ExternalPage;
