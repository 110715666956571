import { memo, useCallback, useState } from 'react';
import { Upload, Modal, message } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { token } from '@/utils/utils';
import { prefixSystem } from '@/services/common';
import { HOST } from '@/constant';

interface VieoUploadProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}
const uploadButton = (
  <div>
    <PlusOutlined />
  </div>
);
interface TPreviewInfo {
  videoSrc?: string;
  previewVisible: boolean;
}

// 获取文件后缀类型
function fileExtension(filePath: string) {
  // 获取最后一个.的位置
  const index = filePath.lastIndexOf('.');
  // 获取后缀
  const type = filePath.substr(index + 1);
  // 返回类型
  return type.toLowerCase();
}

const VieoUpload = memo<VieoUploadProps>(({ configInfo }) => {
  const {
    preFileLink = '',
    fileType,
    fileTypeLimit = ['mp4', 'rmvb', 'avi', 'mkv'],
    maxCount = 3,
    sizeLimit = false,
    singleFileSize = 2,
    limitPrompt = '',
  } = configInfo;

  const [previewInfo, setPreviewInfo] = useState<TPreviewInfo>({
    videoSrc: '',
    previewVisible: false,
  });
  // const componentId = '5236303148810766463'; // TODO: 替换成真实的组件id
  const [fileList, setFileList] = useState([]);

  // 监听视频上传
  const onChangeImg = useCallback(
    ({ fileList: newFileList, file }) => {
      newFileList.forEach((imgItem: UploadFile) => {
        if (
          imgItem &&
          imgItem.status == 'done' &&
          imgItem.response &&
          imgItem.response.data &&
          preFileLink.length > 0
        ) {
          imgItem.thumbUrl = `${preFileLink}${imgItem.response.data.path}`;
        }
      });

      setFileList(
        newFileList.filter((item: UploadFile) => {
          if (['uploading', 'success', 'removed'].includes(item.status as string)) {
            return true;
          }
          if (item.status == 'done' && item.response.rCode == 0) {
            return true;
          }
          return false;
        }),
      ); // 阻止上传的视频，不存入fileList

      const { status, response } = file;

      switch (status) {
        case 'error':
          message.error('上传失败');
          break;
        case 'done':
          if (response.rCode !== 0) {
            message.error('上传失败');
            return;
          }
          message.success('上传成功');
          break;
      }
    },
    [preFileLink],
  );

  // 预览
  const handlePreview = useCallback((file) => {
    setPreviewInfo({
      videoSrc: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }, []);

  const handleCancel = useCallback(() => {
    setPreviewInfo({
      previewVisible: false,
    });
  }, []);

  // 限制视频 格式、size、分辨率
  const handleBeforeUpload = useCallback(
    (file) => {
      if (preFileLink.length < 1) {
        Modal.error({
          title: '请在交互设置中填写“图片资源的url前缀”',
        });
        return false;
      }

      if (typeof fileType === 'undefined') {
        Modal.error({
          title: '请在交互设置中填写“内部上传编码”',
        });
        return false;
      }

      const isSafeImage = fileTypeLimit.includes(fileExtension(file.name));

      if (!isSafeImage) {
        Modal.error({
          title:
            limitPrompt.length > 0 ? limitPrompt : `只能上传${fileTypeLimit.join('，')}格式的视频`,
        });
        return false;
      }
      const isLowerLimit = file.size / 1024 / 1024 < singleFileSize; // 视频*m大小的限制
      if (sizeLimit && !isLowerLimit) {
        Modal.error({
          title: `超过${singleFileSize}M限制，不允许上传~`,
        });
        return false;
      }
      return true;
    },
    [preFileLink, fileType, singleFileSize, sizeLimit, limitPrompt, fileTypeLimit],
  );

  return (
    <>
      <Upload
        action={HOST + prefixSystem('upload/file')}
        listType="picture-card"
        data={{
          fileType,
        }}
        headers={{
          'X-SSO-Authorization': token as string,
        }}
        fileList={fileList}
        maxCount={maxCount}
        onChange={onChangeImg}
        onPreview={handlePreview}
        beforeUpload={handleBeforeUpload}
        withCredentials
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>
      <Modal visible={previewInfo.previewVisible} footer={null} onCancel={handleCancel}>
        <video width="100%" controls id="video">
          <source src={previewInfo.videoSrc} type="video/mp4" />
          <object data={previewInfo.videoSrc} width="100%">
            <embed src={previewInfo.videoSrc} width="100%" />
          </object>
          您的浏览器不支持video标签
        </video>
      </Modal>
    </>
  );
});

export default VieoUpload;
