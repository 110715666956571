import React, { memo } from 'react';
import { Tooltip, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IDeleteIconButtonProps {
  msg?: string;
  disabled?: boolean;
  color?: string;
  size?: number;
  onClick?: () => void;
}

const DeleteIconButton = memo<IDeleteIconButtonProps>(
  ({ msg = '删除', disabled = false, color = 'red', size, onClick }) => {
    if (disabled) {
      return <DeleteOutlined style={{ color: '#ccc' }} />;
    }
    return (
      <Tooltip title={msg}>
        <Typography.Link onClick={onClick} disabled={disabled}>
          <DeleteOutlined style={{ color, fontSize: size || 12 }} />
        </Typography.Link>
      </Tooltip>
    );
  },
);
export default DeleteIconButton;
