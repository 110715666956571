import { Modal, ModalFuncProps } from 'antd';

/* 二次确认 */
export const promisifyConfirm = (
  props: Omit<ModalFuncProps, 'onOk' | 'onCancel'>,
): Promise<boolean> =>
  new Promise((resolve) => {
    Modal.confirm({
      ...props,
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });

// 转换服务端的数据类型对应到Editor识别的类型
export function changeDataTypeToEditor(type: string | undefined) {
  switch (type) {
    case 'List':
      return 'array';
    case 'Object':
      return 'object';
    case 'String':
      return 'string';
    case 'Integer':
      return 'integer';
    case 'Long':
      return 'number';
    case 'Boolean':
      return 'boolean';
    case 'Float':
      return 'float';
    case 'Double':
      return 'double';
    case 'Date':
      return 'date';
    case 'Byte':
      return 'byte';
    case 'Short':
      return 'short';
    default:
      return 'string';
  }
}
// Editor数据类型对应到服务端识别的类型
export function changeEditorTypeToServerDataType(type: string | undefined) {
  switch (type) {
    case 'array':
      return 'List';
    case 'object':
      return 'Object';
    case 'string':
      return 'String';
    case 'integer':
      return 'Integer';
    case 'number':
      return 'Long';
    case 'boolean':
      return 'Boolean';
    case 'double':
      return 'Double';
    case 'float':
      return 'Float';
    case 'date':
      return 'Date';
    case 'byte':
      return 'Byte';
    case 'short':
      return 'Short';
    default:
      return 'String';
  }
}

// 判断是否是json
export function isJsonString(str: string) {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true;
    }
  } catch (e) {
    //
  }
  return false;
}

export function transferValueFromInAndOutParams(
  editValue: Record<string, any>,
  writeKey: string,
  readValue: any,
) {
  const newEditValue = {
    ...editValue,
  };
  let now = newEditValue;
  const keys = writeKey.split('.');
  const len = keys.length - 1;

  keys.forEach((k: string, index) => {
    if (typeof editValue[k] === 'undefined' && index < len) {
      newEditValue[k] = {};
      now = newEditValue[k];
    } else if (index < len) {
      now = newEditValue[k];
    } else {
      now[k] = readValue;
    }
  });

  return newEditValue;
}
