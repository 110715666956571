import React, { memo, useEffect, useState } from 'react';
import { Cascader } from 'antd';
import useRegioEnv from './useRegioEnv';

interface RegioEnvSelectorProps {
  value?: any;
  onChange?: (val: any) => void;
  readOnly?: boolean;
}
const RegioEnvSelector = memo<RegioEnvSelectorProps>(({ value, onChange, readOnly }) => {
  const [options, setOptions] = useState([]);
  const { load, loading } = useRegioEnv();

  useEffect(() => {
    const init = async () => {
      const data = await load();
      setOptions(data);
    };
    init();
  }, []);

  return (
    <>
      <Cascader
        disabled={readOnly}
        showSearch
        allowClear
        loading={loading}
        options={options}
        onChange={(v) => {
          onChange?.(
            v?.length
              ? {
                  region: v[0],
                  businessEnv: v[1] || null,
                }
              : null,
          );
        }}
        value={value ? [value.region, value.businessEnv].filter((o) => Boolean(o)) : undefined}
        fieldNames={{
          label: 'name',
          value: 'code',
          children: 'businessEnvs',
        }}
      />
    </>
  );
});

export default RegioEnvSelector;
