import React, { useState } from 'react';
import { Button, Modal, Typography, Space } from 'antd';
import { useParams } from 'react-router-dom';
import useFormGeneratorStore from '@/store/form-generator';
import List from '@/components/PageParser/List';
import useFormGeneratorParseStore from '@/store/form-generator-parse';

const { Text } = Typography;

const ListSelector: React.FC<{
  onChange?: (data: any) => void;
  onFill?: (values: Record<string, any>) => void;
  // value?: any;
  configInfo?: any;
}> = ({ onChange, onFill, configInfo }) => {
  const [displayItems, setDisplayItems] = useState<Array<{ label: string; value: string }>>([]);
  const pageModeId = useFormGeneratorStore((state) => state.currentPageGroupId);
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const { dataIndex, listItems, disabled } = configInfo;
  const listRefresh = useFormGeneratorParseStore((state) => state.listRefresh);
  const uListRefresh = useFormGeneratorParseStore((state) => state.uListRefresh);

  const onCancel = () => {
    uListRefresh(listRefresh + 1);
    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} disabled={disabled}>
        {configInfo?.text || ' '}
      </Button>
      <div style={{ marginTop: 5 }}>
        <Space direction="horizontal">
          {displayItems.map((n) => (
            <Text keyboard>
              {n.label}：{n.value}
            </Text>
          ))}
        </Space>
      </div>
      <Modal visible={visible} onCancel={onCancel} onOk={onCancel} width="90%">
        <List
          pageModeId={params.pageId ?? pageModeId}
          pageId={configInfo?.page}
          rowSelectionType={'radio'}
          onSelect={(record) => {
            onChange?.(record[dataIndex]);
            const filled: Record<string, any> = {};
            const keys = Object.keys(listItems);
            const items = keys
              .filter((key) => listItems[key].displayField)
              .map((key) => {
                const { displayTitle: label } = listItems[key] ?? {};
                return {
                  label,
                  value: record[key],
                };
              });
            keys.forEach((key) => {
              const { backfills } = listItems[key] ?? {};
              backfills?.forEach((n: string) => {
                filled[n] = record[key];
              });
            });
            setDisplayItems(items);
            onFill?.(filled);
          }}
        />
      </Modal>
    </>
  );
};

export default ListSelector;
