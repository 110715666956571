import useListStore from '@/store/form-generator-list';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { isEmpty } from 'lodash';
import MethodsValidation from '../../MethodsValidation';
import { parseString2Json, filterNullMethods } from '@/components/FormGeneractor/help';
import useFormGeneratorStore from '@/store/form-generator';

const commonConfig = getCommonFieldConfig();

let debounceTime: any;
export default function ListBox() {
  const { listConfig, uListConfig, bindedMethods, listPageServiceConfig } = useListStore();
  const properties: any = parseString2Json(listPageServiceConfig?.properties ?? '{}');
  const { methodsValidationConfig } = properties;
  // console.log('methodsValidationConfig list', methodsValidationConfig);
  const { currentPage } = useFormGeneratorStore((state) => state);
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          layout="horizontal"
          onValuesChange={(_, allValsChange) => {
            if (debounceTime) {
              clearTimeout(debounceTime);
            }
            debounceTime = setTimeout(() => {
              uListConfig(allValsChange);
            }, 500);
          }}
          config={{
            usedTitle: {
              label: '启用标题',
              // defaultValue: typeof listConfig.usedTitle === 'undefined' ? true : listConfig.usedTitle,
              fieldType: EWidgetType.Switch,
              getDynamicFieldProps() {
                if (isEmpty(listConfig)) {
                  return;
                }

                if (typeof listConfig?.usedTitle !== 'undefined') {
                  return {
                    defaultValue: listConfig.usedTitle,
                    value: listConfig.usedTitle,
                  };
                }
              },
            },
            usedSearch: {
              label: '启用搜索区',
              // defaultValue: typeof listConfig.usedSearch === 'undefined' ? true : listConfig.usedSearch,
              fieldType: EWidgetType.Switch,
              getDynamicFieldProps() {
                if (isEmpty(listConfig)) {
                  return;
                }

                if (typeof listConfig?.usedSearch !== 'undefined') {
                  return {
                    defaultValue: listConfig.usedSearch,
                    value: listConfig.usedSearch,
                  };
                }
              },
            },
            usedPagination: {
              label: '启用分页',
              // defaultValue: typeof listConfig.usedPagination === 'undefined' ? true : listConfig.usedPagination,
              fieldType: EWidgetType.Switch,
              getDynamicFieldProps() {
                if (isEmpty(listConfig)) {
                  return;
                }

                if (typeof listConfig?.usedPagination !== 'undefined') {
                  return {
                    defaultValue: listConfig.usedPagination,
                    value: listConfig.usedPagination,
                  };
                }
              },
            },
          }}
        />
      ),
    },
    {
      title: '方法校验',
      key: `methods-validation-config${currentPage?.id}`,
      configComponent: (
        <MethodsValidation
          bindedMethods={bindedMethods}
          bindedMethodsCconfig={methodsValidationConfig}
          onChange={(values) => {
            // 删除方法
            if (!values && listConfig.methodsValidationConfig) {
              delete listConfig.methodsValidationConfig;
            } else {
              values.loadMethods = filterNullMethods(values.loadMethods || []);
            }

            listConfig.methodsValidationConfig = { ...values };

            uListConfig({ ...listConfig });
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} defaultOpenFirstOne />;
}
