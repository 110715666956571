import { memo } from 'react';
import { Radio } from 'antd';
import pick from 'lodash/pick';

interface RadioGroupProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const RadioGroup = memo<RadioGroupProps>(({ onChange, value, configInfo }) => {
  return (
    <Radio.Group
      {...pick(configInfo, ['disabled', 'placeholder', 'options'])}
      defaultValue={configInfo.defaultValue}
      value={value || configInfo.defaultValue}
      onChange={onChange}
    />
  );
});

export default RadioGroup;
