import { useState } from 'react';
import { getDataSourceInfo } from '@/services/datasource';

export default function usePageInfoById() {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const load = async (id: string): Promise<any> => {
    try {
      if (loading) {
        return null;
      }
      setLoading(true);
      const res = await getDataSourceInfo(id);
      if (res.rCode === 0) {
        setData(res.data || null);
        return res.data || null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    load,
  };
}
