import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { Image, Avatar, Row, Typography } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import type { BasicLayoutProps } from '@ant-design/pro-layout';

import { default as ProLayout, PageContainer } from '@ant-design/pro-layout';
import type { Menu } from '@/services/user';
import { queryMenu } from '@/services/user';
import type { MenuDataItem } from '@ant-design/pro-layout/lib/typings';
import { addTokenToUrl } from '@/utils/utils';
import useStore from '@/store/global';
import Logo from './hujing.png';

// 将 sso 接口返回的菜单数据转为 ProLayout 可以识别的 Menu Route 数据
function menusToRoutes(menus: Menu[]): MenuDataItem[] {
  return menus.map((n) => {
    let path: string | undefined = n.url;
    let routes: MenuDataItem[] | undefined;

    if (n.subMenus && n.subMenus.length > 0) {
      path = undefined;
      routes = menusToRoutes(n.subMenus);
    } else if (/^(https?:)?\/\//.test(n.url)) {
      const url = addTokenToUrl(n.url);
      path = `/iframe/${encodeURIComponent(url)}`;
    }

    return {
      icon: (n.icon ?? '').length === 0 ? <AppstoreOutlined /> : n.icon,
      name: n.name,
      path,
      target: n.url,
      routes,
    };
  });
}

function menuRender(
  item: MenuDataItem & {
    isUrl: boolean;
  },
  defaultDom: React.ReactNode,
): ReactNode | undefined {
  return <Link to={item.path ?? '/'}>{defaultDom}</Link>;
}

const BaseLayout = () => {
  const { uUser } = useStore((state) => state);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [position] = useState<'header' | 'menu'>('header');
  const [routes, setRoutes] = useState<MenuDataItem[]>([]);
  const [user, setUser] = useState<{
    realName?: string;
    account?: string;
    avatar?: string;
    userId?: number;
    userType?: number;
  } | null>(null);

  useEffect(() => {
    queryMenu()
      .then((res) => {
        setLoading(false);
        uUser(res?.data?.user);
        setRoutes(menusToRoutes(res?.data?.menus || []));
        setUser((res?.data as any)?.user || null);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, []);
  const children = (
    <PageContainer>
      <div
        style={{
          height: '120vh',
        }}
      />
    </PageContainer>
  );
  const props: BasicLayoutProps = {
    loading,
    route: {
      path: '',
      routes,
    },
    location: {
      pathname: location.pathname,
    },
    navTheme: 'dark',
    collapsed,
    fixSiderbar: true,
    collapsedButtonRender: false,
    primaryColor: '#409EFF',

    menuItemRender: menuRender,
    subMenuItemRender: menuRender,
  };
  if (position === 'menu') {
    return (
      <ProLayout
        {...props}
        onCollapse={setCollapsed}
        postMenuData={(menuData: any) => {
          return [
            {
              icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
              name: ' ',
              onTitleClick: () => setCollapsed(!collapsed),
            },
            ...(menuData || []),
          ];
        }}
      >
        {children}
      </ProLayout>
    );
  }
  return (
    <ProLayout
      title={'运营配置平台'}
      logo={<Image style={{ position: 'relative' }} src={Logo} width={30} />}
      {...props}
      onCollapse={setCollapsed}
      rightContentRender={() => {
        if (user) {
          return (
            <Row justify="center" align="middle">
              <Avatar
                icon={user.avatar ? undefined : <UserOutlined />}
                src={user.avatar || undefined}
                size="small"
              />
              <Typography.Text style={{ marginLeft: 5 }}>{user.realName || '-'}</Typography.Text>
            </Row>
          );
        } else {
          return null;
        }
      }}
      headerContentRender={() => {
        return (
          <div
            onClick={() => setCollapsed(!collapsed)}
            style={{
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        );
      }}
    >
      <Outlet />
    </ProLayout>
  );
};

export default BaseLayout;
