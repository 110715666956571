import { memo, useEffect } from 'react';
import { Card, Form, List, Row, Popconfirm, Typography } from 'antd';
import DeleteIconButton from '@/components/DeleteIconButton';
import useDetailStore from '@/store/form-generator-detail';
import useStore from '@/store/form-generator';
import { nanoid } from 'nanoid';
import HelpText from '@/components/HelpText';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import { EDataDisplayCmpType, EPageLayoutCmpType } from '@/constant';
import {
  getDefaultLabelByCmpType,
  LeftFixedName,
  formatAddEditDetailConfig,
  IconPlusButton,
} from '../help';

import { findCmpViewByType } from '../PickCmps/helper';

import './index.less';

export const Name = 'view_box';

const ListItem = ({ children, info }: any) => {
  const fgStore = useStore((state) => state);
  const { bindEvents, isFocus } = useEvents(info.controlId);
  const { fields, uFields } = useDetailStore((state) => state);

  // 添加组件
  const handlePushField = (fieldCmpType: any, postion: number) => {
    const curControlIndex = fields.findIndex((o) => o.controlId === info.controlId);
    const newField = {
      hide: false,
      label: getDefaultLabelByCmpType(fieldCmpType),
      controlType: fieldCmpType,
      controlId: `${nanoid()}`,
    };
    fields.splice(curControlIndex + postion, 0, newField);
    uFields([...fields]);
  };

  return (
    <div {...bindEvents('fg-view-item common-area')} key={info.controlId}>
      {isFocus && (
        <LeftFixedName name={`${getDefaultLabelByCmpType(info.controlType)}：${info.controlId}`} />
      )}
      {/* 处理添加组件 删除组件 */}
      {isFocus && (
        <>
          <Row justify="start" align="middle" className="fg-field-toolbar">
            <Popconfirm
              title="确定移除？"
              onConfirm={() => {
                const targetIdx = fields.findIndex((o) => o.controlId === info.controlId);
                if (targetIdx !== -1) {
                  fields.splice(targetIdx, 1);
                  uFields([...fields]);
                }
              }}
            >
              <div className="fg-field-delete">
                <DeleteIconButton color="#fff" />
              </div>
            </Popconfirm>
          </Row>

          <IconPlusButton
            position="top"
            onClick={() => {
              new Promise((resolve) => {
                fgStore.uCmpListPanel({
                  visible: true,
                  resolve,
                });
              }).then((cmp) => handlePushField(cmp, 0));
            }}
          />
          <IconPlusButton
            position="bottom"
            onClick={() => {
              new Promise((resolve) => {
                fgStore.uCmpListPanel({
                  visible: true,
                  resolve,
                });
              }).then((cmp) => handlePushField(cmp, 1));
            }}
          />
        </>
      )}
      {children}
    </div>
  );
};

/* 【查看表单】内容区组件 */
const ViewContent = memo(() => {
  const { bindEvents, isFocus } = useEvents(Name);
  const [form] = Form.useForm();
  const detailStore = useDetailStore((state) => state);
  const fgStore = useStore((state) => state);
  const usedFields = detailStore.fields.filter((o) => Boolean(!o.hide));
  const { uCmpListPanel } = fgStore;

  useEffect(() => {
    if (!detailStore.serviceConfig) {
      return;
    }
    // 加载数据
    const initWork = async () => {
      fgStore.uLoading(true);
      const info = await formatAddEditDetailConfig({
        serviceConfig: detailStore.serviceConfig,
        defaultCmpType: EDataDisplayCmpType.文本,
      });
      detailStore.uTitle(info.title);
      detailStore.uLabelColWidth(info.labelColWidth);
      detailStore.uWrapperColWidth(info.wrapperColWidth);
      if (!info.fields.length) {
        info.fields = (detailStore.bindedMethods?.submitMethod?.inputParams || []).map(
          (o: any) => ({
            controlType: EDataDisplayCmpType.文本,
            controlId: nanoid(),
            label: o.name,
            used: true,
            bindParam: [
              {
                type: 'loadMethod',
                bindMethod: {
                  id: detailStore.bindedMethods?.loadMethod?.id,
                  name: detailStore.bindedMethods?.loadMethod?.name,
                },
                bindVar: {
                  fieldName: o.name,
                  fieldId: o.id,
                },
                methodParamType: 'outputParams',
              },
            ],
          }),
        );
      }
      detailStore.uFields(info.fields);
      detailStore.uReady(true);
      fgStore.uCurrentFocusCmp(Name);
      fgStore.uLoading(false);
    };
    initWork();
  }, [detailStore.serviceConfig]);

  useEffect(() => {
    form.validateFields();
  }, [JSON.stringify(usedFields)]);

  return (
    <div className="view-box">
      <Card
        title={detailStore.title}
        bodyStyle={{ height: '100%', overflowY: 'scroll' }}
        style={{ height: '100%' }}
        {...bindEvents('fg-add-box common-area')}
      >
        {isFocus && <LeftFixedName name="查看页面" />}
        <List
          dataSource={usedFields}
          rowKey="controlId"
          renderItem={(item) => {
            const isTitle = item.controlType === EPageLayoutCmpType.标题;
            const hasLabel = (item?.label ?? '').length > 0;
            const showLabel = !isTitle && hasLabel;
            const ControlComponent: any = findCmpViewByType(item.controlType);
            const DisplayControlComponent = (
              <ControlComponent key={item.controlId} {...item} configInfo={item} />
            );
            return (
              <ListItem info={item}>
                <Row justify="start" align="middle">
                  {showLabel && (
                    <Typography.Text
                      className="fg-view-item-label"
                      style={{ flex: `0 0 ${detailStore.labelColWidth || 200}px` }}
                    >
                      {item.label}{' '}
                      {item.hasTip && item.tipContent && <HelpText toolTip={item.tipContent} />} ：
                    </Typography.Text>
                  )}
                  <div
                    style={{
                      flex: detailStore.wrapperColWidth
                        ? `0 0 ${detailStore.wrapperColWidth}px`
                        : 1,
                    }}
                  >
                    {DisplayControlComponent}
                  </div>
                </Row>
              </ListItem>
            );
          }}
        />
        {!usedFields.length && (
          <Row style={{ position: 'relative', marginTop: 10 }}>
            <IconPlusButton position="top" onClick={() => uCmpListPanel({ visible: true })} />
          </Row>
        )}
        <div style={{ height: 500 }} />
      </Card>
    </div>
  );
});

export default ViewContent;
