import { memo, useRef, useEffect } from 'react';
import useFormGeneratorStore from '@/store/form-generator-list';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import type { ProFormInstance } from '@ant-design/pro-form';
import WithCollapse from '../../WithCollapse';

interface IListHeaderProps {
  visible?: boolean;
}

let debounceTimer: any;
const ListHeader = memo<IListHeaderProps>(() => {
  const fgStore = useFormGeneratorStore((state) => state);
  const formRef = useRef<ProFormInstance<any>>();

  const { ready } = fgStore;

  useEffect(() => {
    formRef.current?.setFieldsValue({
      titleName: fgStore.title?.titleName || '',
      hasTip: !!fgStore?.title?.hasTip,
      tipContent: fgStore?.title?.tipContent || '',
    });
  }, [JSON.stringify(fgStore.title)]);

  const Content = (
    <>
      <FormCreator
        submitter={{ render: () => null }}
        layout="horizontal"
        debug
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        onMounted={(ref) => {
          formRef.current = ref;
        }}
        onValuesChange={(changedValues: any, allValues: any) => {
          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
          debounceTimer = setTimeout(() => {
            fgStore.uTitle(allValues);
          }, 500);
        }}
        config={{
          titleName: {
            label: '标题名称',
            defaultValue: fgStore.title.titleName || '',
            fieldType: EWidgetType.Input,
            fieldProps: {
              maxLength: 50,
            },
          },
          hasTip: {
            label: '图标提示',
            defaultValue: fgStore.title.hasTip || false,
            fieldType: EWidgetType.Switch,
          },
          tipContent: {
            label: '提示文案',
            defaultValue: fgStore.title.tipContent || '',
            // whenHidden: (formData: any) => !formData.hasTip,
            fieldType: EWidgetType.Input,
            fieldProps: {
              maxLength: 2000,
            },
          },
        }}
      />
    </>
  );

  if (!ready) {
    // 数据末准备好，则不能渲染表单，否则初始化的表单是有误的
    return null;
  }

  return (
    <WithCollapse
      collapseDatas={[
        {
          key: 'base',
          title: '基础属性',
          configComponent: Content,
        },
      ]}
    />
  );
});
export default ListHeader;
