import { useState, useCallback, useEffect } from 'react';
import { Card, Row, Col, Select, Input, Space, Button, Modal, Divider, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
  SymbolEqOptions,
  OptionsInterbehaviorTypes,
  OptionsExtractTypes,
  EPageType,
} from '@/constant';
import { cloneDeep } from 'lodash';
import PickMethodVar from '@/components/PickMethodVar';
import useFormGeneratorStore from '@/store/form-generator';

import './index.less';
import { TMethodsValidationConfig, TMethodsParams } from '@/types';
import TextArea from 'antd/lib/input/TextArea';
import { isNotNullArray } from '@/components/FormGeneractor/help';

interface TProp {
  bindedMethods: any;
  bindedMethodsCconfig?: TMethodsValidationConfig;
  onChange?: (values: TMethodsValidationConfig) => void;
}

enum EMethodsType {
  LOAD,
  UPDATE,
}

const selectStyle = { width: '100px' };

const MethodsValidation = ({ bindedMethods, bindedMethodsCconfig, onChange }: TProp) => {
  const { currentPage } = useFormGeneratorStore((state) => state);
  const pageType = currentPage.type;

  const defaultSource: TMethodsParams = {
    interbehaviorTypes: '0',
    extractTypes: '0',
    symbolEq: '0',
    symbolValue: null,
    result: { fieldId: '', fieldName: '绑定结果字段' },
    error: { fieldId: '', fieldName: '绑定异常字段' },
  };
  // console.log('bindedMethods',bindedMethods)

  const loadMethodsDefault = bindedMethodsCconfig?.loadMethods ?? [cloneDeep(defaultSource)];
  const updateMethodsDefault = bindedMethodsCconfig?.updateMethods ?? [cloneDeep(defaultSource)];

  const [loadMethodsSources, setLoadMethodsSources] =
    useState<TMethodsParams[]>(loadMethodsDefault);
  const [updateMethodsSources, setUpdateMethodsSources] =
    useState<TMethodsParams[]>(updateMethodsDefault);

  const handlerChange = useCallback(
    (type: EMethodsType, value: any, index: number, field: keyof TMethodsParams) => {
      let values = value;
      if (Object.keys(values).length === 0) {
        let fieldName = '';
        if (field === 'result') {
          fieldName = '绑定结果字段';
        }
        if (field === 'error') {
          fieldName = '绑定异常字段';
        }
        values = { fieldId: '', fieldName };
      }

      let sources: TMethodsParams[];
      if (type === EMethodsType.LOAD) {
        // 加载方法配置
        sources = cloneDeep(loadMethodsSources);
        sources[index][field] = cloneDeep(values);
        setLoadMethodsSources([...sources]);

        if (field === 'extractTypes') {
          // 异常方式切换需重置

          sources[index].error = values === '0' ? { fieldId: '', fieldName: '绑定异常字段' } : '';
        }
        if ([Number(EPageType.列表页面), Number(EPageType.查看页面)].includes(pageType)) {
          onChange?.({
            loadMethods: [...sources],
          });
          return;
        }

        // 编辑
        onChange?.({
          loadMethods: [...sources],
          updateMethods: [...updateMethodsSources],
        });
      } else {
        // 更新方法配置
        sources = cloneDeep(updateMethodsSources);
        sources[index][field] = cloneDeep(values);
        setUpdateMethodsSources([...sources]);

        if (field === 'extractTypes') {
          // 异常方式切换需重置
          sources[index].error = values === '0' ? { fieldId: '', fieldName: '绑定异常字段' } : '';
        }
        if (Number(EPageType.新增页面) === pageType) {
          onChange?.({
            updateMethods: [...sources],
          });
          return;
        }
        // 编辑
        onChange?.({
          loadMethods: [...loadMethodsSources],
          updateMethods: [...sources],
        });
      }
    },
    [loadMethodsSources, updateMethodsSources, pageType, onChange],
  );

  const ValidationDom = (sources: TMethodsParams[], type: EMethodsType) => {
    const methodId =
      type === EMethodsType.LOAD ? bindedMethods?.loadMethod?.id : bindedMethods?.submitMethod?.id;
    return (
      <>
        <div>{type === EMethodsType.LOAD ? '加载' : '更新'}方法校验</div>
        {sources.map((item, index) => (
          <div className="card-wraper" key={`${index}${type}`}>
            <Card>
              <div className="del-wraper">
                <Button
                  className="padding0"
                  style={{ padding: 0 }}
                  onClick={() => {
                    const deleteMethod = () => {
                      if (type === EMethodsType.LOAD) {
                        loadMethodsSources.splice(index, 1);
                        setLoadMethodsSources([...loadMethodsSources]);
                        onChange?.(
                          cloneDeep({
                            loadMethods: [...loadMethodsSources],
                            updateMethods: [...updateMethodsSources],
                          }),
                        );
                        return;
                      }
                      updateMethodsSources.splice(index, 1);
                      setUpdateMethodsSources([...updateMethodsSources]);
                      onChange?.(
                        cloneDeep({
                          loadMethods: [...loadMethodsSources],
                          updateMethods: [...updateMethodsSources],
                        }),
                      );
                    };
                    if (item.result.fieldId) {
                      Modal.confirm({
                        title: '删除方法',
                        content: '是否删除该方法，删除后不可恢复？',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: () => deleteMethod(),
                      });
                      return;
                    }
                    deleteMethod();
                  }}
                  type="link"
                  icon={<DeleteOutlined />}
                />
              </div>
              <Row>
                <Col span={8}>
                  <PickMethodVar
                    value={item.result}
                    contextField="outputParams"
                    isTableList={false}
                    methodId={methodId}
                    onSelected={(value) => handlerChange(type, value, index, 'result')}
                  />
                </Col>
                <Col span={16}>
                  <Space>
                    <Select
                      style={selectStyle}
                      placeholder="请选择"
                      options={SymbolEqOptions}
                      defaultValue={item.symbolEq}
                      onChange={(value) => handlerChange(type, value, index, 'symbolEq')}
                    />
                    <Input
                      placeholder="请输入"
                      defaultValue={item.symbolValue || undefined}
                      onBlur={(e) => handlerChange(type, e.target.value, index, 'symbolValue')}
                    />
                  </Space>
                </Col>
              </Row>
              <div style={{ height: 15 }} />
              <Row>
                <Col span={8}>页面交互</Col>
                <Col span={16}>
                  <div style={{ marginBottom: 15 }}>
                    <Select
                      style={selectStyle}
                      placeholder="请选择"
                      defaultValue={item.interbehaviorTypes}
                      options={OptionsInterbehaviorTypes}
                      onChange={(value) => handlerChange(type, value, index, 'interbehaviorTypes')}
                    />
                  </div>

                  <div style={{ marginBottom: 10 }}>
                    <Select
                      style={selectStyle}
                      placeholder="请选择"
                      defaultValue={item.extractTypes}
                      options={OptionsExtractTypes}
                      onChange={(value) => handlerChange(type, value, index, 'extractTypes')}
                    />
                  </div>

                  {item.extractTypes === '1' ? (
                    <TextArea
                      rows={3}
                      defaultValue={item.error}
                      onBlur={(e) => handlerChange(type, e.target.value, index, 'error')}
                    />
                  ) : (
                    <PickMethodVar
                      value={item.error}
                      isTableList={false}
                      contextField="outputParams"
                      methodId={methodId}
                      onSelected={(value) => handlerChange(type, value, index, 'error')}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          </div>
        ))}
        <Button
          type="link"
          className="padding0"
          style={{ padding: 0 }}
          onClick={() => {
            if (type === EMethodsType.LOAD) {
              loadMethodsSources.push(defaultSource);
              setLoadMethodsSources([...loadMethodsSources]);
              return;
            }
            updateMethodsSources.push(defaultSource);
            setUpdateMethodsSources([...updateMethodsSources]);
          }}
        >
          + 添加结果处理
        </Button>
      </>
    );
  };

  useEffect(() => {
    // 切换方法重置方法检验
    window.addEventListener('setBindedMethods', () => {
      setLoadMethodsSources([]);
      setUpdateMethodsSources([]);
      setLoadMethodsSources([cloneDeep(defaultSource)]);
      setUpdateMethodsSources([cloneDeep(defaultSource)]);
    });
  }, []);

  useEffect(() => {
    if (!loadMethodsSources || loadMethodsSources.length < 1) {
      const loadMethods = isNotNullArray(bindedMethodsCconfig?.loadMethods)
        ? bindedMethodsCconfig?.loadMethods
        : [cloneDeep(defaultSource)];
      setLoadMethodsSources(loadMethods || []);
    }
    if (!updateMethodsSources || updateMethodsSources.length < 1) {
      const updateMethods = isNotNullArray(bindedMethodsCconfig?.updateMethods)
        ? bindedMethodsCconfig?.updateMethods
        : [cloneDeep(defaultSource)];
      setUpdateMethodsSources(updateMethods || []);
    }
  }, [JSON.stringify(bindedMethodsCconfig)]);

  const loadDom = () => {
    if (pageType !== Number(EPageType.新增页面)) {
      if (bindedMethods?.loadMethod?.id) {
        return <div key="load1">{ValidationDom(loadMethodsSources, EMethodsType.LOAD)}</div>;
      }
      return (
        <>
          <div>加载方法校验</div>
          <Empty />
        </>
      );
    }
  };
  const updateDom = () => {
    if ([Number(EPageType.新增页面), Number(EPageType.编辑页面)].includes(pageType)) {
      if (bindedMethods?.submitMethod?.id) {
        return <div key="update1">{ValidationDom(updateMethodsSources, EMethodsType.UPDATE)}</div>;
      }
      return (
        <>
          <div>更新方法校验</div>
          <Empty />
        </>
      );
    }
  };
  return (
    <>
      <div key="load">{loadDom()}</div>
      {pageType === Number(EPageType.编辑页面) && <Divider />}
      <div key="update">{updateDom()}</div>
    </>
  );
};

export default MethodsValidation;
