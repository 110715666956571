import { FC } from 'react';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { debounce } from 'lodash';
import PickMethod from '@/components/PickMethod';
import TransformReadAndWrite, { ReadOrWrite } from './TransformReadAndWrite';
import useFormGeneratorStore from '@/store/form-generator-list';

enum ParamSource {
  TABLE = 'table',
  FUNCTION = 'function',
}
// choice data source
const DATA_SOURCE_TYPE = [
  {
    label: '引用表格数据',
    value: ParamSource.TABLE,
  },
  {
    label: '执行方法',
    value: ParamSource.FUNCTION,
  },
];

enum DataFlow {
  FLOW_TO_EXTERNAL_PAGE = 'flowToExternalPage',
  FLOW_TO_SYSTEM_PAGE = 'flowToSystemPage',
}
const DATA_FLOW = [
  {
    label: '从系统页面流向外部页面',
    value: DataFlow.FLOW_TO_EXTERNAL_PAGE,
  },
  {
    label: '从外部页面流向系统页面',
    value: DataFlow.FLOW_TO_SYSTEM_PAGE,
  },
];

function getReadParamsFromTable(bindParam: any[]) {
  const loadMethodInfo = bindParam.find((item) => item.type === 'loadMethod');
  const childKeys: any[] = loadMethodInfo?.bindVar?.childKeys ?? [];
  const paramsList = childKeys.map((item) => {
    return {
      dataType: item.dataType,
      id: item.id,
      name: item.key.split('.').slice(-1)[0],
      path: item.key,
      title: item.title,
    };
  });
  return paramsList;
}

const Config: FC<{
  externalPageInAndOutParams: any;
  value: undefined | Record<string, any>;
  onChange: (value: any) => void;
}> = ({ externalPageInAndOutParams, value = {}, onChange }) => {
  // const inputProperties = externalPageInAndOutParams?.inputProperties ?? '[]';
  const outputProperties = externalPageInAndOutParams?.outputProperties ?? '[]';
  // const externalPageInputParams = JSON.parse(inputProperties.length < 1 ? '[]' : inputProperties);
  const externalPageOutputParams = JSON.parse(
    outputProperties.length < 1 ? '[]' : outputProperties,
  );
  const fgStore = useFormGeneratorStore((state) => state);
  const tableReadParams = getReadParamsFromTable(fgStore?.listTable?.bindParam ?? []);
  const {
    bindFunction,
    getTransformParamType,
    transformInAndOutParamsWithFunction,
    transformInAndOutParamsWithTableRowData,
    dataFlow,
  } = value;

  return (
    <FormCreator
      submitter={{ render: () => null }}
      labelAlign="right"
      debug
      layout="horizontal"
      onValuesChange={debounce((_, values) => {
        onChange?.(values);
      }, 500)}
      config={{
        getTransformParamType: {
          label: '动作处理',
          fieldType: EWidgetType.Select,
          defaultValue: getTransformParamType,
          fieldProps: {
            options: DATA_SOURCE_TYPE,
            allowClear: false,
          },
        },
        dataFlow: {
          label: '数据流向',
          fieldType: EWidgetType.Select,
          defaultValue: dataFlow,
          whenHidden: (formData: any) => formData.getTransformParamType === ParamSource.TABLE,
          fieldProps: {
            options: DATA_FLOW,
            allowClear: false,
          },
        },
        bindFunction: {
          label: '执行方法',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: bindFunction,
          whenHidden: (formData: any) => formData.getTransformParamType !== ParamSource.FUNCTION,
          fieldProps: {},
          custom({ onChange: _onChange }) {
            const initValue = typeof bindFunction !== 'undefined' ? [bindFunction] : undefined;
            return (
              <PickMethod
                value={initValue}
                getLabelByValue={(methods) => {
                  return methods?.length ? methods[0].name : '请选择';
                }}
                checkedList={[value].filter((o) => Boolean(o))}
                onChange={(data) => {
                  _onChange?.(data[0]);
                }}
              />
            );
          },
        },
        // get params by excute function
        transformInAndOutParamsWithFunction: {
          label: '传参绑定',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: transformInAndOutParamsWithFunction,
          whenHidden: (formData: any) => formData.getTransformParamType !== ParamSource.FUNCTION,
          custom({ onChange: _onChange, formData }) {
            const flowToExternalPage = formData?.dataFlow === DataFlow.FLOW_TO_EXTERNAL_PAGE;
            const bindFunctionOutputParams = formData?.bindFunction?.outputParams ?? [];
            const bindFunctionInputParams = formData?.bindFunction?.inputParams ?? [];
            const readTitle = flowToExternalPage ? '绑定方法的出参' : '外部页面的出参';
            const writeTitle = flowToExternalPage ? '外部页面的出参' : '绑定方法的入参';
            const readParams = flowToExternalPage
              ? bindFunctionOutputParams
              : externalPageOutputParams;
            const writeParams = flowToExternalPage
              ? externalPageOutputParams
              : bindFunctionInputParams;

            return (
              <TransformReadAndWrite
                readTitle={readTitle}
                writeTitle={writeTitle}
                readParams={readParams}
                writeParams={writeParams}
                readOrWrite={ReadOrWrite.READ_DISABLED}
                value={transformInAndOutParamsWithFunction}
                onChange={(data) => {
                  _onChange?.(data);
                }}
              />
            );
          },
        },
        // get params by table data
        transformInAndOutParamsWithTableRowData: {
          label: '传参绑定',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: transformInAndOutParamsWithTableRowData,
          whenHidden: (formData: any) => formData.getTransformParamType !== ParamSource.TABLE,
          custom({ onChange: _onChange }) {
            // const flowToExternalPage = formData?.dataFlow === DataFlow.FLOW_TO_EXTERNAL_PAGE;
            // const bindFunctionOutputParams = formData?.bindFunction?.outputParams ?? [];
            // const bindFunctionInputParams = formData?.bindFunction?.inputParams ?? [];
            const readParams = tableReadParams;
            const writeParams = externalPageOutputParams;
            const readTitle = '列字段';
            const writeTitle = '外部页面的出参';
            return (
              <TransformReadAndWrite
                readTitle={readTitle}
                writeTitle={writeTitle}
                readParams={readParams}
                writeParams={writeParams}
                readOrWrite={ReadOrWrite.READ_DISABLED}
                value={transformInAndOutParamsWithTableRowData}
                onChange={(data) => {
                  _onChange?.(data);
                }}
              />
            );
          },
        },
      }}
    />
  );
};

export default Config;
