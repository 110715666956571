import { memo, useCallback, useEffect, useState } from 'react';
import { value2Label } from '@lizhife/schema-shared';
import { EOptionsFromType } from '@/constant';
import { Tag } from 'antd';
import useEnumRequest from '@/components/PageParser/useEnumRequest';
import { EModeType } from '@/store/form-generator-parse';

interface EnumViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const EnumView = memo<EnumViewProps>(({ configInfo }) => {
  const { fetchOptions } = useEnumRequest();
  const [content, setContent] = useState<any>('');

  const getContent = useCallback(async () => {
    if (configInfo.mode !== EModeType.production) {
      return '示例数据';
    }
    let valueList: any[] = [];
    if (Array.isArray(configInfo.finalValue)) {
      valueList = configInfo.finalValue;
    } else if (configInfo.finalValue !== null && configInfo.finalValue !== undefined) {
      valueList.push(String(configInfo.finalValue));
    }
    if (configInfo.optionsFromType === EOptionsFromType.静态数据) {
      return valueList.map((o) => (
        <Tag key={o}>{value2Label(configInfo?.options, String(o)) || o}</Tag>
      ));
    } else if (configInfo?.dyOptionsInfo && configInfo.mode === EModeType.production) {
      const options = await fetchOptions(configInfo?.dyOptionsInfo);
      return (valueList || []).map((o) => (
        <Tag key={o}>{value2Label(options || [], String(o)) || o}</Tag>
      ));
    }
  }, [configInfo]);

  useEffect(() => {
    getContent().then((res) => {
      setContent(res || '');
    });
  }, [configInfo]);

  return <>{content || '-'}</>;
});

export default EnumView;
