import request from '@/utils/request';
import type { TReturn } from '@/types';
import { PREFIX_API_VAR } from '@/constant';

export const prefixApi = (name: string) => `${PREFIX_API_VAR ? `/${PREFIX_API_VAR}` : ''}${name}`;
export const prefixSystem = (name: string) => prefixApi(`/system/${name}`);
export const prefixPageGroup = (name: string) => prefixSystem(`pageGroup/${name}`);
export const prefixExternalPage = (name: string) => prefixSystem(`externalPage/${name}`);
export const prefixListPage = (name: string) => prefixSystem(`listPage/${name}`);
export const prefixFormPage = (name: string) => prefixSystem(`formPage/${name}`);
export const prefixMethod = (name: string) => prefixSystem(`customMethod/${name}`);
export const prefixPageConfig = (name: string) => `pageConfig/${name}`;
export const prefixSource = (name: string) => prefixSystem(`datasource/${name}`);
export const prefixDataSource = (name: string) => prefixSource(`info/${name}`);

/**
 * 根据namespace获取所有表
 * @param env_info_id  环境信息ID
 * @param namespace 数据库namespace
 * @returns
 */
export const getDataSourceByNamespace = (env_info_id: string, namespace: string) => {
  return request.get<TReturn<string[]>>(prefixSystem('datasource/table/getList'), {
    params: {
      env_info_id,
      namespace,
    },
  });
};

/**
 * 获取分类列表
 */
export async function test(params: any) {
  return await request.get<TReturn<any>>('/gift/group/list', { params });
  /*  return Promise.resolve({
    data: data?.list,
    // success 请返回 true，
    // 不然 table 会停止解析数据，即使有数据
    success: code === 0,
    // 不传会使用 data 的长度，如果是分页一定要传
    total: data?.total,
  }); */
}
