import create from 'zustand';
import deepmerge from 'deepmerge';
import type { ListHeaderConfig, ListSearchConfig, ListPaginationConfig } from './types';

interface StoreType {
  hasChange: boolean;
  uHasChange: (hasChange: boolean) => void;

  ready: boolean;
  uReady: (ready: boolean) => void;

  listConfig: any;
  uListConfig: (...args: any[]) => void;

  listPageMethodId: string;
  uListPageMethodId: (...args: any[]) => void;

  listPageServiceConfig: any;
  uListPageServiceConfig: (...args: any[]) => void;

  bindedMethods: Record<string, any>;
  uBindedMethods: (...args: any[]) => void;

  title: ListHeaderConfig;
  uTitle: (data: ListHeaderConfig) => void;

  listSearch: ListSearchConfig;
  uListSearch: (...args: any[]) => void;

  listTable: any;
  uListTable: (...args: any[]) => void;
  uListTableToolbarButton: (values: any) => void;

  listPagination: ListPaginationConfig;
  uListPagination: (...args: any[]) => void;

  methodsValidationConfig: {};
  uMethodsValidationConfig: (...args: any[]) => void;
  // 数据重置
  reset: () => void;
}

// 列表
const useFormGeneratorListStore = create<StoreType>((set) => ({
  hasChange: false, // 标记是否修改过
  uHasChange: (hasChange: boolean) =>
    set(() => ({
      hasChange,
    })),

  // 页面数据是否准备好
  ready: false,
  uReady: (bool) => set(() => ({ ready: bool })),

  // 列表整体配置
  listConfig: {
    // usedTitle: true,
    // usedSearch: true,
    // usedPagination: true,
  },
  uListConfig: (listConfig: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      listConfig: Object.assign({}, state.listConfig, listConfig),
    })),

  // 列表页面关联的"查看列表"方法
  listPageMethodId: '',
  uListPageMethodId: (listPageMethodId: string) =>
    set((state) => ({ hasChange: !!state.ready, listPageMethodId })),

  // 列表页服务端配置信息
  listPageServiceConfig: null,
  uListPageServiceConfig: (listPageServiceConfig: any) => set(() => ({ listPageServiceConfig })),

  // 列表页 - 头部标题区配置
  title: {
    titleName: '小游戏',
    used: true,
  },
  uTitle: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      title: deepmerge(state.title || {}, val, {
        // 数组合并，要覆盖，不要追加
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    })),

  // 当前页全局绑定的方法
  bindedMethods: {},
  uBindedMethods: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      bindedMethods: deepmerge(state.bindedMethods, val, {
        // 数组合并，要覆盖，不要追加
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    })),

  methodsValidationConfig: {},
  uMethodsValidationConfig: (methodsValidationConfig) =>
    set((state) => ({ hasChange: !!state.ready, methodsValidationConfig })),

  // 列表页 - 搜索区配置
  listSearch: {
    used: true,
    defaultCollapsed: false,
    labelWidth: 100,
    formItemWidth: 8,
    fields: [],
  },
  uListSearch: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      listSearch: Object.assign({}, state.listSearch, val),
    })),

  // 列表页 - 表格配置
  listTable: {
    used: true,
    toolbarButtons: [],
    bindParam: [],
    fields: [
      /*  {
        title: 'ID',
        dataIndex: 'id',
        displayType: 'text',
        linkToDetail: false,
        hasTip: true,
        tipContent: '这里是ID',
        ellipsisTooltip: false,
        copyable: true,
        width: 150,
        used: true,
      },
      {
        title: '名称',
        dataIndex: 'name',
        displayType: 'text',
        linkToDetail: true,
        hasTip: true,
        tipContent: '这里是名称',
        ellipsisTooltip: true,
        copyable: false,
        width: 100,
        used: true,
      },
      {
        title: '开始时间',
        dataIndex: 'startTime',
        displayType: 'date-time',
        formatTime: '',
        linkToDetail: true,
        hasTip: false,
        tipContent: '',
        ellipsisTooltip: true,
        copyable: false,
        width: 200,
        used: true,
      },
      {
        title: '状态',
        dataIndex: 'status',
        displayType: 'enums',
        width: 100,
        used: true,
      },
      {
        title: '封面图',
        dataIndex: 'cover',
        displayType: 'image',
        linkToDetail: true,
        width: 120,
        used: true,
      },
      {
        title: '视频',
        dataIndex: 'video',
        displayType: 'video',
        width: 250,
        used: true,
        videoPoster:
          'https://internal-amis-res.cdn.bcebos.com/images/2019-12/1577157239810/da6376bf988c.png',
      },
      {
        title: '操作',
        fixed: 'right',
        width: 100,
        used: true,
        editConfig: {
          text: '编辑',
          callFunction: 'edit',
        },
        delConfig: {
          text: '删除',
          callFunction: 'del',
        },
      }, */
    ],
  },
  uListTable: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      listTable: Object.assign(state.listTable, val),
    })),

  uListTableToolbarButton(val: any) {
    set((state) => {
      const toolbarButtons = state.listTable.toolbarButtons.map((button: any) => {
        if (button.frontComponentId === val.frontComponentId) {
          return val;
        } else {
          return button;
        }
      });
      return {
        listTable: {
          ...state.listTable,
          toolbarButtons,
        },
      };
    });
  },

  // 列表页 - 分页配置
  listPagination: {
    used: true,
    pageSize: 10,
  },
  uListPagination: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      listPagination: {
        ...state.listPagination,
        ...val,
      },
    })),

  // methodsValidationConfig: [],
  // uMethodsValidationConfig: (methodsValidationConfig) =>
  //   set((state) => ({ hasChange: !!state.ready, methodsValidationConfig })),

  reset: () =>
    set(() => ({
      hasChange: false,
      ready: false,
      listPageMethodId: '',
      bindedMethods: {},
      // methodsValidationConfig: {},
      title: {},
      listSearch: {
        used: true,
        defaultCollapsed: false,
        labelWidth: 150,
        formItemWidth: 10,
        fields: [],
      },
      listTable: {
        used: true,
        toolbarButtons: [],
        fields: [],
      },
      listPageServiceConfig: null,
      listPagination: {},
      listConfig: {},
    })),
}));

export default useFormGeneratorListStore;
