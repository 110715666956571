import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './style.less';

const type = 'DraggableBodyRow';

export const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      const { index: dragIndex } = monitor.getItem() || ({} as any);
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop(item: any) {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: {
      type,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};
export const dragComponents = () => ({
  body: {
    row: DraggableBodyRow,
  },
});
