import { memo } from 'react';
import type { FC } from 'react';

import { Input, Form } from 'antd';

import { sandbox } from '@/utils/sandbox';

const arrayValidator = async (rule: any, value: any) => {
  if (value.length < 1) {
    // array can be empty.
    return true;
  }
  const data = sandbox(`try{return ${value};}catch(err){console.log('err', err);return '';}`, {});
  if (!Array.isArray(data)) {
    throw new Error('格式有误!');
  }
};

const { TextArea } = Input;

const PLACEHOLDER = `请输入数据字段和对应的数据格式，比如输入
[
  {
      "orderNum": 1,
      "name": "id",
      "title": "特效ID",
      "dataType": "Long"
  },
  {
      "orderNum": 2,
      "name": "name",
      "title": "特效名称",
      "dataType": "String"
  }
]
将会用于以下的数据:
{
  "id": 666,
  "name": "我叫游戏",
}
`;

interface Props {
  index: number;
  rootName: string;
}
const ConfigEditor: FC<Props> = memo(({ rootName, index }) => {
  return (
    <>
      <Form.Item
        name={[rootName, index, 'name']}
        label="名称"
        rules={[{ required: true }, { max: 100, message: '字符长度不能超过100个' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[rootName, index, 'key']}
        label="消息Key"
        rules={[{ required: true }, { max: 100, message: '字符长度不能超过100个' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[rootName, index, 'inputProperties']}
        label="入参"
        rules={[
          {
            validator: arrayValidator,
          },
        ]}
      >
        <TextArea
          placeholder={PLACEHOLDER}
          autoSize={{ minRows: 5, maxRows: 20 }}
          style={{ marginTop: '20px' }}
        />
      </Form.Item>
      <Form.Item
        name={[rootName, index, 'outputProperties']}
        label="出参"
        rules={[
          {
            validator: arrayValidator,
          },
        ]}
      >
        <TextArea
          placeholder={PLACEHOLDER}
          autoSize={{ minRows: 5, maxRows: 20 }}
          style={{ marginTop: '20px' }}
        />
      </Form.Item>
    </>
  );
});

export default ConfigEditor;
