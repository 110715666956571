const isDev = process.env.NODE_ENV === 'development';

export const HOST = (() => {
  switch (process.env.SERVER_ENV) {
    case 'mock':
      return '//yapi.feoffice.lizhi.fm/mock/1819';
    case 'docker':
      return '//orca.yfxn.lizhi.fm';
    case 'xm-docker':
      return '//orca-xm.yfxn.lizhi.fm';
    case 'dhp-docker':
      return '//orca-dhp.yfxn.lizhi.fm';
    case 'vopi-docker':
      return '//orca-vopi.yfxn.lizhi.fm';
    case 'public-docker':
      return '//orca-public.yfxn.lizhi.fm';
    case 'hy-docker':
      return '//orcahy.yfxn.lizhi.fm';
    case 'pp-docker':
      return '//orca-pp.yfxn.lizhi.fm';
    case 'xinqing-docker':
      return '//orca-xinqing.yfxn.lizhi.fm';
    case 'lz-pre':
      return '//orcapre.lizhifm.com';
    case 'lz-prod':
      return '//orca.lizhifm.com';
    case 'hy-pre':
      return '//orcapre-admin.lizhilive.com';
    case 'hy-prod':
      return '//orca-admin.lizhilive.com';
    case 'cp-prod':
      return `//orca-seal.lzpscn1.com`;
    case 'ap-prod':
      return '//orca-seal.lzpsap1.com';
    case 'ap-pre':
      return '//orca-sealpre.lzpsap1.com';
    case 'pp-prod':
      return '//orca-admin.pparty.com';
    case 'pp-pre':
      return '//orca-adminpre.pparty.com';
    case 'xingqing-pre':
      return '//orca-adminpre.zhiyinzhibo.cn';
    case 'xingqing-prod':
      return '//orca-admin.zhiyinzhibo.cn';
    case 'eu-pre':
      return '//orca-sealpre.lzpseu1.com';
    case 'eu-prod':
      return '//orca-seal.lzpseu1.com';
    case 'us-pre':
      return '//orca-sealpre.lzpsus1.com';
    case 'us-prod':
      return '//orca-seal.lzpsus1.com';
    case 'dhp-pre':
      return '//orcapre.lzpipi.com';
    case 'dhp-prod':
      return '//orca.lzpipi.com';
    case 'vopi-pre':
      return '//orcapre.vopi.jp';
    case 'vopi-prod':
      return '//orca.vopi.jp';
    case 'vopi-audit-prod':
      return '//orca-audit.vopi.jp';
    case 'fishing-docker':
      return '//orca-fishing.yfxn.lizhi.fm';
    default:
      if (isDev) {
        return '//orca.yfxn.lizhi.fm'; //  'http://192.168.39.238:7661';
      }
      return '';
  }
})();

export const PREFIX_API_VAR = (() => {
  switch (process.env.SERVER_ENV) {
    case 'docker':
      return '';
    case 'ap-pre':
    case 'eu-pre':
    case 'us-pre':
      return 'server';
    case 'cp-prod':
    case 'ap-prod':
    case 'eu-prod':
    case 'us-prod':
    case 'dhp-prod':
      return 'server';
    default:
      return '';
  }
})();

export const SSO_URL = (() => {
  const DOCKER = 'lzssooffice.lizhi.fm';
  const PRE = 'lzssopre.lizhi.fm';
  const AP_PRE = 'ssoap.lizhifm.com';
  const PROD = 'lzsso.lizhi.fm';
  const AP_PROD = 'ssoap.lizhifm.com';
  const EU_PRE = 'ssous.lizhifm.com';
  const EU_PROD = 'ssous.lizhifm.com';
  const US_PRE = 'ssous.lizhifm.com';
  const US_PROD = 'ssous.lizhifm.com';
  const dockers = [
    'docker',
    'pp-docker',
    'hy-docker',
    'public-docker',
    'funbox-docker',
    'xm-docker',
    'dhp-docker',
    'vopi-docker',
    'ocean-docker',
    'fishing-docker',
  ];
  const pres = ['pre', 'cp-pre', 'lz-pre', 'hy-pre', 'pp-pre', 'xingqing-pre', 'xm-pre', 'dhp-pre'];
  const apPres = ['ap-pre', 'vopi-pre'];
  const euPres = ['eu-pre', 'funbox-pre'];
  const usPres = ['us-pre'];
  const prods = [
    'prod',
    'cp-prod',
    'lz-prod',
    'hy-prod',
    'pp-prod',
    'xingqing-prod',
    'xm-prod',
    'dhp-prod',
  ];
  const apProds = ['ap-prod', 'vopi-prod', 'vopi-audit-prod'];
  const euProds = ['eu-prod', 'funbox-prod'];
  const usProds = ['us-prod'];
  const env = process.env.SERVER_ENV;
  if (prods.includes(env)) {
    return PROD;
  } else if (apProds.includes(env)) {
    return AP_PROD;
  } else if (euProds.includes(env)) {
    return EU_PROD;
  } else if (usProds.includes(env)) {
    return US_PROD;
  } else if (apPres.includes(env)) {
    return AP_PRE;
  } else if (euPres.includes(env)) {
    return EU_PRE;
  } else if (usPres.includes(env)) {
    return US_PRE;
  } else if (pres.includes(env)) {
    return PRE;
  } else if (dockers.includes(env)) {
    return DOCKER;
  } else {
    return PROD;
  }
})();

export const databaseTablesTypes = [
  {
    label: '主表',
    value: '主表',
  },
  {
    label: '附表',
    value: '附表',
  },
  {
    label: '单表',
    value: '单表',
  },
];

export const Areas = [
  {
    label: '中国区',
    value: '中国区',
  },
  {
    label: '美国区',
    value: '美国区',
  },
  {
    label: '亚太区',
    value: '亚太区',
  },
  {
    label: '欧洲区',
    value: '欧洲区',
  },
];

export const DataTypes = [
  {
    label: 'MySQL',
    value: '1',
  },
  {
    label: 'Redis',
    value: '2',
  },
];

export const GenerateStrategys = [
  {
    label: '默认',
    value: '0',
  },
  {
    label: '自增',
    value: '1',
  },
  {
    label: 'ID生成器策略',
    value: '2',
  },
];

export const Business = [
  {
    label: '荔枝',
    value: 'lz',
  },
  {
    label: '黑叶',
    value: 'hy',
  },
  {
    label: 'PP',
    value: 'PP',
  },
  {
    label: 'PongPong',
    value: 'PongPong',
  },
];

// int、 long、 Decimal、double、DateTime、bytes
export const databaseFieldTypes = [
  {
    label: 'String',
    value: 'String',
  },
  {
    label: 'Date',
    value: 'Date',
  },
  {
    label: 'Boolean',
    value: 'Boolean',
  },
  {
    label: 'Integer',
    value: 'Integer',
  },
  {
    label: 'Char',
    value: 'Char',
  },
  {
    label: 'Double',
    value: 'Double',
  },
  {
    label: 'Float',
    value: 'Float',
  },
  {
    label: 'BigDecimal',
    value: 'BigDecimal',
  },
  {
    label: 'Long',
    value: 'Long',
  },
  {
    label: 'DateTime',
    value: 'DateTime',
  },
  // {
  //   label: 'Object',
  //   value: 'Object',
  // },
];

export enum EDataSourceMethod {
  Detail = '0',
  Add = '1',
  Edit = '2',
  Del = '3',
  List = '4',
  DyEnums = '5',
  Custom = '6',
}

export const DEFAULT_MODEL_FUNCTIONS = [
  {
    label: '新增',
    value: EDataSourceMethod.Add,
  },
  {
    label: '编辑',
    value: EDataSourceMethod.Edit,
  },
  {
    label: '删除',
    value: EDataSourceMethod.Del,
  },
  {
    label: '查询详情',
    value: EDataSourceMethod.Detail,
  },
  {
    label: '查询列表',
    value: EDataSourceMethod.List,
  },
  {
    label: '动态枚举',
    value: EDataSourceMethod.DyEnums,
  },
];

export const pageTypes = [
  {
    label: '表单页面',
    value: '表单页面',
  },
  {
    label: '自定义页面',
    disabled: true,
    value: '自定义页面',
  },
];

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_LIST_SCROLL = { x: 1000 };

export enum EPageLayoutCmpType {
  标题 = 'title',
}

export const PageLayoutCmpTypes = [
  {
    label: '标题',
    value: EPageLayoutCmpType.标题,
  },
];

export enum EDataDisplayCmpType {
  文本 = 'pure-text',
  图片 = 'picture',
  枚举 = 'enums',
  轮播图 = 'swiper',
  视频 = 'video',
  HTML文本 = 'html',
  列表 = 'table',
}

export const DataDisplayCmpTypes = [
  {
    label: '图片',
    value: EDataDisplayCmpType.图片,
  },
  {
    label: '纯文本',
    value: EDataDisplayCmpType.文本,
  },
  {
    label: '轮播图',
    value: EDataDisplayCmpType.轮播图,
  },
  {
    label: '视频',
    value: EDataDisplayCmpType.视频,
  },
  {
    label: '枚举',
    value: EDataDisplayCmpType.枚举,
  },
  {
    label: 'HTML文本',
    value: EDataDisplayCmpType.HTML文本,
  },
];

export enum FieldCmpType {
  输入框 = 'text',
  联动输入 = 'text-fill',
  多行文本框 = 'textarea',
  密码输入框 = 'password',
  下拉框 = 'select',
  下拉多选框 = 'multi-select',
  数字输入框 = 'digit',
  日期选择器 = 'date',
  日期时间 = 'date-time',
  日期区间 = 'date-range',
  日期范围选择器 = 'date-time-range',
  复选框 = 'checkbox-group',
  单选框 = 'radio-group',
  // 单文件上传 = 'single-file-upload',
  文件上传 = 'upload',
  单文件上传 = 'single-file-upload',
  表格文件上传 = 'excel-file-upload',
  列表 = 'table',
  弹窗选择 = 'list-selector',
  开关 = 'switch',
  单一复选框 = 'checkbox',
  单图上传 = 'single-image-upload',
  多图上传 = 'multi-image-upload',
  视频上传 = 'video-upload',
  富文本 = 'rich-text',
  弹窗多选 = 'list-checkbox',
  多值文本 = 'multi-value-text',
}

// 某些组件不需要表单提交，需要过滤掉bindParam
// To Do 配置表单数据*2 + store数据 = 组件配置，若store有需要置null的数据，只能通过编程手动置null。
export const NoNeedBindParam: Array<FieldCmpType | EPageLayoutCmpType | EDataDisplayCmpType> = [
  FieldCmpType.弹窗多选,
  FieldCmpType.弹窗选择,
];

// 校验预设规则
export enum ERuleType {
  数值大小范围 = 'number-range',
  字符串最大长度 = 'string-range',
  URL格式 = 'url',
  不允许特殊字符 = 'special-string',
  正整数 = 'positive-integer',
  整数 = 'integer',
  小数 = 'decimal',
  正则 = 'regular',
}

export const RuleTyps = [
  {
    label: '字符串最大长度',
    value: ERuleType.字符串最大长度,
  },
  {
    label: '数值大小范围',
    value: ERuleType.数值大小范围,
  },
  {
    label: '整数',
    value: ERuleType.整数,
  },
  {
    label: '正整数',
    value: ERuleType.正整数,
  },
  {
    label: '小数',
    value: ERuleType.小数,
  },
  {
    label: '不允许特殊字符',
    value: ERuleType.不允许特殊字符,
  },
  {
    label: '正则',
    value: ERuleType.正则,
  },
];

export const FieldControls = [
  {
    label: '输入框',
    value: FieldCmpType.输入框,
  },
  {
    label: '联动输入',
    value: FieldCmpType.联动输入,
  },
  {
    label: '文本域',
    value: FieldCmpType.多行文本框,
  },
  {
    label: '多值文本',
    value: FieldCmpType.多值文本,
  },
  {
    label: '数字框',
    value: FieldCmpType.数字输入框,
  },
  {
    label: '下拉框',
    value: FieldCmpType.下拉框,
  },
  {
    label: '下拉多选框',
    value: FieldCmpType.下拉多选框,
  },
  {
    label: '复选框',
    value: FieldCmpType.复选框,
  },
  {
    label: '单选框',
    value: FieldCmpType.单选框,
  },
  {
    label: '开关',
    value: FieldCmpType.开关,
  },
  {
    label: '弹窗单选',
    value: FieldCmpType.弹窗选择,
  },
  {
    label: '弹窗多选',
    value: FieldCmpType.弹窗多选,
  },
  {
    label: '列表',
    value: FieldCmpType.列表,
  },
  {
    label: '单文件上传',
    value: FieldCmpType.单文件上传,
  },
  {
    label: '上传表格',
    value: FieldCmpType.表格文件上传,
  },
  {
    label: '多图上传',
    value: FieldCmpType.多图上传,
  },
  {
    label: '视频上传',
    value: FieldCmpType.视频上传,
  },
  {
    label: '富文本',
    value: FieldCmpType.富文本,
  },
  {
    label: '单日期',
    value: FieldCmpType.日期时间,
  },
  {
    label: '范围日期',
    value: FieldCmpType.日期区间,
  },
];

export const SearchFieldControls = [
  {
    label: '输入框',
    value: FieldCmpType.输入框,
  },
  {
    label: '数字输入框',
    value: FieldCmpType.数字输入框,
  },
  {
    label: '下拉单框',
    value: FieldCmpType.下拉框,
  },
  {
    label: '下拉多框',
    value: FieldCmpType.下拉多选框,
  },
  {
    label: '日期选择器',
    value: FieldCmpType.日期选择器,
  },
  {
    label: '日期范围选择器',
    value: FieldCmpType.日期范围选择器,
  },
  {
    label: '多值文本',
    value: FieldCmpType.多值文本,
  },
];

export const SymbolEqOptions = [
  {
    label: '等于',
    value: '0',
  },
  {
    label: '不等于',
    value: '1',
  },
  {
    label: '小于',
    value: '2',
  },
  {
    label: '小于等于',
    value: '3',
  },
  {
    label: '大于',
    value: '4',
  },
  {
    label: '大于等于',
    value: '5',
  },
  // {
  //   label: '模糊',
  //   value: '5',
  // },
  // {
  //   label: '多选项',
  //   value: '6',
  // },
];

export enum ESymbolParamSourceOption {
  '前端请求' = '0',
  'userID 后端生成' = '1',
  '账号后端生成' = '2',
  '新增、修改时间' = '3',
}

export const SymbolParamSourceOptions = [
  {
    label: '前端请求',
    value: ESymbolParamSourceOption.前端请求,
  },
  {
    label: 'userID 后端生成',
    value: ESymbolParamSourceOption['userID 后端生成'],
  },
  {
    label: '账号后端生成',
    value: ESymbolParamSourceOption.账号后端生成,
  },
  {
    label: '新增、修改时间',
    value: ESymbolParamSourceOption['新增、修改时间'],
  },
];

export const TABLE_COLUMN_DISPLAY_TYPES = [
  {
    label: '文本类型',
    value: FieldCmpType.输入框,
  },
  {
    label: '枚举类型',
    value: 'enums',
  },
  {
    label: '日期类型',
    value: FieldCmpType.日期选择器,
  },
  {
    label: '图片类型',
    value: 'image',
  },
  {
    label: '视频类型',
    value: 'video',
  },
  {
    label: '按钮类型',
    value: 'button',
  },
];

export enum EPageOpenMode {
  DRAWER = 'open-page-by-drawer',
  MODAL = 'open-page-by-modal',
}

export const PAGE_OPEN_MODES = [
  {
    label: '抽屉页面',
    value: EPageOpenMode.DRAWER,
  },
  {
    label: '弹窗页面',
    value: EPageOpenMode.MODAL,
  },
];

export enum EPageSourceType {
  BUILT_IN = 'built-in',
  EXTERNAL = 'external',
}

export const PAGE_SOURCE_TYPES = [
  {
    label: '内部页面',
    value: EPageSourceType.BUILT_IN,
  },
  {
    label: '外部页面',
    value: EPageSourceType.EXTERNAL,
  },
];

export enum EPageRenderDataType {
  PURE = 'pure',
  REQUEST_DATA = 'request-data',
}

export const PAGE_RENDER_DATA_TYPES = [
  {
    label: '无内置渲染',
    value: EPageRenderDataType.PURE,
    disabled: true,
  },
  {
    label: '内置渲染',
    value: EPageRenderDataType.REQUEST_DATA,
  },
];

export const SORT_TYPES = [
  {
    label: '降序',
    value: 'descend',
  },
  {
    label: '升序',
    value: 'ascend',
  },
];

// 页面类型枚举
export enum EPageType {
  列表页面 = '1',
  新增页面 = '2',
  编辑页面 = '3',
  查看页面 = '4',
}

export const PageTypes = [
  {
    label: '列表页面',
    value: EPageType.列表页面,
  },
  {
    label: '新增页面',
    value: EPageType.新增页面,
  },
  {
    label: '编辑页面',
    value: EPageType.编辑页面,
  },
  {
    label: '查看页面',
    value: EPageType.查看页面,
  },
];

// 页面状态
export enum EPageStatus {
  未发布 = '1',
  已发布 = '2',
  禁用 = '3',
}

export const PageStatus = [
  {
    label: '未发布',
    value: EPageStatus.未发布,
  },
  {
    label: '已发布',
    value: EPageStatus.已发布,
  },
  {
    label: '禁用',
    value: EPageStatus.禁用,
  },
];

// 操作
export enum EDraft {
  草稿 = '1',
  非草稿 = '2',
}

export enum EPageModelType {
  标准模版页面组 = '1',
  定制开发页面组 = '2',
}

export const PageGroupTypes = [
  {
    label: '标准模版页面组',
    value: EPageModelType.标准模版页面组,
  },
  { label: '定制开发页面组', value: EPageModelType.定制开发页面组, disabled: true },
];

export enum EOptionsFromType {
  静态数据 = '1',
  动态数据 = '2',
}
// 枚举来源类型
export const OptionsFromTypes = [
  {
    label: '静态数据',
    value: EOptionsFromType.静态数据,
  },
  {
    label: '动态数据',
    value: EOptionsFromType.动态数据,
  },
];

export const PLACEHOLDER_OPTIONS = [
  {
    label: '选项A',
    value: 'a',
  },
  {
    label: '选项B',
    value: 'b',
  },
];

// 页面类型枚举
export enum EPageTypeNew {
  列表页面,
  表单页面,
}

// 枚举交互行为类型
export const OptionsInterbehaviorTypes = [
  {
    label: '吐司提示',
    value: '0',
  },
  // {
  //   label: '返回值提取',
  //   value: '1',
  // },
];

// 文案提取方式

export const OptionsExtractTypes = [
  {
    label: '返回值提取',
    value: '0',
  },
  {
    label: '提示文案',
    value: '1',
  },
];

// 执行动作
export const ACTION = {
  uploadData: 'uploadData', // 上传数据
  downloadData: 'downloadData', // 下载数据
  excuteFunction: 'excuteFunction', // 执行方法
};

// 触发条件
export const TRIGGER = {
  click: 'click',
};

// 文件类型
export enum FILE_TYPE {
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
}
