import { memo, useEffect, useMemo, useRef } from 'react';
import {
  QueryFilter,
  ProFormText,
  ProFormDatePicker,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormDigit,
  ProFormDateTimePicker,
  ProFormDateTimeRangePicker,
  ProFormTextArea,
} from '@ant-design/pro-form';
import { EOptionsFromType, FieldCmpType } from '@/constant';
import { parseString2Json } from '@/components/FormGeneractor/help';
import { DEFAULT_CONFIIG } from '@/components/FormGeneractor/ListContent/Search';
import deepmerge from 'deepmerge';
import { EModeType } from '@/store/form-generator-parse';
import _ from 'lodash';
import moment from 'moment';
import { getBindVarFromBindParam } from '../helper';
import useCurPageStore from '../useCurPageStore';
import useEnumRequest from '../useEnumRequest';

interface SearchProps {
  pageModeId: string;
  onUpdateParams?: (formData: Record<string, any>) => void;
  onFinish?: (formData: Record<string, any>) => void;
  hasCondition?: boolean;
  listPageInfo?: Record<string, any> | null;
}
const Search = memo<SearchProps>(
  ({ pageModeId, onUpdateParams, onFinish, hasCondition, listPageInfo }) => {
    const { fetchOptions } = useEnumRequest();
    const { curPageStore } = useCurPageStore(pageModeId);
    const { mode } = curPageStore;
    const ref = useRef<any>(null);

    const searchFields: any = useMemo(() => {
      if (!listPageInfo?.searchParams) {
        return [];
      }
      let fields = listPageInfo?.searchParams
        .sort((a: any, b: any) => a.orderNum - b.orderNum)
        .map((item: any) => {
          const info: any = parseString2Json(item.properties);
          const name = getBindVarFromBindParam(info.bindParam, 'loadMethod');
          return {
            ...info,
            name,
          };
        });
      if (!hasCondition) {
        fields = [];
      }

      // 有默认值的话，更新请求参数
      if (typeof onUpdateParams === 'function') {
        const target = {};
        let count = 0;
        fields.forEach((field: any) => {
          if (field.defaultValue !== undefined && field.defaultValue !== null) {
            _.set(target, field.name.join('.'), field.defaultValue);
            count++;
          }
        });
        if (count > 0) {
          onUpdateParams({ ...target });
        }
      }
      return fields;
    }, [listPageInfo, hasCondition]);

    const extraConfig = useMemo(() => {
      return deepmerge(DEFAULT_CONFIIG, parseString2Json(listPageInfo?.searchProperties || ''));
    }, [listPageInfo]);

    useEffect(() => {
      ref.current.resetFields();
      const data = ref.current.getFieldsValue();
      console.log('searchData', data);
      searchOnFinish(data);
    }, [listPageInfo]);

    const searchOnFinish = async (values: Record<string, any>) => {
      if (typeof onFinish === 'function') {
        // 时间控件转换为毫秒问题
        // eslint-disable-next-line guard-for-in
        for (const field in searchFields) {
          const info = searchFields[field];
          const path = info.name.join('.');
          const val = _.get(values, path, '');
          if ([FieldCmpType.日期范围选择器, FieldCmpType.日期选择器].includes(info.controlType)) {
            if (typeof val === 'string' && val !== '') {
              _.set(
                values,
                path,
                moment(val).format(
                  (info.transferFormat === 'custom'
                    ? info.transferFormatCustom
                    : info.transferFormat) ?? 'x',
                ),
              );
            } else if (Array.isArray(val) && val.length) {
              const startTimeName = info.bindParam[0].bindVar.mix.startTime.fieldName;
              const endTimeName = info.bindParam[0].bindVar.mix.endTime.fieldName;
              const data = val.map((o) =>
                moment(o).format(
                  (info.transferFormat === 'custom'
                    ? info.transferFormatCustom
                    : info.transferFormat) ?? 'x',
                ),
              );
              _.set(values, startTimeName, data[0]);
              _.set(values, endTimeName, data[1]);
            }
          }
          if (val && [FieldCmpType.多值文本].includes(info.controlType)) {
            _.set(
              values,
              path,
              (val as string).split(new RegExp(info.separator ?? '')).filter((o) => o !== ''),
            );
          }
        }
        onFinish(values);
      }
    };

    return (
      <div className="fg-search common-area">
        <QueryFilter
          defaultCollapsed={false}
          labelCol={{ span: 6 }}
          labelWidth={extraConfig?.labelWidth}
          formRef={ref}
          span={extraConfig?.formItemWidth}
          onFinish={searchOnFinish}
        >
          {searchFields
            .filter((o: any) => o.used !== false)
            .map((field: any) => {
              const commonProps = {
                key: field.cmpId,
                name: field.name,
                label: field.label,
                tooltip: field.hasTip ? field.tipContent : undefined,
                initialValue: field.defaultValue,
                fieldProps: {
                  placeholder:
                    // eslint-disable-next-line no-nested-ternary
                    field.controlType === FieldCmpType.日期范围选择器
                      ? field.placeholders
                        ? field.placeholders.split(',')
                        : ['开始时间', '结束时间']
                      : field.placeholder,
                  defaultValue: field.defaultValue,
                },
              };
              const hasDyOpts =
                mode === EModeType.production &&
                field.optionsFromType === EOptionsFromType.动态数据 &&
                field.dyOptionsInfo;

              switch (field.controlType) {
                case FieldCmpType.下拉框:
                  return (
                    <ProFormSelect
                      {...deepmerge(commonProps, {
                        options: field.options || [],
                        request: hasDyOpts
                          ? async () => {
                              return await fetchOptions(field.dyOptionsInfo);
                            }
                          : undefined,
                      })}
                    />
                  );
                case FieldCmpType.下拉多选框:
                  return (
                    <ProFormSelect
                      {...deepmerge(commonProps, {
                        options: field.options || [],
                        request: hasDyOpts
                          ? async () => {
                              return await fetchOptions(field.dyOptionsInfo);
                            }
                          : undefined,
                        fieldProps: {
                          mode: 'multiple',
                        },
                      } as any)}
                    />
                  );
                case FieldCmpType.日期选择器: {
                  const dateProps = deepmerge(commonProps, {});
                  (dateProps.fieldProps as any).format = field.showFormat || 'YYYY-MM-DD';
                  if (field.showFormat === 'YYYY-MM-DD HH:mm:ss') {
                    (dateProps.fieldProps as any).showTime = { format: 'HH:mm:ss' };
                  } else {
                    (dateProps.fieldProps as any).showTime = false;
                  }
                  if ((dateProps.fieldProps as any).showTime) {
                    return <ProFormDateTimePicker {...dateProps} />;
                  }
                  return <ProFormDatePicker {...dateProps} />;
                }
                case FieldCmpType.日期范围选择器: {
                  const daterangeProps = deepmerge(commonProps, {});
                  (daterangeProps.fieldProps as any).format = field.showFormat || 'YYYY-MM-DD';
                  if (field.showFormat === 'YYYY-MM-DD HH:mm:ss') {
                    (daterangeProps.fieldProps as any).showTime = { format: 'HH:mm:ss' };
                  } else {
                    (daterangeProps.fieldProps as any).showTime = false;
                  }
                  if (field.defaultTime === 1) {
                    (daterangeProps as any).initialValue = [
                      moment()
                        .startOf('days')
                        .add(-(field.defaultDays - 1), 'days'),
                      moment().endOf('days'),
                    ];
                  }
                  if ((daterangeProps.fieldProps as any).showTime) {
                    return <ProFormDateTimeRangePicker {...daterangeProps} />;
                  }
                  return <ProFormDateRangePicker {...daterangeProps} />;
                }
                case FieldCmpType.数字输入框:
                  return <ProFormDigit {...deepmerge(commonProps, {})} />;
                case FieldCmpType.多值文本:
                  return <ProFormTextArea {...deepmerge(commonProps, {})} />;
                default:
                  return <ProFormText {...commonProps} />;
              }
            })}
        </QueryFilter>
      </div>
    );
  },
);

export default Search;
