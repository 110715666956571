import { memo, useCallback } from 'react';
import { Popconfirm } from 'antd';
import useDetailStore from '@/store/form-generator-detail';
import FormCreator, { EWidgetType, FCRules } from '@/components/FormCreator';
import SortableTable from '@/components/SortableTable';
import DeleteIconButton from '@/components/DeleteIconButton';
import update from 'immutability-helper';
import WithCollapse from '../../WithCollapse';
import MethodsValidation from '../../MethodsValidation';
import { parseString2Json, filterNullMethods } from '@/components/FormGeneractor/help';
import useFormGeneratorStore from '@/store/form-generator';

let debounceTimer: any;
const ViewBox = memo(() => {
  const detailStore = useDetailStore();
  const { fields, uFields, title, ready, bindedMethods, serviceConfig, uMethodsValidationConfig } =
    detailStore;
  const usedFields = fields;
  const properties: any = parseString2Json(serviceConfig?.properties ?? '{}');
  const { methodsValidationConfig } = properties;
  const { currentPage } = useFormGeneratorStore((state) => state);

  const columns: any[] = [
    {
      title: '组件',
      ellipsis: true,
      render: (_: any, record: any) =>
        `${record.label} （${record.paramName || record.controlId}）`,
    },
    {
      title: '操作',
      fixed: 'right',
      width: 60,
      render: (_: any, record: any, index: number) => (
        <>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              usedFields.splice(index, 1);
              uFields([...fields]);
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </>
      ),
    },
  ];

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!usedFields?.length) return;
      const dragRow = usedFields[dragIndex];
      const newFields = update(usedFields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      uFields([...newFields]);
    },
    [usedFields, uFields],
  );

  const CollapseDatas = [
    {
      title: '基础属性',
      key: 'box-config',
      configComponent: (
        <>
          <FormCreator
            submitter={{ render: () => null }}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            debug
            layout="horizontal"
            onValuesChange={(_, allValsChange) => {
              const { labelColWidth, wrapperColWidth } = allValsChange;
              if (debounceTimer) {
                clearTimeout(debounceTimer);
              }
              debounceTimer = setTimeout(() => {
                detailStore.uTitle(title || '');
                detailStore.uLabelColWidth(labelColWidth || '');
                detailStore.uWrapperColWidth(wrapperColWidth || '');
              }, 500);
            }}
            config={{
              title: {
                label: '标题',
                defaultValue: title || '',
                fieldType: EWidgetType.Input,
                rules: [FCRules.Required()],
                fieldProps: {},
              },
              labelColWidth: {
                label: '标题区宽度',
                defaultValue: detailStore.labelColWidth || '',
                fieldType: EWidgetType.InputNumber,
                extra: '默认 200 px',
                fieldProps: {},
              },
              wrapperColWidth: {
                label: '内容宽度',
                defaultValue: detailStore.wrapperColWidth || '',
                fieldType: EWidgetType.InputNumber,
                extra: '默认铺满',
                fieldProps: {},
              },
            }}
          />
        </>
      ),
    },
    {
      title: '方法校验',
      key: `methods-validation-config${currentPage?.id}`,
      configComponent: (
        <MethodsValidation
          bindedMethods={bindedMethods}
          bindedMethodsCconfig={methodsValidationConfig}
          onChange={(values) => {
            values.loadMethods = filterNullMethods(values.loadMethods || []);

            uMethodsValidationConfig(values);
          }}
        />
      ),
    },
  ];

  if (!ready) {
    // 数据末准备好，则不能渲染表单，否则初始化的表单是有误的
    return null;
  }

  return (
    <>
      <WithCollapse collapseDatas={CollapseDatas} defaultOpenFirstOne={false} expandAll />
      <SortableTable
        bordered
        size="small"
        title={() => null}
        columns={columns}
        showHeader={false}
        dataSource={usedFields}
        rowKey="controlId"
        pagination={false}
        moveRow={moveRow}
      />
    </>
  );
});

export default ViewBox;
