import { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Tree, Popover, Typography, Spin } from 'antd';
import { typeOf } from '@/utils/utils';
import type { MethodParam } from '@/types';
import useSetState from '@/hooks/useSetState';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import useMethodDetail from '@/hooks/useMethodDetail';
import { useClickAway } from 'ahooks';
import { FieldCmpType, EDataDisplayCmpType } from '@/constant';
import DeleteIconButton from '../DeleteIconButton';
import _ from 'lodash';
import type { FieldItem } from '@/store/types';

export const doFill = (data: any, info: any) => {
  if (Array.isArray(info) && info.length) {
    const firstRow = info[0];
    if (_.isObject(firstRow)) {
      const itemStruct: any = {
        title: (
          <Typography.Text style={{ fontSize: 12, color: '#666' }}>List/Object</Typography.Text>
        ),
        key: '[0]',
        children: [],
      };
      data.children.push(itemStruct);
    }
  } else if (_.isObject(info)) {
    // eslint-disable-next-line guard-for-in
    for (const attr in info) {
      const item = (info as any)[attr];
      let type: string;
      if (item?.length) {
        type = typeOf(item[0]);
      } else if (Array.isArray(item?.[0])) {
        type = 'List';
      } else {
        type = typeOf(item || '');
      }
      const child = {
        title: (
          <>
            {attr}{' '}
            <Typography.Text style={{ fontSize: 12, color: '#666' }}>
              {Array.isArray(item) ? ` （List/${type}）` : ` （${type}）`}
            </Typography.Text>
          </>
        ),
        key: `${data.key ? `${data.key}.` : ''}${attr}`,
        children: [],
      };
      if (!data.children) {
        data.children = [];
      }
      data.children.push(child);
      if (item) {
        doFill(child, Array.isArray(item) && item?.length ? item[0] : item);
      }
    }
  }
};

interface IPickMethodVarProps {
  onSelected?: (...args: any[]) => void;
  value?: any;
  // 方法配置的参数类型
  // dataSource: MethodParam[],
  contextField?: 'outputParams' | 'inputParams';
  methodId: string;
  // 组件字段类型
  targetFieldType?: string;
  mixBindVarFieldName?: string;
  isTableList?: boolean;
  otherValue?: FieldItem;
}

const PickMethodVar = memo<IPickMethodVarProps>(
  ({
    isTableList = false,
    mixBindVarFieldName,
    onSelected,
    value,
    contextField = 'inputParams',
    methodId,
    targetFieldType,
    otherValue,
  }) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const [popoverInfo, setPopoverInfo] = useSetState<{
      visible: boolean;
    }>({
      visible: false,
    });
    useClickAway(() => {
      setPopoverInfo({ visible: false });
    }, ref);
    const { load, loading, data: methodDetail } = useMethodDetail();
    const [treeData, setTreeData] = useState<any>(null);
    const fieldName =
      typeof mixBindVarFieldName !== 'undefined'
        ? value?.mix?.[mixBindVarFieldName]?.fieldName
        : value?.fieldName;

    useEffect(() => {
      if (methodId && popoverInfo.visible) {
        load(methodId);
      }
    }, [methodId, popoverInfo.visible]);

    // 参数转换为 tree
    const transform2Tree = useCallback(
      (paramsStruct: MethodParam[], path = ''): any[] => {
        if (paramsStruct?.length) {
          return paramsStruct.map((item) => {
            let disabled = false;
            // 限制可选范围
            if (
              [
                FieldCmpType.数字输入框,
                FieldCmpType.输入框,
                FieldCmpType.联动输入,
                FieldCmpType.单选框,
                FieldCmpType.多行文本框,
                FieldCmpType.富文本,
                FieldCmpType.日期选择器,
                FieldCmpType.日期范围选择器,
              ].includes(targetFieldType as FieldCmpType) ||
              [EDataDisplayCmpType.文本, EDataDisplayCmpType.图片].includes(
                targetFieldType as EDataDisplayCmpType,
              )
            ) {
              disabled = ![
                EFieldType.Double,
                EFieldType.Float,
                EFieldType.Integer,
                EFieldType.Long,
                EFieldType.String,
                EFieldType.Date,
              ].includes(item.dataType as EFieldType);
            } else if ([FieldCmpType.下拉框].includes(targetFieldType as FieldCmpType)) {
              disabled = ![
                EFieldType.Double,
                EFieldType.Float,
                EFieldType.Integer,
                EFieldType.Long,
                EFieldType.String,
                EFieldType.Date,
                EFieldType.Boolean,
              ].includes(item.dataType as EFieldType);
            } else if (
              [FieldCmpType.日期时间, FieldCmpType.日期区间].includes(
                targetFieldType as FieldCmpType,
              )
            ) {
              disabled = ![EFieldType.String, EFieldType.Long, EFieldType.Date].includes(
                item.dataType as EFieldType,
              );
            } else if (
              [FieldCmpType.下拉多选框, FieldCmpType.复选框].includes(
                targetFieldType as FieldCmpType,
              )
            ) {
              disabled = !(
                (item.dataType as EFieldType) === EFieldType.List &&
                [
                  EFieldType.String,
                  EFieldType.Double,
                  EFieldType.Float,
                  EFieldType.Integer,
                  EFieldType.Long,
                ].includes(item.genericType as EFieldType)
              );
            } else if (
              targetFieldType === FieldCmpType.多图上传 ||
              targetFieldType === EDataDisplayCmpType.轮播图
            ) {
              if (otherValue?.isSingle) {
                disabled = ![EFieldType.String].includes(item.dataType as EFieldType);
              } else {
                disabled = !(
                  (item.dataType as EFieldType) === EFieldType.List &&
                  [EFieldType.String].includes(item.genericType as EFieldType)
                );
              }
            } else if (targetFieldType === 'image') {
              // 展示的图片， string | list/string
              disabled = !(
                [EFieldType.String].includes(item.dataType as EFieldType) ||
                ((item.dataType as EFieldType) === EFieldType.List &&
                  [EFieldType.String].includes(item.genericType as EFieldType))
              );
            } else if (targetFieldType === FieldCmpType.单文件上传) {
              // 单文件上传 string
              disabled = ![EFieldType.String].includes(item.dataType as EFieldType);
            } else if (targetFieldType === FieldCmpType.表格文件上传) {
              // 表格文件上传，限制为List类型可选
              disabled = ![EFieldType.List].includes(item.dataType as EFieldType);
            } else if (targetFieldType === FieldCmpType.列表) {
              // 显示列表数据，限制为List类型可选
              disabled = ![EFieldType.List].includes(item.dataType as EFieldType);
            } else if (
              [EDataDisplayCmpType.视频, EDataDisplayCmpType.枚举, FieldCmpType.视频上传].includes(
                targetFieldType as EDataDisplayCmpType,
              )
            ) {
              disabled = false;
            } else if (targetFieldType === 'enumObj') {
              // [{label: string, value: string}]
              disabled = !(
                (item.dataType as EFieldType) === EFieldType.List &&
                [EFieldType.Object].includes(item.genericType as EFieldType)
              );
            } else if (targetFieldType === FieldCmpType.开关) {
              disabled = !(item.dataType === EFieldType.Boolean);
            } else if (targetFieldType === FieldCmpType.弹窗多选) {
              disabled = ![EFieldType.List].includes(item.dataType as EFieldType);
            } else if (targetFieldType === FieldCmpType.多值文本) {
              disabled = !(
                (item.dataType as EFieldType) === EFieldType.List &&
                ![EFieldType.Object, EFieldType.Boolean, EFieldType.Date, EFieldType.List].includes(
                  item.genericType as EFieldType,
                )
              );
            } else if ((targetFieldType || '').split('/')?.length === 2) {
              // 2边的参数对应上
              const [a, b] = (targetFieldType || '').split('/');
              disabled = !((a as EFieldType) === item.dataType && b === item.genericType);
            } else if (
              // 2边的参数对应上
              targetFieldType &&
              (targetFieldType || '').split('/')?.length === 1
            ) {
              disabled = !((targetFieldType as EFieldType) === item.dataType);
            }
            // 列表限制只能选择 list 类型的字段
            if (isTableList) {
              disabled = item.dataType !== 'List';
            }
            return {
              title: (
                <>
                  {item.name}{' '}
                  <Typography.Text style={{ fontSize: 12, color: '#666' }}>
                    {item.title}（{item.dataType}
                    {item.genericType ? `/${item.genericType}` : ''}）
                  </Typography.Text>
                </>
              ),
              key: `${path ? `${path}.` : ''}${item.name}_${item.id}_${item.dataType}_${
                item.title
              }`,
              children: transform2Tree(item.fields || [], item.name),
              disabled,
            };
          });
        } else {
          return [];
        }
      },
      [targetFieldType],
    );

    useEffect(() => {
      if (methodDetail) {
        setTreeData(transform2Tree((methodDetail as any)[contextField]));
      } else {
        setTreeData(null);
      }
    }, [methodDetail, contextField]);

    return (
      <Spin spinning={loading}>
        <Popover
          visible={popoverInfo?.visible}
          content={
            <div style={{ minWidth: 700 }}>
              <Typography.Text>方法参数</Typography.Text>
              {treeData && (
                <Tree
                  showLine={{ showLeafIcon: false }}
                  showIcon={false}
                  treeData={treeData}
                  onSelect={([selectedKey], info) => {
                    if (!selectedKey) return;
                    const [name, id, dataType] = String(selectedKey).split('_');
                    const { children = [] } = info.selectedNodes[0];
                    const childKeys: any[] = []; // List类型，透传其Item的字段
                    children.forEach(({ key }) => {
                      const [childName, childId, childDataType, childTitle] =
                        String(key).split('_');
                      childKeys.push({
                        key: childName,
                        title: childTitle,
                        id: childId,
                        dataType: childDataType,
                      });
                    });
                    onSelected?.({
                      fieldName: name,
                      fieldId: id,
                      fieldType: dataType,
                      childKeys,
                    });
                  }}
                  defaultExpandAll
                  height={400}
                />
              )}
            </div>
          }
          placement="left"
          trigger="click"
        >
          <a
            ref={ref}
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            onClick={() => {
              setPopoverInfo({
                visible: true,
              });
            }}
          >
            {fieldName ? `${fieldName}` : '请绑定变量'}
          </a>
          {fieldName && (
            <DeleteIconButton
              onClick={() => {
                onSelected?.({});
              }}
            />
          )}
        </Popover>
      </Spin>
    );
  },
);
export default PickMethodVar;
