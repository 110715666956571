import { useMemo, forwardRef, useCallback, useImperativeHandle } from 'react';
import { Row, message, Tag, Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { value2Label } from '@/components/SchemaView/utils/help';
import useFormGeneratorStore from '@/store/form-generator';
import useListStore from '@/store/form-generator-list';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useDetailStore from '@/store/form-generator-detail';
import useMethodDetail from '@/hooks/useMethodDetail';
import { promisifyConfirm } from '@/utils/helper';
import PickMethod from '@/components/PickMethod';
import { EPageType, PageStatus, EPageStatus } from '@/constant';
import { getListPageConfig, getFormPageConfig } from '@/services/page';
import CreatePage from './CreatePage';
import SelectPage from './SelectPage';
import { Name as ListBoxName } from './ListContent';
import { Name as AddBoxName } from './AddContent';
import { Name as EditBoxName } from './EditContent';
// import { Name as ViewBoxName } from './ViewContent'

import './Header.less';
import { formatPageTypes } from './help';
import useGroupPageTypeList from '@/hooks/useGroupPageTypeList';

interface HeaderProps {
  handleSave: (data?: any) => void;
  validatePagination: () => boolean;
  reInit?: (id?: string) => void;
}
interface RefType {
  togglePage: (pageId: string, needConfirm?: boolean) => Promise<void>;
}

const CONFIRM_TEXT = '切换方法会重置所有配置，确定吗？';
const Header = forwardRef<RefType, HeaderProps>(
  ({ handleSave, reInit, validatePagination }, ref) => {
    const {
      hasChange: listStoreHasChange,
      listPageServiceConfig,
      uListPageServiceConfig,
      uListConfig,
      uListPagination,
      reset: listStoreReset,
      bindedMethods: listBindedMethods,
      uBindedMethods: listUBindedMethods,
    } = useListStore((state) => state);
    const {
      hasChange: addStoreHasChange,
      serviceConfig: addServiceConfig,
      uServiceConfig: uAddServiceConfig,
      reset: addStoreReset,
      uMethodsValidationConfig: uAddMethodsValidationConfig,
      bindedMethods: addBindedMethods,
      uBindedMethods: addUBindedMethods,
    } = useAddStore((state) => state);
    const {
      hasChange: editStoreHasChange,
      serviceConfig: editServiceConfig,
      uServiceConfig: uEditServiceConfig,
      uMethodsValidationConfig: uEditMethodsValidationConfig,

      reset: editStoreReset,
      bindedMethods: editBindedMethods,
      uBindedMethods: editUBindedMethods,
    } = useEditStore((state) => state);
    const {
      hasChange: detailStoreHasChange,
      serviceConfig: detailServiceConfig,
      uServiceConfig: uDetailServiceConfig,
      reset: detailStoreReset,
      // methodsValidationConfig,
      uMethodsValidationConfig: uDetailMethodsValidationConfig,

      bindedMethods: detailBindedMethods,
      uBindedMethods: detailUBindedMethods,
    } = useDetailStore((state) => state);
    const fgStore = useFormGeneratorStore((state) => state);
    const { currentPage, currentPageGroupId } = fgStore;
    const { load: loadMethod } = useMethodDetail();
    const { pageList, uPageList, uCurrentPage, uDefaultPage, uRemovePage } = fgStore;

    const { load: loadPageList } = useGroupPageTypeList(false);

    const isDraft =
      fgStore.currentPageGroupInfo?.status == EPageStatus.已发布 &&
      (listPageServiceConfig?.draft ||
        addServiceConfig?.draft ||
        editServiceConfig?.draft ||
        detailServiceConfig?.draft ||
        fgStore.isDraft);

    const getEditMethodConfig = (properties: string) => {
      if (!properties) return {};
      return JSON.parse(properties).methodsValidationConfig;
    };
    // 切换
    const togglePage = useCallback(
      async (pageId: string, needSave = true) => {
        const havePagination = validatePagination();
        if (!havePagination) return;
        fgStore.uLoading(true);
        if (
          needSave &&
          (listStoreHasChange || addStoreHasChange || editStoreHasChange || detailStoreHasChange)
        ) {
          // 先保存编辑的草稿，再切换
          await handleSave();
        }
        // let confirmRes = needConfirm ? false : true
        // if (needConfirm) {
        //   confirmRes = await promisifyConfirm('是否将当前页面保存为草稿，若不保存则修改的数据将丢失')
        // }
        // if (!confirmRes) return
        try {
          const targetPage = pageList?.find((o: any) => o.id === pageId);
          const api =
            String(targetPage.type) === EPageType.列表页面 ? getListPageConfig : getFormPageConfig;
          const res = await api(pageId);
          let pageInfo: any = null;
          if (res.rCode !== 0) {
            message.error(res.msg);
            return;
          }
          pageInfo = res.data;

          // 切换到其它页面，离开当前页时，清除掉当前页的 store， 不然会有 bug
          if (pageId !== currentPage?.id) {
            switch (String(currentPage?.type)) {
              case EPageType.列表页面:
                listStoreReset();
                break;
              case EPageType.新增页面:
                addStoreReset();
                break;
              case EPageType.编辑页面:
                editStoreReset();
                break;
              case EPageType.查看页面:
                detailStoreReset();
                break;
              default:
                break;
            }
          }

          // 把服务端返回的配置信息存储起来
          switch (String(targetPage.type)) {
            case EPageType.列表页面:
              uListPageServiceConfig(pageInfo);
              fgStore.uCurrentFocusCmp(ListBoxName);
              // 方法回选
              if (pageInfo.loadMethodId) {
                const method = await loadMethod(pageInfo.loadMethodId);
                if (method) {
                  listUBindedMethods({
                    loadMethod: method,
                  });
                }
              }
              break;
            case EPageType.新增页面:
              uAddServiceConfig(pageInfo);
              uAddMethodsValidationConfig(getEditMethodConfig(pageInfo.properties));
              fgStore.uCurrentFocusCmp(AddBoxName);
              // 方法回选
              if (pageInfo.submitMethodId) {
                const method = await loadMethod(pageInfo.submitMethodId);
                if (method) {
                  addUBindedMethods({
                    submitMethod: method,
                  });
                }
              }
              break;
            case EPageType.编辑页面:
              uEditServiceConfig(pageInfo);
              uEditMethodsValidationConfig(getEditMethodConfig(pageInfo.properties));
              fgStore.uCurrentFocusCmp(EditBoxName);
              // 方法回选
              if (pageInfo.loadMethodId) {
                const method = await loadMethod(pageInfo.loadMethodId);
                if (method) {
                  editUBindedMethods({
                    loadMethod: method,
                  });
                }
              }
              if (pageInfo.submitMethodId) {
                const method = await loadMethod(pageInfo.submitMethodId);
                if (method) {
                  editUBindedMethods({
                    submitMethod: method,
                  });
                }
              }
              break;
            case EPageType.查看页面:
              uDetailServiceConfig(pageInfo);
              uDetailMethodsValidationConfig(getEditMethodConfig(pageInfo.properties));
              fgStore.uCurrentFocusCmp(EditBoxName);
              // 方法回选
              if (pageInfo.loadMethodId) {
                const method = await loadMethod(pageInfo.loadMethodId);
                if (method) {
                  detailUBindedMethods({
                    loadMethod: method,
                  });
                }
              }
              break;
            default:
          }

          uCurrentPage(targetPage);
        } catch (error) {
          console.log(error);
        } finally {
          fgStore.uLoading(false);
        }
      },
      [currentPage, fgStore, pageList, listStoreHasChange, addStoreHasChange, handleSave],
    );

    useImperativeHandle(
      ref,
      () => ({
        togglePage,
      }),
      [togglePage],
    );

    const MethodView = useMemo(() => {
      const LoadMethod = ({ onChange, value }: any) => (
        <>
          <span>加载方法：</span>
          <PickMethod
            value={value ? [value] : undefined}
            getLabelByValue={(methods) => (methods?.length ? methods[0].name : '请选择')}
            checkedList={[value].filter((o) => Boolean(o))}
            onChange={onChange}
          />
        </>
      );

      const SubmitMethod = ({ onChange, value }: any) => (
        <>
          <span>更新方法：</span>
          <PickMethod
            value={value ? [value] : undefined}
            getLabelByValue={(methods) => (methods?.length ? methods[0].name : '请选择')}
            checkedList={[value].filter((o) => Boolean(o))}
            onChange={onChange}
          />
        </>
      );

      let View: React.ReactNode = null;
      switch (String(currentPage?.type)) {
        case EPageType.列表页面:
          View = (
            <>
              <LoadMethod
                value={listBindedMethods?.loadMethod}
                onChange={async (val: any) => {
                  let bool = true;
                  if (listBindedMethods?.loadMethod) {
                    bool = await promisifyConfirm({ content: CONFIRM_TEXT });
                  }
                  if (bool) {
                    uListConfig({});
                    uListPagination({ pageNumberField: {}, pageSizeField: {}, totalField: {} });
                    listUBindedMethods({
                      loadMethod: Array.isArray(val) && val?.length ? val[0] : null,
                    });
                    uListPageServiceConfig({
                      ...listPageServiceConfig,
                      properties: '',
                      titleProperties: '',
                      searchProperties: '',
                      paginationProperties: '',
                      contentProperties: '',
                      searchParams: [],
                      toolbarButtons: [],
                      operationButtons: [],
                      contentColumns: [],
                      methodReferences: [],
                    });
                    const event = new CustomEvent('setBindedMethods');
                    window.dispatchEvent(event);
                  }
                }}
              />
            </>
          );
          break;
        case EPageType.新增页面:
          View = (
            <>
              <SubmitMethod
                value={addBindedMethods?.submitMethod}
                onChange={async (val: any) => {
                  let bool = true;
                  if (addBindedMethods?.submitMethod) {
                    bool = await promisifyConfirm({ content: CONFIRM_TEXT });
                  }
                  if (bool) {
                    addUBindedMethods({
                      submitMethod: Array.isArray(val) && val?.length ? val[0] : null,
                    });
                    uAddServiceConfig({
                      ...addServiceConfig,
                      // properties: "",
                      components: [],
                      methodReferences: [],
                    });
                    uAddMethodsValidationConfig({});
                    const event = new CustomEvent('setBindedMethods');
                    window.dispatchEvent(event);
                  }
                }}
              />
            </>
          );
          break;
        case EPageType.编辑页面:
          View = (
            <>
              <LoadMethod
                value={editBindedMethods?.loadMethod}
                onChange={async (val: any) => {
                  let bool = true;
                  if (editBindedMethods?.loadMethod) {
                    bool = await promisifyConfirm({ content: CONFIRM_TEXT });
                  }
                  if (bool) {
                    editUBindedMethods({
                      loadMethod: Array.isArray(val) && val?.length ? val[0] : null,
                    });
                    uEditServiceConfig({
                      ...editServiceConfig,
                      // properties: "",
                      components: [],
                      methodReferences: [],
                    });
                    uEditMethodsValidationConfig({});
                    const event = new CustomEvent('setBindedMethods');
                    window.dispatchEvent(event);
                  }
                }}
              />
              <div style={{ width: 30 }} />
              <SubmitMethod
                value={editBindedMethods?.submitMethod}
                onChange={async (val: any) => {
                  let bool = true;
                  if (editBindedMethods?.submitMethod) {
                    bool = await promisifyConfirm({ content: CONFIRM_TEXT });
                  }
                  if (bool) {
                    editUBindedMethods({
                      submitMethod: Array.isArray(val) && val?.length ? val[0] : null,
                    });
                    uEditServiceConfig({
                      ...editServiceConfig,
                      // properties: "",
                      components: [],
                      methodReferences: [],
                    });
                    uEditMethodsValidationConfig({});
                    const event = new CustomEvent('setBindedMethods');
                    window.dispatchEvent(event);
                  }
                }}
              />
            </>
          );
          break;
        case EPageType.查看页面:
          View = (
            <>
              <LoadMethod
                value={detailBindedMethods?.loadMethod}
                onChange={async (val: any) => {
                  let bool = true;
                  if (detailBindedMethods?.loadMethod) {
                    bool = await promisifyConfirm({ content: CONFIRM_TEXT });
                  }
                  if (bool) {
                    detailUBindedMethods({
                      loadMethod: Array.isArray(val) && val?.length ? val[0] : null,
                    });
                    uDetailServiceConfig({
                      ...detailServiceConfig,
                      // properties: "",
                      components: [],
                      methodReferences: [],
                    });
                    uDetailMethodsValidationConfig({});
                    const event = new CustomEvent('setBindedMethods');
                    window.dispatchEvent(event);
                  }
                }}
              />
            </>
          );
          break;
        default:
          break;
      }
      return View;
    }, [
      currentPage,
      listPageServiceConfig,
      listBindedMethods,
      addBindedMethods,
      addServiceConfig,
      editBindedMethods,
      editServiceConfig,
      detailBindedMethods,
      detailServiceConfig,
    ]);

    return (
      <Row className="fg-header" justify="space-between" align="middle">
        <Row justify="start" align="middle">
          <CreatePage
            groudId={currentPageGroupId}
            onCreate={async (id) => {
              reInit?.(id);
            }}
          />
          <SelectPage
            id={currentPage?.id}
            pages={pageList}
            onChange={async (id) => {
              await togglePage(id);
            }}
            onDefault={uDefaultPage}
            onCreate={async (id) => {
              reInit?.(id);
            }}
            onRemove={(id) => {
              const next = pageList.findIndex((n) => String(n.id) === String(id)) + 1;
              uRemovePage(id);
              // 删除后切换到默认页面
              const target = next >= pageList.length ? pageList[0] : pageList[next];
              togglePage(target.id);
            }}
            onEditPage={async (data) => {
              await handleSave(data);
              togglePage(currentPage?.id, false);
              const pageListData = await loadPageList(currentPageGroupId);
              uPageList(formatPageTypes(pageListData));
              // console.log(loadPageList, uPageList, formatPageTypes);
            }}
          />
          <div style={{ width: 20 }} />
          <Tag
            color={[undefined, undefined, '#87d068', '#f50'][fgStore.currentPageGroupInfo?.status]}
          >
            {value2Label(PageStatus, fgStore.currentPageGroupInfo?.status) || ''}
          </Tag>
          {fgStore.currentPageGroupInfo?.status == EPageStatus.已发布 ? (
            <Tooltip title="有修改过的页面，发布后才能生效">
              <div>
                <Tag>当前页{isDraft ? '有' : '未'}修改过</Tag>
              </div>
            </Tooltip>
          ) : null}
        </Row>
        <Row justify="start" align="middle">
          {MethodView}
          <Button
            size="small"
            icon={<ReloadOutlined />}
            style={{ marginLeft: 15 }}
            onClick={async () => {
              const confirmRes = await promisifyConfirm({
                content: '未保存草稿的数据刷新后会丢失，确定吗？',
              });
              if (confirmRes && currentPage?.id) {
                togglePage(currentPage.id);
              }
            }}
          >
            刷新
          </Button>
        </Row>
      </Row>
    );
  },
);

export default Header;
