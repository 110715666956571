import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function MultiImageUploadConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            isSingle: {
              label: '取消多图功能',
              defaultValue: fieldInfo?.isSingle || false,
              fieldType: EWidgetType.Select,
              options: [
                { label: '是', value: true },
                { label: '否', value: false },
              ],
            },
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            fileType: {
              label: '内部上传编码',
              defaultValue: fieldInfo?.fileType,
              fieldType: EWidgetType.InputNumber,
            },
            preFileLink: {
              label: '资源http前缀',
              defaultValue: fieldInfo?.preFileLink,
              fieldType: EWidgetType.Input,
            },
            isFilterPrefix: {
              label: '提交时去前缀',
              defaultValue: fieldInfo?.isFilterPrefix || false,
              fieldType: EWidgetType.Select,
              options: [
                { label: '是', value: true },
                { label: '否', value: false },
              ],
            },
            fileTypeLimit: {
              label: '限制格式',
              defaultValue: fieldInfo?.fileTypeLimit || ['jpeg', 'png', 'gif'],
              fieldType: EWidgetType.CheckboxGroup,
              options: [
                { label: 'jpg', value: 'jpeg' },
                { label: 'gif', value: 'gif' },
                { label: 'png', value: 'png' },
              ],
            },
            maxCount: {
              label: '上传张数',
              defaultValue: fieldInfo?.maxCount || 3,
              fieldType: EWidgetType.InputNumber,
              addonBefore: <span>最多</span>,
              addonAfter: <span>张</span>,
            },
            sizeLimit: {
              label: '限制大小',
              defaultValue: fieldInfo?.sizeLimit || false,
              fieldType: EWidgetType.Switch,
            },
            singleFileSize: {
              label: '每张大小',
              defaultValue: fieldInfo?.singleFileSize || 2,
              fieldType: EWidgetType.InputNumber,
              addonBefore: <span>限制</span>,
              addonAfter: <span>m</span>,
            },
            limitPrompt: {
              label: '校验文案',
              tooltip: '上传格式不符合时提示文案',
              defaultValue: fieldInfo?.limitPrompt ?? '',
              fieldType: EWidgetType.Input,
            },
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
