import { createContext } from 'react';
import { EPassValueType } from './types';
import type { IStore, IConfigFields, IRowItem } from './types';

export const StoreContext = createContext<IStore>({
  state: {
    datas: [],
    mode: 'opera',
    checkeds: [],
  },
});

export const FIELD_WIDTH = 250;

export const TABLE_HEAD_FIELDS: IConfigFields = {
  /* '-': {
    label: '-',
    style: {
      width: 100,
    },
  }, */
  dataType: {
    label: '字段类型',
    style: {
      marginLeft: 24,
    },
  },
};

export const DEFAULT_CONFIG_FIELDS: IConfigFields = {
  name: {
    label: '字段标识',
    rules: [
      (value) => {
        return !value ? Promise.reject('字段名称必填') : Promise.resolve('');
      },
      (value) => {
        return /^\d/.test(value) ? Promise.reject('字段名称不能以数字开头') : Promise.resolve('');
      },
      (value) => {
        return value.length > 60 ? Promise.reject('不能超过60字') : Promise.resolve('');
      },
      (value, datas, row) => {
        let isSameName = false;
        let camleOne = '';
        const check = (list: any[]) => {
          for (const item of list) {
            if (item._id !== row._id && row._level === item._level) {
              if (value && item.name === value) {
                isSameName = true;
                break;
              }
              if (
                value &&
                item.name.length === value.length &&
                value.slice(1) === item.name.slice(1) &&
                value.charAt(0) !== item.name.charAt(0) &&
                value.charAt(0).toLowerCase() === item.name.charAt(0).toLowerCase()
              ) {
                camleOne = item.name;
                break;
              }
            }
            if (item._folds && item._folds.length) {
              check(item._folds);
            }
          }
        };
        check(datas!);
        if (isSameName) {
          return Promise.reject(`【${value}】字段名称已存在`);
        } else if (camleOne) {
          return Promise.reject(`【${value}】和【${camleOne}】字段名称之间会冲突`);
        } else {
          return Promise.resolve('');
        }
      },
    ],
  },
  title: {
    label: '字段标题',
    rules: [
      (value: string) => {
        return !value ? Promise.reject('字段标题必填') : Promise.resolve('');
      },
      (value) => {
        return value.length > 60 ? Promise.reject('不能超过60字') : Promise.resolve('');
      },
    ],
  },
};

export const INDENT_SIZE = 24;

export const PassValueTypes = [
  {
    label: '直接传值',
    value: EPassValueType.直接传值,
  },
  {
    label: '前端变量',
    value: EPassValueType.前端传值,
  },
  {
    label: '后端变量',
    value: EPassValueType.后端传值,
  },
];

export const cacheSnap = new Map<string, IRowItem[]>();
