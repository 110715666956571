import React, { memo } from 'react';
import { Card } from 'antd';

interface ImageViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const ImageView: React.FC<ImageViewProps> = () => {
  return (
    <>
      <Card>Video</Card>
    </>
  );
};

export default memo(ImageView);
