import { memo } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space } from 'antd';

interface ExportFileKeys {
  value?: any[];
  onChange?: (data: any[]) => void;
  bindParam?: any;
}

interface ExportKey {
  id: string;
  label: string;
  value: string;
}
interface FormExportKeys {
  key: string;
}

const { Option } = Select;

export default memo<ExportFileKeys>(({ value, bindParam = [], onChange }) => {
  const formValue: {
    formExportKeys: FormExportKeys[];
  } = { formExportKeys: [] };

  // 有旧的值
  if (typeof value !== 'undefined') {
    const oldExportKeys: FormExportKeys[] = [];
    value.forEach(({ id }) => {
      oldExportKeys.push({
        key: id,
      });
    });
    formValue.formExportKeys = oldExportKeys;
  }

  const bindInfo = bindParam.find((item: any) => item.methodParamType === 'outputParams');
  let exportKeys: ExportKey[] = []; // 获取到可绑定的字段
  const allKeysInfo: Record<string, any> = {};
  if (typeof bindInfo !== 'undefined') {
    const keys = bindInfo?.bindVar?.childKeys ?? [];
    exportKeys = [];
    keys.forEach((item: any) => {
      allKeysInfo[item.id] = {
        ...item,
      };
      exportKeys.push({
        id: item.id,
        label: item.title,
        value: item.key.split('.').slice(-1)[0],
      });
    });
  }

  const [form] = Form.useForm();

  const onValuesChange = (_: any, values: any) => {
    const newSaveValue: any[] = [];
    values.formExportKeys.forEach((item: { key: string | undefined }) => {
      if (typeof item === 'undefined') {
        return false;
      }
      const { key = '' } = item;
      if (key.length < 1) {
        return false;
      }
      const info = allKeysInfo[key];
      if (typeof info !== 'undefined') {
        newSaveValue.push({
          ...info,
          propName: info.key.split('.').slice(-1)[0],
        });
      }
    });
    if (typeof onChange === 'function') {
      onChange(newSaveValue);
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={formValue}
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <Form.List name="formExportKeys">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.key !== curValues.key}
                  >
                    {() => (
                      <Form.Item {...field} name={[field.name, 'key']}>
                        <Select style={{ width: 200 }}>
                          {exportKeys.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  增加表头字段
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
});
