import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { FieldCmpType } from '@/constant';
import WithCollapse from '../../WithCollapse';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function RadioCheckboxConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const multiExtraConfig =
    fieldInfo.controlType === FieldCmpType.复选框
      ? {
          multiSelectMaxCount: {
            label: '最多可选数',
            defaultValue: fieldInfo?.multiSelectMaxCount || '',
            fieldType: EWidgetType.InputNumber,
            extra: '不填默认不限制',
          },
        }
      : {};
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            ...multiExtraConfig,
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
