import { memo } from 'react';
import { InputNumber } from 'antd';
import pick from 'lodash/pick';

interface InputNumberControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const InputNumberControl = memo<InputNumberControlProps>(({ onChange, value, configInfo }) => {
  return (
    <InputNumber
      {...pick(configInfo, ['disabled', 'placeholder'])}
      style={{ width: '100%' }}
      defaultValue={configInfo.defaultValue}
      placeholder={configInfo.placeholder || '请输入'}
      value={value || configInfo.defaultValue}
      onChange={onChange}
    />
  );
});

export default InputNumberControl;
