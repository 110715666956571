import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonStaticConfig';
import FormCreator from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { debounce } from 'lodash';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

export default function EnumsConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
  ];
  return <WithCollapse collapseDatas={CollapseDatas} />;
}
