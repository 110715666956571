import React, { memo, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Table, Popconfirm, Row, Button } from 'antd';
import _ from 'lodash';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import type { ProFormInstance } from '@ant-design/pro-form';
import { MenuOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import DeleteIconButton from '@/components/DeleteIconButton';
import BindParam, { getDefaultDataSource } from '../common/BindParam';
import { dragComponents } from '@/components/SortableTable/dragable';
import useFormGeneratorStore from '@/store/form-generator-list';
import update from 'immutability-helper';
import Text from '@/components/Column/Text';
import { OptionsFromTypes, SearchFieldControls, EOptionsFromType, FieldCmpType } from '@/constant';
import EnumsEditor from '../common/EnumsEditor';
import DyEnumsEditor from '../common/DyEnumsEditor';
import WithCollapse from '../../WithCollapse';
import { EDefaultTimeOptions, showOptions } from '@/types';
import { isDateBindParam, newTransferOptions, transferDefault } from '../helper';

export interface InfoItem {
  fieldKey: string;
  fieldName: string;
  fieldType: string;
  controlType: string;
  used?: boolean;
}
interface ConfigSearchProps {
  onChange?: (list: any[]) => void;
}

let debounceTimer: any;
const ConfigSearch = memo<ConfigSearchProps>(({ onChange }) => {
  const [expandedRows, setExpandedRows] = useState<readonly React.Key[]>([]);
  const fgStore = useFormGeneratorStore((state) => state);
  const { bindedMethods } = fgStore;
  const { fields } = fgStore.listSearch;
  const formRef = useRef<ProFormInstance<any>>();
  const list = fields;

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!list?.length) return;
      const dragRow = list[dragIndex];
      const newFields = update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      fgStore.uListSearch({
        fields: newFields,
      });
    },
    [list, onChange, fgStore.uListSearch],
  );

  /* const title = (
    <Row justify='end'>
      <TooltipSlot destroyTooltipOnHide={false} placement='bottom' selector={!refresh ? (
        <Space direction="vertical">
          <Select
            style={{ width: 150 }}
            placeholder="请选择"
            onChange={(val) => {
              setSelectOne(val)
            }}
            size="small"
            options={selectList}
          />
          <Row justify='end'>
            <Button disabled={!selectOne} type="link" onClick={() => {
              if (!selectOne) return
              const target = fields.find(o => o.name === selectOne)
              if (!target) return
              target.used = true
              fgStore.uListSearch({
                fields: [...list, target]
              })
              setRefresh(true)
              setTimeout(() => setRefresh(false), 16)
              setSelectOne(null)
            }}>确定</Button>
          </Row>
        </Space>
      ) : null}>
        <Button type="link" size="small">添加搜索条件</Button>
      </TooltipSlot>
    </Row>
  ) */
  const title = (
    <Row justify="end">
      <Button
        type="link"
        size="small"
        onClick={() => {
          /* new Promise((resolve) => {
            store.uCmpListPanel({
              visible: true,
              resolve
            })
          }) */
          fields[fields.length] = {
            name: 'text',
            label: '未命名',
            cmpId: nanoid(),
            fieldTypeChar: 'string',
            placeholder: '请输入',
            controlType: 'text',
          };
          fgStore.uListSearch({
            fields: [...fields],
          });
        }}
      >
        添加
      </Button>
    </Row>
  );

  const columns: any[] = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 50,
      render: () => <MenuOutlined />,
    },
    {
      title: '数据字段',
      dataIndex: 'fieldName',
      render: (text: any, record: any) => <Text value={`${record.label || record.cmpId}`} />,
    },
    {
      fixed: 'right',
      title: '操作',
      width: 50,
      render: (text: any, record: any, index: number) => (
        <>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              fields.splice(index, 1);
              fgStore.uListSearch({
                fields: [...fields],
              });
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    formRef.current?.setFieldsValue({
      labelWidth: fgStore.listSearch?.labelWidth || 150,
      formItemWidth: fgStore?.listSearch?.formItemWidth || 10,
    });
  }, [JSON.stringify(fgStore.listSearch)]);

  const CollapseDatas = useMemo(() => {
    return [
      {
        title: '基础属性',
        key: 'base-config',
        configComponent: (
          <>
            <FormCreator
              submitter={{ render: () => null }}
              labelAlign="right"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 15 }}
              onMounted={(ref) => {
                formRef.current = ref;
              }}
              debug
              layout="horizontal"
              onValuesChange={(values: any, allValsChange) => {
                if (debounceTimer) {
                  clearTimeout(debounceTimer);
                }
                debounceTimer = setTimeout(() => {
                  fgStore.uListSearch({
                    ...fgStore.listSearch,
                    ...allValsChange,
                  });
                }, 500);
              }}
              config={{
                labelWidth: {
                  label: 'Label 宽度',
                  fieldType: EWidgetType.InputNumber,
                  defaultValue: fgStore.listSearch?.labelWidth || 150,
                  extra: '单位px',
                  fieldProps: {
                    min: 10,
                  },
                },
                formItemWidth: {
                  label: '表单项 宽度',
                  fieldType: EWidgetType.InputNumber,
                  extra: '范围：0 - 24； 按比例',
                  defaultValue: fgStore?.listSearch?.formItemWidth || 10,
                  fieldProps: {
                    min: 0,
                    max: 24,
                  },
                },
              }}
            />
          </>
        ),
      },
    ];
  }, [fgStore]);

  return (
    <>
      <WithCollapse collapseDatas={CollapseDatas} />
      <Table<any>
        size="small"
        bordered={false}
        components={expandedRows?.length ? {} : { ...dragComponents() }}
        onRow={(record, index) => ({ index, moveRow } as any)}
        pagination={false}
        style={{ width: 600 }}
        title={() => title}
        rowKey="cmpId"
        showHeader={false}
        columns={columns}
        dataSource={list}
        expandable={{
          onExpandedRowsChange(expandedKeys) {
            setExpandedRows(expandedKeys);
          },
          expandedRowRender(record, index) {
            return (
              <>
                <FormCreator
                  submitter={{ render: () => null }}
                  labelAlign="right"
                  labelCol={{ span: 5 }}
                  debug
                  layout="horizontal"
                  onValuesChange={(values, allValsChange) => {
                    if (debounceTimer) {
                      clearTimeout(debounceTimer);
                    }
                    debounceTimer = setTimeout(() => {
                      list[index] = Object.assign(list[index], allValsChange);
                      fgStore.uListSearch({
                        fields: [...list],
                      });
                    }, 500);
                  }}
                  config={{
                    label: {
                      label: '标题',
                      fieldType: EWidgetType.Input,
                      defaultValue: record.label || '',
                      fieldProps: {
                        maxLength: 50,
                      },
                    },
                    cmpId: {
                      label: '组件ID',
                      fieldType: EWidgetType.Text,
                      defaultValue: record.cmpId,
                    },
                    bindParam: {
                      label: '绑定参数',
                      tooltip: '设置当前组件关联对应方法下的参数',
                      defaultValue: record?.bindParam?.length
                        ? record?.bindParam
                        : getDefaultDataSource({
                            loadMethod: _.pick(bindedMethods?.loadMethod, ['id', 'name']),
                            loadMethodParamType: 'inputParams',
                          }),
                      fieldType: EWidgetType.CustomWidget,
                      custom(props) {
                        if ([FieldCmpType.日期范围选择器].includes(record.controlType)) {
                          return (
                            <>
                              <BindParam
                                {...props}
                                mixBindVarFieldName="startTime"
                                customLabel="开始时间"
                                bindedMethods={bindedMethods}
                              />
                              <BindParam
                                {...props}
                                mixBindVarFieldName="endTime"
                                customLabel="结束时间"
                                bindedMethods={bindedMethods}
                              />
                            </>
                          );
                        } else {
                          return <BindParam {...props} />;
                        }
                      },
                    },
                    controlType: {
                      label: '组件类型',
                      fieldType: EWidgetType.Select,
                      defaultValue: record?.controlType || '',
                      fieldProps: {
                        options: SearchFieldControls,
                        allowClear: false,
                      },
                    },
                    optionsFromType: {
                      label: '枚举值',
                      fieldType: EWidgetType.RadioGroup,
                      whenHidden: (fromData: any) =>
                        !['multi-select', 'select'].includes(fromData.controlType),
                      defaultValue: record?.optionsFromType || EOptionsFromType.静态数据,
                      fieldProps: {
                        options: OptionsFromTypes,
                      },
                    },
                    options: {
                      label: '', // '',
                      fieldType: EWidgetType.CustomWidget,
                      colon: false,
                      defaultValue: record.options || [],
                      whenHidden(formData: any) {
                        return (
                          String(formData.optionsFromType ?? 1) !== EOptionsFromType.静态数据 ||
                          !['multi-select', 'select'].includes(formData.controlType)
                        );
                      },
                      custom: (props: any) => <EnumsEditor {...props} />,
                    },
                    dyOptionsInfo: {
                      label: '',
                      fieldType: EWidgetType.CustomWidget,
                      custom: (props: any) => (
                        <DyEnumsEditor {...props} style={{ marginLeft: 0 }} />
                      ),
                      defaultValue: record?.dyOptionsInfo,
                      whenHidden: (fromData: any) =>
                        String(fromData.optionsFromType) !== EOptionsFromType.动态数据 ||
                        !['multi-select', 'select'].includes(fromData.controlType),
                    },
                    defaultValue: {
                      label: '默认值',
                      defaultValue: record?.defaultValue,
                      fieldType: EWidgetType.Input,
                      whenHidden: (formData: any) =>
                        [FieldCmpType.日期范围选择器, FieldCmpType.日期选择器].includes(
                          formData.controlType,
                        ),
                    },
                    separator: {
                      label: '分割符',
                      tooltip: '例如按半角逗号（ , ）分割或按换行符分割',
                      defaultValue: record?.separator || '',
                      fieldType: EWidgetType.Input,
                      whenHidden: (formData: any) =>
                        ![FieldCmpType.多值文本].includes(formData.controlType),
                    },
                    placeholder: {
                      label: '占位符',
                      fieldType: EWidgetType.Input,
                      defaultValue: record?.placeholder ?? '请输入',
                      whenHidden: (fromData: any) =>
                        String(fromData.controlType) === FieldCmpType.日期范围选择器,
                      fieldProps: {
                        maxLength: 50,
                      },
                    },
                    placeholders: {
                      label: '占位符',
                      fieldType: EWidgetType.Input, // FieldCmpType
                      whenHidden: (fromData: any) =>
                        String(fromData.controlType) !== FieldCmpType.日期范围选择器,
                      defaultValue: '开始时间,结束时间',
                      extra: '逗号分隔，例如： 开始时间,结束时间',
                      fieldProps: {
                        maxLength: 50,
                      },
                    },
                    hasTip: {
                      label: '图标提示',
                      defaultValue: record.hasTip || '',
                      fieldType: EWidgetType.Switch,
                    },
                    tipContent: {
                      label: '提示内容',
                      defaultValue: record.tipContent || '',
                      whenHidden: (formData: any) => !formData.hasTip,
                      fieldType: EWidgetType.Input,
                      fieldProps: {
                        maxLength: 2000,
                      },
                    },
                    showFormat: {
                      label: '展示格式',
                      defaultValue: record.showFormat || 'YYYY-MM-DD',
                      fieldType: EWidgetType.Select,
                      fieldProps: {
                        options: showOptions,
                      },
                      whenHidden: (formData: any) =>
                        ![FieldCmpType.日期范围选择器, FieldCmpType.日期选择器].includes(
                          formData.controlType,
                        ),
                    },
                    transferFormat: {
                      label: '传值格式',
                      tooltip: '数据类型为Date时，请选择时间戳，其余均不生效',
                      defaultValue: record.transferFormat || transferDefault(record),
                      fieldType: EWidgetType.Select,
                      fieldProps: {
                        value: isDateBindParam(record)
                          ? 'x'
                          : record.transferFormat || 'YYYY-MM-DD',
                        options: newTransferOptions(record),
                      },
                      whenHidden: (formData: any) =>
                        ![FieldCmpType.日期范围选择器, FieldCmpType.日期选择器].includes(
                          formData.controlType,
                        ),
                    },
                    transferFormatCustom: {
                      label: '自定义',
                      defaultValue: record.transferFormatCustom || '',
                      whenHidden: (formData: any) => formData.transferFormat !== 'custom',
                      fieldType: EWidgetType.Input,
                    },
                    defaultTime: {
                      label: '默认时间',
                      defaultValue: record.defaultTime ?? EDefaultTimeOptions.nearlyXDays,
                      fieldType: EWidgetType.Select,
                      fieldProps: {
                        options: [
                          {
                            label: '无',
                            value: EDefaultTimeOptions.noTime,
                          },
                          {
                            label: '近X自然天',
                            value: EDefaultTimeOptions.nearlyXDays,
                          },
                        ],
                      },
                      whenHidden: (formData: any) =>
                        ![FieldCmpType.日期范围选择器].includes(formData.controlType),
                    },
                    defaultDays: {
                      label: '天数',
                      defaultValue: record?.defaultDays,
                      fieldType: EWidgetType.InputNumber,
                      whenHidden(formData) {
                        return !(
                          [FieldCmpType.日期范围选择器].includes(formData.controlType) &&
                          record.defaultTime === EDefaultTimeOptions.nearlyXDays
                        );
                      },
                    },
                  }}
                />
              </>
            );
          },
        }}
      />
    </>
  );
});

export default ConfigSearch;
