import { memo, useEffect } from 'react';
import useListStore from '@/store/form-generator-list';
import { formatListPageInfo, LeftFixedName } from '../help';
import { nanoid } from 'nanoid';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import Header from './Header';
import Search from './Search';
import Table from './Table';
import _ from 'lodash';
import Pagination from './Pagination';

import './index.less';

export const Name = 'list_box';

export const DEFAULT_CONFIIG = {
  usedTitle: true,
  usedSearch: true,
  usedPagination: true,
};

/* 列表中的组件  */
const ListBox = memo(() => {
  const listStore = useListStore((state) => state);
  const {
    uReady,
    uTitle,
    uListSearch,
    uListTable,
    uListPagination,
    listPageServiceConfig,
    bindedMethods,
    listConfig,
    uListConfig,
  } = listStore;
  const { bindEvents, isFocus } = useEvents(Name);

  useEffect(() => {
    const initWork = async () => {
      const config = await formatListPageInfo({
        ...listPageServiceConfig,
      });
      uListConfig(config.listConfig);
      uTitle(config?.title || {});
      uListPagination(config?.listPagination || {});

      // 默认搜索配置初始化
      if (!config?.listSearch?.fields?.length && bindedMethods?.loadMethod?.inputParams?.length) {
        config.listSearch.fields = bindedMethods?.loadMethod?.inputParams.map((o: any) => ({
          // 第一次初始化是，默认绑定对应字段
          bindParam: [
            {
              bindMethod: _.pick(bindedMethods?.loadMethod, ['id', 'name']),
              bindVar: {
                fieldName: o.name,
                fieldId: o.id,
              },
              methodParamType: 'inputParams',
              type: 'loadMethod',
            },
          ],
          name: o.name,
          label: o.title || o.name,
          cmpId: nanoid(),
          fieldTypeChar: 'string',
          used: true,
          placeholder: '请输入',
          controlType: 'text',
        }));
      }
      uListSearch(config?.listSearch || {});

      uListTable(config?.listTable || {});

      uReady(true);
    };
    // 转换数据
    initWork();
  }, [listPageServiceConfig, bindedMethods?.loadMethod]);

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      <div {...bindEvents('common-area fg-list-box')}>
        {isFocus && <LeftFixedName name="列表页面" />}
        {/* 头部标题 */}
        {listConfig?.usedTitle && <Header />}
        {/* 搜素组件 */}
        {listConfig?.usedSearch && <Search />}
        {/* 表格组件 */}
        <Table />
        {/* 分页 */}
        {listConfig?.usedPagination && <Pagination />}
      </div>
    </div>
  );
});

export default ListBox;
