import create from 'zustand';
import deepmerge from 'deepmerge';
import type { AddEditDetailCommonStoreType } from './types';

type StoreType = Omit<
  AddEditDetailCommonStoreType,
  'cancelButtonConfig' | 'uCancelButtonConfig' | 'submitButtonConfig' | 'uSubmitButtonConfig'
>;

const Store = create<StoreType>((set) => ({
  hasChange: false, // 标记是否修改过
  uHasChange: (hasChange: boolean) =>
    set(() => ({
      hasChange,
    })),

  ready: false,
  uReady: (bool) => set(() => ({ ready: bool })),

  title: '详情页',
  uTitle: (title) => set((state) => ({ hasChange: !!state.ready, title })),

  bindedMethods: {},
  uBindedMethods: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      bindedMethods: deepmerge(state.bindedMethods, val),
    })),

  addMethod: null,
  uAddMethod: (addMethod: any) => set((state) => ({ hasChange: !!state.ready, addMethod })),

  labelColWidth: 200,
  uLabelColWidth: (labelColWidth) => set((state) => ({ hasChange: !!state.ready, labelColWidth })),

  wrapperColWidth: undefined,
  uWrapperColWidth: (wrapperColWidth) =>
    set((state) => ({ hasChange: !!state.ready, wrapperColWidth })),

  fields: [],
  uFields: (fields) => set((state) => ({ hasChange: !!state.ready, fields })),

  serviceConfig: null,
  uServiceConfig: (serviceConfig: any) =>
    set((state) => ({ hasChange: !!state.ready, serviceConfig })),

  methodsValidationConfig: {},
  uMethodsValidationConfig: (methodsValidationConfig) =>
    set((state) => ({ hasChange: !!state.ready, methodsValidationConfig })),

  reset: () =>
    set(() => ({
      hasChange: false,
      fields: [],
      bindedMethods: {},
      // methodsValidationConfig: {},
      serviceConfig: null,
      wrapperColWidth: undefined,
      labelColWidth: 200,
      title: '新建表单',
      ready: false,
    })),
}));

export default Store;
