import React, { memo, useRef, useEffect, useState } from 'react';
import type { ProFormInstance } from '@ant-design/pro-form';
// import PickDataModel from '@/components/PickDataModel';
import usePageInfoById from '@/hooks/usePageInfoById';
import Proform, {
  StepsForm,
  ProFormText,
  ProFormSelect,
  ProFormTextArea,
} from '@ant-design/pro-form';
import omit from 'lodash/omit';
import { updatePageGroup, createPageGroup } from '@/services/pageGroup';
import { PageGroupTypes, EPageModelType, EPageType } from '@/constant';
import { Form, Spin, Tabs, Typography, message } from 'antd';
import { createListPageConfig, createFormPageConfig } from '@/services/page';
import PageCmps, { DefaultPages } from './PageCmps';

interface EditorProps {
  id: string;
  onSubmitOk?: (id?: string) => void;
  onCancel?: () => void;
}
let filled1 = false;
const Editor = memo<EditorProps>(({ id, onSubmitOk }) => {
  const [submiting, setSubmiting] = useState(false);
  // const [refresh, setRefresh] = useState(false);
  const formRef = useRef<ProFormInstance>();
  const formRef1 = useRef<ProFormInstance>();
  const formRef2 = useRef<ProFormInstance>();
  const [actTabKey, setActTabKey] = useState('baseInfo');
  const { load: loadDetail, data: detail, loading } = usePageInfoById();
  useEffect(() => {
    if (id) {
      loadDetail(id);
    }
  }, [id, loadDetail]);

  // useEffect(() => {
  //   formRef2.current?.setFieldsValue({
  //     cmps:
  //   })
  // }, [])

  useEffect(() => {
    if (detail) {
      if (actTabKey === 'baseInfo' && !filled1) {
        formRef1.current?.setFieldsValue({
          name: '名称',
          type: '1',
          styleType: '1',
          description: '',
        });
        filled1 = true;
      }
    }
  }, [detail, actTabKey]);

  const submitBaseInfo = async (info: Record<string, any>) => {
    try {
      const api = id
        ? updatePageGroup.bind(null, {
            ...info,
            id,
          })
        : createPageGroup.bind(null, info);
      const res = await api();
      if (res.rCode === 0) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const baseInfoFields = (
    <>
      <ProFormText
        name="name"
        label="页面组名称"
        required
        rules={[{ required: true, message: '必填' }]}
      />
      <ProFormSelect
        name="type"
        label="页面组类型"
        required
        rules={[{ required: true, message: '必选' }]}
        options={PageGroupTypes}
      />
      <ProFormSelect
        name="styleType"
        label="页面组样式"
        required
        rules={[{ required: true, message: '必选' }]}
        options={[
          { label: '传统列表模版', value: '1' },
          { label: '卡片列表模版', value: '2', disabled: true },
        ]}
      />
      <ProFormTextArea name="description" label="页面描述" />
    </>
  );

  const pageCmppFields = (
    <>
      <Typography.Text>
        请选择不同类型页面对应的方法，选中后系统自动将使用方法字段，进行页面组件的初始化。
        <br />
        初始化的组件默认为文本框，可自行修改为其他类型的组件。
      </Typography.Text>
      <Form.Item name="cmps" label="" style={{ margin: '10px 0' }}>
        <PageCmps pageGroupId={id} />
      </Form.Item>
    </>
  );

  const AddContent = (
    <StepsForm
      formRef={formRef}
      onFinish={async (vals) => {
        const info = omit(vals, ['cmps']);
        setSubmiting(true);
        const submitRes = await submitBaseInfo(info);
        if (submitRes) {
          message.success('提交成功');
        } else {
          message.error('提交失败');
          setSubmiting(false);
          return;
        }
        if (!id && !vals?.cmps?.length) {
          vals.cmps = DefaultPages;
        }
        const promises: Array<Promise<any>> | undefined = vals?.cmps?.map(async (item: any) => {
          const pageName = ['', '列表页面', '新建页面', '编辑页面', '查看页面'][
            Number(item.pageTypeValue)
          ];
          if (item.pageTypeValue === EPageType.列表页面) {
            return await createListPageConfig({
              pageGroupId: submitRes?.id,
              loadMethodId: item?.loadMethod?.id,
              name: pageName || '',
              description: '',
              properties: '',
              orderNum: 1,
              titleProperties: '',
              paginationProperties: '',
              contentProperties: '',
              searchParams: [],
              toolbarButtons: [],
              operationButtons: [],
              contentColumns: [],
              methodReferences: [],
            });
          } else if (
            [EPageType.新增页面, EPageType.编辑页面, EPageType.查看页面].includes(
              item.pageTypeValue,
            )
          ) {
            return await createFormPageConfig(
              {
                name: pageName || '',
                pageGroupId: submitRes?.id,
                loadMethodId: item?.loadMethod?.id,
                submitMethodId: item?.submitMethod?.id,
                type: item.pageTypeValue,
                description: '',
                properties: '',
                orderNum: 1,
                components: [],
                methodReferences: [],
              },
              item.pageTypeValue,
            );
          }
        });
        if (promises?.length) {
          Promise.all(promises)
            .then(() => {
              setTimeout(() => {
                setSubmiting(false);
                onSubmitOk?.(submitRes?.id);
              }, 20);
            })
            .catch(() => message.error('创建失败'));
        } else {
          onSubmitOk?.();
        }
      }}
    >
      <StepsForm.StepForm
        name="baseInfo"
        title="基本信息"
        key="baseInfo"
        layout="horizontal"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          type: EPageModelType.标准模版页面组,
          styleType: '1',
        }}
      >
        {baseInfoFields}
      </StepsForm.StepForm>

      <StepsForm.StepForm
        name="pageComponent"
        key="pageComponent"
        title="配置方法"
        // initialValues={{
        //   cmps: DefaultPages.slice()
        // }}
        labelCol={{ span: 3 }}
        layout="horizontal"
      >
        {pageCmppFields}
      </StepsForm.StepForm>
    </StepsForm>
  );

  const EditContent = (
    <Tabs
      defaultActiveKey={actTabKey}
      onChange={(actKey) => {
        setActTabKey(actKey);
      }}
    >
      {[
        {
          key: 'baseInfo',
          tab: '基本信息',
          formRef: formRef1,
          fields: baseInfoFields,
        },
        {
          key: 'pageComponent',
          tab: '页面组件',
          formRef: formRef2,
          fields: pageCmppFields,
        },
      ].map((item) => {
        return (
          <Tabs.TabPane key={item.key} tab={item.tab}>
            <Proform
              labelCol={{ span: 5 }}
              layout="horizontal"
              wrapperCol={{ span: 20 }}
              formRef={item.formRef}
            >
              {item.fields}
            </Proform>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );

  return (
    <Spin spinning={id ? loading || submiting : submiting || false} tip="加载中...">
      <div style={{ marginTop: 20 }}>{id ? EditContent : AddContent}</div>
    </Spin>
  );
});

export default Editor;
