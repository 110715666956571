import omit from 'lodash/omit';

const ExtraProps: string[] = ['$path', 'cx', 'id', 'propKey', 'type', 'serviceStore'];
export function omitExtraProps(props: any, customProps?: string[]) {
  return omit(props, [...ExtraProps, ...(customProps || [])]);
}

export function value2Label(list: Array<{ label: string; value: any }>, value: any) {
  const match = list.find((o) => String(o.value) === String(value));
  return match ? match.label : null;
}
