import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

import TransformKeys from '../common/TransformKeys';
import { Table } from 'antd';
import Text from '@/components/Column/Text';
import TableUploadConfigItem from './TableUploadConfigItem';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function TableUploadConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            transformKeys: {
              label: '列表字段',
              tooltip: '配置表格需要上传的字段',
              defaultValue: fieldInfo?.transformKeys,
              fieldType: EWidgetType.CustomWidget,
              custom(props: any) {
                return (
                  <TransformKeys
                    {...props}
                    buttonLabel="增加列"
                    keepOneMessage="必须存在1个或以上列字段"
                    bindedMethods={store?.bindedMethods}
                    execlData={fieldInfo.value}
                    bindParam={fieldInfo.bindParam}
                  />
                );
              },
            },
            tableConfig: {
              label: '列表配置',
              tooltip: '配置表格单元格',
              defaultValue: fieldInfo?.tableConfig,
              fieldType: EWidgetType.CustomWidget,
              custom(props: any) {
                const { formData, value } = props;
                const dataSource: any[] = [];
                if (formData?.transformKeys) {
                  for (const key of Object.keys(formData.transformKeys)) {
                    dataSource.push({
                      key,
                      title: `${formData.transformKeys[key].excelKey}(${key})`,
                      dataIndex: key,
                    });
                  }
                }
                return (
                  <Table
                    size="small"
                    columns={[
                      {
                        title: '列表项配置',
                        dataIndex: 'title',
                        render: (text: any, record: any) => <Text value={`${record.title} `} />,
                      },
                    ]}
                    dataSource={dataSource}
                    expandable={{
                      expandedRowRender(record, index) {
                        const { dataIndex } = record;
                        return (
                          <TableUploadConfigItem
                            key={index}
                            // currentId={fieldInfo.controlId}
                            value={value === undefined ? {} : value[dataIndex] ?? {}}
                            transformKeysItem={formData?.transformKeys[dataIndex]}
                            onChange={(values) => {
                              props?.onChange?.({
                                ...value,
                                [dataIndex]: {
                                  ...values,
                                },
                              });
                            }}
                          />
                        );
                      },
                    }}
                  />
                );
              },
            },
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
