import { memo, useEffect, useState, useCallback, ReactNode } from 'react';
import usePageGroupInfo from '@/hooks/usePageGroupInfo';
import useStore, { EModeType } from '@/store/form-generator-parse';
import useGroupPageTypeList from '@/hooks/useGroupPageTypeList';
import { EPageStatus, EPageType } from '@/constant';
import { Typography, Spin, Empty } from 'antd';
// import { log } from '@/utils/utils';
import _ from 'lodash';
import List from './List';
import Editor from './Editor';
import useCurPageStore from './useCurPageStore';

interface PageParserProps {
  pageModelId: string;
  // 模式： 正式 ｜ 预览
  mode?: EModeType;
}

let pageGroupId: any;
const PageParser = memo<PageParserProps>(({ pageModelId, mode = EModeType.preview }) => {
  const { uPageList, uLoading, uCurrentPageGroupInfo, uMode, reset } = useStore((state) => state);
  // const store = useStore((state) => state);
  // 页面组 ID
  const [pageModeId, setPageModeId] = useState('');
  const [page, setPage] = useState<any | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { load: loadPageGroup, errMsg, loading: loadingPageGroup } = usePageGroupInfo(false);
  // const { load: loadListPageInfo } = useListPageInfo()
  const { load: loadPageList } = useGroupPageTypeList(true);
  const { curPageStore, store: pageStore } = useCurPageStore(pageModeId);
  const { uEditorVisible, uCurrentDrawerServiceTarget } = pageStore;

  // log('store', store);

  useEffect(() => {
    if (pageModelId) {
      uMode(pageModelId, mode);
    }
  }, [pageModelId, mode]);

  useEffect(() => {
    return () => {
      if (pageModeId) {
        reset(pageModeId);
      }
    };
  }, [pageModeId]);

  const initWork = useCallback(async () => {
    try {
      // 清理异常信息
      setErrors([]);
      // 1. 查询页面组信息
      const pageGroupInfo = await loadPageGroup(pageModelId);
      if (!pageGroupInfo?.id) return;
      const pageList = await loadPageList(pageGroupInfo.id);
      setPageModeId(pageGroupInfo.id);
      pageGroupId = pageGroupInfo.id;
      uCurrentPageGroupInfo(pageGroupInfo.id, pageGroupInfo);
      uLoading(pageGroupInfo.id, true);
      uPageList(pageGroupInfo.id, pageList || []);

      if (String(pageGroupInfo?.status) === EPageStatus.禁用) {
        setErrors([...errors, '该页面已被禁用']);
      }

      // 找到 列表页面
      const indexPage =
        pageList.find((o: any) => o.firstPage) ||
        pageList.find((o: any) => String(o.type) === EPageType.列表页面);
      if (!indexPage) {
        setErrors([...errors, '未配置首页']);
        return;
      }
      setPage(indexPage);
    } catch (error) {
      console.log(error);
    } finally {
      if (pageGroupId) {
        uLoading(pageGroupId, false);
      }
    }
  }, [pageModelId, errors, mode]);

  useEffect(() => {
    if (page) {
      const type = String(page.type);
      if (
        type === EPageType.新增页面 ||
        type === EPageType.查看页面 ||
        type === EPageType.编辑页面
      ) {
        uCurrentDrawerServiceTarget(pageModeId, page.id);
        uEditorVisible(pageModeId, true);
      }
    }
  }, [page]);

  // 加载页面组信息
  useEffect(() => {
    if (pageModelId) {
      initWork();
    }
  }, [pageModelId]);

  if (errors?.length) {
    return (
      <Empty
        description={
          <>
            {_.uniq(errors).map((error: string) => (
              <div key={error}>
                <Typography.Text type="danger">{error}</Typography.Text>
                <br />
              </div>
            ))}
          </>
        }
      />
    );
  }
  if (errMsg) {
    return (
      <Empty
        description={
          <>
            <Typography.Text type="danger">{errMsg}</Typography.Text>
          </>
        }
      />
    );
  }

  const { id: pageId, type } = page || {};
  let content: ReactNode | null = null;
  if (curPageStore && pageId) {
    if (String(type) === EPageType.列表页面) {
      content = (
        <>
          <List pageModeId={pageModeId} pageId={pageId} />
          {curPageStore.editorVisible && <Editor pageModeId={pageModeId} />}
        </>
      );
    }
    if (
      String(type) === EPageType.新增页面 ||
      String(type) === EPageType.编辑页面 ||
      String(type) === EPageType.查看页面
    ) {
      content = <Editor pageModeId={pageModeId} withDrawer={false} />;
    }
  }
  return <Spin spinning={!!curPageStore?.loading || loadingPageGroup}>{content}</Spin>;
});

export default PageParser;
