import { useCallback, useMemo, FC } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';

interface SingleDatePickerControlProps {
  onChange?: (data: any) => void;
  value?: any;
  fieldProps?: any;
  showFormat?: string;
  transferFormat?: string;
  transferFormatCustom?: string;
  defaultFormat?: string;
}
// 新建一个元组件，待日后替换现有组件
// Create a new meta-component to replace an existing one later
const SingleDatePicker: FC<SingleDatePickerControlProps> = ({
  onChange,
  value,
  fieldProps,
  showFormat,
  transferFormat,
  transferFormatCustom,
  defaultFormat = 'YYYY-MM-DD',
}) => {
  const newValue = useMemo(() => {
    if (transferFormat === 'custom') return moment(value, transferFormatCustom || defaultFormat);
    return moment(
      transferFormat === 'x' || transferFormat === 'X' ? Number(value) : value,
      transferFormat || defaultFormat,
    );
  }, [defaultFormat, transferFormat, transferFormatCustom, value]);

  const showTime = useMemo(() => {
    if (showFormat === 'YYYY-MM-DD HH:mm:ss') {
      return { format: 'HH:mm:ss' };
    } else {
      return false;
    }
  }, [showFormat]);

  const dateOnChange = useCallback(
    (data: Moment) => {
      const newData = data.format(transferFormat || defaultFormat);
      onChange?.(newData);
    },
    [defaultFormat, onChange, transferFormat],
  );
  return (
    <DatePicker
      format={showFormat}
      showTime={showTime}
      {...fieldProps}
      value={newValue}
      onChange={dateOnChange}
    />
  );
};

export default SingleDatePicker;
