import useStore from '@/store/form-generator-parse';
import type { ParseItemType } from '@/store/form-generator-parse';

// 当前页面组 store
export default function useCurPageStore(pageModeId: string) {
  const store = useStore();
  const curPageStore = pageModeId ? store.pages[pageModeId] : ({} as ParseItemType);
  return {
    curPageStore,
    store,
  };
}
