import { memo, useEffect } from 'react';
import { Spin } from 'antd';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import PickMethod from '@/components/PickMethod';
import PickMethodVar from '@/components/PickMethodVar';
import useMethodDetail from '@/hooks/useMethodDetail';
// import { findTree } from '@/utils/utils';

interface ValueType {
  method?: any;
  listField?: string;
  labelField?: string;
  valueField?: string;
}

interface DyEnumsEditorProps {
  style?: React.CSSProperties;
  formData?: any;
  value?: ValueType;
  onChange?: (data: ValueType) => void;
}
let debounceTime: any;
// 动态枚举 - 方法选择 - 参数映射
const DyEnumsEditor = memo<DyEnumsEditorProps>(({ value, onChange, style }) => {
  const {
    load: loadMethodDetail,
    loading: loadingMethodDetail,
    data: methodDetail,
  } = useMethodDetail();
  // const [optionContext, setOptionContext] = useState<Record<string, any>>({});
  useEffect(() => {
    if (value?.method?.id) {
      loadMethodDetail(value?.method?.id);
    }
  }, [value, methodDetail]);

  return (
    <div style={{ marginLeft: 100, width: '100%', ...(style || {}) }}>
      <Spin spinning={loadingMethodDetail}>
        <FormCreator
          submitter={{ render: () => null }}
          layout="horizontal"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 20 }}
          initialValues={value}
          onValuesChange={(changedValues: any, allValues: any) => {
            if (debounceTime) {
              clearTimeout(debounceTime);
            }
            debounceTime = setTimeout(() => {
              onChange?.(allValues);
            }, 500);
          }}
          config={{
            method: {
              label: '绑定方法',
              tooltip: '动态枚举加载的方法',
              fieldType: EWidgetType.CustomWidget,
              defaultValue: value?.method ? value.method : undefined,
              custom(props: any) {
                return (
                  <PickMethod
                    {...props}
                    value={props?.value ? [props?.value] : undefined}
                    getLabelByValue={(methods) => {
                      return methods?.length ? methods[0].name : '请选择';
                    }}
                    checkedList={[props?.value].filter((o) => Boolean(o))}
                    onChange={(methods) => {
                      const method = methods?.length ? methods[0] : null;
                      if (method) {
                        props?.onChange?.({
                          id: method.id,
                          name: method.name,
                        });
                      }
                    }}
                  />
                );
              },
            },
            listField: {
              label: 'Options 参数',
              whenHidden: (formData: any) => !formData.method,
              tooltip: '绑定枚举最外层参数变量，例如：options',
              fieldType: EWidgetType.CustomWidget,
              defaultValue: value?.listField ? value?.listField : undefined,
              custom: (props: any) => (
                <PickMethodVar
                  {...props}
                  contextField="outputParams"
                  methodId={methodDetail?.id}
                  targetFieldType="enumObj"
                  onSelected={(val) => {
                    props?.onChange?.(val);
                    // if (val) {
                    //   const findU = findTree(
                    //     methodDetail?.outputParams as any[],
                    //     (item) => {
                    //       return item.name === val.filedName;
                    //     },
                    //     'fields',
                    //   );
                    //   if (findU) {
                    //     setOptionContext(findU.fields || []);
                    //   }
                    // }
                  }}
                />
              ),
            },
            labelField: {
              label: '选项名Label',
              whenHidden: (formData: any) => !formData.method || !formData.listField,
              tooltip: '为选项名绑定方法参数',
              fieldType: EWidgetType.CustomWidget,
              defaultValue: value?.labelField ? value?.labelField : undefined,
              custom: (props: any) => (
                <PickMethodVar
                  {...props}
                  // value={props.value ? `${props.value}` : undefined}
                  contextField="outputParams"
                  methodId={methodDetail?.id}
                  targetFieldType="text"
                  onSelected={(val) => {
                    props?.onChange?.(val);
                  }}
                />
              ),
            },
            valueField: {
              label: '选项值Value',
              tooltip: '为选项值绑定方法参数',
              defaultValue: value?.valueField ? value?.valueField : undefined,
              whenHidden: (formData: any) => !formData.method || !formData.listField,
              fieldType: EWidgetType.CustomWidget,
              custom: (props: any) => (
                <PickMethodVar
                  {...props}
                  // value={props.value ? `${props.value}` : undefined}
                  contextField="outputParams"
                  methodId={methodDetail?.id}
                  targetFieldType="text"
                  onSelected={(val) => {
                    props?.onChange?.(val);
                  }}
                />
              ),
            },
          }}
        />
      </Spin>
    </div>
  );
});

export default DyEnumsEditor;
