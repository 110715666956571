import { memo } from 'react';
import { DatePicker } from 'antd';
import pick from 'lodash/pick';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

interface SingleDatePickerControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const SingleDatePicker = memo<SingleDatePickerControlProps>(({ onChange, value, configInfo }) => {
  const otherProps: Record<string, any> = {};
  if (typeof configInfo.defaultValue !== 'undefined') {
    otherProps.defaultValue = moment(configInfo.defaultValue);
  } else {
    otherProps.defaultValue = undefined;
  }
  if (configInfo.showFormat === 'YYYY-MM-DD HH:mm:ss') {
    otherProps.showTime = { format: 'HH:mm:ss' };
  }
  return (
    <DatePicker
      {...pick(configInfo, ['disabled', 'placeholder'])}
      format={configInfo.showFormat || dateFormat}
      {...otherProps}
      value={value}
      onChange={onChange}
    />
  );
});

export default SingleDatePicker;
