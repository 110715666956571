import { memo, useRef, useState, useCallback, useEffect } from 'react';
import { Row, Space, Button, Spin, message } from 'antd';
import { FooterToolbar } from '@ant-design/pro-layout';
import type { FormCreatorHandle } from '@/components/FormCreator';
import FormCreator, { FCRules, EWidgetType } from '@/components/FormCreator';
import type { ProFormInstance } from '@ant-design/pro-form';
import MultiConfig from './components/MultiConfig';
import { updateExternalPage, addExternalPage, getExternalPageDetail } from '@/services/pageGroup';

import _ from 'lodash';
import { FormCreatorConfig } from '@/components/FormCreator/type';

export type Mode = 'view' | 'edit' | 'add' | 'copy';

interface EditorProps {
  onCancel?: () => void;
  onComplete?: () => void;
  mode: Mode;
  id: string;
}

function sleep(seconds = 400) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, seconds);
  });
}

export default memo<EditorProps>(({ id, mode, onComplete, onCancel }) => {
  const fcHelpRef = useRef<FormCreatorHandle | null>(null);
  const [submiting, setSubmiting] = useState(false);
  const formRef = useRef<ProFormInstance<Record<string, any>>>();
  const inputElement = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getConfig = useCallback<() => FormCreatorConfig>(() => {
    const idInfo: any = {};
    if (mode === 'edit') {
      idInfo.id = {
        label: '页面ID',
        fieldType: EWidgetType.Text,
        rules: [],
        defaultValue: id,
      };
    }
    const result: any = {
      ...idInfo,
      name: {
        label: '页面名称',
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required(), FCRules.Count(20)],
      },
      url: {
        label: '页面URL',
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required(), FCRules.Count(1000)],
      },
      loadEvents: {
        label: '加载事件',
        fieldType: EWidgetType.CustomWidget,
        custom({ onChange, value }: any) {
          return (
            <MultiConfig value={value} onChange={onChange} title="加载配置" name="loadEvents" />
          );
        },
        fieldProps: {},
      },
      messageEvents: {
        label: '动作事件',
        fieldType: EWidgetType.CustomWidget,
        custom({ onChange, value }: any) {
          return (
            <MultiConfig value={value} onChange={onChange} title="动作配置" name="messageEvents" />
          );
        },
        fieldProps: {},
      },
    };

    return result;
  }, [mode, id]);

  const handleSubmit = useCallback(
    async (vals: any) => {
      try {
        const datas = _.clone(vals);
        setSubmiting(true);

        if (mode === 'edit') {
          const res = await updateExternalPage({ ...datas, id }).catch(() => {
            message.error('保存失败');
          });

          if (res?.rCode === 0) {
            message.success('保存成功');
            onComplete?.();
          } else {
            message.error(res?.msg ?? '保存失败');
          }
        } else if (mode === 'add') {
          const res = await addExternalPage(datas).catch(() => {
            message.error('保存失败');
          });

          if (res?.rCode === 0) {
            message.success('保存成功');
            onComplete?.();
          } else {
            message.error(res?.msg ?? '保存失败');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmiting(false);
      }
    },
    [onComplete, id, mode],
  );

  const submitter = {
    render: () => (
      <FooterToolbar style={{ width: '100%' }}>
        <Row justify="center" align="middle" className="submit-view">
          <Space>
            <Button onClick={() => onCancel?.()}>取消</Button>
            <Button
              type="primary"
              loading={submiting}
              onClick={async () => {
                // 对表单input焦点进行统一失焦处理
                inputElement.current?.focus();
                await sleep(200); // 等待*毫秒后，form value已更新，再进行 getFieldsValue操作

                formRef.current
                  ?.validateFields()
                  .then(async (vals) => {
                    handleSubmit(vals);
                  })
                  .catch(({ errorFields }) => {
                    console.log('errorFields', errorFields);
                  });
              }}
            >
              保存
            </Button>
          </Space>
        </Row>
      </FooterToolbar>
    ),
  };

  useEffect(() => {
    const getData = async () => {
      if (mode === 'edit') {
        const result = await getExternalPageDetail({
          id,
        })
          .catch(() => {
            message.error('请求外部页面详情失败！');
          })
          .finally(() => {
            setLoading(false);
          });

        if (result?.rCode === 0 && typeof result.data !== 'undefined') {
          const { name, url, loadEvents, messageEvents } = result.data;
          const newFormData = {
            id,
            name,
            url,
            loadEvents,
            messageEvents,
          };
          formRef.current?.setFieldsValue(newFormData);
        }
      } else {
        setLoading(false);
      }
    };

    getData();
  }, [id, mode]);

  const config = getConfig();

  return (
    <Spin spinning={loading}>
      <input type="text" ref={inputElement} style={{ visibility: 'hidden', position: 'fixed' }} />

      <FormCreator
        debug
        onMounted={(form) => {
          formRef.current = form;
        }}
        ref={fcHelpRef}
        submitter={mode === 'view' ? false : submitter}
        labelCol={{ span: 3 }}
        layout="horizontal"
        config={config}
        readonly={mode === 'view'}
      />
    </Spin>
  );
});
