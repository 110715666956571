import { memo } from 'react';
import { Image } from 'antd';
import { EModeType } from '@/store/form-generator-parse';
import { isString } from 'lodash';

interface ImageViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const ImageView = memo<ImageViewProps>(({ configInfo }) => {
  let urls = configInfo.finalValue;
  if (Array.isArray(urls)) {
    urls.map((item: string) => {
      if (
        item.indexOf('http://') === -1 &&
        item.indexOf('https://') === -1 &&
        configInfo?.preFileLink
      ) {
        return (configInfo.preFileLink as string) + item;
      }
      return item;
    });
  } else if (
    isString(urls) &&
    urls.indexOf('http://') === -1 &&
    urls.indexOf('https://') === -1 &&
    configInfo?.preFileLink
  ) {
    urls = (configInfo.preFileLink as string) + urls;
  }

  return <>{configInfo.mode === EModeType.production ? <Image width={100} src={urls} /> : '-'}</>;
});

export default ImageView;
