import { useState, useCallback } from 'react';

export default function useSetState<T extends Record<string, any>>(
  initialState: T = {} as T,
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] {
  const [state, set] = useState<T>(initialState);
  const setState = useCallback(
    (patch) => {
      set((prevState) =>
        Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch),
      );
    },
    [set],
  );
  return [state, setState];
}
