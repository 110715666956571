import { unstable_batchedUpdates } from 'react-dom';
import { EWidgetType } from '@/components/FormCreator';
import { EDefaultTimeOptions, ExecuteMethodParam, MethodParam, TMethodsParams } from '@/types';
import { message } from 'antd';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import _ from 'lodash';
import useGlobalStore from '@/store/global';
import { FieldCmpType, EPageLayoutCmpType } from '@/constant';
import { EPassValueType } from '../ConfigFieldEditor/types';
import moment from 'moment';

const getValueByPathInGlobalUser = (path: string) => {
  return new Promise<any>((resolve) => {
    unstable_batchedUpdates(() => {
      resolve(_.get(useGlobalStore.getState(), path, ''));
    });
  });
};

/**
 * 遍历方法执行参数的递归结构
 * @param list
 * @param iterator
 */
export const eachExecuteMethodParam = (
  list: ExecuteMethodParam[],
  iterator: (item: any, paths: any[]) => any,
  paths: any[] = [],
) => {
  list?.forEach((item) => {
    iterator(item, paths);
    if (item?.objectValue?.length) {
      eachExecuteMethodParam(item?.objectValue, iterator, [...paths, item]);
    }
    if (item?.listObjectValue?.length) {
      item?.listObjectValue.forEach((sub) => {
        eachExecuteMethodParam(sub, iterator, [...paths, item]);
      });
    }
  });
};

/**
 * 方法执行参数的递归结构中匹配某一项
 * @param list
 * @param iterator
 */
export const findInExecuteMethodParam = (
  list: ExecuteMethodParam[],
  iterator: (item: any, paths: any[]) => any,
) => {
  let result: any = null;
  eachExecuteMethodParam(list, (item, paths) => {
    if (iterator(item, paths)) {
      result = item;
    }
  });
  return result;
};

/**
 * 配置类型转换为 FormCreator 类型
 */
export const formatControlType = (controlType: string) => {
  switch (controlType) {
    case FieldCmpType.输入框:
      return EWidgetType.Input;
    case EPageLayoutCmpType.标题:
      return EWidgetType.Title;
    case FieldCmpType.下拉多选框:
    case FieldCmpType.下拉框:
      return EWidgetType.Select;
    case FieldCmpType.复选框:
      return EWidgetType.CheckboxGroup;
    case FieldCmpType.单选框:
      return EWidgetType.RadioGroup;
    case FieldCmpType.多行文本框:
      return EWidgetType.TextArea;
    case FieldCmpType.富文本:
      return EWidgetType.RichText;
    case FieldCmpType.开关:
      return EWidgetType.Switch;
    case FieldCmpType.数字输入框:
      return EWidgetType.InputNumber;
    case FieldCmpType.弹窗选择:
    case FieldCmpType.单文件上传:
    case FieldCmpType.表格文件上传:
    case FieldCmpType.列表:
    case FieldCmpType.多图上传:
    case FieldCmpType.单图上传:
    case FieldCmpType.视频上传:
      return EWidgetType.CustomWidget;
    case FieldCmpType.日期时间:
      return EWidgetType.DatePicker;
    case FieldCmpType.日期区间:
      return EWidgetType.RangePicker;
    default:
      return null;
  }
};

/**
 * 从绑定参数配置中抽离对应的参数
 * @param bindParam
 * @param methodType
 * @returns
 */
export const getBindVarFromBindParam = (bindParam: any[], methodType: string) => {
  const { bindVar } = bindParam?.find((o: any) => o.type === methodType) || {};
  if (!bindVar) return [];
  let depParam = bindVar?.fieldName || '';
  depParam = depParam.split('.');
  return depParam;
};

/**
 * 判断方法校验规则是否命中
 * @param codeContent
 * @returns boolean
 */
export const isInRule = (codeContent: { validateItem: TMethodsParams; value: number }) => {
  const { validateItem, value } = codeContent;
  const { symbolEq, symbolValue } = validateItem;
  const valueToNumber = Number(value); // 填写值
  const symbolValueToNumber = Number(symbolValue); // 选择字段值

  switch (symbolEq) {
    case '0':
      return valueToNumber === symbolValueToNumber;
    case '1':
      return valueToNumber !== symbolValueToNumber;
    case '2':
      return valueToNumber < symbolValueToNumber;
    case '3':
      return valueToNumber <= symbolValueToNumber;
    case '4':
      return valueToNumber > symbolValueToNumber;
    case '5':
      return valueToNumber >= symbolValueToNumber;
    default:
      break;
  }
};

/**
 * 判断是否通过方法校验
 * @param validateItem 方法配置
 * @param fieldMap 参数映射
 * @returns boolean
 */

export const isValidateSuccess = (validateItem: TMethodsParams, fieldMap: any) => {
  return new Promise((resolve) => {
    const codeValue = fieldMap[validateItem?.result?.fieldId];
    console.log('codeValue', codeValue);
    if (codeValue !== undefined) {
      if (isInRule({ validateItem, value: codeValue })) {
        // 命中规则
        if (validateItem.extractTypes === '1') {
          // 自定义提示文案
          message.error(validateItem.error);
          return resolve(false);
        }
        if (validateItem.extractTypes === '0') {
          const msgId = validateItem?.error?.fieldId;
          const msgValue = fieldMap[msgId];

          /// 字段中查找
          message.error(msgValue);
          return resolve(false);
        }

        return resolve(true);
      }
      return resolve(true);
    }
    return resolve(true);
  });
};

/**
 * 校验方法请求是否成功，即 rCode === 0 ?
 * @param responseData 响应数据
 * @param methodsConfig 方法检验配置
 *
 */
export const validateRequest = async ({
  responseData,
  methodsConfig = [],
  bindFieldId,
}: {
  responseData: {
    paramValues: ExecuteMethodParam[];
  };
  methodsConfig?: TMethodsParams[];
  bindFieldId?: string | undefined;
}) => {
  let isSuccess = false;

  if (!responseData?.paramValues)
    return {
      isSuccess: true,
      dataTarget: responseData,
    };

  if ((!methodsConfig || methodsConfig.length === 0) && !bindFieldId) {
    return {
      isSuccess: true,
      dataTarget: { objectValue: responseData?.paramValues },
    };
  }

  const fieldMap: any = {};
  let dataTargetResponse: any = null;

  // TODO fieldMap 通过 id 映射值，在 listObjectValue 中会出现多个值，这里不支持这种场景
  const traverseParamValues = (paramValues: any[]) => {
    paramValues?.forEach((value) => {
      fieldMap[value.paramId] = value.value;
      if (bindFieldId && bindFieldId === value.paramId) {
        dataTargetResponse = value;
      }

      if (value.objectValue) {
        traverseParamValues(value.objectValue);
      } else if (value.listObjectValue) {
        value.listObjectValue.forEach((item: any[]) => traverseParamValues(item));
      }
    });
  };

  traverseParamValues(responseData.paramValues);

  const validateRes = await Promise.all(
    methodsConfig?.map((validateItem) => {
      return isValidateSuccess(validateItem, fieldMap);
    }),
  );
  // console.log('help validateRes', validateRes);
  // console.log('dataTargetResponse validateRequest', dataTargetResponse);
  isSuccess = validateRes.every((res) => res === true);
  if (typeof bindFieldId === 'undefined') {
    dataTargetResponse = {
      objectValue: responseData.paramValues,
    };
  }
  return {
    isSuccess,
    dataTarget: dataTargetResponse,
  };
};

/**
 * 校验方法请求是否成功，即 rCode === 0 ?
 * @param responseData 响应数据
 * @param outputParams 当前方法的出参
 * @param bindFieldId 指定数据字段id
 * @param needErrorToast 方法请求失败，是否提示
 */
export const isRequestSuccess = ({
  responseData,
  outputParams,
  bindFieldId,
  needErrorToast = true,
}: {
  responseData: {
    paramValues: ExecuteMethodParam[];
  };
  outputParams: MethodParam[];
  bindFieldId?: string | undefined;
  needErrorToast?: boolean;
}) => {
  const FIELD_RCODE = ['rcode', 'code'];
  const FIELD_MESSAGE = ['message', 'msg'];
  let dataTargetResponse: any = null;
  let isSuccess = false;
  let msg = '';
  if (!responseData?.paramValues)
    return {
      isSuccess: false,
      dataTarget: responseData,
    };
  console.log('responseData', responseData);

  const rCodeItem = outputParams.find((o) => FIELD_RCODE.includes(o.name.toLowerCase()));
  const messaageItem = outputParams.find((o) => FIELD_MESSAGE.includes(o.name));

  const findItem = (item: any) => {
    // 匹配到 rCode
    if (rCodeItem?.id === item.paramId) {
      isSuccess = String(item.value) === '0';
      return;
    }
    if (messaageItem?.id === item.paramId) {
      msg = item.value;
      return;
    }

    // data 在没有指定数据字段id的情况下，默认没有命中rcode和message的字段，就是数据字段
    if (typeof bindFieldId !== 'undefined') {
      if (bindFieldId === item.paramId) {
        dataTargetResponse = item;
      } else if (_.isArray(item.objectValue)) {
        // 递归查找 数据字段
        item.objectValue.forEach((innerItem: any) => {
          findItem(innerItem);
        });
      }
    } else {
      dataTargetResponse = item;
    }
  };
  responseData.paramValues?.forEach((item) => {
    console.log('findItem item', item);
    findItem(item);
  });

  // 如果 rCode 不为 0，根据需要进行 toast message
  if (!isSuccess && msg) {
    if (needErrorToast) {
      message.error(msg);
    }
  }
  if (rCodeItem === undefined && messaageItem === undefined) {
    isSuccess = true;
  }
  if (typeof bindFieldId === 'undefined') {
    dataTargetResponse = {
      objectValue: responseData.paramValues,
    };
  }
  return {
    isSuccess,
    dataTarget: dataTargetResponse || null,
  };
};

/**
 * 从响应数据中找到表格list下的数据
 * @param targetResponse 响应数据
 * @param listConfig list 配置
 */
export const findTableListResponseByConfig = (
  targetResponse: ExecuteMethodParam,
  bindParamItem: any,
) => {
  if (!bindParamItem || !targetResponse) return [];

  const { bindVar } = bindParamItem;
  if (!bindVar?.fieldName) return [];
  const finU = findInExecuteMethodParam([targetResponse], (item) => {
    return item.paramId === bindVar.fieldId;
  });
  return finU?.listObjectValue?.length ? finU?.listObjectValue : [];
};

/**
 * @param targetResponse 响应数据
 * @param totalConfig total 配置
 */
interface TField {
  fieldId: string;
  fieldName: string;
}
export const findTotalResByConfig = (targetResponse: ExecuteMethodParam[], totalField: TField) => {
  if (!targetResponse || !totalField?.fieldId) return 0;
  const totalConfig = findInExecuteMethodParam(targetResponse, (item) => {
    return item.paramId === totalField.fieldId;
  });
  return totalConfig ? totalConfig.value : 0;
};

// TODO 临时的处理数据结构嵌套的方式，后边需要统一优化 schema 结构的解析和绑定
// TODO 不支持 list 的绑定
type SpecExecuteMethodParam = ExecuteMethodParam & {
  objectValueIds?: string[];
  // listObjectValueIds?: string[][];
  ref?: boolean;
};

/**
 * 给目标方法构造入参结构
 * @param inputParamMap 通常是配置的 callMethod
 * @param record 真实数据， eg {id: 'xxx'}
 * @param listOutputParamsMap 当前方法的出参映射
 * @returns
 */
export const makeTargetMethodParamValues = async (
  inputParamMap: Record<
    string,
    {
      fieldId: string;
      fieldName: string;
    }
  >,
  record: Record<string, any>,
  listOutputParamsMap: Record<string, MethodParam>,
  methodInParamMap: Record<string, MethodParam>,
) => {
  const results: Record<string, SpecExecuteMethodParam> = {};
  // eslint-disable-next-line guard-for-in
  for (const fieldId in methodInParamMap) {
    const paramConfig = methodInParamMap[fieldId];
    // 删除接口的入参： name_id
    const attr = `${paramConfig.name}_${paramConfig.id}`;
    const item: SpecExecuteMethodParam = {
      paramId: fieldId,
    };
    if (String(paramConfig?.passValueType) === EPassValueType.直接传值) {
      // 值对应 record 的哪个参数
      const recordFieldId = inputParamMap[attr]?.fieldId;
      // 根据参数id，获取该参数的配置
      const recordFieldConfig = listOutputParamsMap?.[recordFieldId];
      // 获取字段名
      const recordFieldName = recordFieldConfig?.name;
      // 填充值
      const value = record[recordFieldName];
      // 约定：long类型，直接转换为 string； 避免精度丢失问题
      item.value = value;
      if (paramConfig?.dataType === EFieldType.Long) {
        if (value) {
          item.value = String(value);
        } else {
          item.value = null;
        }
      }
      if (paramConfig.fields) {
        if (paramConfig.dataType === 'Object') {
          item.objectValueIds = paramConfig.fields.map((n) => n.id);
        }
        // if (paramConfig.dataType === 'List' && paramConfig.genericType === 'Object') {
        // }
      }
    }
    results[item.paramId] = item;
  }
  // 前端传值
  // eslint-disable-next-line guard-for-in
  for (const paramId in methodInParamMap) {
    const config = methodInParamMap[paramId];
    if (String(config.passValueType) === EPassValueType.前端传值) {
      const value = config.passValueConfig
        ? await getValueByPathInGlobalUser(config.passValueConfig)
        : null;
      results[paramId] = {
        paramId,
        value: [EFieldType.Long, EFieldType.String].includes(config.dataType as EFieldType)
          ? String(value)
          : value,
      };
    }
  }

  // eslint-disable-next-line guard-for-in
  for (const paramId in results) {
    const param = results[paramId];
    if (param.objectValueIds) {
      param.objectValue = param.objectValueIds
        .map((n) => {
          const t = results[n];
          if (t) {
            t.ref = true;
          }
          return t;
        })
        .filter((n) => n);
    }
    // if (param.listObjectValueIds) {
    // }
  }

  const params = [];
  // eslint-disable-next-line guard-for-in
  for (const paramId in results) {
    const param = results[paramId];
    if (!param.ref) params.push(param);
  }
  return params;
};

/**
 * 确保方法参数的 value 都有值，并建立 hash 映射
 * @param data 执行方法参数
 * @param paramsHash 对应方法的参数
 * @returns
 */
export function ensureEachExecuteMethodParamValue(
  data: ExecuteMethodParam,
  paramsHash: Record<string, MethodParam>,
) {
  const dataClone = _.cloneDeep(data);
  const hashResult: Record<string, any> = {};

  // 递归收集
  const doRecursion = (info: ExecuteMethodParam) => {
    let collect: any;
    if (info?.value) {
      collect = info.value;
    } else if (info?.objectValue?.length) {
      collect = {};
      info?.objectValue?.forEach((item) => {
        const fieldName = paramsHash[item.paramId]?.name;
        if (item.value) {
          if (fieldName) {
            collect[fieldName] = item.value;
          }
        } else {
          const val = doRecursion(item);
          if (fieldName) {
            collect[fieldName] = val;
          }
        }
      });
      // 针对复杂类型，夸层级收集
      info.value = collect;
    } else if (info?.listObjectValue?.length) {
      collect = info?.listObjectValue.map((item) => {
        const res: Record<string, any> = {};
        if (item?.length) {
          item.forEach((sub) => {
            const fieldName = paramsHash[sub.paramId]?.name;
            if (sub.value) {
              if (fieldName) {
                res[fieldName] = sub.value;
              }
            } else if (fieldName) {
              res[fieldName] = doRecursion(sub);
            }
          });
        }
        return res;
      });
      info.value = collect;
    }

    // 针对复杂类型，夸层级收集
    return collect;
  };
  doRecursion(dataClone);
  eachExecuteMethodParam([dataClone], (item) => {
    hashResult[item?.paramId] = item?.value;
  });
  return hashResult;
}

const formatProcessing = function (value: any) {
  const config = {
    showFormat: 'yyyy-MM-dd',
  };
  if (value.showFormat) {
    config.showFormat = value.showFormat;
  }
  return config;
};

/**
 * 检测value的值，对应controlType是否正确，需要转换与否
 */
export function checkValueByControlType(value: any, controlType: FieldCmpType) {
  const defaultValue = value.defaultValue || '';
  switch (controlType) {
    case FieldCmpType.日期时间: {
      const config = formatProcessing(value);
      if (defaultValue.length < 1) {
        return config;
      }
      return { defaultValue: moment(defaultValue), ...config };
    }
    case FieldCmpType.日期区间: {
      const config = formatProcessing(value);
      const { startTime, endTime, defaultTime, defaultDays } = value;
      if (defaultTime === EDefaultTimeOptions.specifiedTime) {
        let startTimeData = null;
        let endTimeData = null;
        if (typeof startTime !== 'undefined') {
          startTimeData = moment(startTime);
        }
        if (typeof endTime !== 'undefined') {
          endTimeData = moment(endTime);
        }
        return { defaultValue: [startTimeData, endTimeData], ...config };
      } else if (defaultTime === EDefaultTimeOptions.nearlyXDays) {
        return {
          defaultValue: [
            moment()
              .startOf('days')
              .add(-(defaultDays - 1), 'days'),
            moment().endOf('days'),
          ],
          ...config,
        };
      }
      return config;
    }
    default:
      return { defaultValue };
  }
}
