import { memo, useState, useRef, useEffect, useCallback } from 'react';
import { Drawer, Space, Button, Row, Typography, Popover, Spin, message, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useStore from '@/store/form-generator';
import useListStore from '@/store/form-generator-list';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useDetailStore from '@/store/form-generator-detail';
import { promisifyConfirm } from '@/utils/helper';
import { nanoid } from 'nanoid';
import usePageGroupInfo from '@/hooks/usePageGroupInfo';
import useListPageInfo from '@/hooks/useListPageInfo';
import useGroupPageTypeList from '@/hooks/useGroupPageTypeList';
import PickCmps from './PickCmps';
import Header from './Header';
// import { log } from '@/utils/utils'
import {
  saveListPageData,
  publishPageGroup,
  disablePageGroup,
  rollbackListPageData,
} from '@/services/pageGroup';
import { updateFormPageConfig } from '@/services/page';
import { EPageType, PLACEHOLDER_OPTIONS, FieldCmpType } from '@/constant';
import {
  formatPageTypes,
  findTargetPageFromPages,
  createListSubmitDataByStore,
  createOtherSubmitDataByStore,
  getDefaultLabelByCmpType,
} from './help';
import EditPageInfo from './EditPageInfo';
import Content from './Content';
import './index.less';
import deepmerge from 'deepmerge';

const Main = memo(() => {
  const fgStore = useStore((state) => state);
  const listStore = useListStore((state) => state);
  const addStore = useAddStore((state) => state);
  const editStore = useEditStore((state) => state);
  const detailStore = useDetailStore((state) => state);
  const headerRef = useRef<any>(null);
  const {
    uIsDraft,
    currentPageGroupId,
    uPageList,
    loading,
    uLoading,
    uCurrentPageGroupInfo,
    editorVisible,
    currentFocusCmp,
    currentPage,
    cmpListPanel,
    uCmpListPanel,
  } = fgStore;
  const { load: loadPageList } = useGroupPageTypeList(false);
  const [closePopover, setClosePopover] = useState(false);
  const { load: loadPageGroupDetail } = usePageGroupInfo();
  const { load: loadPageListInfo } = useListPageInfo();
  // 加载入口
  // 参数用于指定跳转到哪个页面
  const initWork = useCallback(
    async (id?: string) => {
      if (!editorVisible) {
        return;
      }
      uLoading(true);
      // 1. 根据页面组 ID 获取页面组信息
      const detail = await loadPageGroupDetail(currentPageGroupId);
      const pageList = await loadPageList(currentPageGroupId);
      if (detail) {
        uCurrentPageGroupInfo(detail);
      }
      if (pageList?.length) {
        uPageList(formatPageTypes(pageList));
        const pageTarget =
          pageList.find((n) => n.firstPage) ??
          findTargetPageFromPages(pageList || [], EPageType.列表页面);
        setTimeout(() => {
          const target = id ?? pageTarget?.id;
          if (target) {
            headerRef.current?.togglePage?.(target, false);
          }
          uLoading(false);
        }, 50);
      } else {
        uLoading(false);
      }
    },
    [currentPageGroupId, loadPageGroupDetail, loadPageListInfo, editorVisible, currentFocusCmp],
  );

  const validatePagination = () => {
    if (String(currentPage?.type) !== EPageType.列表页面) return true;
    const { usedPagination } = listStore?.listConfig;
    const { pageNumberField, pageSizeField, totalField } = listStore?.listPagination;
    if (
      usedPagination &&
      (!pageNumberField ||
        !Object.keys(pageNumberField).length ||
        !pageSizeField ||
        !Object.keys(pageSizeField).length ||
        !totalField ||
        !Object.keys(totalField).length)
    ) {
      Modal.error({
        title: '请检查分页组件【页数】、【页码】、【总条数】是否绑定字段',
      });
      return false;
    }
    return true;
  };

  // 保存列表进草稿
  const saveListDraft = useCallback(
    async (editData?: any) => {
      if (listStore.ready === false) {
        message.warning('保存操作过于频繁');
        return;
      }
      const data = editData
        ? deepmerge(createListSubmitDataByStore(listStore), editData)
        : createListSubmitDataByStore(listStore);
      try {
        uLoading(true);
        const res = await saveListPageData(data);
        if (res.rCode === 0) {
          message.success('保存成功');
        } else {
          message.error(res.msg || '保存失败');
        }
      } catch (error) {
        console.log(error);
      } finally {
        uLoading(false);
      }
    },
    [listStore, uLoading],
  );

  // 保存新增/编辑/详情进草稿
  const saveOtherDraft = useCallback(
    async (pageType: EPageType, editData?: any) => {
      const storeMap = {
        [`${EPageType.新增页面}`]: addStore,
        [`${EPageType.编辑页面}`]: editStore,
        [`${EPageType.查看页面}`]: detailStore,
      };
      if (storeMap[pageType].ready === false) {
        message.warning('保存操作过于频繁');
        return;
      }
      // console.log('storeMap', storeMap);
      console.log('storeMap[pageType]', JSON.stringify(storeMap[pageType]));
      const data = editData
        ? deepmerge(createOtherSubmitDataByStore(fgStore, storeMap[pageType]), editData)
        : createOtherSubmitDataByStore(fgStore, storeMap[pageType]);

      try {
        uLoading(true);
        const res = await updateFormPageConfig(data, pageType);
        if (res.rCode === 0) {
          message.success('保存成功');
        } else {
          message.error(res.msg || '保存失败');
        }
      } catch (error) {
        console.log(error);
      } finally {
        uLoading(false);
      }
    },
    [addStore, fgStore, editStore, detailStore],
  );

  useEffect(() => {
    if (!currentPageGroupId) {
      return;
    }
    initWork();
  }, [currentPageGroupId]);

  useEffect(() => {
    if (!editorVisible) {
      fgStore.reset();
      listStore.reset();
      addStore.reset();
      editStore.reset();
      detailStore.reset();
    }
  }, [editorVisible]);

  const EditView = <Typography.Link style={{ marginLeft: 5 }}>{<EditOutlined />}</Typography.Link>;

  const triggerClosePopover = () => {
    setClosePopover(true);
    setTimeout(() => setClosePopover(false), 16);
  };

  const Title = (
    <Row justify="space-between" align="middle" style={{ padding: 0, margin: 0 }}>
      <Typography.Text>页面配置</Typography.Text>
      <Typography.Text>
        {fgStore.currentPageGroupInfo?.name || ''}
        {/* 编辑页面信息 */}
        {closePopover ? (
          EditView
        ) : (
          <Popover
            trigger="click"
            placement="bottom"
            content={
              <EditPageInfo
                onSaveOk={async () => {
                  triggerClosePopover();
                  const detail = await loadPageGroupDetail(currentPageGroupId);
                  if (detail) {
                    detail.pages = formatPageTypes(detail.pages || []);
                    uCurrentPageGroupInfo(detail);
                  }
                }}
                onCancel={() => triggerClosePopover()}
              />
            }
          >
            {EditView}
          </Popover>
        )}
      </Typography.Text>
      <Typography.Text style={{ opacity: 0 }}>表单</Typography.Text>
    </Row>
  );

  const handleSave = async (data?: any) => {
    switch (String(currentPage?.type)) {
      case EPageType.列表页面:
        await saveListDraft(data);
        uIsDraft(true);
        break;
      case EPageType.新增页面:
      case EPageType.编辑页面:
      case EPageType.查看页面:
        await saveOtherDraft(currentPage.type, data);
        uIsDraft(true);
        break;
      default:
        break;
    }
  };

  const rollback = useCallback(async () => {
    const bool = await promisifyConfirm({
      content:
        '若确认回滚，页面组内所有页面数据将回滚到最后一次正式发布状态。当前页面组内所有未保存的页面配置数据将丢失。',
      okText: '确定回滚',
      okType: 'danger',
      cancelText: '不做回滚',
    });
    if (!bool) {
      return;
    }
    // 回滚接口
    const res = await rollbackListPageData(currentPageGroupId);

    if (res.rCode === 0) {
      message.success('已回滚成功');
      fgStore.rollback();
      listStore.reset();
      addStore.reset();
      initWork();
    } else {
      message.error(res.msg || '回滚失败');
    }
  }, [fgStore, listStore, addStore, currentPageGroupId, initWork]);

  // const isDraft =
  //   fgStore.currentPageGroupInfo?.status == EPageStatus.已发布 &&
  //   (listStore.listPageServiceConfig?.draft ||
  //     addStore.serviceConfig?.draft ||
  //     editStore.serviceConfig?.draft ||
  //     detailStore.serviceConfig?.draft ||
  //     hasSaveDraft);

  const Extra = (
    <Space>
      {/* <Button>预览</Button> */}
      <Button onClick={rollback}>回滚</Button>
      <Button onClick={async () => await handleSave()} loading={loading}>
        存为草稿
      </Button>
      <Button
        type="primary"
        onClick={async () => {
          const havePagination = validatePagination();
          if (!havePagination) return;
          const bool = await promisifyConfirm({
            content: '请问是否发布，若确认发布，则页面组内所有页面将全部更新',
          });
          if (bool) {
            try {
              await handleSave();
              await disablePageGroup(fgStore.currentPageGroupInfo?.id);
              const res = await publishPageGroup(fgStore.currentPageGroupInfo?.id);
              if (res.rCode === 0) {
                message.success('发布成功');
                fgStore.reset();
                listStore.reset();
                addStore.reset();
                fgStore.uEditorVisible(false);
              } else {
                message.error(res.msg || '发布失败');
              }
            } catch (error) {
              console.log(error);
            }
          }
        }}
      >
        发布
      </Button>
    </Space>
  );

  return (
    <>
      <Drawer
        title={Title}
        visible={fgStore.editorVisible}
        placement="bottom"
        width="100%"
        height="100%"
        maskClosable={false}
        className="form-generator"
        destroyOnClose
        onClose={async () => {
          const havePagination = validatePagination();
          if (!havePagination) return;
          if (
            listStore.hasChange ||
            addStore.hasChange ||
            editStore.hasChange ||
            detailStore.hasChange
          ) {
            await handleSave();
            fgStore.reset();
            listStore.reset();
            addStore.reset();
            editStore.reset();
            detailStore.reset();
            fgStore.uEditorVisible(false);
          } else {
            fgStore.reset();
            listStore.reset();
            addStore.reset();
            editStore.reset();
            detailStore.reset();
            fgStore.uEditorVisible(false);
          }
          // const res = await promisifyConfirm('当前表单处于编辑状态，关闭表单可能导致数据丢失')
          // if (res) {
          //   fgStore.reset()
          //   listStore.reset()
          //   addStore.reset()
          //   fgStore.uEditorVisible(false)
          // }
        }}
        bodyStyle={{ overflow: 'hidden' }}
        extra={Extra}
      >
        <div className="form-generator-body">
          <div className="form-generator-body-inner">
            {/* 头部 */}
            <Header
              ref={headerRef}
              validatePagination={validatePagination}
              handleSave={async (data) => {
                await handleSave(data);
                listStore.reset();
                addStore.reset();
                editStore.reset();
                detailStore.reset();
              }}
              reInit={initWork}
            />
            {/* 内容区 */}
            <Content />
          </div>
          {fgStore.loading && <Spin spinning tip="正在加载中..." className="fg-loading" />}
        </div>
      </Drawer>
      <PickCmps
        visible={cmpListPanel.visible}
        onClose={() => uCmpListPanel({ visible: false })}
        onConfirm={(cmp) => {
          if (typeof cmpListPanel.resolve === 'function') {
            cmpListPanel.resolve(cmp);
            uCmpListPanel({ resolve: null, visible: false });
            return;
          }
          let curStore: any = null;
          switch (String(currentPage.type)) {
            case EPageType.新增页面:
              curStore = addStore;
              break;
            case EPageType.编辑页面:
              curStore = editStore;
              break;
            case EPageType.查看页面:
              curStore = detailStore;
              break;
            default:
              break;
          }
          const newCmp = {
            hide: false,
            label: getDefaultLabelByCmpType(cmp),
            controlType: cmp,
            controlId: `${nanoid()}`,
            options: [
              FieldCmpType.下拉多选框,
              FieldCmpType.下拉框,
              FieldCmpType.复选框,
              FieldCmpType.单选框,
            ].includes(cmp)
              ? PLACEHOLDER_OPTIONS
              : undefined,
          };
          curStore.uFields([...curStore.fields, newCmp]);
          uCmpListPanel({ visible: false });
        }}
      />
    </>
  );
});
export default Main;
