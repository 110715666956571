import React, { memo, useState } from 'react';
import { Typography, Popover } from 'antd';
import Methods from './Methods';

interface Props {
  btnText?: string;
  checkedList?: any[];
  onChange?: (list: any[]) => void;
  value?: any;
  getLabelByValue?: (value: any) => string;
}

export default memo<Props>(
  ({ btnText = '选择', checkedList, onChange, value, getLabelByValue = (v) => v }) => {
    const [open, setOpen] = useState(false);
    const btn = <Typography.Link>{value ? getLabelByValue(value) : btnText}</Typography.Link>;

    if (open) {
      return btn;
    }
    return (
      <Popover
        title={null}
        trigger="click"
        content={
          <Methods
            defaultCheckeds={checkedList}
            onGetModel={(list) => {
              setOpen(true);
              setTimeout(() => setOpen(false), 16);
              onChange?.(list);
            }}
          />
        }
        destroyTooltipOnHide
      >
        {btn}
      </Popover>
    );
  },
);
