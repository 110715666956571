import React, { memo } from 'react';
import { EModeType } from '@/store/form-generator-parse';

interface HTMLTextProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const HTMLText = memo<HTMLTextProps>(({ configInfo }) => {
  return (
    <div>
      {configInfo.mode !== EModeType.production ? (
        '示例数据'
      ) : (
        <div dangerouslySetInnerHTML={{ __html: configInfo.finalValue || '' }} />
      )}
    </div>
  );
});

export default HTMLText;
