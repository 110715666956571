import React, { memo, useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { Row, Space, Button, Spin, message, Select, Form, Col } from 'antd';
import { FooterToolbar } from '@ant-design/pro-layout';
import type { FormCreatorHandle } from '@/components/FormCreator';
import FormCreator, { FCRules, EWidgetType } from '@/components/FormCreator';
import type { ConfigFieldEditorHandle } from '@/components/ConfigFieldEditor';
import ConfigFieldEditor from '@/components/ConfigFieldEditor';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import ParamsEditor from '@/components/ParamsEditor';
import useMethodDetail from '@/hooks/useMethodDetail';
import { createMethod, updateMethod } from '@/services/method';
import type { DCParams } from '@/components/ParamsEditor/DCParamsModal/Form';
import type { ProFormInstance } from '@ant-design/pro-form';
// import JSONCollect from '@/components/JSONCollect'
import {
  EJSONType,
  MethodTypes,
  EMethodType,
  MethodPoolTypes,
  EMethodPoolType,
  MethodRequestTypes,
  EMethodRequestType,
  EMethodRequestEncode,
  GetMethodRequestEncode,
  PostMethodRequestEncode,
  TransmitMethod,
  SQLMethods,
  SQLSubtypes,
  ESQLSubtypes,
} from '@/types';

import RegioEnvSelector from '@/components/RegioEnvSelector';
import OneClickModal from '@/pages/MethodConfig/SQLMethodModal/OneClickModal';
import HandEditingModal from '@/pages/MethodConfig/SQLMethodModal/HandEditingModal';
// import useSetState from '@/hooks/useSetState';
import { mapTree, uuid } from '@/utils/utils';
import _ from 'lodash';
import { FormCreatorConfig } from '@/components/FormCreator/type';
import { IRowItem, EPassValueType } from '@/components/ConfigFieldEditor/types';
import omit from 'lodash/omit';

export type Mode = 'view' | 'edit' | 'add' | 'copy';

interface EditorProps {
  onCancel?: () => void;
  onComplete?: () => void;
  mode: Mode;
  id?: string;
}

function sleep(seconds = 400) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, seconds);
  });
}

const FIXED_OUTPUT_PARAMS = [
  {
    dataType: EFieldType.Integer,
    // fixed: true, // 标识是否固定不能修改 true: 不能修改 false: 能修改
    name: 'rCode',
    title: '状态码',
    _id: uuid(),
    _level: 0,
  },
];
const EFFECTEDROWS_OUTPUT_PARAMS = [
  {
    dataType: EFieldType.Integer,
    name: 'effectedRows',
    title: '影响行数',
    _id: uuid(),
    _level: 0,
  },
];

const needPrecheck = [
  ESQLSubtypes.update,
  ESQLSubtypes.logicalDeletion,
  ESQLSubtypes.physicalDeletion,
];

const { Option } = Select;

export default memo<EditorProps>(({ id, mode, onComplete, onCancel }) => {
  const cfeInputParamRef = useRef<ConfigFieldEditorHandle | null>(null);
  const cfeOutputParamRef = useRef<ConfigFieldEditorHandle | null>(null);
  const fcHelpRef = useRef<FormCreatorHandle | null>(null);
  const { load: loadDetail, data: detail, loading: loadingDetail } = useMethodDetail();
  const [submiting, setSubmiting] = useState(false);
  const formRef = useRef<ProFormInstance<Record<string, any>>>();
  const isCopy = mode === 'copy';
  const [oneClickShow, setOneClickShow] = useState(false);
  const [handEditingShow, setHandEditingShow] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const inputElement = useRef<any>(null);

  // const [jsonEditor, setJsonEditor] = useSetState<any>({
  //   visible: false,
  //   val: '',
  // });

  useEffect(() => {
    if (id) {
      loadDetail(id);
    }
  }, [id]);

  useEffect(() => {
    if (detail) {
      let outputParams = detail.outputParams || [];
      outputParams.forEach((item: any) => {
        if (FIXED_OUTPUT_PARAMS.map((o) => o.name).includes(item.name)) {
          item.fixed = false;
        }
      });
      if (detail.protocolType === EMethodPoolType.DC) {
        detail.dcConfig.region_businessEnv = {
          region: detail.dcConfig.region,
          businessEnv: detail.dcConfig.businessEnv,
        };
      }
      if (detail.protocolType === EMethodPoolType.NEW_DC) {
        detail.pojoDcConfig.region_businessEnv = {
          region: detail.pojoDcConfig.region,
          businessEnv: detail.pojoDcConfig.businessEnv,
        };
      }
      if (detail.type === EMethodType.SQL) {
        detail.mysqlConfig.region_businessEnv = {
          region: detail.mysqlConfig.region,
          businessEnv: detail.mysqlConfig.businessEnv,
        };
      }
      if (isCopy) {
        detail.name += '_复制';
        detail.inputParams = mapTree(
          detail.inputParams || [],
          (item) => {
            return _.omit(item, 'id');
          },
          0,
          false,
          [],
          'fields',
        );
        outputParams = mapTree(
          detail.outputParams || [],
          (item) => {
            return _.omit(item, 'id');
          },
          0,
          false,
          [],
          'fields',
        );
      }
      setTimeout(() => {
        const formData = {
          ..._.omit(detail, isCopy ? ['id'] : []),
          inputParams: detail.inputParams,
          outputParams,
        };
        // 根据接口返回数据进行重置
        cfeInputParamRef.current?.restoreTreeData(detail.inputParams);
        cfeOutputParamRef.current?.restoreTreeData(outputParams);
        formRef.current?.setFieldsValue(formData);
        // 需要使用另外的回显函数才能显示正常

        (fcHelpRef as React.MutableRefObject<FormCreatorHandle>).current?.restore(formData);
        console.log('setTimeout formData', formData);
      }, 20);
    } else {
      cfeOutputParamRef.current?.restoreTreeData(FIXED_OUTPUT_PARAMS);
    }
  }, [detail, isCopy]);

  const onJsonSave = useCallback((data: any) => {
    if (data.jsonType === EJSONType.IN_PARAM) {
      cfeInputParamRef.current?.jsonData2TreeData(JSON.parse(data.json));
    }
    if (data.jsonType === EJSONType.OUT_PARAM) {
      cfeOutputParamRef.current?.jsonData2TreeData(JSON.parse(data.json));
    }
  }, []);

  const onDCSave = useCallback((data: DCParams) => {
    const oldValues = formRef.current?.getFieldsValue() || {};

    const inputParams = mapTree(
      data.inputParams || [],
      (item) => {
        return _.omit(item, 'id');
      },
      0,
      false,
      [],
      'fields',
    );
    const outputParams = mapTree(
      data.outputParams || [],
      (item) => {
        return _.omit(item, 'id');
      },
      0,
      false,
      [],
      'fields',
    );

    let config = {};
    if (data.protocolType === EMethodPoolType.DC) {
      config = {
        dcConfig: {
          ...oldValues?.dcConfig,
          op: data.op,
          domain: data.domain,
          serviceName: data.serviceName,
          methodName: data.methodName,
          groupId: data.groupId,
          artifactId: data.artifactId,
          version: data.version,
        },
      };
    } else {
      config = {
        pojoDcConfig: {
          ...oldValues?.pojoDcConfig,
          op: data.op,
          domain: data.domain,
          serviceName: data.serviceName,
          methodName: data.methodName,
          groupId: data.groupId,
          artifactId: data.artifactId,
          version: data.version,
        },
      };
    }

    const formData = {
      ...oldValues,
      ...config,
      protocolType: data.protocolType,
      inputParams,
      outputParams,
    };
    console.log('data', data);
    console.log('outputParams', outputParams);
    cfeInputParamRef.current?.restoreTreeData(inputParams);
    cfeOutputParamRef.current?.restoreTreeData(outputParams);
    formRef.current?.setFieldsValue(formData); // 回填数据
  }, []);

  const clearSQL = () => {
    let preset: any[] = [];
    const methodChildType =
      formRef.current?.getFieldsValue()?.mysqlConfig?.methodChildType ?? undefined;
    // 判断是否属于编辑，删除
    if (needPrecheck.includes(methodChildType)) {
      preset = EFFECTEDROWS_OUTPUT_PARAMS;
    }
    cfeInputParamRef.current?.restoreTreeData([]);
    cfeOutputParamRef.current?.restoreTreeData(preset);
    formRef.current?.resetFields([
      ['mysqlConfig', 'tableData'],
      ['mysqlConfig', 'mysqlNamespace'],
      ['mysqlConfig', 'region_businessEnv'],
      ['mysqlConfig', 'generateSql'],
    ]);
    formRef.current?.setFieldValue(['inputParams'], []);
    formRef.current?.setFieldValue(['outputParams'], preset);
  };

  const config = useMemo<FormCreatorConfig>(() => {
    const dc = {
      'dcConfig.region_businessEnv': {
        label: '区域/业务环境',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.CustomWidget,
        rules: [FCRules.Required()],
        custom: (props: any) => <RegioEnvSelector {...props} />,
      },
      'dcConfig.domain': {
        label: 'DC接口domain值',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.InputNumber,
        defaultValue: '',
        rules: [FCRules.Required()],
      },
      'dcConfig.op': {
        label: 'DC接口op值',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.InputNumber,
        defaultValue: '',
        rules: [FCRules.Required()],
      },
      'dcConfig.serviceName': {
        label: 'DC接口全类名',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
      },
      'dcConfig.methodName': {
        label: 'DC接口方法名',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
      },
      'dcConfig.groupId': {
        label: 'groupId',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'dcConfig.artifactId': {
        label: 'artifactId',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'dcConfig.version': {
        label: 'version',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
    };

    const newdc = {
      'pojoDcConfig.region_businessEnv': {
        label: '区域/业务环境',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.CustomWidget,
        rules: [FCRules.Required()],
        custom: (props: any) => <RegioEnvSelector {...props} />,
      },

      'pojoDcConfig.serviceName': {
        label: 'DC接口全类名',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
      },
      'pojoDcConfig.methodName': {
        label: 'DC接口方法名',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
      },
      'pojoDcConfig.groupId': {
        label: 'groupId',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'pojoDcConfig.artifactId': {
        label: 'artifactId',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'pojoDcConfig.version': {
        label: 'version',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.NEW_DC && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
    };

    const http = {
      'httpConfig.proUrl': {
        label: '接口url',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.HTTP && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        getDynamicFieldProps(formData: any) {
          if (typeof formData?.httpConfig?.proUrl !== 'undefined') {
            if (
              formData?.httpConfig?.testUrl?.length < 1 &&
              formData?.httpConfig?.preUrl?.length < 1
            ) {
              formData.httpConfig.preUrl = undefined;
              formData.httpConfig.testUrl = undefined;
            }
            if (formData?.httpConfig?.proUrl?.length < 1) {
              formData.httpConfig.proUrl = undefined;
            }
            return;
          }
          const proUrl = formData?.httpConfig?.proUrl ?? '';
          const preUrl = formData?.httpConfig?.preUrl ?? '';
          const testUrl = formData?.httpConfig?.testUrl ?? '';
          const autoUrl =
            (proUrl.length > 0 && proUrl) ||
            (preUrl.length > 0 && preUrl) ||
            (testUrl.length > 0 && testUrl);

          if (typeof autoUrl !== 'undefined' && autoUrl !== false) {
            return {
              defaultValue: autoUrl,
              value: autoUrl,
            };
          }
        },
      },
      'httpConfig.preUrl': {
        label: '接口url(预发环境)',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.HTTP && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        getDynamicFieldProps(formData: any) {
          if (typeof formData?.httpConfig?.preUrl !== 'undefined') {
            if (
              formData?.httpConfig?.testUrl?.length < 1 &&
              formData?.httpConfig?.proUrl?.length < 1
            ) {
              formData.httpConfig.proUrl = undefined;
              formData.httpConfig.testUrl = undefined;
            }
            if (formData?.httpConfig?.preUrl?.length < 1) {
              formData.httpConfig.preUrl = undefined;
            }
            return;
          }
          const proUrl = formData?.httpConfig?.proUrl ?? '';
          const preUrl = formData?.httpConfig?.preUrl ?? '';
          const testUrl = formData?.httpConfig?.testUrl ?? '';
          const autoUrl =
            (proUrl.length > 0 && proUrl) ||
            (preUrl.length > 0 && preUrl) ||
            (testUrl.length > 0 && testUrl);

          if (typeof autoUrl !== 'undefined' && autoUrl !== false) {
            return {
              defaultValue: autoUrl,
              value: autoUrl,
            };
          }
        },
      },
      'httpConfig.testUrl': {
        label: '接口url(灯塔环境)',
        defaultValue: '',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.HTTP && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        getDynamicFieldProps(formData: any) {
          if (typeof formData?.httpConfig?.testUrl !== 'undefined') {
            if (
              formData?.httpConfig?.proUrl?.length < 1 &&
              formData?.httpConfig?.preUrl?.length < 1
            ) {
              formData.httpConfig.proUrl = undefined;
              formData.httpConfig.preUrl = undefined;
            }
            if (formData?.httpConfig?.testUrl?.length < 1) {
              formData.httpConfig.testUrl = undefined;
            }
            return;
          }
          const proUrl = formData?.httpConfig?.proUrl ?? '';
          const preUrl = formData?.httpConfig?.preUrl ?? '';
          const testUrl = formData?.httpConfig?.testUrl ?? '';
          const autoUrl =
            (proUrl.length > 0 && proUrl) ||
            (preUrl.length > 0 && preUrl) ||
            (testUrl.length > 0 && testUrl);

          if (typeof autoUrl !== 'undefined' && autoUrl !== false) {
            return {
              defaultValue: autoUrl,
              value: autoUrl,
            };
          }
        },
      },
      'httpConfig.requestMethodType': {
        label: '请求方式',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.HTTP && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required()],
        defaultValue: detail?.httpConfig?.requestMethodType || EMethodRequestType.GET,
        fieldProps: {
          options: MethodRequestTypes,
        },
      },
      'httpConfig.contentType': {
        label: '编码类型',
        whenHidden: (formData: any) =>
          !(formData.protocolType === EMethodPoolType.HTTP && formData.type === EMethodType.RPC),
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required()],
        getDynamicFieldProps(formData: any, form: any) {
          const httpConfig = formData?.httpConfig ?? detail?.httpConfig;
          if (
            typeof httpConfig !== 'undefined' &&
            httpConfig?.requestMethodType === EMethodRequestType.POST
          ) {
            form?.current?.setFieldsValue({
              httpConfig: {
                ...httpConfig,
                contentType: EMethodRequestEncode.APPLICATION_JSON,
              },
            });

            return {
              defaultValue: String(EMethodRequestEncode.APPLICATION_JSON),
              value: EMethodRequestEncode.APPLICATION_JSON,
              options: PostMethodRequestEncode,
            };
          }

          form?.current?.setFieldsValue({
            httpConfig: {
              ...httpConfig,
              contentType: EMethodRequestEncode.X_WWW_FORM_URLENCODED,
            },
          });

          return {
            defaultValue: String(EMethodRequestEncode.X_WWW_FORM_URLENCODED),
            value: EMethodRequestEncode.X_WWW_FORM_URLENCODED,
            options: GetMethodRequestEncode,
          };
        },
      },
    };

    const sql = {
      'mysqlConfig.methodChildType': {
        label: '方法子类型',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required()],
        options: SQLSubtypes,
        onChange() {
          clearSQL();
        },
      },
      'mysqlConfig.generateSqlWay': {
        label: 'SQL生成方式',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.CustomWidget,
        rules: [FCRules.Required()],
        onChange(value: number) {
          if (value === 0) {
            clearSQL();
          }
        },
        custom(props: any) {
          let formData;
          const data = formRef.current?.getFieldsValue();
          if (data?.mysqlConfig?.generateSqlWay === undefined && detail) {
            formData = detail;
          } else {
            formData = data;
          }
          const mysqlConfig = formData?.mysqlConfig;
          const oneClick = mysqlConfig?.generateSql === undefined ? '生成脚本' : '再次生成';
          const options = ['一键生成SQL脚本（只支持单表）', '手工编辑SQL脚本（支持多表）'];
          const name = props.id.split('_');
          return (
            <Row>
              <Col span={20}>
                <Form.Item name={name} rules={props.rules} initialValue={0}>
                  <Select disabled={mode === 'view'}>
                    {options.map((item, index) => (
                      <Option value={index} key={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button
                  disabled={mode === 'view'}
                  type="primary"
                  onClick={() => {
                    formRef.current
                      ?.validateFields([['mysqlConfig', 'methodChildType'], 'protocolType'])
                      .then(() => {
                        if (mysqlConfig?.generateSqlWay === 1) {
                          setHandEditingShow(true);
                          setIsEditor(false);
                        } else {
                          setOneClickShow(true);
                        }
                      });
                  }}
                >
                  {mysqlConfig?.generateSqlWay === 1 ? '编辑脚本' : oneClick}
                </Button>
                {mysqlConfig?.generateSql !== undefined ? (
                  <Button
                    type="link"
                    onClick={() => {
                      setHandEditingShow(true);
                      setIsEditor(true);
                    }}
                  >
                    查看脚本
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          );
        },
      },
      'mysqlConfig.region_businessEnv': {
        label: 'region_businessEnv',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'mysqlConfig.expectMaxEffectRow': {
        label: '预期影响行数',
        defaultValue: '1',
        whenHidden: (formData: any) =>
          !(
            formData.type === EMethodType.SQL &&
            needPrecheck.includes(formData?.mysqlConfig?.methodChildType)
          ),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required(), FCRules.Int()],
      },
      'mysqlConfig.mysqlNamespace': {
        label: 'mysqlNamespace',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'mysqlConfig.tableData': {
        label: 'tableData',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
      'mysqlConfig.generateSql': {
        label: 'generateSql',
        defaultValue: '',
        whenHidden: (formData: any) => !(formData.type === EMethodType.SQL),
        fieldType: EWidgetType.Input,
        rules: [FCRules.Required()],
        noStyle: true,
        fieldProps: {
          hidden: true,
        },
      },
    };

    const result: any = {
      name: {
        label: '名称',
        fieldType: EWidgetType.Input,
        defaultValue: '',
        rules: [FCRules.Required()],
      },
      description: {
        label: '描述',
        fieldType: EWidgetType.TextArea,
        defaultValue: '',
        rules: [],
      },
      type: {
        label: '方法类型',
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required()],
        defaultValue: EMethodType.RPC,
        fieldProps: {
          options: MethodTypes,
        },
        onChange(value: any) {
          if (value === EMethodType.SQL) {
            clearSQL();
          }
          const formData = formRef.current?.getFieldsValue();
          if (formData?.mysqlConfig) {
            clearSQL();
          }
          if (formData?.protocolType) {
            formData.protocolType = null;
            fcHelpRef?.current?.restore(formData);
          }
        },
      },
      protocolType: {
        label: '协议类型',
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required()],
        getDynamicFieldProps(formData: any, form: any) {
          const { type } = formData;
          let defaultValue = detail?.protocolType;
          let options: any = MethodPoolTypes;
          let readonly = false;
          // 方法类型为透传方法时，不提供协议类型选项
          if (type === EMethodType.LPC) {
            defaultValue = TransmitMethod.value;
            options = [TransmitMethod];
            readonly = true;
            form?.current?.setFieldsValue({
              protocolType: TransmitMethod.value,
            });
          } else if (type === EMethodType.SQL) {
            options = SQLMethods;
          }
          return {
            disabled: mode === 'view',
            defaultValue,
            value: defaultValue,
            fieldProps: {
              options,
            },
            readonly,
          };
        },
      },
      ...newdc,
      ...dc,
      ...http,
      ...sql,
      _quickParams: {
        label: '方法出/入参快捷设置',
        defaultValue: '',
        fieldType: EWidgetType.CustomWidget,
        custom: (props: any) => (
          <ParamsEditor
            formRef={formRef}
            mode={mode}
            onDCSave={onDCSave}
            onJsonSave={onJsonSave}
            {...props}
          />
        ),
      },
      inputParams: {
        label: '方法入参',
        fieldType: EWidgetType.CustomWidget,
        // rules: [FCRules.Required('请配置入参')],
        custom(props: any) {
          return (
            <ConfigFieldEditor
              showBindGlobal
              isShowDeclaredClass={props?.formData?.protocolType === EMethodPoolType.NEW_DC}
              isShowGenericClass={props?.formData?.protocolType === EMethodPoolType.NEW_DC}
              {...props}
              mode={mode === 'view' ? 'view' : 'opera'}
              ref={cfeInputParamRef}
            />
          );
        },
        fieldProps: {},
      },
      outputParams: {
        label: '方法出参',
        fieldType: EWidgetType.CustomWidget,
        rules: [FCRules.Required('请配置出参')],
        custom(props: any) {
          const { formData } = props;
          const { type } = formData;

          // const value = [
          //   { ...FIXED_OUTPUT_PARAMS[0] },
          //   {
          //     name: 'data',
          //     title: '结果数据',
          //     dataType: EFieldType.Object,
          //     _id: uuid(),
          //     fields: [
          //       ...(formData.inputParams.map((item: IRowItem) => {
          //         console.log(this);
          //         return item;
          //       }) ?? []),
          //     ],
          //   },
          // ];

          if (type === EMethodType.LPC) {
            return (
              <div>
                <strong>透传方法的出参数据与入参数据一致，无需编辑</strong>
              </div>
            );
          }

          return (
            <ConfigFieldEditor
              {...props}
              key={EMethodType.RPC}
              mode={mode === 'view' ? 'view' : 'opera'}
              ref={cfeOutputParamRef}
            />
          );
        },
        fieldProps: {},
        getDynamicFieldProps(formData: any, form: any) {
          const { type, inputParams } = formData;
          if (type === EMethodType.LPC) {
            const dataId = uuid();
            const setParentId = (arr: IRowItem[] | undefined, parentId: string, level: number) => {
              if (arr === undefined || arr.length === 0) return;
              for (const item of arr) {
                const newItem = Object.assign(item, {
                  _id: uuid(),
                  _parentId: parentId,
                  _level: level + 1,
                });
                if (newItem.fields && newItem.fields.length > 0) {
                  newItem._folds = newItem.fields;
                  omit(newItem, 'fields');
                  setParentId(newItem._folds, newItem._id, level + 1);
                }
              }
            };
            setParentId(inputParams, dataId, 0);
            form?.current?.setFieldsValue({
              outputParams: [
                { ...FIXED_OUTPUT_PARAMS[0] },
                {
                  name: 'data',
                  title: '结果数据',
                  dataType: EFieldType.Object,
                  fields: [...(inputParams ?? [])],
                  _id: dataId,
                  _folds: [...(inputParams ?? [])],
                  _level: 0,
                },
              ],
            });
          }
          return {};
        },
      },
    };
    return result;
  }, [detail, mode]);

  const handleSubmit = useCallback(
    async (vals: any) => {
      try {
        const datas = _.clone(vals);
        if (_.isObject(datas?.dcConfig?.region_businessEnv)) {
          datas.dcConfig = {
            ...datas.dcConfig,
            ...vals.dcConfig.region_businessEnv,
          };
          delete datas.dcConfig.region_businessEnv;
        }
        if (_.isObject(datas?.pojoDcConfig?.region_businessEnv)) {
          datas.pojoDcConfig = {
            ...datas.pojoDcConfig,
            ...vals.pojoDcConfig.region_businessEnv,
          };
          delete datas.pojoDcConfig.region_businessEnv;
        }
        if (_.isObject(datas?.mysqlConfig?.region_businessEnv)) {
          datas.mysqlConfig = {
            ...datas.mysqlConfig,
            ...vals.mysqlConfig.region_businessEnv,
          };
          delete datas.mysqlConfig.region_businessEnv;
        }

        setSubmiting(true);
        const api =
          id && !isCopy
            ? updateMethod.bind(null, { ...datas, id })
            : createMethod.bind(null, datas);
        const res = await api();
        if (res.rCode === 0) {
          message.success('提交成功');
          formRef.current?.resetFields();
          onComplete?.();
        } else {
          message.error(res.msg || '提交失败');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmiting(false);
      }
    },
    [id, onComplete, isCopy],
  );

  const validatePassValueConfig = (vals: any) => {
    try {
      let isNullpassValueConfig = false;
      mapTree(
        vals.inputParams || [],
        (item) => {
          if (
            [EPassValueType.前端传值, EPassValueType.后端传值].includes(
              item.passValueType?.toString(),
            ) &&
            !item.passValueConfig
          ) {
            isNullpassValueConfig = true;
          }
        },
        0,
        false,
        [],
        'fields',
      );
      return isNullpassValueConfig;
    } catch (error) {
      console.log('error', error);
    }
  };

  const submitter = {
    render: () => (
      <FooterToolbar style={{ width: '100%' }}>
        <Row justify="center" align="middle" className="submit-view">
          <Space>
            <Button onClick={() => onCancel?.()}>取消</Button>
            <Button
              type="primary"
              loading={submiting}
              onClick={async () => {
                // 对表单input焦点进行统一失焦处理
                inputElement.current?.focus();
                await sleep(200); // 等待*毫秒后，form value已更新，再进行 getFieldsValue操作

                // 对httpconfig自动填充部分，设置进fields
                const values = formRef.current?.getFieldsValue();

                if (typeof values !== 'undefined' && values.protocolType === EMethodPoolType.HTTP) {
                  const httpConfig = values.httpConfig || {};
                  httpConfig.proUrl =
                    httpConfig?.proUrl ?? httpConfig?.preUrl ?? httpConfig?.testUrl ?? '';
                  httpConfig.testUrl =
                    httpConfig?.testUrl ?? httpConfig?.proUrl ?? httpConfig?.preUrl ?? '';
                  httpConfig.preUrl =
                    httpConfig?.preUrl ?? httpConfig?.proUrl ?? httpConfig?.testUrl ?? '';
                  formRef.current?.setFieldsValue({
                    httpConfig: {
                      ...httpConfig,
                    },
                  });
                }

                formRef.current
                  ?.validateFields()
                  .then(async (vals) => {
                    // TODO 原有的检验出入参逻辑取消，等待后续完善
                    // const boolIn = await cfeInputParamRef.current?.checkBeforeSubmit();
                    // if (boolIn) {
                    //   if (vals.type !== EMethodType.LPC) {
                    //     const boolOut = await cfeOutputParamRef.current?.checkBeforeSubmit(
                    //       true,
                    //       vals.type === EMethodType.SQL,
                    //     );
                    //     if (boolOut) {
                    //       handleSubmit(vals);
                    //     }
                    //   } else {

                    if (validatePassValueConfig(vals)) {
                      message.error('方法入参未绑定变量');
                      return;
                    }

                    handleSubmit(vals);
                    //   }
                    // }
                  })
                  .catch(({ errorFields }) => {
                    console.log('errorFields', errorFields);
                    let mysqlConfig = true;
                    errorFields.forEach((item: any) => {
                      if (mysqlConfig && item.name[0] === 'mysqlConfig') {
                        message.error('mysql未正确配置，请检查');
                        mysqlConfig = false;
                      }
                      if (item.name[0] === 'outputParams') {
                        message.error('方法出参未配置');
                      }
                    });
                  });
              }}
            >
              保存
            </Button>
          </Space>
        </Row>
      </FooterToolbar>
    ),
  };

  const sqlSubmit = useCallback((data) => {
    const oldValues = formRef.current?.getFieldsValue() || {};
    if (data.inputParams) {
      cfeInputParamRef.current?.restoreTreeData(data.inputParams);
    }
    if (data.outputParams) {
      cfeOutputParamRef.current?.restoreTreeData(data.outputParams);
    }

    formRef.current?.setFieldsValue({
      ...oldValues,
      ...data,
    });
  }, []);

  return (
    <Spin spinning={id ? loadingDetail : false}>
      <input type="text" ref={inputElement} style={{ visibility: 'hidden', position: 'fixed' }} />
      {/* 协助开发人员快速导入 */}
      {/*  <Button onClick={() => {
        setJsonEditor({
          visible: true,
          ref: cfeInputParamRef.current
        })
      }}>导入到入参</Button>
      <Button onClick={() => {
        setJsonEditor({
          visible: true,
          ref: cfeOutputParamRef.current
        })
      }}>导入到出参</Button>
      <JSONCollect
        visible={jsonEditor.visible}
        onChange={(val) => {
          setJsonEditor({
            val: val
          })
        }}
        onOk={async () => {
          setJsonEditor({
            visible: false
          })
          console.log('jsonEditor.val', jsonEditor.val)
          jsonEditor.val && jsonEditor?.ref?.restoreTreeData(jsonEditor.val)
        }}
        onCancel={() => setJsonEditor({
          visible: false
        })}
      /> */}
      <FormCreator
        debug
        onMounted={(form) => {
          formRef.current = form;
        }}
        ref={fcHelpRef}
        submitter={mode === 'view' ? false : submitter}
        labelCol={{ span: 3 }}
        layout="horizontal"
        config={config}
        readonly={mode === 'view'}
      />
      <OneClickModal
        visible={oneClickShow}
        isEditor={false}
        submit={(data) => {
          sqlSubmit(data);
          setOneClickShow(false);
        }}
        formRef={formRef}
        handleCancel={() => {
          setOneClickShow(false);
        }}
      />
      <HandEditingModal
        visible={handEditingShow}
        isEditor={isEditor}
        submit={(data) => {
          sqlSubmit(data);
          setHandEditingShow(false);
        }}
        formRef={formRef}
        handleCancel={() => {
          setHandEditingShow(false);
        }}
      />
    </Spin>
  );
});
