import { memo, useRef } from 'react';
import { Card, Row, Button, Space, message } from 'antd';
import useFormGeneratorStore from '@/store/form-generator';
import type { ProFormInstance } from '@ant-design/pro-form';
import { updatePageGroup } from '@/services/pageGroup';
import FormCreator, { EWidgetType, FCRules } from '@/components/FormCreator';

interface EditPageInfoProps {
  onCancel?: () => void;
  onSaveOk?: () => void;
}

/** 编辑页面信息 */
const EditPageInfo = memo<EditPageInfoProps>(({ onCancel, onSaveOk }) => {
  const formRef = useRef<ProFormInstance<any>>();
  const { currentPageGroupInfo } = useFormGeneratorStore((state) => state);

  return (
    <Card title="编辑页面" style={{ width: 550 }} size="small">
      <FormCreator
        submitter={{
          resetButtonProps: {
            style: {
              display: 'none',
            },
          },
          render: () => (
            <Row justify="center" align="middle">
              <Space>
                <Button onClick={() => onCancel?.()}>取消</Button>
                <Button
                  onClick={async () => {
                    try {
                      formRef.current?.validateFields().then(async (vals) => {
                        const res = await updatePageGroup({
                          ...vals,
                          id: currentPageGroupInfo.id,
                        });
                        if (res.rCode === 0) {
                          message.success('修改成功');
                          onSaveOk?.();
                        } else {
                          message.error(res.msg || '提交失败');
                        }
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  type="primary"
                >
                  保存
                </Button>
              </Space>
            </Row>
          ),
        }}
        labelAlign="right"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 15 }}
        debug
        layout="horizontal"
        onMounted={(ref) => {
          formRef.current = ref;
        }}
        config={{
          name: {
            label: '页面组名称',
            fieldType: EWidgetType.Input,
            rules: [FCRules.Required()],
            defaultValue: currentPageGroupInfo?.name || '',
          },
          description: {
            label: '页面组描述',
            fieldType: EWidgetType.TextArea,
            defaultValue: currentPageGroupInfo?.description || '',
          },
        }}
      />
    </Card>
  );
});

export default EditPageInfo;
