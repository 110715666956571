import { useState } from 'react';
import { message } from 'antd';
import { getListPageInfo } from '@/services/pageGroup';

export default function useListPageInfo() {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const load = async (id: string, published?: boolean): Promise<any | null> => {
    try {
      if (loading) {
        return null;
      }
      setLoading(true);
      const res = await getListPageInfo({
        id,
        published: !!published,
      });
      if (res.rCode === 0) {
        setData(res.data);
        return res.data;
      } else {
        message.error(res.msg);
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    load,
  };
}
