import { memo, useEffect } from 'react';
import { Image } from 'antd';
import { EModeType } from '@/store/form-generator-parse';
import AwesomeSwiper from 'react-awesome-swiper';
import './index.less';

interface SwiperControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}
let count = 0;
const SwiperControl = memo<SwiperControlProps>(({ configInfo }) => {
  const { autoPlay, timeInterval, finalValue, swiperWidth, swiperHeight } = configInfo;
  const isPreview = configInfo.mode === EModeType.preview;
  const cls = `swiper_${++count}`;
  useEffect(() => {
    setTimeout(() => {
      const wrapper: any = document.querySelector(`.${cls}`);
      if (wrapper) {
        wrapper.style.width = `${configInfo.swiperWidth}px`;
      }
    }, 16);
  }, [configInfo]);

  if (!isPreview && (!Array.isArray(finalValue) || !finalValue?.length)) {
    return <div>-</div>;
  }

  return (
    <>
      <AwesomeSwiper
        className={`custom-awesome-swiper2 ${cls}`}
        style={{ width: 200 }}
        config={{
          loop: !!autoPlay,
          autoplay: {
            delay: autoPlay ? (timeInterval || 1) * 1000 : 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          lazy: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          width: swiperWidth || 200,
          height: swiperHeight,
          autoHeight: swiperHeight ? undefined : true,
        }}
      >
        <div className="swiper-wrapper">
          {isPreview
            ? ['#99CCCC', '#FFCC99', '#FFCCCC'].map((item: string) => (
                <div className="swiper-slide" key={item}>
                  <div
                    style={{
                      height: swiperHeight || 150,
                      backgroundColor: item,
                      color: '#fff',
                      textAlign: 'center',
                    }}
                  >
                    <span style={{ opacity: 0 }}>{item}</span>
                  </div>
                </div>
              ))
            : (finalValue || []).map((item: string) => (
                <div style={{ height: swiperHeight || 150 }} className="swiper-slide" key={item}>
                  <Image width={swiperWidth || 200} src={item} />
                </div>
              ))}
        </div>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </AwesomeSwiper>
    </>
  );
});

export default SwiperControl;
