import React, { useState } from 'react';
import type { ReactNode } from 'react';
import { Collapse } from 'antd';

/** 展开伸缩 */
const WithCollapse = React.memo(
  ({
    collapseDatas,
    defaultOpenFirstOne = true,
    expandAll = false, // 展开所有
  }: {
    collapseDatas: Array<{
      title: string;
      key: string;
      configComponent: ReactNode;
    }>;
    defaultOpenFirstOne?: boolean;
    expandAll?: boolean;
  }) => {
    const settings: any = {};
    const defaultOpenKey = collapseDatas?.length ? collapseDatas[0].key : [];
    const keys = collapseDatas.map((item) => item.key);
    const [clickPanelKey, setClickPanelKey] = useState('');

    if (!expandAll) {
      settings.accordion = true;
    }
    // 注意点：展示数据涉及后端接口请求的，需要接口请求完成后，再执行render
    let activeKey: string | number | Array<string | number> | undefined;
    if (expandAll) {
      activeKey = keys;
    } else if (defaultOpenFirstOne) {
      activeKey = defaultOpenKey;
    }
    return (
      <Collapse ghost className="fg-collapse" defaultActiveKey={activeKey}>
        {(collapseDatas || []).map((o) => (
          <>
            <Collapse.Panel
              key={o.key}
              header={o.title}
              className={`${clickPanelKey === o.key ? 'edit-item' : ''}`}
            >
              <div onClick={() => setClickPanelKey(o.key)}>{o.configComponent}</div>
            </Collapse.Panel>
          </>
        ))}
      </Collapse>
    );
  },
);

export default WithCollapse;
