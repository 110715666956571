import { memo, useMemo } from 'react';
import useListStore from '@/store/form-generator-list';
import {
  TABLE_COLUMN_DISPLAY_TYPES,
  PAGE_OPEN_MODES,
  EPageOpenMode,
  PAGE_SOURCE_TYPES,
  PAGE_RENDER_DATA_TYPES,
  EPageRenderDataType,
  EPageSourceType,
  EOptionsFromType,
  OptionsFromTypes,
  EPageType,
  FieldCmpType,
} from '@/constant';
import { pick, debounce } from 'lodash';
import FormCreator, { EWidgetType, FCRules } from '@/components/FormCreator';
import EnumsEditor from '../common/EnumsEditor';
import DyEnumsEditor from '../common/DyEnumsEditor';
import BindExistMethodInputParam from '../common/BindExistMethodInputParam';
import BindParam, { getDefaultDataSource } from '../common/BindParam';
import { isDateBindParam, newTransferOptions, transferDefault } from '../helper';
import ConfigOperators from './ConfigOperators';
import useFormGeneratorStore from '@/store/form-generator';
import PickExternalPage from '@/components/PickExternalPage';

import ExternalPageEventsBind from '@/components/FormGeneractor/ConfigComponent/ExternalPageEventsBind';

interface IConfigItemProps {
  visible?: boolean;
  index?: number;
  record?: any;
  onChange: (info: any) => void;
}
const ConfigItem = memo<IConfigItemProps>(({ record, onChange }) => {
  const { bindedMethods } = useListStore((state) => state);
  const pageList = useFormGeneratorStore((state) => state.pageList);
  const commonConfig = useMemo(
    () => ({
      hasTip: {
        label: '图标提示',
        defaultValue: record?.hasTip || '',
        fieldType: EWidgetType.Switch,

        fieldProps: {},
      },
      tipContent: {
        label: '提示文案',
        defaultValue: record?.tipContent || '',
        whenHidden: (formData: any) => !formData.hasTip,
        fieldType: EWidgetType.Input,

        fieldProps: {},
      },
    }),
    [record],
  );
  const customButtonConfig = useMemo(() => {
    return {
      pageOpenMode: {
        label: '交互方式',
        defaultValue: record?.pageOpenMode || EPageOpenMode.DRAWER,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_OPEN_MODES,
        },
        whenHidden: (formData: any) => formData.displayType !== 'button',
      },
      pageSourceType: {
        label: '绑定页面',
        defaultValue: record?.pageSourceType || EPageSourceType.BUILT_IN,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_SOURCE_TYPES,
        },
        whenHidden: (formData: any) => formData.displayType !== 'button',
      },
      pageRenderDataType: {
        label: '页面类型',
        defaultValue: record?.pageRenderDataType || EPageRenderDataType.REQUEST_DATA,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_RENDER_DATA_TYPES,
        },
        whenHidden: (formData: any) =>
          formData.displayType !== 'button' ||
          (formData.displayType === 'button' &&
            formData.pageSourceType !== EPageSourceType.EXTERNAL),
      },
      builtInPage: {
        label: '内部页面',
        defaultValue: record?.builtInPage,
        fieldType: EWidgetType.Select,
        fieldProps: {
          options: pageList
            .filter((page) => String(page.type) === EPageType.查看页面)
            .map((page) => ({
              label: page.name,
              value: page.id,
            })),
        },
        whenHidden: (formData: any) =>
          formData.displayType !== 'button' ||
          (formData.displayType === 'button' &&
            formData.pageSourceType !== EPageSourceType.BUILT_IN),
      },
      externalInfo: {
        label: '外部页面',
        fieldType: EWidgetType.CustomWidget,
        defaultValue: record?.externalInfo ?? undefined,
        whenHidden(formData: any) {
          return (
            formData.displayType !== 'button' ||
            (formData.displayType === 'button' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL)
          );
        },
        custom(props: any) {
          return (
            <PickExternalPage
              {...props}
              value={props?.value ? [props?.value] : undefined}
              getLabelByValue={(row) => {
                return row?.length ? row[0].name : '请选择外部页面';
              }}
              checkedList={[props?.value].filter((o) => Boolean(o))}
              onChange={(row) => {
                const info = row?.length ? row[0] : null;
                if (info) {
                  props?.onChange?.({
                    ...info,
                    id: info.id,
                    name: info.name,
                    url: info.url,
                    loadEvents: info.loadEvents,
                    messageEvents: info.messageEvents,
                  });
                }
              }}
            />
          );
        },
      },
      loadEvents: {
        defaultValue: record?.loadEvents ?? [],
        fieldType: EWidgetType.CustomWidget,
        fieldProps: {},
        whenHidden(formData: any) {
          return (
            formData.displayType !== 'button' ||
            (formData.displayType === 'button' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
            !Array.isArray(formData?.externalInfo?.loadEvents ?? null)
          );
        },
        custom(props: any) {
          const { value = {} } = props;
          const eventList = props?.formData?.externalInfo?.loadEvents ?? [];
          const safeValue: Record<string, any> = {};
          eventList.forEach(({ key }: { key: string }) => {
            safeValue[key] = value[key];
          });

          return (
            <ExternalPageEventsBind
              title="加载配置"
              value={safeValue}
              eventList={eventList}
              onChange={(data) => {
                props?.onChange(data);
              }}
            />
          );
        },
      },
      messageEvents: {
        defaultValue: record?.messageEvents ?? [],
        fieldType: EWidgetType.CustomWidget,
        fieldProps: {},
        whenHidden(formData: any) {
          return (
            formData.displayType !== 'button' ||
            (formData.displayType === 'button' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
            !Array.isArray(formData?.externalInfo?.messageEvents ?? null)
          );
        },
        custom(props: any) {
          const { value = [] } = props;
          const eventList = props?.formData?.externalInfo?.messageEvents ?? [];
          const safeValue: Record<string, any> = {};
          eventList.forEach(({ key }: { key: string }) => {
            safeValue[key] = value[key];
          });

          return (
            <ExternalPageEventsBind
              title="动作配置"
              value={safeValue}
              eventList={eventList}
              onChange={(data) => {
                props?.onChange(data);
              }}
            />
          );
        },
      },
    };
  }, [record, pageList]);
  const bindParamDefaultValue = record?.bindParam?.length
    ? record?.bindParam
    : getDefaultDataSource({
        loadMethod: pick(bindedMethods?.loadMethod, ['id', 'name']),
        loadMethodParamType: 'outputParams',
      });

  const config = {
    used: {
      label: '是否启用',
      defaultValue: record?.used ?? false,
      fieldType: EWidgetType.Switch,
    },
    title: {
      label: '列名称',
      defaultValue: record?.title || '',
      fieldType: EWidgetType.Input,
      fieldProps: {},
    },
    dataIndex: {
      label: '字段命名',
      tooltip: '列表字段索引值，需要确保唯一',
      defaultValue: record?.dataIndex,
      fieldType: EWidgetType.Input,
    },
    bindParam: {
      label: '绑定参数',
      tooltip: '设置当前组件关联对应方法下的参数',
      defaultValue: bindParamDefaultValue,
      fieldType: EWidgetType.CustomWidget,
      custom: (props: any) => <BindParam {...props} value={bindParamDefaultValue} />,
    },
    displayType: {
      label: '组件类型',
      defaultValue: record?.displayType || '',
      fieldType: EWidgetType.Select,
      rules: [FCRules.Required('请选择')],
      fieldProps: {
        options: TABLE_COLUMN_DISPLAY_TYPES,
      },
    },
    ...customButtonConfig,
    // valueExpression: {
    //   label: '值转换',
    //   defaultValue: record?.valueExpression || '',
    //   fieldType: EWidgetType.Input,
    // },
    inlineStyle: {
      label: '自定义样式',
      defaultValue: record?.inlineStyle || '',
      fieldType: EWidgetType.Input,
    },
    optionsFromType: {
      label: '枚举值',
      fieldType: EWidgetType.RadioGroup,
      defaultValue: record?.optionsFromType || EOptionsFromType.静态数据,
      whenHidden: (fromData: any) => !['enums'].includes(fromData.displayType),
      fieldProps: {
        options: OptionsFromTypes,
      },
    },
    options: {
      label: '', // '',
      fieldType: EWidgetType.CustomWidget,
      colon: false,
      defaultValue: record?.options || [],
      whenHidden: (fromData: any) =>
        fromData.optionsFromType !== EOptionsFromType.静态数据 ||
        !['enums'].includes(fromData.displayType),
      custom: (props: any) => <EnumsEditor {...props} />,
    },
    dyOptionsInfo: {
      label: '',
      fieldType: EWidgetType.CustomWidget,
      custom: (props: any) => <DyEnumsEditor {...props} />,
      defaultValue: record.dyOptionsInfo,
      whenHidden: (fromData: any) =>
        fromData.optionsFromType !== EOptionsFromType.动态数据 ||
        !['enums'].includes(fromData.displayType),
    },
    formatDateTime: {
      label: '展示格式',
      defaultValue: record?.formatDateTime || 'YYYY-MM-DD HH:mm:ss',
      whenHidden: (fromData: any) => !['date'].includes(fromData.displayType),
      fieldType: EWidgetType.Input,
      extra: '以”-“区分年月日，例如格式为YY-MM-DD',
    },
    transferFormat: {
      label: '传值格式',
      tooltip: '数据类型为Date时，请选择时间戳，其余均不生效',
      defaultValue: record.transferFormat || transferDefault(record),
      fieldType: EWidgetType.Select,
      fieldProps: {
        value: isDateBindParam(record) ? 'x' : record.transferFormat || 'YYYY-MM-DD',
        options: newTransferOptions(record),
      },
      whenHidden: (formData: any) => ![FieldCmpType.日期选择器].includes(formData.displayType),
    },
    transferFormatCustom: {
      label: '自定义',
      defaultValue: record.transferFormatCustom || '',
      whenHidden: (formData: any) => formData.transferFormat !== 'custom',
      fieldType: EWidgetType.Input,
    },
    preFileLink: {
      label: '资源http前缀',
      defaultValue: record.preFileLink || '',
      whenHidden: (formData: any) => formData.displayType !== 'image',
      fieldType: EWidgetType.Input,
    },
    width: {
      label: '列宽度',
      defaultValue: record?.width || '',
      fieldType: EWidgetType.InputNumber,
      fieldProps: {},
    },
    linkToDetail: {
      label: '详情页链接',
      defaultValue: record?.linkToDetail || '',
      fieldType: EWidgetType.Switch,
      fieldProps: {},
    },
    callMethod: {
      label: '绑定入参',
      fieldType: EWidgetType.CustomWidget,
      defaultValue: record.callMethod,
      whenHidden: (formData: any) => !formData.linkToDetail,
      custom(props: any) {
        return props.formData?.linkToDetail ? (
          <BindExistMethodInputParam
            {...props}
            targetPageType={EPageType.查看页面}
            outExistMethodIdMessage="查看页面未绑定加载方法"
          />
        ) : null;
      },
    },
    ...commonConfig,
    ellipsisTooltip: {
      label: '文字提示',
      defaultValue: record?.ellipsisTooltip || '',
      fieldType: EWidgetType.Switch,
      fieldProps: {},
    },
    copyable: {
      label: '复制内容',
      defaultValue: record?.copyable || '',
      fieldType: EWidgetType.Switch,
      fieldProps: {},
    },
    sortable: {
      label: '支持排序',
      defaultValue: record?.sortable || false,
      fieldType: EWidgetType.Switch,
      fieldProps: {},
    },
    // sortType: {
    //   label: '排序方式',
    //   whenHidden: (formData: any) => !formData.sortable,
    //   defaultValue: record?.sortType || 'descend',
    //   fieldType: EWidgetType.RadioGroup,
    //
    //   fieldProps: {
    //     options: SORT_TYPES
    //   }
    // },
  };

  // 操作列配置
  if (record.fixed) {
    return <ConfigOperators record={record} onChange={onChange} />;
  }

  return (
    <>
      <FormCreator
        submitter={{ render: () => null }}
        layout="horizontal"
        debug
        labelCol={{ flex: '100px' }}
        wrapperCol={{ flex: 1 }}
        style={{ overflow: 'scroll' }}
        config={config}
        onValuesChange={debounce((_, allValues: any) => {
          console.log('save alll allValues', allValues);
          onChange?.({ ...allValues });
        })}
      />
    </>
  );
});
export default ConfigItem;
