import { memo, useEffect, useState, useMemo } from 'react';
import { value2Label } from '@lizhife/schema-shared';
import { EModeType } from '@/store/form-generator-parse';
import { Tag } from 'antd';
import useEnumRequest from '../useEnumRequest';

interface DyEnumTextProps {
  dyOptionsInfo: any;
  value: string | string[];
  mode: EModeType;
}
const DyEnumText = memo<DyEnumTextProps>(({ dyOptionsInfo, value, mode }) => {
  const { fetchOptions } = useEnumRequest();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const getOpts = async (info: any) => {
      setOptions(await fetchOptions(info));
    };
    if (dyOptionsInfo && mode === EModeType.production) {
      getOpts(dyOptionsInfo);
    }
  }, [dyOptionsInfo, mode]);

  const content = useMemo(() => {
    let valueList: any[] = [];
    if (Array.isArray(value)) {
      valueList = value;
    } else if (value !== null && value !== undefined) {
      valueList.push(String(value));
    }
    return valueList.map((o) => <Tag key={o}>{value2Label(options, String(o)) || o}</Tag>);
  }, [options, value]);
  return <>{content}</>;
});

export default DyEnumText;
