import { debounce } from 'lodash';
import type { FieldItem } from '@/store/types';
import { Select } from 'antd';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useFormGeneratorStore from '@/store/form-generator';
import { EPageType } from '@/constant';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

interface Props {
  fieldInfo: FieldItem & { md5Digest: string[]; sha1Digest: string[] };
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

export default function SingleFileUploadConfig({ fieldInfo, store, onChange }: Props) {
  const fgStore = useFormGeneratorStore((state) => state);
  const { currentPage } = fgStore;
  const type = String(currentPage.type);
  const editStore = useEditStore((state) => state);
  const addStore = useAddStore((state) => state);

  let fields: FieldItem[] = [];
  if (type === EPageType.新增页面) {
    fields = addStore.fields;
  }
  if (type === EPageType.编辑页面) {
    fields = editStore.fields;
  }

  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            fileType: {
              label: '内部上传编码',
              defaultValue: fieldInfo?.fileType,
              fieldType: EWidgetType.InputNumber,
            },
            preFileLink: {
              label: '资源http前缀',
              defaultValue: fieldInfo?.preFileLink,
              fieldType: EWidgetType.Input,
            },
            fileTypeLimit: {
              label: '限制格式',
              defaultValue: fieldInfo?.fileTypeLimit || ['zip'],
              fieldType: EWidgetType.CheckboxGroup,
              options: [
                { label: 'zip', value: 'zip' },
                { label: 'doc', value: 'doc' },
                { label: 'docx', value: 'docx' },
                { label: 'xls', value: 'xls' },
                { label: 'xlsx', value: 'xlsx' },
                { label: 'ppt', value: 'ppt' },
                { label: 'pptx', value: 'pptx' },
                { label: 'pdf', value: 'pdf' },
              ],
            },
            sizeLimit: {
              label: '限制大小',
              defaultValue: fieldInfo?.sizeLimit || false,
              fieldType: EWidgetType.Switch,
            },
            singleFileSize: {
              label: '文件大小',
              defaultValue: fieldInfo?.singleFileSize || 2,
              fieldType: EWidgetType.InputNumber,
              addonBefore: <span>限制</span>,
              addonAfter: <span>m</span>,
            },
            limitPrompt: {
              label: '校验文案',
              tooltip: '上传格式不符合时提示文案',
              defaultValue: fieldInfo?.limitPrompt ?? '',
              fieldType: EWidgetType.Input,
            },
            md5Digest: {
              label: 'MD5 回填组件',
              fieldType: EWidgetType.CustomWidget,
              defaultValue: fieldInfo?.md5Digest ?? [],
              custom(props: any) {
                return (
                  <Select
                    mode="multiple"
                    defaultValue={props.value}
                    onChange={(values) => {
                      props?.onChange?.(values);
                    }}
                    options={fields
                      .filter((n) => n.controlId !== fieldInfo.controlId)
                      .map((n) => ({
                        label: n.controlName || n.label || n.controlId,
                        value: n.controlId,
                      }))}
                  />
                );
              },
            },
            // sha1Digest: {
            //   label: 'SHA1 回填组件',
            //   fieldType: EWidgetType.CustomWidget,
            //   defaultValue: fieldInfo?.sha1Digest ?? [],
            //   custom(props: any) {
            //     return (
            //       <Select
            //         mode="multiple"
            //         defaultValue={props.value}
            //         onChange={(values) => {
            //           props?.onChange?.(values);
            //         }}
            //         options={fields
            //           .filter((n) => n.controlId !== fieldInfo.controlId)
            //           .map((n) => ({
            //             label: n.controlName || n.label || n.controlId,
            //             value: n.controlId,
            //           }))}
            //       />
            //     );
            //   },
            // },
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
