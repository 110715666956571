import React, { useMemo } from 'react';
import { Button, Row, Popconfirm } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import WithCollapse from '../../WithCollapse';
import BindMethodInputParam from '../common/BindMethodInputParam';
import BindExistMethodInputParam from '../common/BindExistMethodInputParam';
import FormCreator, { EWidgetType, FCRules } from '@/components/FormCreator';
import {
  EPageType,
  PAGE_SOURCE_TYPES,
  PAGE_RENDER_DATA_TYPES,
  EPageRenderDataType,
  EPageSourceType,
  PAGE_OPEN_MODES,
  EPageOpenMode,
} from '@/constant';
import DeleteIconButton from '@/components/DeleteIconButton';
import useFormGeneratorStore from '@/store/form-generator';
import { DEFAULT_BUTTON_PROPERTIES } from './ConfigToolbarButton';
import PickExternalPage from '@/components/PickExternalPage';

import ExternalPageEventsBind from '@/components/FormGeneractor/ConfigComponent/ExternalPageEventsBind';

interface ConfigOperatorsProps {
  record?: any;
  onChange: (info: any) => void;
}

export default function ConfigOperators({ record, onChange }: ConfigOperatorsProps) {
  // buttons 为操作列的按钮控制
  const { buttons = [] } = record;
  const pageList = useFormGeneratorStore((state) => state.pageList);

  // 通用的按钮配置项
  const getCommonConfig = (buttonConfig: any) => {
    return {
      hide: {
        label: '是否隐藏',
        defaultValue: buttonConfig?.hide ?? '',
        fieldType: EWidgetType.Switch,
      },
      hideExpression: {
        label: '隐藏表达式',
        defaultValue: buttonConfig.hideExpression ?? '',
        fieldType: EWidgetType.Input,
      },
      text: {
        label: '按钮名称',
        defaultValue: buttonConfig?.text ?? '',
        fieldType: EWidgetType.Input,
      },
      shapeType: {
        label: '按钮类型',
        defaultValue: buttonConfig?.shapeType ?? 'default',
        fieldType: EWidgetType.Select,
        fieldProps: {
          options: [
            { label: '标准按钮', value: 'default' },
            { label: '危险按钮', value: 'danger' },
          ],
          allowClear: false,
        },
      },
      styleType: {
        label: '样式类型',
        defaultValue: buttonConfig?.styleType ?? 'link',
        fieldType: EWidgetType.Select,
        fieldProps: {
          options: [{ label: '文字按钮', value: 'link' }],
          allowClear: false,
        },
      },
      action: {
        label: '执行动作',
        defaultValue: buttonConfig?.action || DEFAULT_BUTTON_PROPERTIES.action,
        fieldType: EWidgetType.Select,
        fieldProps: {
          options: [
            { label: '打开页面', value: 'openPage' },
            { label: '调用接口', value: 'callAPI' },
          ],
          allowClear: false,
        },
      },
      pageSourceType: {
        label: '绑定页面',
        defaultValue: buttonConfig?.pageSourceType || EPageSourceType.BUILT_IN,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_SOURCE_TYPES,
        },
        whenHidden: (formData: any) => formData.action !== 'openPage',
      },
      pageOpenMode: {
        label: '交互方式',
        defaultValue: buttonConfig?.pageOpenMode || EPageOpenMode.DRAWER,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_OPEN_MODES,
        },
        whenHidden: (formData: any) =>
          formData.action !== 'openPage' ||
          (formData.action === 'openPage' && formData.pageSourceType !== EPageSourceType.EXTERNAL),
      },
      pageRenderDataType: {
        label: '页面类型',
        defaultValue: buttonConfig?.pageRenderDataType || EPageRenderDataType.REQUEST_DATA,
        fieldType: EWidgetType.Select,
        rules: [FCRules.Required('请选择')],
        fieldProps: {
          options: PAGE_RENDER_DATA_TYPES,
        },
        whenHidden: (formData: any) =>
          formData.action !== 'openPage' ||
          (formData.action === 'openPage' && formData.pageSourceType !== EPageSourceType.EXTERNAL),
      },
      page: {
        label: '内部页面',
        defaultValue: buttonConfig?.page,
        whenHidden: (formData: any) =>
          formData.action !== 'openPage' ||
          (formData.action === 'openPage' && formData.pageSourceType !== EPageSourceType.BUILT_IN),
        fieldType: EWidgetType.Select,
        fieldProps: {
          options: pageList
            .filter((page) => String(page.type) === EPageType.编辑页面)
            .map((page) => ({
              label: page.name,
              value: page.id,
            })),
        },
      },
      externalInfo: {
        label: '外部页面',
        fieldType: EWidgetType.CustomWidget,
        defaultValue: buttonConfig?.externalInfo ?? undefined,
        whenHidden(formData: any) {
          return (
            formData.action !== 'openPage' ||
            (formData.action === 'openPage' && formData.pageSourceType !== EPageSourceType.EXTERNAL)
          );
        },
        custom(props: any) {
          return (
            <PickExternalPage
              {...props}
              value={props?.value ? [props?.value] : undefined}
              getLabelByValue={(row) => {
                return row?.length ? row[0].name : '请选择外部页面';
              }}
              checkedList={[props?.value].filter((o) => Boolean(o))}
              onChange={(row) => {
                const info = row?.length ? row[0] : null;
                if (info) {
                  props?.onChange?.({
                    ...info,
                    id: info.id,
                    name: info.name,
                    url: info.url,
                    loadEvents: info.loadEvents,
                    messageEvents: info.messageEvents,
                  });
                }
              }}
            />
          );
        },
      },
      loadEvents: {
        defaultValue: buttonConfig?.loadEvents ?? [],
        fieldType: EWidgetType.CustomWidget,
        fieldProps: {},
        whenHidden(formData: any) {
          return (
            formData.action !== 'openPage' ||
            (formData.action !== 'openPage' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
            !Array.isArray(formData?.externalInfo?.loadEvents ?? null)
          );
        },
        custom(props: any) {
          const { value = [] } = props;
          const eventList = props?.formData?.externalInfo?.loadEvents ?? [];

          const safeValue: Record<string, any> = {};
          eventList.forEach(({ key }: { key: string }) => {
            safeValue[key] = value[key];
          });

          return (
            <ExternalPageEventsBind
              title="加载配置"
              value={safeValue}
              eventList={eventList}
              onChange={(data) => {
                props?.onChange(data);
              }}
            />
          );
        },
      },
      messageEvents: {
        defaultValue: buttonConfig?.messageEvents ?? [],
        fieldType: EWidgetType.CustomWidget,
        fieldProps: {},
        whenHidden(formData: any) {
          return (
            formData.action !== 'openPage' ||
            (formData.action !== 'openPage' &&
              formData.pageSourceType !== EPageSourceType.EXTERNAL) ||
            !Array.isArray(formData?.externalInfo?.messageEvents ?? null)
          );
        },
        custom(props: any) {
          const { value = [] } = props;
          const eventList = props?.formData?.externalInfo?.messageEvents ?? [];

          const safeValue: Record<string, any> = {};
          eventList.forEach(({ key }: { key: string }) => {
            safeValue[key] = value[key];
          });

          return (
            <ExternalPageEventsBind
              title="动作配置"
              value={safeValue}
              eventList={eventList}
              onChange={(data) => {
                props?.onChange(data);
              }}
            />
          );
        },
      },
      callMethod: {
        label: '动作参数',
        fieldType: EWidgetType.CustomWidget,
        defaultValue: buttonConfig?.callMethod ?? '',
        whenHidden: (formData: any) =>
          (formData.action !== 'openPage' && formData.action !== 'callAPI') ||
          formData.pageSourceType === EPageSourceType.EXTERNAL,
        custom(props: any) {
          const { formData } = props;
          if (formData.action === 'openPage') {
            const pageId = formData?.page;

            if (!pageId) {
              return '请先选择绑定页面';
            }

            return (
              <BindExistMethodInputParam
                {...props}
                targetPageId={pageId}
                outExistMethodIdMessage="编辑页面未绑定加载方法"
              />
            );
          }
          if (formData.action === 'callAPI') {
            return <BindMethodInputParam {...props} />;
          }
          return null;
        },
      },

      confirmMessage: {
        label: '二次确认',
        defaultValue: buttonConfig?.confirmMessage ?? '',
        fieldType: EWidgetType.TextArea,
        fieldProps: {},
      },
    };
  };
  const CollapseDatas = useMemo(() => {
    return buttons.map((buttonConfig: any, index: number) => ({
      title: (
        <Row justify="space-between">
          <span>{`按钮配置 - ${buttonConfig.text || ''}`}</span>
          <Popconfirm
            title="确定要删除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              buttons.splice(index, 1);
              onChange?.({
                ...record,
                buttons,
              });
            }}
          >
            <div style={{ width: 40 }}>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </Row>
      ),
      key: buttonConfig.id,
      configComponent: (
        <FormCreator
          submitter={{ render: () => null }}
          labelAlign="right"
          labelCol={{ flex: '100px' }}
          wrapperCol={{ flex: 1 }}
          style={{ overflow: 'scroll' }}
          debug
          layout="horizontal"
          onValuesChange={debounce((_, allValsChange) => {
            buttons[index] = { ...allValsChange };
            onChange?.({
              ...record,
              buttons,
            });
          }, 500)}
          config={{
            ...getCommonConfig(buttonConfig),
          }}
        />
      ),
    }));
  }, [record, buttons, onChange, getCommonConfig]);

  return (
    <div>
      <WithCollapse collapseDatas={CollapseDatas ?? []} defaultOpenFirstOne={false} />
      <Button
        type="link"
        onClick={() => {
          onChange({
            ...record,
            buttons: [...buttons, { id: nanoid(), text: '按钮' }],
          });
        }}
        icon={<PlusCircleOutlined />}
      >
        添加按钮
      </Button>
    </div>
  );
}
