import { FieldCmpType, EPageLayoutCmpType, EDataDisplayCmpType } from '@/constant';

// 展示
import InputView from '../Controls/Input';
import CheckboxGroupView from '../Controls/CheckboxGroup';
import InputNumberView from '../Controls/InputNumber';
import RadioGroupView from '../Controls/RadioGroup';
import SelectView from '../Controls/Select';
import TextAreaView from '../Controls/TextArea';
import SwitchView from '../Controls/Switch';
import DatePickerView from '../Controls/DatePicker';
import UploadView from '../Controls/Upload';
import MultiUploadView from '../Controls/MultiUpload';
import ExcelFileUploadView from '../Controls/ExcelFileUpload';
import TableUploadView from '../Controls/TableUpload';
import VieoUploadView from '../Controls/VieoUpload';
import RichTextView from '../Controls/RichText';
import TitleView from '../Controls/Title';
import SwiperView from '../Controls/Swiper';
import ImageView from '../Controls/ImageView';
import EnumView from '../Controls/EnumView';
import VideoView from '../Controls/VideoView';
import SingleDatePicker from '../Controls/SingleDatePicker';
import RangePicker from '../Controls/RangePicker';
import ListSelector from '../Controls/ListSelector';
import InputFillControl from '../Controls/InputFill';

// 配置
import InputConfig from '../ConfigComponent/InputConfig';
import InputNumberConfig from '../ConfigComponent/InputNumberConfig';
import SelectConfig from '../ConfigComponent/SelectConfig';
import RadioCheckboxConfig from '../ConfigComponent/RadioCheckboxConfig';
import SwitchConfig from '../ConfigComponent/SwitchConfig';
import RichTextConfig from '../ConfigComponent/RichTextConfig';
import SingleImageUploadConfig from '../ConfigComponent/SingleImageUploadConfig';
import MultiImageUploadConfig from '../ConfigComponent/MultiImageUploadConfig';
import VideoUploadConfig from '../ConfigComponent/VideoUploadConfig';
import SingleDatePickerConfig from '../ConfigComponent/SingleDatePickerConfig';
import RangePickerConfig from '../ConfigComponent/RangePickerConfig';
// import SwiperConfig from '../ConfigComponent/SwiperConfig'
import PureTextConfig from '../ConfigComponent/PureTextConfig';
import EnumsConfig from '../ConfigComponent/EnumsConfig';
import ViewImageConfig from '../ConfigComponent/ViewImageConfig';
import ViewTitleConfig from '../ConfigComponent/ViewTitleConfig';
import ViewSwiperConfig from '../ConfigComponent/ViewSwiperConfig';
import ViewVideoConfig from '../ConfigComponent/ViewVideoConfig';
import ListSelectorConfig from '../ConfigComponent/ListSelectorConfig';
import SingleFileUploadConfig from '../ConfigComponent/SingleFileUploadConfig';
import ExcelFileUploadConfig from '../ConfigComponent/ExcelFileUploadConfig';
import TableUploadConfig from '../ConfigComponent/TableUploadConfig';
import InputFillConfig from '../ConfigComponent/InputFillConfig';
//
import PureText from '@/components/PageParser/Editor/View/PureText';
import ListCheckboxConfig from '../ConfigComponent/ListCheckboxConfig';
import ListCheckbox from '../Controls/ListCheckbox';
import MultiValueTextConfig from '../ConfigComponent/MultiValueTextConfig';

// 根据组件类型挑选展示组件
export const findCmpViewByType = (controlType: string) => {
  let DisplayComponent: any;
  switch (controlType as string) {
    case FieldCmpType.输入框:
      DisplayComponent = InputView;
      break;
    case FieldCmpType.联动输入:
      DisplayComponent = InputFillControl;
      break;
    case FieldCmpType.下拉框:
    case FieldCmpType.下拉多选框:
      DisplayComponent = SelectView;
      break;
    case FieldCmpType.数字输入框:
      DisplayComponent = InputNumberView;
      break;
    case FieldCmpType.多行文本框:
      DisplayComponent = TextAreaView;
      break;
    case FieldCmpType.复选框:
      DisplayComponent = CheckboxGroupView;
      break;
    case FieldCmpType.单选框:
      DisplayComponent = RadioGroupView;
      break;
    case FieldCmpType.弹窗选择:
      DisplayComponent = ListSelector;
      break;
    case FieldCmpType.弹窗多选:
      DisplayComponent = ListCheckbox;
      break;
    case FieldCmpType.开关:
      DisplayComponent = SwitchView;
      break;
    case FieldCmpType.日期选择器:
      DisplayComponent = DatePickerView;
      break;
    case FieldCmpType.单图上传:
      DisplayComponent = UploadView;
      break;
    case FieldCmpType.表格文件上传:
      DisplayComponent = ExcelFileUploadView;
      break;
    case FieldCmpType.列表:
      DisplayComponent = TableUploadView;
      break;
    case FieldCmpType.多图上传:
      DisplayComponent = MultiUploadView;
      break;
    case FieldCmpType.视频上传:
      DisplayComponent = VieoUploadView;
      break;
    case FieldCmpType.富文本:
      DisplayComponent = RichTextView;
      break;
    case FieldCmpType.日期时间:
      DisplayComponent = SingleDatePicker;
      break;
    case FieldCmpType.日期区间:
      DisplayComponent = RangePicker;
      break;
    case FieldCmpType.多值文本:
      DisplayComponent = TextAreaView;
      break;
    case EPageLayoutCmpType.标题:
      // info.isFormItemControl = false
      DisplayComponent = TitleView;
      break;
    case EDataDisplayCmpType.轮播图:
      DisplayComponent = SwiperView;
      break;
    case EDataDisplayCmpType.图片:
      DisplayComponent = ImageView;
      break;
    case EDataDisplayCmpType.文本:
      DisplayComponent = PureText;
      break;
    case EDataDisplayCmpType.枚举:
      DisplayComponent = EnumView;
      break;
    case EDataDisplayCmpType.视频:
      DisplayComponent = VideoView;
      break;
    default:
      DisplayComponent = null;
  }
  return (props: any = {}) => {
    if (!DisplayComponent) return null;
    return <DisplayComponent {...props} />;
  };
};

// 根据组件类型挑选配置组件
export const findCmpConfigViewByType = (controlType: string) => {
  let DisplayConfigCom: any;
  switch (controlType as string) {
    case FieldCmpType.输入框:
      DisplayConfigCom = InputConfig;
      break;
    case FieldCmpType.联动输入:
      DisplayConfigCom = InputFillConfig;
      break;
    case FieldCmpType.数字输入框:
      DisplayConfigCom = InputNumberConfig;
      break;
    case FieldCmpType.下拉框:
    case FieldCmpType.下拉多选框:
      DisplayConfigCom = SelectConfig;
      break;
    case FieldCmpType.复选框:
    case FieldCmpType.单选框:
      DisplayConfigCom = RadioCheckboxConfig;
      break;
    case FieldCmpType.弹窗选择:
      DisplayConfigCom = ListSelectorConfig;
      break;
    case FieldCmpType.弹窗多选:
      DisplayConfigCom = ListCheckboxConfig;
      break;
    case FieldCmpType.多行文本框:
      DisplayConfigCom = SelectConfig;
      break;
    case FieldCmpType.开关:
      DisplayConfigCom = SwitchConfig;
      break;
    case FieldCmpType.视频上传:
      DisplayConfigCom = VideoUploadConfig;
      break;
    case FieldCmpType.单图上传:
      DisplayConfigCom = SingleImageUploadConfig;
      break;
    case FieldCmpType.多图上传:
      DisplayConfigCom = MultiImageUploadConfig;
      break;
    case FieldCmpType.富文本:
      DisplayConfigCom = RichTextConfig;
      break;
    case FieldCmpType.日期时间:
      DisplayConfigCom = SingleDatePickerConfig;
      break;
    case FieldCmpType.日期区间:
      DisplayConfigCom = RangePickerConfig;
      break;
    case FieldCmpType.单文件上传:
      DisplayConfigCom = SingleFileUploadConfig;
      break;
    case FieldCmpType.表格文件上传:
      DisplayConfigCom = ExcelFileUploadConfig;
      break;
    case FieldCmpType.多值文本:
      DisplayConfigCom = MultiValueTextConfig;
      break;
    case FieldCmpType.列表:
      DisplayConfigCom = TableUploadConfig;
      break;
    // case EPageLayoutCmpType.标题:
    //   DisplayConfigCom = TitleConfig
    //   break;
    case EPageLayoutCmpType.标题:
      DisplayConfigCom = ViewTitleConfig;
      break;
    case EDataDisplayCmpType.轮播图:
      DisplayConfigCom = ViewSwiperConfig;
      break;
    case EDataDisplayCmpType.图片:
      DisplayConfigCom = ViewImageConfig;
      break;
    case EDataDisplayCmpType.文本:
    case EDataDisplayCmpType.HTML文本:
      DisplayConfigCom = PureTextConfig;
      break;
    case EDataDisplayCmpType.枚举:
      DisplayConfigCom = EnumsConfig;
      break;
    case EDataDisplayCmpType.视频:
      DisplayConfigCom = ViewVideoConfig;
      break;
    default:
      break;
  }
  if (!DisplayConfigCom) return null;
  return DisplayConfigCom;
};
