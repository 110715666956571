import { useEffect, useCallback, useState } from 'react';
import { executeMethod } from '@/services/method';
import useMethodDetail from '@/hooks/useMethodDetail';
import { message } from 'antd';
import { eachTree, log } from '@/utils/utils';
import useOtherPageInfo from '@/hooks/useOtherPageInfo';
import useCurPageStore from './useCurPageStore';
import { EModeType } from '@/store/form-generator-parse';
import { parseString2Json } from '@/components/FormGeneractor/help';

import _ from 'lodash';
import {
  validateRequest,
  // isRequestSuccess,
  makeTargetMethodParamValues,
  ensureEachExecuteMethodParamValue,
} from './helper';
import { EPageType } from '@/constant';

const inputParamsHashMap: any = {};
const outputParamsHashMap: any = {};

// 调用详情方法
const useHandleDetail = (pageModeId: string) => {
  const [loading, setLoading] = useState(false);
  const { load: loadMethod } = useMethodDetail();
  const { curPageStore, store } = useCurPageStore(pageModeId);
  const { load: loadConfig } = useOtherPageInfo();
  const { uAddEditViewConfigInfo } = store;
  const {
    rowInfo,
    listOutputParamsMap,
    configForDetailInit,
    currentDrawerServicePage,
    pageList,
    editorVisible,
    addEditViewConfigInfo,
    mode,
  } = curPageStore || {};
  const callMethod = configForDetailInit?.callMethod;

  // const detailHashMap = configForDetailInit?.detailHashMap;

  const doHandle = useCallback(
    async (methodId: string) => {
      try {
        setLoading(true);
        const method = await loadMethod(methodId);
        // log('详情方法的配置', method);
        // 入参建立 hash
        eachTree(
          method?.inputParams || [],
          (param) => {
            inputParamsHashMap[param.id] = param;
          },
          0,
          'fields',
        );
        // 出参建立 hash
        eachTree(
          method?.outputParams || [],
          (param) => {
            outputParamsHashMap[param.id] = param;
          },
          0,
          'fields',
        );
        // log('详情方法入参数 hash', inputParamsHashMap);
        // log('详情方法出参数 hash', outputParamsHashMap);

        // 调用接口
        const executeRes = await executeMethod({
          methodId,
          // 构造参数
          paramValues: await makeTargetMethodParamValues(
            // callMethod 配置信息
            _.omit(callMethod, ['methodId']),
            // 行数据
            rowInfo,
            // 列表方法出参 map
            listOutputParamsMap,
            // 详情方法入参 map
            inputParamsHashMap,
          ),
        });
        if (executeRes?.rCode !== 0) {
          return message.error(executeRes.msg);
        }
        const responseData = executeRes.data;
        const properties: any = parseString2Json(addEditViewConfigInfo?.properties ?? '{}');

        const res = await validateRequest({
          responseData,
          methodsConfig: properties?.methodsValidationConfig?.loadMethods,
        });

        if (res.isSuccess) {
          // const detailResponsee = res.dataTarget?.objectValue;
          log('详情方法执行....', outputParamsHashMap, res.dataTarget);
          // 把详情数据转化为 hash
          const detailHashMap = ensureEachExecuteMethodParamValue(
            res.dataTarget,
            outputParamsHashMap,
          );
          // log('详情 hash 映射', detailHashMap);
          // 存储
          store.uConfigForDetailInit(pageModeId, {
            detailHashMap,
            outputParamsHashMap,
            inputParamsHashMap,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [rowInfo, callMethod, listOutputParamsMap, pageModeId, addEditViewConfigInfo],
  );

  // 触发查询详情接口
  useEffect(() => {
    const methodId = addEditViewConfigInfo?.loadMethodId;
    if (
      !methodId ||
      ![EPageType.查看页面, EPageType.编辑页面].includes(
        String(currentDrawerServicePage?.type || '') as EPageType,
      ) ||
      !editorVisible ||
      methodId === '0'
    ) {
      return;
    }
    if (mode === EModeType.production) {
      doHandle(methodId);
    }
  }, [addEditViewConfigInfo, currentDrawerServicePage, editorVisible, mode]);

  // 加载配置信息
  useEffect(() => {
    const pageConfig = currentDrawerServicePage;
    if (!pageConfig?.id || !editorVisible) return;
    loadConfig(pageConfig.id, mode === EModeType.production).then((res) => {
      uAddEditViewConfigInfo(pageModeId, res);
    });
  }, [currentDrawerServicePage, mode, pageList, pageModeId, editorVisible]);

  return [loading];
};

export default useHandleDetail;
