import create from 'zustand';
import deepmerge from 'deepmerge';
import type { AddEditDetailCommonStoreType } from './types';
import { nanoid } from 'nanoid';
import { FieldCmpType } from '@/constant';

type StoreType = AddEditDetailCommonStoreType;

const Store = create<StoreType>((set) => ({
  hasChange: false, // 标记是否修改过
  uHasChange: (hasChange: boolean) =>
    set(() => ({
      hasChange,
    })),

  ready: false,
  uReady: (bool) => set(() => ({ ready: bool })),

  title: '',
  uTitle: (title) => set((state) => ({ hasChange: !!state.ready, title })),

  bindedMethods: {},
  uBindedMethods: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      bindedMethods: deepmerge(state.bindedMethods, val, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    })),

  addMethod: null,
  uAddMethod: (addMethod: any) => set((state) => ({ hasChange: !!state.ready, addMethod })),

  labelColWidth: 200,
  uLabelColWidth: (labelColWidth) => set((state) => ({ hasChange: !!state.ready, labelColWidth })),

  wrapperColWidth: undefined,
  uWrapperColWidth: (wrapperColWidth) =>
    set((state) => ({ hasChange: !!state.ready, wrapperColWidth })),

  cancelButtonConfig: {
    text: '取消',
    shapeType: 'default',
    styleType: 'default',
  },
  uCancelButtonConfig: (cancelButtonConfig) =>
    set((state) => ({ hasChange: !!state.ready, cancelButtonConfig })),

  submitButtonConfig: {
    text: '保存',
    styleType: 'default',
    shapeType: 'default',
    callFunction: 'save',
  },
  uSubmitButtonConfig: (submitButtonConfig) =>
    set((state) => ({ hasChange: !!state.ready, submitButtonConfig })),

  fields: [
    {
      controlType: FieldCmpType.输入框,
      controlId: `${nanoid()}`,
      label: '游戏名称',
      required: true,
      used: true,
    },
  ],
  uFields: (fields) => set((state) => ({ hasChange: !!state.ready, fields })),

  serviceConfig: null,
  uServiceConfig: (serviceConfig: any) =>
    set((state) => ({ hasChange: !!state.ready, serviceConfig })),

  methodsValidationConfig: {},
  uMethodsValidationConfig: (methodsValidationConfig) =>
    set((state) => ({ hasChange: !!state.ready, methodsValidationConfig })),

  reset: () =>
    set(() => ({
      hasChange: false,
      fields: [],
      serviceConfig: null,
      bindedMethods: {},
      // methodsValidationConfig:{},
      submitButtonConfig: {
        text: '保存',
        styleType: 'default',
        shapeType: 'default',
        callFunction: 'save',
      },
      cancelButtonConfig: {
        text: '取消',
        shapeType: 'default',
        styleType: 'default',
      },
      wrapperColWidth: undefined,
      labelColWidth: 200,
      title: '新建表单',
      ready: false,
    })),
}));

export default Store;
