import React, { memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextProps {
  value?: any;
  onChange?: (value: any) => void;
}

const RichText = memo<RichTextProps>(({ value, onChange }) => {
  const Component: any = ReactQuill;
  return (
    <>
      <Component
        value={value || ''}
        onChange={(input: any) => {
          onChange?.(input || '');
        }}
      />
    </>
  );
});

export default RichText;
