import { memo, useCallback, useState } from 'react';
import type { FC } from 'react';
import { Card, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ConfigEditor from './ConfigEditor';

import Style from './index.module.less';

const getDefaultConfig = () => ({
  _id: `${Math.random()}`,
  key: '',
  name: '',
  inputProperties: '',
  outputProperties: '',
  orderNum: 0,
});

interface Props {
  title: string;
  value: any[];
  name: string;
  onChange?: (param: any) => any;
}
const MultiConfig: FC<Props> = memo(({ title = '', value, onChange, name }) => {
  const [dataList, setDataList] = useState<any[] | undefined>(value);

  if (typeof dataList === 'undefined' && typeof value !== 'undefined') {
    setDataList(value);
  }

  const onDataChange = useCallback(
    (data) => {
      onChange?.(data);
    },
    [onChange],
  );

  // add one config data to dataList
  const addConfig = useCallback(() => {
    if (typeof dataList === 'undefined') {
      const newList = [getDefaultConfig()];
      setDataList(newList);
      onDataChange?.(newList);

      return;
    }

    const newList = [...value, getDefaultConfig()];
    setDataList(newList);
    onDataChange?.(newList);
  }, [dataList, onDataChange, value]);

  // delete one config data
  const deleteConfig = useCallback(
    (index) => {
      if (typeof dataList === 'undefined') {
        return;
      }

      const newList = [...value];
      newList.splice(index, 1);
      setDataList(newList);
      onDataChange?.(newList);
    },
    [dataList, onDataChange, value],
  );

  return (
    <>
      <div className={Style.buttonWrap}>
        <Button type="primary" onClick={addConfig}>
          点击添加
        </Button>
      </div>
      <div className={Style.list}>
        {dataList &&
          dataList.map((currentData, index) => {
            const { _id } = currentData;
            return (
              <Card
                key={`${_id}-${index}`}
                title={title}
                style={{ width: 600 }}
                extra={
                  <DeleteOutlined
                    style={{ color: '#ff4d4f' }}
                    onClick={() => deleteConfig(index)}
                  />
                }
              >
                <ConfigEditor index={index} rootName={name} />
              </Card>
            );
          })}
      </div>
    </>
  );
});

export default MultiConfig;
