import { memo } from 'react';
import { EModeType } from '@/store/form-generator-parse';

interface ImageViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const ImageView = memo<ImageViewProps>(({ configInfo }) => {
  let finalValue =
    Array.isArray(configInfo?.finalValue) && configInfo?.finalValue?.length
      ? configInfo.finalValue[0]
      : configInfo.finalValue;
  if (configInfo.mode === EModeType.preview) {
    finalValue = 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4';
  }
  return (
    <>
      {finalValue ? (
        <video
          width={400}
          controls
          // poster={o.videoPoster}
        >
          <source src={finalValue} type="video/mp4" />
        </video>
      ) : (
        '-'
      )}
    </>
  );
});

export default ImageView;
