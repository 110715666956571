import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonStaticConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { EDataDisplayCmpType } from '@/constant';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function PureTextConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const textConfig =
    fieldInfo.controlType === EDataDisplayCmpType.文本
      ? {
          textExpression: {
            label: '展示转换',
            defaultValue: fieldInfo?.textExpression || '',
            fieldType: EWidgetType.TextArea,
            extra: 'JS 代码表达式',
          },
        }
      : {};

  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            ...textConfig,
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];
  return <WithCollapse collapseDatas={CollapseDatas} />;
}
