import { useState } from 'react';
import { message } from 'antd';
import { pageGroupDetail } from '@/services/pageGroup';

export default function usePageModelInfo(needErrToast = true) {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const load = async (id: string): Promise<any | null> => {
    try {
      if (loading) {
        return null;
      }
      setLoading(true);
      const res = await pageGroupDetail(id);
      if (res.rCode === 0) {
        setData(res.data);
        return res.data;
      } else {
        setErrMsg(res.msg || '加载数据失败');
        if (needErrToast) {
          message.error(res.msg || '加载数据失败');
        }
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    load,
    errMsg,
  };
}
