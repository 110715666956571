import { memo } from 'react';
import { Switch } from 'antd';
import pick from 'lodash/pick';

interface SwitchControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const SwitchControl = memo<SwitchControlProps>(({ onChange, value, configInfo }) => {
  return (
    <Switch
      {...pick(configInfo, ['disabled', 'placeholder'])}
      checked={value || configInfo.defaultValue}
      defaultChecked={configInfo.defaultValue}
      onChange={onChange}
    />
  );
});

export default SwitchControl;
