import React, { useState } from 'react';
import { methodPageGroupIds } from '@/services/method';
import { Button, Popover, List, Spin } from 'antd';
import { PageGroup } from '@/types/index';

interface Props {
  id: string;
}

const SelectRender: React.FC<Props> = (props) => {
  const [finish, setFinish] = useState(true);
  const [loading, setLoading] = useState(false);
  const [single, setSingle] = useState(true);
  const [data, setData] = useState<PageGroup[]>([]);
  const [params, setParams] = useState({
    id: props.id,
    pageSize: 20,
    pageNumber: 1,
  });
  const getList = async () => {
    const res = await methodPageGroupIds(params);

    setData([...data, ...(res.data?.list as PageGroup[])]);

    if (res.data?.totalPage !== undefined) {
      setFinish(res.data?.totalPage <= params.pageNumber);
    }
    setLoading(false);
  };

  const onLoadMore = () => {
    setLoading(true);
    const newParams = params;
    newParams.pageNumber += 1;
    setParams(newParams);
    getList();
  };

  const loadDiv = !loading ? (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 24,
        lineHeight: '24px',
      }}
    >
      <Button onClick={onLoadMore}>加载更多</Button>
    </div>
  ) : (
    <Spin size="small" />
  );
  const loadMore = finish ? null : loadDiv;

  const content = (
    <List
      style={{ width: '120px', height: '240px', overflowY: 'auto' }}
      dataSource={data}
      // style={{ width: '120px', height: '240px' }}
      loadMore={loadMore}
      renderItem={(item) => <List.Item>{item.name}</List.Item>}
    />
  );

  return (
    <Popover content={content} trigger="click">
      <Button
        onClick={async () => {
          if (single) {
            getList();
            setSingle(!single);
          }
        }}
      >
        查看页面组
      </Button>
    </Popover>
  );
};
export default SelectRender;
