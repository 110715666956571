import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { Table, Popconfirm, Row, Button, Switch } from 'antd';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DeleteIconButton from '@/components/DeleteIconButton';
import { findTree } from '@/utils/utils';
import _, { debounce } from 'lodash';
import Text from '@/components/Column/Text';
import { dragComponents } from '@/components/SortableTable/dragable';
import useFormGeneratorStore from '@/store/form-generator-list';
import update from 'immutability-helper';
import ConfigItem from './ConfigItem';
import WithCollapse from '../../WithCollapse';
import BindParam, { getDefaultDataSource } from '../common/BindParam';
import ConfigToolbarButton from './ConfigToolbarButton';
import { nanoid } from 'nanoid';
import { DEFAULT_CONFIIG } from '../../ListContent/Table';
import { getDefaultOperatorField } from '../../help';

function useAutoInitListTable(fgStore: any) {
  useEffect(() => {
    // 必须等到页面数据准备完成后，再进行判断是否需要自动初始化
    if (fgStore.ready == false) {
      return;
    }

    // 获取操作列的配置
    let operatorField = _.last<any>(fgStore.listTable?.fields ?? []);
    if (!operatorField || !operatorField.fixed) {
      operatorField = getDefaultOperatorField();
    }

    const bindParamDefaultValue = fgStore.listTable?.bindParam?.length
      ? fgStore.listTable?.bindParam
      : getDefaultDataSource({
          loadMethod: _.pick(fgStore?.bindedMethods?.loadMethod, ['id', 'name']),
          loadMethodParamType: 'outputParams',
        });

    // 初始化时，没有选中方法，则帮助默认选中
    // 先找出加载方法
    const loadMethodInfo = bindParamDefaultValue.find((item: any) => item.type === 'loadMethod');
    if (typeof loadMethodInfo === 'undefined') {
      return;
    }

    // 查看bindVar的设置，如果fieldId为空，证明还没有绑定，可以自动绑定
    const fieldId = loadMethodInfo?.bindVar?.fieldId ?? '';
    if (fieldId.length > 0) {
      const fieldName = loadMethodInfo?.bindVar?.fieldName ?? '';
      // 已绑定字段，旧数据没有childKeys，需要兼容
      // 拿到当前绑定的加载方法的信息
      const outputParams = fgStore?.bindedMethods?.loadMethod?.outputParams ?? [];

      if (outputParams.length < 1) {
        return;
      }

      for (let i = 0; i < outputParams.length; i++) {
        const treeNode = outputParams[i];
        // 找出一级节点是object类型（Object类型，默认为）
        if (!['Object', 'List'].includes(treeNode.dataType)) {
          continue;
        }
        // 拿出二级节点集合，找出列表数据
        const { fields } = treeNode;

        let nodeInfo: any;
        if (treeNode.dataType === 'List') {
          nodeInfo = treeNode;
        } else {
          nodeInfo = fields.find((item: any) => item.dataType === 'List');
        }

        if (typeof nodeInfo === 'undefined' || nodeInfo.path !== fieldName) {
          return;
        }

        const childKeys: any[] = []; // List类型，透传其Item的字段
        nodeInfo.fields.forEach(({ path, id, title, dataType }: any) => {
          childKeys.push({
            key: path,
            title,
            id,
            dataType,
          });
        });

        loadMethodInfo.bindVar = {
          ...loadMethodInfo.bindVar,
          childKeys,
        };
      }

      return;
    }
    // 拿到当前绑定的加载方法的信息
    const outputParams = fgStore?.bindedMethods?.loadMethod?.outputParams ?? [];

    if (outputParams.length < 1) {
      return;
    }

    for (let i = 0; i < outputParams.length; i++) {
      const treeNode = outputParams[i];
      // 找出一级节点是object类型（Object类型，默认为）
      if (!['Object', 'List'].includes(treeNode.dataType)) {
        continue;
      }
      // 拿出二级节点集合，找出列表数据
      const { fields } = treeNode;
      let nodeInfo: any;
      if (treeNode.dataType === 'List') {
        nodeInfo = treeNode;
      } else {
        nodeInfo = fields.find((item: any) => item.dataType === 'List');
      }

      if (typeof nodeInfo === 'undefined') {
        return;
      }

      const childKeys: any[] = []; // List类型，透传其Item的字段
      nodeInfo.fields.forEach(({ path, id, title, dataType }: any) => {
        childKeys.push({
          key: path,
          title,
          id,
          dataType,
        });
      });
      // 组织新的 bindParam
      const newBindVar = {
        fieldId: nodeInfo.id,
        fieldName: nodeInfo.path,
        childKeys,
      };
      const newBindParam = [...bindParamDefaultValue];
      newBindParam.forEach((item) => {
        if (item.type === 'loadMethod') {
          item.bindVar = { ...newBindVar };
        }
      });

      // 组织新的列表字段
      const newFields = nodeInfo.fields.map((o: any) => ({
        // 默认绑定字段
        bindParam: [
          {
            bindMethod: _.pick(fgStore?.bindedMethods?.loadMethod, ['id', 'name']),
            type: 'loadMethod',
            bindVar: {
              fieldName: `${nodeInfo.path}.${o.name}`,
              fieldId: `${o.id}`,
            },
            methodParamType: 'outputParams',
          },
        ],
        title: o.title || o.name,
        dataIndex: o.name,
        displayType: 'text',
        used: true,
      }));

      // 更新数据
      fgStore.uListTable({
        ...fgStore.listTable,
        bindParam: newBindParam,
        fields: [...newFields, operatorField],
      });
      break;
    }
  }, [fgStore]);
}

interface PageProps {
  onChange?: (list: any[]) => void;
}

const Page = memo<PageProps>(() => {
  const [expandedRows, setExpandedRows] = useState<readonly React.Key[]>([]);
  // const [selectOne, setSelectOne] = useState<any>(null)
  const fgStore = useFormGeneratorStore((state) => state);
  const { fields, toolbarButtons } = fgStore.listTable;
  const list = fields;
  const { ready } = fgStore;

  useAutoInitListTable(fgStore);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!list?.length) return;
      const dragRow = list[dragIndex];
      const newFields = update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      fgStore.uListTable({
        fields: [...newFields],
      });
    },
    [list, fgStore.uListTable],
  );

  const columns: any[] = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 50,
      render: () => <MenuOutlined />,
    },
    {
      title: '列表项',
      dataIndex: 'title',
      render: (text: any, record: any) => <Text value={`${record.title} `} isOmitted />,
    },
    {
      fixed: 'right',
      title: '操作',
      width: 60,
      render(text: any, record: any, index: number) {
        if (record?.fixed) {
          return (
            <div style={{ fontSize: 10 }}>
              {record.used ? '启用' : '不启用'}
              <Switch
                size="small"
                checked={!!record.used}
                onChange={(bool) => {
                  list.forEach((o: any) => {
                    if (o.fixed === 'right') {
                      o.used = bool;
                    }
                  });
                  fgStore.uListTable({
                    fields: [...list],
                  });
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <Popconfirm
                title="确定移除？"
                cancelText="取消"
                okText="确定"
                onConfirm={() => {
                  list.splice(index, 1);
                  fgStore.uListTable({
                    fields: [...list],
                  });
                }}
              >
                <div>
                  <DeleteIconButton />
                </div>
              </Popconfirm>
            </>
          );
        }
      },
    },
  ];

  const CollapseDatas = useMemo(() => {
    const bindParamDefaultValue = fgStore.listTable?.bindParam?.length
      ? fgStore.listTable?.bindParam
      : getDefaultDataSource({
          loadMethod: _.pick(fgStore?.bindedMethods?.loadMethod, ['id', 'name']),
          loadMethodParamType: 'outputParams',
        });

    return [
      {
        title: '基础配置',
        key: 'base-config',
        configComponent: (
          <FormCreator
            submitter={{ render: () => null }}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            debug
            layout="horizontal"
            onValuesChange={debounce((__, allValsChange) => {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              let fields: any[] = [];
              const bindVarObj =
                allValsChange?.bindParam.find((o: any) => o.type === 'loadMethod')?.bindVar || {};
              if (bindVarObj) {
                const bindVar = bindVarObj.fieldName;
                // 找到绑定字段下面的字段
                const findU = findTree(
                  fgStore?.bindedMethods?.loadMethod?.outputParams,
                  (item, key, level, paths) => {
                    return [...paths.map((path) => path.name), item.name].join('.') === bindVar;
                  },
                  'fields',
                );

                fields = (findU?.fields || []).map((o: any) => ({
                  // 默认绑定字段
                  bindParam: [
                    {
                      bindMethod: _.pick(fgStore?.bindedMethods?.loadMethod, ['id', 'name']),
                      type: 'loadMethod',
                      bindVar: {
                        fieldName: `${bindVar}.${o.name}`,
                        fieldId: `${o.id}`,
                      },
                      methodParamType: 'outputParams',
                    },
                  ],
                  title: o.title || o.name,
                  dataIndex: o.name,
                  displayType: 'text',
                  used: true,
                }));
              }

              let operatorField = _.last<any>(fgStore.listTable?.fields ?? []);
              if (!operatorField || !operatorField.fixed) {
                operatorField = getDefaultOperatorField();
              }
              fgStore.uListTable({
                ...fgStore.listTable,
                ...allValsChange,
                fields: [...fields, operatorField],
              });
            }, 500)}
            config={{
              bindParam: {
                label: '绑定参数',
                tooltip: 'table字段来源字段。 例如，绑定 data.list，会从 data.list 中读取字段',
                fieldType: EWidgetType.CustomWidget,
                defaultValue: bindParamDefaultValue,
                custom: (props: any) => (
                  <BindParam {...props} isTableList value={bindParamDefaultValue} />
                ),
              },
            }}
          />
        ),
      },
      ...toolbarButtons.map((buttonConfig: any) => {
        return {
          title: (
            <Row justify="space-between">
              <span>{`按钮配置 - ${buttonConfig?.properties?.text || ''}`}</span>
              <Popconfirm
                title="确定要删除？"
                cancelText="取消"
                okText="确定"
                onConfirm={() => {
                  const index = toolbarButtons.findIndex(
                    (item: any) => buttonConfig?.frontComponentId === item.frontComponentId,
                  );
                  toolbarButtons.splice(index, 1);
                  fgStore.uListTable({
                    toolbarButtons,
                  });
                }}
              >
                <div style={{ width: 40 }}>
                  <DeleteIconButton />
                </div>
              </Popconfirm>
            </Row>
          ),
          key: buttonConfig.frontComponentId,
          configComponent: (
            <ConfigToolbarButton
              bindParam={bindParamDefaultValue}
              properties={buttonConfig.properties}
              onChange={(values) => {
                fgStore.uListTableToolbarButton({
                  ...buttonConfig,
                  properties: values,
                });
              }}
            />
          ),
        };
      }),
    ];
  }, [fgStore, toolbarButtons]);

  if (!ready) {
    // 数据末准备好，则不能渲染表单，否则初始化的表单是有误的
    return null;
  }

  return (
    <>
      <WithCollapse collapseDatas={CollapseDatas} expandAll />
      <Row>
        <Button
          type="link"
          onClick={() => {
            const buttonConfig = {
              frontComponentCode: 'add_button',
              frontComponentId: nanoid(),
              properties: { ...DEFAULT_CONFIIG.addButton },
              orderNum: toolbarButtons.length + 1,
            };
            fgStore.uListTable({
              toolbarButtons: [...toolbarButtons, buttonConfig],
            });
          }}
          icon={<PlusCircleOutlined />}
        >
          添加按钮
        </Button>
      </Row>
      <Row>
        <Button
          type="link"
          onClick={() => {
            const item = {
              title: '未命名',
              dataIndex: String(Date.now()),
              displayType: 'text',
              used: true,
            };
            fgStore.uListTable({
              fields: [item, ...fields],
            });
          }}
          icon={<PlusCircleOutlined />}
        >
          添加列
        </Button>
      </Row>
      <div style={{ overflow: 'auto' }}>
        <Table<any>
          size="small"
          bordered={false}
          components={expandedRows?.length ? {} : { ...dragComponents() }}
          onRow={(record, index) => ({ index, moveRow } as any)}
          pagination={false}
          showHeader={false}
          rowKey={(record) => `${record.dataIndex}}`}
          columns={columns}
          dataSource={list}
          expandable={{
            onExpandedRowsChange(expandedKeys) {
              setExpandedRows(expandedKeys);
            },
            expandedRowRender: (record, index) => (
              <ConfigItem
                record={record}
                index={index}
                onChange={(vals) => {
                  list[index] = vals;
                  fgStore.uListTable({
                    fields: [...list],
                  });
                }}
              />
            ),
          }}
        />
      </div>
    </>
  );
});

export default Page;
