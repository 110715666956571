import { memo, useCallback } from 'react';
import type { FC } from 'react';
import { Table } from 'antd';
import Text from '@/components/Column/Text';
import Config from './Config';

interface Props {
  title: string;
  value: undefined | Record<string, any>;
  eventList: any[];
  onChange?: (data: any) => void;
}

// edit loadEvents bind info
const ExternalPageEventsBind: FC<Props> = memo(({ title = '配置', value, eventList, onChange }) => {
  const loadEvents: any[] = eventList;
  const dataSource: any[] = [];
  loadEvents.forEach(({ key, name }) => {
    dataSource.push({
      key,
      title: name,
      dataIndex: key,
    });
  });
  const getExternalPageInAndOutParams = useCallback(
    (key: string) => eventList.find((item) => item.key === key) ?? [],
    [eventList],
  );

  return (
    <Table
      size="small"
      columns={[
        {
          title,
          dataIndex: 'title',
          render: (text: any, _value: any) => <Text value={`${_value.title} `} />,
        },
      ]}
      pagination={false}
      dataSource={dataSource}
      expandable={{
        expandedRowRender(_value) {
          const { dataIndex } = _value;
          return (
            <Config
              externalPageInAndOutParams={getExternalPageInAndOutParams(dataIndex)}
              value={value?.[dataIndex]}
              onChange={(values) => {
                onChange?.({
                  ...value,
                  [dataIndex]: {
                    ...values,
                  },
                });
              }}
            />
          );
        },
      }}
    />
  );
});

export default ExternalPageEventsBind;
