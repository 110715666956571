import React, { memo, useEffect, useState } from 'react';
import { Cascader } from 'antd';

import 'antd/lib/cascader/style/css';

export enum EFieldType {
  String = 'String',
  Boolean = 'Boolean',
  Byte = 'Byte',
  Short = 'Short',
  Integer = 'Integer',
  Long = 'Long',
  Object = 'Object',
  Double = 'Double',
  List = 'List',
  Float = 'Float',
  // Char = 'Char',
  // BigDecimal = 'BigDecimal',
  Date = 'Date',
}

interface IFieldItem {
  level?: number;
  label?: string;
  parent?: string;
  value: EFieldType;
  children?: IFieldItem[];
  disabled?: boolean;
}

const pureFields: IFieldItem[] = [
  { value: EFieldType.String },
  { value: EFieldType.Long },
  { value: EFieldType.Object },
  { value: EFieldType.List },
  { value: EFieldType.Integer },
  // { value: EFieldType.Char },
  { value: EFieldType.Float },
  { value: EFieldType.Double },
  // { value: EFieldType.BigDecimal },
  { value: EFieldType.Boolean },
  { value: EFieldType.Date },
];

const generateNodes = (
  level: number,
  maxLevel: number,
  config: {
    parent?: string;
    complexTypeDisabled?: boolean;
  },
) => {
  let fields: IFieldItem[] = JSON.parse(JSON.stringify(pureFields));
  if (config.parent && config.parent.startsWith(EFieldType.List)) {
    fields = fields.filter((o) => o.value !== EFieldType.List);
  }
  const results = fields.map((o: IFieldItem) => {
    const isComplexTypeDisabled =
      config.complexTypeDisabled && [EFieldType.Object].includes(o.value);
    o.level = level;
    o.label = o.value;
    o.parent = config.parent;
    if (isComplexTypeDisabled) {
      o.disabled = true;
    }
    if ([EFieldType.List].includes(o.value) && maxLevel >= level) {
      o.children = generateNodes(level + 1, maxLevel, {
        parent: `${o.value}_${o.level}`,
        complexTypeDisabled: config.complexTypeDisabled,
      });
    }
    return o;
  });
  return JSON.parse(JSON.stringify(results));
};

const treeDatas = generateNodes(1, 1, { complexTypeDisabled: false });

interface ISelector {
  onChange?: (vals?: any[]) => void;
  value?: Array<string | number>;
  width?: number;
  disabled?: boolean;
  // 复杂类型是否disabled
  complexTypeDisabled?: boolean;
}

export default memo<ISelector>(
  ({ onChange, value, disabled = false, width = 200, complexTypeDisabled = false }) => {
    const [options, setOptions] = useState<IFieldItem[]>(treeDatas.slice());

    useEffect(() => {
      if (complexTypeDisabled) {
        setOptions(generateNodes(1, 1, { complexTypeDisabled: true }));
      } else {
        setOptions(treeDatas.slice());
      }
    }, [complexTypeDisabled, JSON.stringify(options)]);

    return (
      <Cascader
        showSearch
        allowClear={false}
        style={{ width }}
        placeholder="请选择字段类型"
        onChange={onChange}
        options={options}
        disabled={disabled}
        value={value}
      />
    );
  },
);
