import React, { useRef, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Popconfirm, message } from 'antd';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import DeleteIconButton from '@/components/DeleteIconButton';
import EditIconButton from '@/components/EditIconButton';
import CopyIconButtonn from '@/components/CopyIconButtonn';
import { default as ProTable } from '@ant-design/pro-table';
import Editor from './Editor';
import Text from '@/components/Column/Text';
import { delMethod, methodList } from '@/services/method';
import useSetState from '@/hooks/useSetState';
import { MethodTypes, MethodPoolTypes, SQLMethods } from '@/types';
import omit from 'lodash/omit';
import type { Mode } from './Editor';
import { DEFAULT_PAGE_SIZE, DEFAULT_LIST_SCROLL } from '@/constant';
import SearchGroups from './SearchGroups';
import { pageList } from '@/services/pageGroup';
// import debounce from 'lodash/debounce';

const modeMap = {
  edit: '编辑',
  add: '新增',
  view: '查看',
  copy: '新增',
};
interface option {
  label: string;
  value: string;
}

let optionArr: option[] = [];
let requestIf = false;
const PageRender: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [state, setState] = useSetState({
    editId: '',
    mode: 'add' as Mode,
    visible: false,
  });

  const [searchParams, setSearchParams] = useState({
    name: '',
    pageNumber: 1,
  });
  const [allPageGroups, setAllPageGroups] = useState<option[]>([]);
  const getAllPageGroups = async (params: any) => {
    const res = await pageList(params);
    const list = res.data.list.map((item: any) => {
      const e = {
        label: item.name,
        value: item.id,
      };
      return e;
    });
    requestIf = res.data.totalPage === searchParams.pageNumber;
    optionArr.push(...list);
    setAllPageGroups(optionArr);
  };
  const handleScroll = (e: any) => {
    e.persist();
    const { target } = e;
    const data = searchParams;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight && !requestIf) {
      data.pageNumber += 1;
      setSearchParams(data);
      getAllPageGroups(searchParams);
    }
  };
  const searchPageGroups = (value: string) => {
    // setAllPageGroups([]);
    optionArr = [];
    const data = searchParams;
    data.name = value;
    data.pageNumber = 1;
    setSearchParams(data);
    getAllPageGroups(searchParams);
  };
  // const debounceSearch = debounce(searchPageGroups, 500);

  useEffect(() => {
    getAllPageGroups(searchParams);
  }, []);

  const columns: Array<ProColumns<any>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 200,
      render: (_, record) => <Text copyable value={record.id} />,
    },
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: true,
      render: (_, record) => (
        <Text
          onClick={() => {
            setState({
              visible: true,
              mode: 'view',
              editId: record.id,
            });
          }}
          linkStyle
          value={record.name}
        />
      ),
    },
    {
      title: '关联页面组',
      dataIndex: 'pageGroupIds',
      valueType: 'select',
      fieldProps: {
        options: allPageGroups,
        mode: 'multiple',
        showSearch: true,
        filterOption: false,
        onPopupScroll: handleScroll,
        onSearch: searchPageGroups,
      },
      render: (_, record) => <SearchGroups id={record.id} />,
    },
    {
      title: '方法类型',
      dataIndex: 'type',
      valueType: 'select',
      fieldProps: {
        options: MethodTypes,
      },
    },
    {
      title: '协议类型',
      dataIndex: 'protocolType',
      valueType: 'select',
      fieldProps: {
        options: [...MethodPoolTypes, ...SQLMethods, { label: '-', value: 3 }],
      },
    },
    {
      title: '操作',
      valueType: 'option',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space>
          <EditIconButton
            onClick={() => {
              setState({
                visible: true,
                mode: 'edit',
                editId: record.id,
              });
            }}
          />
          <CopyIconButtonn
            onClick={() => {
              setState({
                visible: true,
                mode: 'copy',
                editId: record.id,
              });
            }}
          />
          <Popconfirm
            title="是否删除当前数据？"
            cancelText="取消"
            okText="确定"
            onConfirm={async () => {
              try {
                const res = await delMethod(record.id);
                if (res.rCode === 0) {
                  message.success('操作成功');
                  actionRef.current?.reload();
                } else {
                  message.error(res.msg);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ProTable<any>
        columns={columns}
        actionRef={actionRef}
        search={{
          defaultCollapsed: false,
        }}
        scroll={DEFAULT_LIST_SCROLL}
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
        }}
        request={async (params) => {
          /*  const weightUnSort = typeof sort.weight === 'undefined';
          params.sortField = weightUnSort ? 0 : 1;
          if (!weightUnSort) {
            params.sortType = ['descend', 'ascend'].findIndex((o) => o === sort.weight);
          } */
          params.pageNumber = params.current;
          const res = await methodList(omit(params, ['current']));
          return {
            data: res?.data?.list || [],
            total: res?.data?.total || 0,
            success: true,
          };
        }}
        rowKey="id"
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setState({
                visible: true,
                editId: '',
                mode: 'add',
              });
            }}
          >
            新增方法配置
          </Button>,
        ]}
      />

      <Drawer
        title={modeMap[state.mode]}
        width="100%"
        placement="right"
        onClose={() => setState({ visible: false })}
        visible={state.visible}
        destroyOnClose
      >
        <Editor
          onCancel={() => {
            setState({ visible: false });
            actionRef.current?.reload();
          }}
          onComplete={() => {
            setState({ visible: false });
            actionRef.current?.reload();
          }}
          mode={state.mode}
          id={state.editId}
        />
      </Drawer>
    </>
  );
};

export default PageRender;
