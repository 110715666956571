import { memo, useEffect, useMemo, useState } from 'react';
import { Table, Typography } from 'antd';
import PickMethodVar from '@/components/PickMethodVar';
import type { MethodInfo } from '@/types';
import _ from 'lodash';

interface BindParam {
  mixBindVarFieldName?: string;
  customLabel?: string;
  value?: any[];
  bindMethods?: any;
  formData?: any;
  isTableList?: any;
  bindedMethods?: any;
  onChange?: (data: any[]) => void;
  checkMethods?: 'loadMethod' | 'submitMethod';
}

export const getDefaultDataSource = ({
  loadMethodParamType,
  loadMethod,
  submitMethodParamType,
  submitMethod,
}: {
  loadMethod?: Pick<MethodInfo, 'id' | 'name'>;
  loadMethodParamType?: 'outputParams' | 'inputParams';
  submitMethod?: Pick<MethodInfo, 'id' | 'name'>;
  submitMethodParamType?: 'outputParams' | 'inputParams';
}) => {
  const list = [
    loadMethod
      ? {
          type: 'loadMethod',
          bindMethod: loadMethod,
          bindVar: {
            fieldName: '',
            fieldId: '',
          },
          methodParamType: loadMethodParamType,
        }
      : null,
    submitMethod
      ? {
          type: 'submitMethod',
          bindMethod: submitMethod,
          bindVar: {
            fieldName: '',
            fieldId: '',
          },
          methodParamType: submitMethodParamType,
        }
      : null,
  ].filter((o) => Boolean(o));
  return list;
};

export default memo<BindParam>(
  ({
    mixBindVarFieldName,
    customLabel,
    value,
    bindedMethods,
    onChange,
    formData,
    isTableList = false,
    checkMethods,
  }) => {
    const [initValue, setInitValue] = useState(value ?? []);

    // 保持初始化的value与bindedMethods中的方法一致
    useEffect(() => {
      const list = getDefaultDataSource({
        loadMethod: bindedMethods?.loadMethod && _.pick(bindedMethods?.loadMethod, ['id', 'name']),
        loadMethodParamType: 'outputParams',
        submitMethod:
          bindedMethods?.submitMethod && _.pick(bindedMethods?.submitMethod, ['id', 'name']),
        submitMethodParamType: 'inputParams',
      });
      setInitValue(list);
    }, [bindedMethods]);

    useEffect(() => {
      if (typeof value === 'undefined' || value.length < 1) {
        if (initValue.length > 0) {
          onChange?.(initValue);
        }
        return;
      }

      // 比较缓存的value与初始化的value中的方法是否一致，不一致，则要剔除对应方法，再加入初始化value中的方法
      const initLoadMethod = initValue.find((item) => item.type == 'loadMethod');
      const initLoadMethodId = initLoadMethod?.bindMethod?.id;
      const initSubmitMethod = initValue.find((item) => item.type == 'submitMethod');
      const initSubmitMethodId = initSubmitMethod?.bindMethod?.id;
      const valueLoadMethodId = value.find((item) => item.type == 'loadMethod')?.bindMethod?.id;
      const valueSubmitMethodId = value.find((item) => item.type == 'submitMethod')?.bindMethod?.id;

      let newValue = [...value];
      let hasChange = false;
      if (valueLoadMethodId !== initLoadMethodId && typeof initLoadMethod !== 'undefined') {
        newValue = newValue.filter((item) => item.type !== 'loadMethod');
        newValue.unshift(initLoadMethod);
        hasChange = true;
      }
      if (valueSubmitMethodId !== initSubmitMethodId && typeof initSubmitMethod !== 'undefined') {
        newValue = newValue.filter((item) => item.type !== 'submitMethod');
        newValue.push(initSubmitMethod);
        hasChange = true;
      }

      if (hasChange) {
        onChange?.(newValue);
      }
    }, [value, initValue, onChange]);

    const columns = useMemo(() => {
      const result: any[] = [
        {
          render(text: any, record: any) {
            return (
              <>
                <Typography.Text style={{ fontSize: 13 }}>
                  {' '}
                  {record.type === 'submitMethod' ? '更新' : '加载'}方法：{' '}
                  {record?.bindMethod?.name || '-'}
                </Typography.Text>
                {typeof customLabel === 'string' ? (
                  <div>
                    <Typography.Text style={{ fontSize: 13, color: '#1890ff' }}>
                      参数：{customLabel}
                    </Typography.Text>
                  </div>
                ) : null}
              </>
            );
          },
        },
      ];
      return result;
    }, []);

    // 将其他参数传入
    const otherValue = {
      controlId: formData.controlId,
      controlType: formData.controlType,
      isSingle: formData?.isSingle,
    };

    return (
      <>
        <Table
          showHeader={false}
          size="small"
          pagination={false}
          columns={columns}
          rowKey={(row) => row.type + row?.bindMethod?.id}
          dataSource={value?.filter(
            (item) => checkMethods === undefined || item.type === checkMethods,
          )}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowRender(record, index) {
              return (
                <>
                  <PickMethodVar
                    isTableList={isTableList}
                    mixBindVarFieldName={mixBindVarFieldName}
                    value={record.bindVar || undefined}
                    otherValue={otherValue}
                    contextField={record.methodParamType}
                    methodId={record?.bindMethod?.id}
                    targetFieldType={formData.controlType || formData.displayType}
                    onSelected={(val) => {
                      if (value?.length) {
                        const newValue = _.cloneDeep(
                          [...value]?.filter(
                            (item) => checkMethods === undefined || item.type === checkMethods,
                          ),
                        );
                        if (typeof mixBindVarFieldName !== 'undefined') {
                          newValue[index].bindVar = {
                            ...val,
                            mix: {
                              ...newValue[index].bindVar.mix,
                              [mixBindVarFieldName]: val,
                            },
                          };
                        } else {
                          newValue[index].bindVar = val;
                        }
                        onChange?.(newValue);
                      }
                    }}
                  />
                </>
              );
            },
          }}
        />
      </>
    );
  },
);
