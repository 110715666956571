import request from '@/utils/request';
import { prefixSystem } from './common';
import type { TReturn } from '@/types';

interface TransferFile {
  sourceUrl: string;
  sourceInnerUrl: string;
  targetFileType: number;
}

interface TransferFileReturnData {
  path: string;
  md5: string;
}

/**
 * transfer effect file to business service
 * */
export const transferFile = (data: TransferFile) => {
  return request.post<TReturn<TransferFileReturnData>>(prefixSystem('upload/transfer'), {
    data,
  });
};
