import { memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const RichText = memo<RichTextProps>(({ onChange }) => {
  return (
    <>
      <ReactQuill
        // value={value || configInfo.defaultValue}
        onChange={(value) => {
          onChange?.(value || '');
        }}
      />
    </>
  );
});

export default RichText;
