import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Table,
  Checkbox,
  Select,
  Input,
  Row,
  Col,
  message,
  Tooltip,
  Button,
  Space,
  Popconfirm,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import FormCreator, { FCRules, EWidgetType } from '@/components/FormCreator';
import { defaultColumns, tableDataType } from '../helper';
import type { ProFormInstance } from '@ant-design/pro-form';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import RegioEnvSelector from '@/components/RegioEnvSelector';
import { clone, omit } from 'lodash';
import { generateSql, getBusinessTables, getTableStructs } from '@/services/method';
import { ESQLSubtypes } from '@/types';

interface Tprops {
  visible: boolean;
  isEditor: boolean;
  submit?: (data: TReturnData) => void;
  handleCancel?: () => void;
  formRef: any;
}

interface TReturnData {
  mysqlConfig: Object;
  inputParams: any[];
  outputParams: any[];
}

enum EColumnDescType {
  conditionalQuery,
  outputParams,
  insert,
  returnTo,
  update,
}

const OneClickModal: React.FC<Tprops> = ({ visible, isEditor, submit, formRef, handleCancel }) => {
  const oneClickFormRef = useRef<ProFormInstance<Record<string, any>>>();
  const [columns, setColumns] = useState<ColumnsType<tableDataType>>([]);
  const dataMap = useRef(new Map());
  const [dataSource, setDataSource] = useState<tableDataType[]>([]);

  const [checkAll_1, setCheckAll_1] = useState(false);
  const [checkAll_2, setCheckAll_2] = useState(false);
  const isFirst = useRef(false);
  const [isSecondEdit, setIsSecondEdit] = useState(false);
  const isFirstReturn = useRef(true);

  useEffect(() => {
    const res: ColumnsType<tableDataType> = [...defaultColumns];
    const onChange = (
      e: CheckboxChangeEvent,
      record: tableDataType,
      columnDescType: number,
      other = {},
    ) => {
      const newRecord = omit(clone(record), ['_isCheck1', '_isCheck2']);
      const mapKey = `${record.columnIdentification}_${columnDescType}`;
      if (e.target.checked) {
        const structFields = {
          ...newRecord,
          columnDescType,
          ...other,
        };
        dataMap.current.set(mapKey, structFields);
      } else {
        dataMap.current.delete(mapKey);
      }
    };
    const onChangeAll = (
      e: CheckboxChangeEvent,
      name: '_isCheck1' | '_isCheck2',
      columnDescType: number,
      other = {},
    ) => {
      let data = [...dataSource];
      if (e.target.checked) {
        data = dataSource.map((item) => {
          item[name] = true;
          return item;
        });
      } else {
        data = dataSource.map((item) => {
          item[name] = false;
          return item;
        });
      }
      setDataSource(data);
      dataSource.forEach((item) => {
        onChange(e, item, columnDescType, other);
      });
    };

    const setCheckAllState = () => {
      let all_1 = true;
      let all_2 = true;
      dataSource.forEach((item) => {
        if (!item._isCheck1) {
          all_1 = false;
        }
        if (!item._isCheck2) {
          all_2 = false;
        }
      });
      setCheckAll_1(all_1);
      setCheckAll_2(all_2);
    };

    // 表单回显
    if (visible && isFirstReturn.current) {
      const { mysqlConfig } = formRef?.current?.getFieldsValue();
      if (mysqlConfig.generateSql) {
        setIsSecondEdit(true);
        const newData = {
          mysqlConfig: {
            mysqlNamespace: mysqlConfig.mysqlNamespace,
            region_businessEnv: mysqlConfig.region_businessEnv,
            tableData: mysqlConfig.tableData[0],
          },
        };
        oneClickFormRef.current?.setFieldsValue(clone(newData));
        getTableDatas();
        getTableStructsData();
        isFirstReturn.current = false;
      }
    }

    // 表格渲染
    if (isFirst.current && dataSource.length === 0) return;
    isFirst.current = true;

    if (visible) {
      const { mysqlConfig } = formRef?.current?.getFieldsValue();
      // 列表查询、单行查询、列表分页查询
      const type_1 = [
        ESQLSubtypes.listSearch,
        ESQLSubtypes.SingleLineQuery,
        ESQLSubtypes.listPagingQuery,
      ];
      // 新建
      const type_2 = [ESQLSubtypes.newlyBuilt];
      // 编辑，逻辑删除
      const type_3 = [ESQLSubtypes.update, ESQLSubtypes.logicalDeletion];
      if (type_1.includes(mysqlConfig?.methodChildType)) {
        const newColumns: ColumnsType<tableDataType> = [
          {
            title: (
              <Checkbox
                checked={checkAll_1}
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeAll(e, '_isCheck1', EColumnDescType.conditionalQuery);
                  setCheckAllState();
                }}
              >
                作为条件查询
              </Checkbox>
            ),
            render(value, record, index) {
              return (
                <Checkbox
                  checked={record._isCheck1}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck1 = !newData[index]._isCheck1;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.conditionalQuery);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title: (
              <Checkbox
                checked={checkAll_2}
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeAll(e, '_isCheck2', EColumnDescType.outputParams);
                  setCheckAllState();
                }}
              >
                作为出参
              </Checkbox>
            ),
            render(value, record, index) {
              return (
                <Checkbox
                  checked={record._isCheck2}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck2 = !newData[index]._isCheck2;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.outputParams);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title: '匹配关系',
            dataIndex: 'matchRelation',
            render(value, record, index) {
              const options = [
                {
                  label: '精确匹配',
                  value: 0,
                },
                {
                  label: '模糊匹配',
                  value: 1,
                },
              ];
              const onValueChange = (data: number) => {
                const newData = [...dataSource];
                newData[index].matchRelation = data;
                setDataSource(newData);
                const columnDescType = EColumnDescType.conditionalQuery;
                const newRecord = {
                  ...omit(clone(newData[index]), ['_isCheck1', '_isCheck2']),
                  columnDescType,
                };
                dataMap.current.set(`${record.columnIdentification}_${columnDescType}`, newRecord);
              };
              return (
                <Row>
                  <Col span={24}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      options={options}
                      value={record.functionValue}
                      disabled={!(record._isCheck1 && record.columnType === 'String')}
                      onChange={onValueChange}
                    />
                  </Col>
                </Row>
              );
            },
          },
        ];
        res.push(...newColumns);
      } else if (type_2.includes(mysqlConfig?.methodChildType)) {
        const newColumns: ColumnsType<tableDataType> = [
          {
            title: (
              <Checkbox
                checked={checkAll_1}
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeAll(e, '_isCheck1', EColumnDescType.insert);
                  setCheckAllState();
                }}
              >
                作为插入字段
              </Checkbox>
            ),
            render(value, record, index) {
              return (
                <Checkbox
                  checked={record._isCheck1}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck1 = !newData[index]._isCheck1;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.insert);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title: (
              <Checkbox
                checked={checkAll_2}
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeAll(e, '_isCheck2', EColumnDescType.returnTo);
                  setCheckAllState();
                }}
              >
                作为返回字段
                <Tooltip title="可以将平台生成的字段值作为结果返回">
                  <InfoCircleOutlined />
                </Tooltip>
              </Checkbox>
            ),
            render(value, record, index) {
              return (
                <Checkbox
                  checked={record._isCheck2}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck2 = !newData[index]._isCheck2;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.returnTo);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title: '插入字段函数传值',
            dataIndex: 'functionValue',
            render(value, record, index) {
              const options = [
                {
                  label: '当前时间',
                  value: 1,
                },
              ];
              const onValueChange = (data: number) => {
                const newData = [...dataSource];
                newData[index].functionValue = data;
                setDataSource(newData);
                const columnDescType = EColumnDescType.insert;
                const newRecord = {
                  ...omit(clone(newData[index]), ['_isCheck1', '_isCheck2']),
                  columnDescType,
                };
                dataMap.current.set(`${record.columnIdentification}_${columnDescType}`, newRecord);
              };
              return (
                <Row>
                  <Col span={24}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      options={options}
                      value={record.functionValue}
                      disabled={!record._isCheck1}
                      onChange={onValueChange}
                    />
                  </Col>
                </Row>
              );
            },
          },
        ];
        res.push(...newColumns);
      } else if (type_3.includes(mysqlConfig?.methodChildType)) {
        const newColumns: ColumnsType<tableDataType> = [
          {
            title() {
              let disabled = false;
              for (const item of dataSource) {
                if (item._isCheck2) {
                  disabled = true;
                  break;
                }
              }
              return (
                <Checkbox
                  disabled={disabled}
                  checked={checkAll_1}
                  onChange={(e: CheckboxChangeEvent) => {
                    onChangeAll(e, '_isCheck1', EColumnDescType.conditionalQuery);
                    setCheckAllState();
                  }}
                >
                  作为查询字段
                </Checkbox>
              );
            },
            render(value, record, index) {
              return (
                <Checkbox
                  disabled={record._isCheck2}
                  checked={record._isCheck1}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck1 = !newData[index]._isCheck1;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.conditionalQuery);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title() {
              let disabled = false;
              for (const item of dataSource) {
                if (item._isCheck1) {
                  disabled = true;
                  break;
                }
              }
              return (
                <Checkbox
                  disabled={disabled}
                  checked={checkAll_2}
                  onChange={(e: CheckboxChangeEvent) => {
                    onChangeAll(e, '_isCheck2', EColumnDescType.update);
                    setCheckAllState();
                  }}
                >
                  作为更新字段
                </Checkbox>
              );
            },
            render(value, record, index) {
              return (
                <Checkbox
                  disabled={record._isCheck1}
                  checked={record._isCheck2}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck2 = !newData[index]._isCheck2;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.update);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
          {
            title: '更新字段函数传值',
            dataIndex: 'functionValue',
            render(value, record, index) {
              const options = [
                {
                  label: '当前时间',
                  value: 1,
                },
              ];
              const onValueChange = (data: number) => {
                const newData = [...dataSource];
                newData[index].functionValue = data;
                setDataSource(newData);
                const columnDescType = EColumnDescType.update;
                const newRecord = {
                  ...omit(clone(newData[index]), ['_isCheck1', '_isCheck2', 'staticValue']),
                  columnDescType,
                };
                dataMap.current.set(`${record.columnIdentification}_${columnDescType}`, newRecord);
              };
              const isDisabled =
                !record._isCheck2 ||
                !(record.staticValue === undefined || record.staticValue === '');
              return (
                <Row>
                  <Col span={24}>
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      options={options}
                      value={record.functionValue}
                      disabled={isDisabled}
                      onChange={onValueChange}
                    />
                  </Col>
                </Row>
              );
            },
          },
        ];
        res.push(...newColumns);
        if (mysqlConfig?.methodChildType === ESQLSubtypes.logicalDeletion) {
          const other: ColumnsType<tableDataType> = [
            {
              title: '更新字段静态传值',
              dataIndex: 'staticValue',
              render(value, record, index) {
                const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value: data } = e.target;
                  const newData = [...dataSource];
                  newData[index].staticValue = data;
                  setDataSource(newData);
                  const columnDescType = EColumnDescType.update;
                  const newRecord = {
                    ...omit(clone(newData[index]), ['_isCheck1', '_isCheck2', 'functionValue']),
                    columnDescType,
                  };
                  dataMap.current.set(
                    `${record.columnIdentification}_${columnDescType}`,
                    newRecord,
                  );
                };
                return (
                  <Input
                    allowClear
                    value={record.staticValue}
                    onChange={onValueChange}
                    disabled={!record._isCheck2 || record.functionValue !== undefined}
                  />
                );
              },
            },
          ];
          res.push(...other);
        }
      } else if (mysqlConfig?.methodChildType === ESQLSubtypes.physicalDeletion) {
        const newColumns: ColumnsType<tableDataType> = [
          {
            title: (
              <Checkbox
                checked={checkAll_1}
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeAll(e, '_isCheck1', EColumnDescType.conditionalQuery);
                  setCheckAllState();
                }}
              >
                作为条件查询
              </Checkbox>
            ),
            render(value, record, index) {
              return (
                <Checkbox
                  checked={record._isCheck1}
                  onChange={(e: CheckboxChangeEvent) => {
                    const newData = [...dataSource];
                    newData[index]._isCheck1 = !newData[index]._isCheck1;
                    setDataSource(newData);
                    onChange(e, record, EColumnDescType.conditionalQuery);
                    setCheckAllState();
                  }}
                />
              );
            },
          },
        ];
        res.push(...newColumns);
      }
    }
    setColumns(res);
  }, [visible, dataSource]);

  const [tableOptions, setTableOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const getTableDatas = async () => {
    const params = await oneClickFormRef.current
      ?.validateFields([
        ['mysqlConfig', 'region_businessEnv'],
        ['mysqlConfig', 'mysqlNamespace'],
      ])
      .then((formData) => {
        setSearchLoading(true);
        const { mysqlConfig } = clone(formData);
        const { region_businessEnv, mysqlNamespace } = mysqlConfig;
        const data = {
          regionCode: region_businessEnv.region,
          businessEnvCode: region_businessEnv.businessEnv,
          namespace: mysqlNamespace,
        };
        return data;
      });
    if (params === undefined) return;
    const res = await getBusinessTables(params);
    if (res.rCode === 0) {
      if (res.data?.tableNames === undefined) return;
      const arr = [...res.data?.tableNames];

      setTableOptions(
        arr.map((item) => {
          return {
            label: item,
            value: item,
          };
        }),
      );
    } else {
      message.error(res.msg || '获取失败');
    }
    setSearchLoading(false);
  };
  const getTableStructsData = async () => {
    const params = await oneClickFormRef.current?.validateFields().then((formData) => {
      const { mysqlConfig } = clone(formData);
      const { region_businessEnv, mysqlNamespace, tableData } = mysqlConfig;
      const data = {
        regionCode: region_businessEnv.region,
        businessEnvCode: region_businessEnv.businessEnv,
        namespace: mysqlNamespace,
        tables: [tableData],
      };
      return data;
    });
    if (params === undefined) return;
    const res = await getTableStructs(params);
    if (res.rCode === 0) {
      if (res.data?.tables[0].structFields) {
        const newArr = res.data?.tables[0].structFields.map((item) => {
          return {
            ...item,
            _isCheck1: false,
            _isCheck2: false,
          };
        });
        setDataSource([...newArr]);
      }
    } else {
      message.error(res.msg || '获取失败');
    }
  };

  const config = {
    'mysqlConfig.region_businessEnv': {
      label: '区域/业务环境',
      fieldType: EWidgetType.CustomWidget,
      rules: [FCRules.Required('请先选择业务')],
      onChange() {
        setTableOptions([]);
        clearData(true);
      },
      custom: (props: any) => <RegioEnvSelector {...props} />,
    },
    'mysqlConfig.mysqlNamespace': {
      label: 'namespace',
      fieldType: EWidgetType.Input,
      rules: [FCRules.Required()],
      fieldProps: {
        onChange() {
          oneClickFormRef.current?.setFieldValue(['mysqlConfig', 'tableData'], undefined);
          setTableOptions([]);
          clearData(true);
        },
        addonAfter: (
          <Button type="link" loading={searchLoading} onClick={getTableDatas}>
            获取数据表
          </Button>
        ),
      },
    },
    'mysqlConfig.tableData': {
      label: '数据表',
      fieldType: EWidgetType.Select,
      rules: [FCRules.Required()],
      fieldProps: {
        showSearch: true,
        options: tableOptions,
      },
      onChange() {
        clearData();
        getTableStructsData();
      },
    },
  };

  const clearData = (clearTable = false) => {
    setCheckAll_1(false);
    setCheckAll_2(false);
    dataMap.current.clear();
    if (clearTable) {
      setDataSource([]);
    }
  };

  const clear = () => {
    dataMap.current.clear();
    oneClickFormRef.current?.resetFields();
    setDataSource([]);
    setCheckAll_1(false);
    setCheckAll_2(false);
    setTableOptions([]);
    isFirstReturn.current = true;
    setIsSecondEdit(false);
  };
  const onOk = async () => {
    const formData = await oneClickFormRef.current?.validateFields().then((data) => data);
    if (formData === undefined) return;
    const structFields = Array.from(dataMap.current.values());
    if (structFields.length === 0) {
      message.error('请选择1个或以上字段');
      return;
    }
    const oldData = formRef.current?.getFieldsValue();

    let isInOne = false;
    let isOutOne = false;
    if (
      [ESQLSubtypes.listSearch, ESQLSubtypes.SingleLineQuery].includes(
        oldData.mysqlConfig.methodChildType,
      )
    ) {
      structFields.forEach((item) => {
        if (item.columnDescType === EColumnDescType.conditionalQuery) {
          isInOne = true;
        }
        if (item.columnDescType === EColumnDescType.outputParams) {
          isOutOne = true;
        }
      });
      if (!isInOne || !isOutOne) {
        message.error('请选择至少选择一个作为条件查询和一个作为出参');
        return;
      }
    } else if (oldData.mysqlConfig.methodChildType === ESQLSubtypes.listPagingQuery) {
      structFields.forEach((item) => {
        if (item.columnDescType === EColumnDescType.outputParams) {
          isOutOne = true;
        }
      });
      if (!isOutOne) {
        message.error('请选择至少选择一个作为出参');
        return;
      }
    }

    const params = {
      tableName: formData.mysqlConfig.tableData,
      methodChildType: oldData.mysqlConfig.methodChildType,
      structFields,
    };
    const res = await generateSql(params);
    if (res.rCode === 0) {
      const tableData = [formData.mysqlConfig.tableData];
      formData.mysqlConfig.tableData = tableData;
      const newData = {
        ...oldData.mysqlConfig,
        ...formData.mysqlConfig,
        generateSql: res.data?.sql,
      };
      if (submit) {
        submit({
          mysqlConfig: clone(newData),
          inputParams: [
            ...(res.data?.inputParams ?? []).map((item) => ({ ...item, passValueType: 1 })),
          ],
          outputParams: [...(res.data?.outputParams ?? [])],
        });
      }

      clear();
    } else {
      message.error(res.msg || '获取失败');
    }
  };
  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    }
    clear();
  };

  return (
    <Modal
      title="一键生成SQL脚本"
      width="80%"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              取消
            </Button>
            {!isSecondEdit ? (
              <Button
                type="primary"
                onClick={() => {
                  onOk();
                }}
              >
                确定
              </Button>
            ) : (
              <Popconfirm title="请问是否保存" onConfirm={onOk} okText="确认" cancelText="取消">
                <Button type="primary">确定</Button>
              </Popconfirm>
            )}
          </Space>
        </Row>
      }
    >
      <FormCreator
        debug
        onMounted={(form) => {
          oneClickFormRef.current = form;
        }}
        submitter={false}
        labelCol={{ span: 3 }}
        layout="horizontal"
        config={config}
        readonly={isEditor}
      />
      <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ y: 400 }} />
    </Modal>
  );
};

export default OneClickModal;
