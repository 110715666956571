import { memo, useMemo } from 'react';
import { Tree, Popover, Typography } from 'antd';
import useStore from '@/store/global';
import { doFill } from '../PickMethodVar';

interface IPickGlobalVarProps {
  onSelected?: (...args: any[]) => void;
  value?: any;
  disabled?: boolean;
  isBackend?: boolean;
}

const PickGlobalVar = memo<IPickGlobalVarProps>(({ onSelected, value, disabled, isBackend }) => {
  const { user } = useStore((state) => state);
  const treeData = useMemo(() => {
    const results: any[] = [];
    if (!user) return results;
    const userInfo: any = {
      title: 'user（SSO 用户信息）',
      key: 'user',
      children: [],
    };
    const idInfo = {
      title: 'genId()（ID自动生成）',
      key: 'genId()',
      children: [],
    };
    if (isBackend) {
      results.push(idInfo);
    } else {
      doFill(userInfo, user);
      results.push(userInfo);
    }
    return results;
  }, [user, isBackend]);

  const contentValue = value ? `当前绑定：${value}` : '请绑定变量';

  if (disabled) {
    return <span>{contentValue}</span>;
  }

  return (
    <>
      {/*  <TreeSelect
          allowClear
          value={value ? value : undefined}
          style={{ width: 250 }}
          placeholder="请选择变量"
          maxTagCount={1}
          onChange={onSelected}
          treeLine={{ showLeafIcon: false }}
          treeData={treeData}
        /> */}
      <Popover
        content={
          <>
            {/* <Typography.Text>页面全局数据</Typography.Text> */}
            <Tree
              showLine={{ showLeafIcon: false }}
              showIcon={false}
              treeData={treeData}
              onSelect={onSelected}
              defaultExpandAll
            />
          </>
        }
        placement="left"
        trigger="click"
      >
        <Typography.Link>{contentValue}</Typography.Link>
      </Popover>
    </>
  );
});
export default PickGlobalVar;
