import { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import MonacoEditor from './monaco';

import type { OnChange, EditorProps } from './monaco';
import type { editor } from 'monaco-editor/esm/vs/editor/editor.api';

interface STJsonEditorProps extends Omit<EditorProps, 'value' | 'onChange'> {
  onChange?: (value: object, ev: editor.IModelContentChangedEvent) => void;
  value?: object | string;
}
/**
 * 这个组件是对monaco-editor的封装，用来编辑json
 * monaco输入的值和onchange的值都是字符串，使用这个组件，输入和onchange的值都是json
 */
function JSONEditor(props: STJsonEditorProps) {
  const [value, setValue] = useState<string>();
  const preValue = useRef(props.value); // 保存上一次onchange出去的值，避免同步时循环触发更新

  const parseValue = useCallback((v?: string | object) => {
    let newV = v;
    if (typeof v === 'string') {
      try {
        newV = JSON.parse(v);
      } catch (e) {
        // do nothing
      }
    }

    return JSON.stringify(newV, null, 2);
  }, []);

  useEffect(() => {
    // props进入的值和上一次onchange的值不一样，表明外部值有更新
    // 将props的值同步给value
    if (props.value !== preValue.current) {
      setValue(parseValue(props.value));
      preValue.current = props.value;
      // 初始化的时候value的值为undefined，这时候需要同步props的值给value
      // 为什么不在useSState的初始化时候同步呢？因为不想每次render的时候都stringify一次
    } else if (typeof value === 'undefined' && preValue.current) {
      setValue(parseValue(props.value));
    }
  }, [props.value, value, parseValue]);

  const onChange: OnChange = useCallback(
    (_value = '', ev) => {
      try {
        // 每次输入时，都parse一次，如果为合法的json，则触发onchange
        // 这次保证外部拿到的都是合法的json
        const json = _value ? JSON.parse(_value) : undefined;
        props.onChange?.(json, ev);
        preValue.current = json;
      } catch (e) {
        // do nothing
      } finally {
        setValue(_value);
      }
    },
    [props],
  );

  return (
    <MonacoEditor height="60vh" language="json" {...props} value={value} onChange={onChange} />
  );
}

export default forwardRef(JSONEditor);
