import { memo, useEffect, useMemo } from 'react';
import { Drawer, Spin } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import useMethodDetail from '@/hooks/useMethodDetail';
import { parseString2Json } from '@/components/FormGeneractor/help';
import AddEdit from './AddEdit';
import View from './View/index';
import useCurPageStore from '../useCurPageStore';
import useHandleDetail from '../useHandleDetail';
import { log } from '@/utils/utils';
import { EPageType } from '@/constant';
import useLinkageStore from '@/store/form-generator-linkage';

interface EditorProps {
  pageModeId: string;
  withDrawer?: boolean;
}

/* const methodMap = {
  add: EDataSourceMethod.Add,
  edit: EDataSourceMethod.Edit,
  view: EDataSourceMethod.Detail
}

const pageMap = {
  add: EPageType.新增页面,
  edit: EPageType.编辑页面,
  view: EPageType.查看页面
}
 */

const Editor = memo<EditorProps>(({ pageModeId, withDrawer = true }) => {
  const { curPageStore, store } = useCurPageStore(pageModeId);
  const {
    load: loadMethodDetail,
    data: methodDetail,
    loading: loadingMethodDetail,
  } = useMethodDetail();
  const {
    uEditorVisible,
    uAddEditViewMethodDetail,
    uAddEditViewConfigInfo,
    uDetailInfo,
    uConfigForDetailInit,
  } = store;
  const { editorVisible, currentDrawerServicePage, addEditViewConfigInfo } = curPageStore;
  const [loadingDetail] = useHandleDetail(pageModeId);
  const resetLinkageStore = useLinkageStore((state) => state.reset);

  const hancleCloseDrawer = () => {
    uAddEditViewMethodDetail(pageModeId, null);
    uAddEditViewConfigInfo(pageModeId, null);
    uDetailInfo(pageModeId, null);
    uConfigForDetailInit(pageModeId, null);
    uEditorVisible(pageModeId, false);
    resetLinkageStore();
  };

  useEffect(() => {
    // 拦截 submit 时机
    if (!editorVisible) {
      uAddEditViewMethodDetail(pageModeId, null);
      uAddEditViewConfigInfo(pageModeId, null);
      uDetailInfo(pageModeId, null);
      uConfigForDetailInit(pageModeId, null);
    }
  }, [editorVisible]);

  useEffect(() => {
    return () => {
      uAddEditViewMethodDetail(pageModeId, null);
      uAddEditViewConfigInfo(pageModeId, null);
      uDetailInfo(pageModeId, null);
      uConfigForDetailInit(pageModeId, null);
    };
  }, []);

  // 收集模型方法详情
  useEffect(() => {
    if (!editorVisible) {
      uAddEditViewMethodDetail(pageModeId, null);
      uAddEditViewConfigInfo(pageModeId, null);
      uDetailInfo(pageModeId, null);
      uConfigForDetailInit(pageModeId, null);
      return;
    }
    if (String(currentDrawerServicePage?.type) === EPageType.查看页面) return;
    const submitMethodId = addEditViewConfigInfo?.submitMethodId;
    if (!submitMethodId) return;
    loadMethodDetail(submitMethodId);
  }, [editorVisible, currentDrawerServicePage, addEditViewConfigInfo]);

  useEffect(() => {
    if (methodDetail) {
      uAddEditViewMethodDetail(pageModeId, methodDetail);
    }
  }, [methodDetail]);

  const BodyRender = useMemo(() => {
    const renderMap = {
      [EPageType.新增页面]: <AddEdit pageModeId={pageModeId} />,
      [EPageType.编辑页面]: <AddEdit pageModeId={pageModeId} />,
      [EPageType.查看页面]: <View pageModeId={pageModeId} />,
    };
    return currentDrawerServicePage?.type
      ? (renderMap as any)[currentDrawerServicePage?.type]
      : null;
  }, [currentDrawerServicePage, pageModeId]);

  log('addEditViewConfigInfo......', addEditViewConfigInfo);

  const content = (
    <div style={{ background: '#fff' }}>
      <PageContainer
        loading={loadingDetail}
        title={(parseString2Json(addEditViewConfigInfo?.properties || '') as any)?.title || '标题'}
      >
        <Spin spinning={loadingMethodDetail}>{loadingMethodDetail ? null : BodyRender}</Spin>
      </PageContainer>
    </div>
  );

  if (withDrawer) {
    return (
      <Drawer
        visible={!!editorVisible}
        width="100%"
        height="100%"
        destroyOnClose
        onClose={hancleCloseDrawer}
        bodyStyle={{ padding: 0, position: 'relative' }}
      >
        {content}
      </Drawer>
    );
  }

  return content;
});

export default Editor;
