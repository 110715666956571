import { memo } from 'react';
import { Typography } from 'antd';
import HelpText from '@/components/HelpText';

interface TitleViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const TitleView = memo<TitleViewProps>(({ configInfo }) => {
  return (
    <Typography.Title
      level={4}
      style={{
        // borderBottom: '1px solid #ccc',
        // padding: '10px 0',
        marginBottom: 5,
        marginTop: 20,
      }}
    >
      {configInfo.label}
      {configInfo.hasTip && configInfo.tipContent && <HelpText toolTip={configInfo.tipContent} />}
    </Typography.Title>
  );
});

export default TitleView;
