import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Modal, Row, Space } from 'antd';
import { useParams } from 'react-router-dom';
import useFormGeneratorStore from '@/store/form-generator';
import List from '@/components/PageParser/List';
import { getListPageConfig } from '@/services/page';
import { parseString2Json } from '../../help';
import { omit } from 'lodash';
import useLinkageStore from '@/store/form-generator-linkage';
import useFormGeneratorParseStore from '@/store/form-generator-parse';

interface TListItems {
  [key: string]: {
    backfills?: string;
    isMainKey?: boolean;
  };
}

const ListCheckbox: React.FC<{
  // onChange?: (data: any) => void;
  // onFill?: (values: Record<string, any>) => void;
  // value?: any;
  configInfo?: any;
}> = ({ configInfo }) => {
  const pageModeId = useFormGeneratorStore((state) => state.currentPageGroupId);
  const { controlId } = configInfo;
  const linkageData = useLinkageStore((state) => state.linkageComponents[controlId]);
  const uLinkageComponents = useLinkageStore((state) => state.uLinkageComponents);
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const { listItems, disabled } = configInfo;
  const [listPageInfo, setPageInfo] = useState<Record<string, any> | null>();
  const pageId = configInfo.page;
  const [dataMainKeys, setDataMainKeys] = useState<string[]>([]);
  const [listMainKeys, setListMainKeys] = useState<string[]>([]);
  const [defaultSet, setDefaultSet] = useState<Set<string>>(new Set());
  const selectData = useRef<any[]>([]);
  const uncheck = useRef<Set<string>>(new Set());
  const listRefresh = useFormGeneratorParseStore((state) => state.listRefresh);
  const uListRefresh = useFormGeneratorParseStore((state) => state.uListRefresh);

  useEffect(() => {
    uLinkageComponents({
      [controlId]: {
        data: [],
        originalData: [],
      },
    });
    if (pageId) {
      // 5. 根据列表页面 ID, 获取相关信息
      getListPageConfig(pageId, false).then((res) => {
        if (res.rCode === 0) {
          setPageInfo(res.data);
          // uListPageInfo(pageGroupInfo.id, res.data);
        } else {
          // setErrors([...errors, res.msg]);
        }
      });
    }
    return () => {};
  }, [pageId, controlId, uLinkageComponents]);

  useEffect(() => {
    if (listPageInfo) {
      // 过滤无效值
      const invalidData: string[] = [];
      const validData: any[] = listPageInfo.contentColumns.map((column: any) => {
        const properties: any = parseString2Json(column.properties);
        return properties.dataIndex;
      });
      if (validData) {
        for (const key in listItems ?? {}) {
          if (!validData.includes(key)) {
            invalidData.push(key);
          }
        }
      }
      const newListItems: TListItems = omit(listItems, invalidData);
      const newDataMainKeys: string[] = [];
      const newListMainKeys: string[] = [];

      for (const key in newListItems) {
        if (newListItems[key]) {
          const item = newListItems[key];
          if (item.isMainKey) {
            newDataMainKeys.push(key);
            if (item.backfills !== undefined) {
              newListMainKeys.push(item.backfills);
            }
          }
        }
      }
      setDataMainKeys(newDataMainKeys);
      setListMainKeys(newListMainKeys);
    }
  }, [listPageInfo, listItems]);

  useEffect(() => {
    if (linkageData) {
      const { originalData } = linkageData;
      let originalKeys: string[] = [];
      if (originalData) {
        originalKeys = (originalData as any[]).map((item) => {
          let res = '';
          for (const key of listMainKeys) {
            res = String(item[key]) + res;
          }
          return res;
        });
      }
      setDefaultSet(new Set(originalKeys));
    }
  }, [linkageData, dataMainKeys, listMainKeys]);

  const onCancel = () => {
    uListRefresh(listRefresh + 1);
    setVisible(false);
  };

  const Footer = (
    <div
      style={{
        position: 'sticky',
        bottom: '0px',
        backgroundColor: '#fff',
        padding: '0 0 10px 0',
        zIndex: 2,
      }}
    >
      <Divider />
      <Row justify={'end'}>
        <Col>
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button
              type="primary"
              onClick={() => {
                const newData = [
                  ...selectData.current
                    .filter((item) => !defaultSet.has(item[`_${dataMainKeys.join('')}`]))
                    .map((item) => {
                      const res: { [key: string]: any } = {};
                      for (const key in listItems) {
                        if (listItems[key].backfills && listItems[key].isBackfills) {
                          res[listItems[key].backfills] = item[key];
                        }
                      }
                      return res;
                    }),
                  ...(linkageData.originalData as any[]).filter((item) => {
                    let res = '';
                    for (const key of listMainKeys) {
                      res = String(item[key]) + res;
                    }
                    return !uncheck.current.has(res);
                  }),
                ];
                uLinkageComponents({
                  [controlId]: {
                    data: newData,
                    originalData: newData,
                  },
                });
                onCancel();
              }}
            >
              确定
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} disabled={disabled}>
        {configInfo?.text || ' '}
      </Button>
      <Modal destroyOnClose visible={visible} onCancel={onCancel} width="90%" footer={null}>
        <List
          pageModeId={params.pageId ?? pageModeId}
          pageId={configInfo?.page}
          rowSelectionType={'checkbox'}
          defaultSet={defaultSet}
          dataMainKeys={dataMainKeys}
          onSelect={(records, uncheckSet) => {
            selectData.current = records;
            if (uncheckSet) {
              uncheck.current = uncheckSet;
            }
          }}
        />
        {Footer}
      </Modal>
    </>
  );
};

export default ListCheckbox;
