import { memo } from 'react';
import { Image } from 'antd';

interface ImageViewProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const ImageView = memo<ImageViewProps>(() => {
  return (
    <>
      <Image
        width={100}
        src="https://cdnimg101.gzlzfm.com/audio_cover/2013/08/09/5112668945392263_300x300.jpg"
      />
    </>
  );
});

export default ImageView;
