import React, { memo } from 'react';
import { Row, Button, Space } from 'antd';
import useAddStore from '@/store/form-generator-add';

import './index.less';

interface SubmitViewProps {
  isFocus: boolean;
}

const LIGHT_LINE = '2px solid #1890ff';

const SubmitView = memo<SubmitViewProps>(({ isFocus }) => {
  const { submitButtonConfig, cancelButtonConfig } = useAddStore((state) => state);
  return (
    <Row
      justify="center"
      align="middle"
      className="submit-box"
      style={
        isFocus
          ? {
              borderBottom: LIGHT_LINE,
              borderRight: LIGHT_LINE,
              borderLeft: LIGHT_LINE,
            }
          : {}
      }
    >
      <Space>
        <Button>{cancelButtonConfig?.text}</Button>
        <Button type="primary">{submitButtonConfig?.text}</Button>
      </Space>
    </Row>
  );
});

export default SubmitView;
