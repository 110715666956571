import { memo, useEffect, useState, useMemo } from 'react';
import { Spin, Typography } from 'antd';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import { getFormPageConfig } from '@/services/page';
import PickMethodVar from '@/components/PickMethodVar';
import useMethodDetail from '@/hooks/useMethodDetail';
import { EPassValueType } from '@/components/ConfigFieldEditor/types';
import useListStore from '@/store/form-generator-list';
import useStore from '@/store/form-generator';
import _ from 'lodash';
import type { EPageType } from '@/constant';

interface BindExistMethodInputParamProps {
  formData?: any;
  value?: any;
  onChange?: (data: any) => void;
  // 已存在的目标页面 ID
  targetPageId?: string;
  // 已存在的目标页面类型，兼容旧流程使用
  targetPageType?: EPageType;
  // 未绑定方法文案
  outExistMethodIdMessage?: string;
}
let debounceTime: any;
let cacheMethodDetail: any = null;

const BindExistMethodInputParam = memo<BindExistMethodInputParamProps>(
  ({ value, onChange, targetPageId, targetPageType, outExistMethodIdMessage }) => {
    const { bindedMethods } = useListStore();
    const { pageList } = useStore();
    const [existMethodId, setExistMethodId] = useState('');
    const {
      load: loadMethodDetail,
      loading: loadingMethodDetail,
      data: methodDetail,
    } = useMethodDetail();
    const pageId = targetPageId ?? pageList.find((o) => String(o.type) === targetPageType)?.id;

    useEffect(() => {
      if (pageId) {
        getFormPageConfig(pageId).then((res) => {
          if (res.rCode === 0) {
            setExistMethodId(res.data?.loadMethodId);
          }
        });
      }
    }, [pageId]);

    useEffect(() => {
      if (existMethodId) {
        if (!cacheMethodDetail || (cacheMethodDetail && cacheMethodDetail.id !== existMethodId)) {
          loadMethodDetail(existMethodId);
        }
      }
    }, [existMethodId]);

    useEffect(() => {
      cacheMethodDetail = methodDetail;
      return () => {
        cacheMethodDetail = null;
      };
    }, [methodDetail]);

    const config = useMemo(() => {
      const result: any = {
        method: {
          label: '',
          fieldType: EWidgetType.Text,
          defaultValue: methodDetail ? (
            <Typography.Link>{methodDetail?.name}</Typography.Link>
          ) : (
            <Typography.Text type="danger">
              {outExistMethodIdMessage || '目标页面未绑定方法'}
            </Typography.Text>
          ),
        },
      };
      if (methodDetail?.inputParams?.length) {
        methodDetail.inputParams
          .filter((o: any) => String(o.passValueType) === EPassValueType.直接传值)
          .forEach((param: any) => {
            const attr = `${param.name}_${param.id}`;
            result[attr] = {
              label: param.name,
              tooltip: '请选择当前入参绑定的字段',
              fieldType: EWidgetType.CustomWidget,
              defaultValue: _.get(value, attr, undefined),
              custom(props: any) {
                return (
                  <PickMethodVar
                    {...props}
                    value={_.get(value, attr)}
                    contextField="outputParams"
                    targetFieldType={`${param.dataType}${
                      param.genericType ? `/${param.genericType}` : ''
                    }`}
                    methodId={bindedMethods?.loadMethod?.id}
                    onSelected={(val) => {
                      props?.onChange?.(val);
                    }}
                  />
                );
              },
            };
          });
      }
      return result;
    }, [value, methodDetail]);

    return (
      <div>
        <Spin spinning={loadingMethodDetail}>
          <FormCreator
            submitter={{ render: () => null }}
            initialValues={value}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            onValuesChange={(changedValues: any, allValues: any) => {
              if (debounceTime) {
                clearTimeout(debounceTime);
              }
              debounceTime = setTimeout(() => {
                onChange?.({
                  ...value,
                  ...allValues,
                  methodId: methodDetail?.id,
                });
              }, 500);
            }}
            config={config}
          />
        </Spin>
      </div>
    );
  },
);

export default BindExistMethodInputParam;
