import { memo, useCallback } from 'react';
import { Popconfirm } from 'antd';
import useEditStore from '@/store/form-generator-edit';
import FormCreator, { EWidgetType, FCRules } from '@/components/FormCreator';
import update from 'immutability-helper';
import SortableTable from '@/components/SortableTable';
import DeleteIconButton from '@/components/DeleteIconButton';
import WithCollapse from '../../WithCollapse';
import MethodsValidation from '../../MethodsValidation';
import { parseString2Json, filterNullMethods } from '@/components/FormGeneractor/help';
import useFormGeneratorStore from '@/store/form-generator';

let debounceTimer: any;
const EdiBox = memo(() => {
  const editStore = useEditStore();
  const {
    cancelButtonConfig,
    submitButtonConfig,
    fields,
    uFields,
    ready,
    bindedMethods,
    serviceConfig,
    uMethodsValidationConfig,
  } = editStore;
  const properties: any = parseString2Json(serviceConfig?.properties ?? '{}');
  const { methodsValidationConfig } = properties;
  const { currentPage } = useFormGeneratorStore((state) => state);
  const usedFields = fields;

  const columns: any[] = [
    {
      title: '组件',
      ellipsis: true,
      render: (_: any, record: any) =>
        `${record.label} （${record.paramName || record.controlId}）`,
    },
    {
      title: '操作',
      fixed: 'right',
      width: 60,
      render: (_: any, record: any, index: number) => (
        <>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              usedFields.splice(index, 1);
              uFields([...fields]);
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </>
      ),
    },
  ];

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!usedFields?.length) return;
      const dragRow = usedFields[dragIndex];
      const newFields = update(usedFields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      uFields([...newFields]);
    },
    [usedFields, uFields],
  );

  const CollapseDatas = [
    {
      title: '基础属性',
      key: 'box-config',
      configComponent: (
        <>
          <FormCreator
            submitter={{ render: () => null }}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            debug
            layout="horizontal"
            onValuesChange={(_, allValsChange) => {
              const { title, labelColWidth, wrapperColWidth } = allValsChange;
              if (debounceTimer) {
                clearTimeout(debounceTimer);
              }
              debounceTimer = setTimeout(() => {
                editStore.uTitle(title || '');
                editStore.uLabelColWidth(labelColWidth || '');
                editStore.uWrapperColWidth(wrapperColWidth || '');
              }, 500);
            }}
            config={{
              title: {
                label: '标题',
                defaultValue: editStore.title || '',
                fieldType: EWidgetType.Input,
                rules: [FCRules.Required()],
                fieldProps: {},
              },
              labelColWidth: {
                label: '标题区宽度',
                defaultValue: editStore.labelColWidth || '',
                fieldType: EWidgetType.InputNumber,
                extra: '默认 200',
                fieldProps: {
                  addonAfter: 'px',
                },
              },
              wrapperColWidth: {
                label: '控件区宽度',
                defaultValue: editStore.wrapperColWidth || '',
                fieldType: EWidgetType.InputNumber,
                extra: '默认铺满',
                fieldProps: {
                  addonAfter: 'px',
                },
              },
            }}
          />
        </>
      ),
    },
    {
      title: '取消按钮属性',
      key: 'cancel-btn-config',
      configComponent: (
        <>
          <FormCreator
            submitter={{ render: () => null }}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            debug
            layout="horizontal"
            onValuesChange={(_, allValsChange) => {
              if (debounceTimer) {
                clearTimeout(debounceTimer);
              }
              debounceTimer = setTimeout(() => {
                editStore.uTitle(allValsChange.title || '');
              }, 500);
            }}
            config={{
              text: {
                label: '按钮名称',
                defaultValue: cancelButtonConfig.text,
                fieldType: EWidgetType.Input,
                rules: [FCRules.Required()],
              },
              shapeType: {
                label: '按钮类型',
                defaultValue: cancelButtonConfig.shapeType,
                fieldType: EWidgetType.Select,
                fieldProps: {
                  options: [{ label: '标准按钮', value: 'default' }],
                  allowClear: false,
                },
              },
              styleType: {
                label: '样式类型',
                defaultValue: cancelButtonConfig.styleType || 'default',
                fieldType: EWidgetType.Select,
                fieldProps: {
                  options: [{ label: '默认', value: 'default' }],
                  allowClear: false,
                },
              },
            }}
          />
        </>
      ),
    },
    {
      title: '保存按钮属性',
      key: 'save-btn-config',
      configComponent: (
        <>
          <FormCreator
            submitter={{ render: () => null }}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            debug
            layout="horizontal"
            onValuesChange={(_, allValsChange) => {
              if (debounceTimer) {
                clearTimeout(debounceTimer);
              }
              debounceTimer = setTimeout(() => {
                editStore.uTitle(allValsChange.title || '');
              }, 500);
            }}
            config={{
              text: {
                label: '按钮名称',
                defaultValue: submitButtonConfig.text || '',
                fieldType: EWidgetType.Input,
                rules: [FCRules.Required()],
              },
              shapeType: {
                label: '按钮类型',
                defaultValue: submitButtonConfig.shapeType || '',
                fieldType: EWidgetType.Select,
                fieldProps: {
                  options: [{ label: '标准按钮', value: 'primary' }],
                  allowClear: false,
                },
              },
              styleType: {
                label: '样式类型',
                defaultValue: submitButtonConfig.styleType || 'link',
                fieldType: EWidgetType.Select,
                fieldProps: {
                  options: [{ label: '文字按钮', value: 'link' }],
                  allowClear: false,
                },
              },
              callFunction: {
                label: '调用方法',
                defaultValue: submitButtonConfig.callFunction,
                fieldType: EWidgetType.Select,
                fieldProps: {
                  options: [
                    {
                      label: '保存',
                      value: 'save',
                    },
                  ],
                  allowClear: false,
                },
              },
            }}
          />
        </>
      ),
    },
    {
      title: '方法校验',
      key: `methods-validation-config${currentPage?.id}`,
      configComponent: (
        <MethodsValidation
          bindedMethods={bindedMethods}
          bindedMethodsCconfig={methodsValidationConfig}
          onChange={(values) => {
            values.loadMethods = filterNullMethods(values.loadMethods || []);
            values.updateMethods = filterNullMethods(values.updateMethods || []);
            uMethodsValidationConfig(values);
          }}
        />
      ),
    },
  ];

  if (!ready) {
    // 数据末准备好，则不能渲染表单，否则初始化的表单是有误的
    return null;
  }

  return (
    <>
      <WithCollapse collapseDatas={CollapseDatas} defaultOpenFirstOne={false} expandAll />
      <SortableTable
        bordered
        size="small"
        title={() => null}
        columns={columns}
        dataSource={usedFields}
        rowKey="controlId"
        pagination={false}
        showHeader={false}
        moveRow={moveRow}
      />
    </>
  );
});

export default EdiBox;
