import { memo } from 'react';
import { Typography } from 'antd';

interface UploadControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const UploadControl = memo<UploadControlProps>(() => {
  return <Typography.Text type="danger">待开发...</Typography.Text>;
});

export default UploadControl;
