import { memo } from 'react';
import { Input, Switch, Select } from 'antd';
import useStore from '@/store/form-generator';
import useAddStore from '@/store/form-generator-add';
import useEditStore from '@/store/form-generator-edit';
import useDetailStore from '@/store/form-generator-detail';
import { EPageType } from '@/constant';

interface HiddensConfigProps {
  value?: any;
  onChange?: any;
  curControlStore?: any;
}

export enum Operators {
  Equal = '==',
  NotEqual = '!=',
  EqualNull = '== null',
  NotEqualNull = '!= null',
}

const HiddensConfig = memo<HiddensConfigProps>(({ value, onChange, curControlStore }) => {
  const { currentPage } = useStore();
  const { fields: addFields } = useAddStore();
  const { fields: editFields } = useEditStore();
  const { fields: detailFields } = useDetailStore();
  const fields =
    {
      [EPageType.新增页面]: addFields,
      [EPageType.编辑页面]: editFields,
      [EPageType.查看页面]: detailFields,
    }[String(currentPage.type)] || [];

  return (
    <>
      <div style={{ height: 5 }} />
      <Switch
        checked={!!value?.used}
        onChange={(checked) => {
          onChange?.({
            ...(value || {}),
            used: checked,
          });
        }}
      />
      <div style={{ height: 10 }} />
      {!!value?.used && (
        <>
          当
          <Select
            /* 只能选除自身以外的组件ID */
            options={fields
              .filter((o) => o.controlId !== curControlStore?.controlId)
              .map((o) => ({
                label: `${o.label}(${o.controlId})`,
                value: o.controlId,
              }))}
            value={value?.targetControlId}
            placeholder="请选择目标组件ID"
            allowClear
            onChange={(selectValue) => {
              onChange({
                ...(value || {}),
                targetControlId: selectValue,
              });
            }}
          />
          <Select
            options={[
              { label: '等于', value: Operators.Equal },
              {
                label: '不等于',
                value: Operators.NotEqual,
              },
              { label: '为空', value: Operators.EqualNull },
              {
                label: '不为空',
                value: Operators.NotEqualNull,
              },
            ]}
            value={value?.triggerTargetControlOperator || Operators.Equal}
            onChange={(operator) => {
              onChange({
                ...(value || {}),
                triggerTargetControlOperator: operator,
              });
            }}
          />
          {value?.triggerTargetControlOperator === Operators.EqualNull ||
          value?.triggerTargetControlOperator === Operators.NotEqualNull ? (
            ''
          ) : (
            <Input
              placeholder="请输入值"
              value={value?.triggerTargetControlValue}
              allowClear
              onChange={(event) => {
                onChange({
                  ...(value || {}),
                  triggerTargetControlValue: event.target.value,
                });
              }}
            />
          )}
          时当前组件隐藏
          <div style={{ height: 30 }} />
        </>
      )}
    </>
  );
});

export default HiddensConfig;
