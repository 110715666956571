import React, { ReactNode, useCallback } from 'react';
import { ToolbarButtonProperties } from '@/components/FormGeneractor/ConfigComponent/ListTable/ConfigToolbarButton';
import { DownOutlined } from '@ant-design/icons';
import HelpText from '@/components/HelpText';
import { Button, Dropdown, Menu, message } from 'antd';
import useCurPageStore from '../useCurPageStore';
import { EPageType, EPageSourceType } from '@/constant';
import { exportJSONToExcelFile } from '@/utils/excle';
import moment from 'moment';
import useEnumRequest from '../useEnumRequest';

const FILE_NAME_TIME_FORMAT = 'YYYYMMDDHHmmss';

// 获取所有搜索结果
async function getAllResult(
  request: (params: any) => any,
  current: number,
  pageSize: number,
): Promise<any[]> {
  const result = await request({
    current,
    pageSize,
  });
  if (!result.success) {
    return [];
  }
  if (result.total < pageSize) {
    return result.data;
  }
  if (result.total > current * pageSize) {
    return result.data.concat(await getAllResult(request, current + 1, pageSize));
  }
  return result.data;
}

// 通过按钮属性生成按钮内容
export function getButtonContents(properties?: ToolbarButtonProperties): ReactNode[] {
  return [
    properties?.text || '新增',
    properties?.hasTip && properties?.tipContent && (
      <HelpText iconColor="#fff" toolTip={properties?.tipContent} />
    ),
    properties?.buttonType === 'dropdown' ? <DownOutlined /> : null,
  ];
}

const ToolbarButton: React.FC<{
  fields?: any[];
  pageModeId: string;
  properties: ToolbarButtonProperties;
  searchParams?: any;
  openExternalPage?: (config: any) => Promise<any>;
  request?: (params: any) => any;
}> = ({ pageModeId, properties, request, searchParams, fields = [], openExternalPage }) => {
  const dataTransform: Record<string, any> = {};
  const { fetchOptions } = useEnumRequest();
  const { store } = useCurPageStore(pageModeId);
  const { pages, uEditorVisible, uCurrentDrawerServiceTarget } = store;

  fields.forEach(
    ({
      dataIndex = '',
      displayType,
      title,
      transferFormat = 'YYYY-MM-DD',
      transferFormatCustom,
      dyOptionsInfo,
      options,
    }) => {
      if (dataIndex.length < 1) {
        return false;
      }
      let timeFormat = ''; // 日期转换
      if (transferFormat === 'custom') {
        timeFormat = transferFormatCustom;
      } else {
        timeFormat = transferFormat;
      }

      dataTransform[dataIndex] = {
        displayType,
        title,
        timeFormat,
        dynamicOptions: dyOptionsInfo, // 动态枚举
        enumOptions: options, // 静态枚举
      };
    },
  );

  // 根据配置绑定的 page 来确定如何打开页面
  const openPage = useCallback(
    async (config: ToolbarButtonProperties) => {
      if (config.action === 'openPage') {
        // open external page by drawer or modal.
        if (config.pageSourceType === EPageSourceType.EXTERNAL) {
          await openExternalPage?.(config);
          return;
        }
      }
      const { page } = config;
      const { pageList } = pages[pageModeId];
      const target = pageList?.find((item: any) => {
        if (page) {
          return item.id === page;
        } else {
          return String(item.type) === EPageType.新增页面;
        }
      });

      if (target) {
        if (String(target.type) === EPageType.新增页面) {
          uCurrentDrawerServiceTarget(pageModeId, target.id);
          uEditorVisible(pageModeId, true);
        }
      }
    },
    [pages, pageModeId, uCurrentDrawerServiceTarget, uEditorVisible],
  );

  const getData = useCallback(async () => {
    const current = 1;
    const pageSize = 1000;

    // 没有请求方法，则是预览界面
    if (typeof request !== 'function') {
      const { exportKeys = [], fileType = 'xlsx', fileName = '导出数据' } = properties;
      const newData: any[] = [];
      exportKeys.forEach(({ title }: { title: string }) => {
        newData.push({
          [title]: '',
        });
      });
      // 输出为excel
      exportJSONToExcelFile({
        jsonDataArray: newData,
        suffix: fileType,
        fileName: `${fileName}_${moment().format(FILE_NAME_TIME_FORMAT)}`,
      });
      return;
    }

    // 检测是否有添加搜索条件 pageSize 和 pageNo 除外
    if (
      Object.entries(searchParams).filter(
        ([key, value]) =>
          !['pageSize', 'pageNo', 'pageNumber'].includes(key) &&
          typeof value !== 'undefined' &&
          value !== '',
      ).length < 1
    ) {
      message.error('请选择搜索条件');
      return;
    }

    // 有请求方法，则使用请求方法
    const result = await getAllResult(request, current, pageSize);
    const { exportKeys = [], fileType = 'xlsx', fileName = '导出数据' } = properties;
    const newData: any[] = [];

    // 遍历用户选中的表字段
    result.forEach((item: any) => {
      const newItem: any = {};
      exportKeys.forEach(async ({ propName, title }: { propName: string; title: string }) => {
        const itemValue = item[propName];
        if (typeof itemValue !== 'undefined') {
          const { displayType, dyOptionsInfo, enumOptions, timeFormat } = dataTransform[propName];
          let enums = enumOptions;
          let itemEnumInfo;
          switch (displayType) {
            case 'enums':
              if (typeof dyOptionsInfo !== 'undefined') {
                // 动态枚举
                enums = await fetchOptions(dyOptionsInfo); // [{label, value}]
              }

              itemEnumInfo = enums.find((enumInfo: any) => enumInfo.value === `${itemValue}`);

              if (typeof itemEnumInfo !== 'undefined') {
                newItem[title] = itemEnumInfo.label;
              }
              break;
            case 'date-time':
              // 日期
              newItem[title] = moment(itemValue).format(timeFormat);
              break;
            default:
              newItem[title] = itemValue;
          }
        } else {
          newItem[title] = '';
        }
      });
      newData.push(newItem);
    });

    // 输出为excel
    exportJSONToExcelFile({
      jsonDataArray: newData,
      suffix: fileType,
      fileName: `${fileName}_${moment().format(FILE_NAME_TIME_FORMAT)}`,
    });
  }, [request, properties, searchParams, dataTransform, fetchOptions]);

  if (properties.hide) {
    return null;
  }

  // 兼容旧数据
  const action = properties.action ?? 'openPage';
  if (action === 'openPage' && properties.buttonType !== 'dropdown') {
    return (
      <Button type="primary" key="add" onClick={() => openPage(properties)}>
        {getButtonContents(properties)}
      </Button>
    );
  }

  // 下拉菜单的支持
  if (properties.buttonType === 'dropdown') {
    const menu = (
      <Menu
        onClick={(e) => {
          const { key } = e;
          const config = properties.children[Number(key)];
          if (config.action === 'openPage') {
            openPage(config);
          }
        }}
        items={(properties.children ?? []).map((buttonProperties, index: number) => ({
          key: index,
          label: getButtonContents(buttonProperties),
        }))}
      />
    );
    return (
      <Dropdown overlay={menu}>
        <Button type="primary">{getButtonContents(properties)}</Button>
      </Dropdown>
    );
  }

  // 导出按钮
  if (properties.buttonType === 'download') {
    const { text } = properties;

    return (
      <Button type="primary" onClick={getData}>
        {text}
      </Button>
    );
  }

  return null;
};

export default ToolbarButton;
