import React, { useCallback, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createFormPageConfig, createListPageConfig } from '@/services/page';
import type { TReturn } from '@/types';
import { EPageType, PageTypes } from '@/constant';
import FormCreator, { EWidgetType, FCRules } from '../FormCreator';
import PickMethod from '../PickMethod';

export const LIST_PAGE = 0;
export const FORM_PAGE = 1;

const CreatePage: React.FC<{ groudId: string; onCreate?: (id: string) => void }> = ({
  groudId,
  onCreate,
}) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<Record<string, any>>({});
  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const onCancel = useCallback(() => {
    setVisible(false);
    setData({});
  }, []);

  const submit = useCallback(async () => {
    const { type, loadMethodId, submitMethodId } = data;
    let res: TReturn<any> | undefined;
    const params = {
      pageGroupId: groudId,
      loadMethodId: loadMethodId?.id,
      submitMethodId: submitMethodId?.id,
      name: data.name || '',
      description: '',
      properties: '',
      orderNum: 1,
      titleProperties: '',
      paginationProperties: '',
      contentProperties: '',
      searchParams: [],
      toolbarButtons: [],
      operationButtons: [],
      contentColumns: [],
      methodReferences: [],
    };

    if (type === EPageType.列表页面) {
      res = await createListPageConfig(params);
    } else if ([EPageType.新增页面, EPageType.编辑页面, EPageType.查看页面].includes(type)) {
      res = await createFormPageConfig(params, type);
    }
    if (res?.rCode === 0) {
      console.log(res.data?.id);
      message.success('页面创建成功');
      onCancel();
      onCreate?.(res.data?.id);
    } else {
      message.error(res?.msg || res?.message || '新建异常');
    }
  }, [groudId, data, onCancel, onCreate]);

  return (
    <>
      <Button type="primary" shape="circle" size="small" onClick={show}>
        <PlusOutlined />
      </Button>
      <Modal title="新建页面" onCancel={onCancel} visible={visible} footer={null} destroyOnClose>
        <FormCreator
          debug
          submitter={{
            render: (props) => [
              <Button htmlType="button" key="cancel" onClick={onCancel}>
                取消
              </Button>,
              <Button
                type="primary"
                htmlType="button"
                key="submit"
                onClick={() => props.form?.submit?.()}
              >
                保存
              </Button>,
            ],
          }}
          labelAlign="left"
          layout="horizontal"
          initialValues={data}
          onFinish={submit}
          onValuesChange={(_, allValues) => {
            setData(allValues);
          }}
          config={{
            type: {
              label: '页面类型',
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: PageTypes,
              },
              defaultValue: EPageType.列表页面,
              rules: [FCRules.Required()],
            },
            name: {
              label: '页面名称',
              fieldType: EWidgetType.Input,
              rules: [FCRules.Required()],
            },
            loadMethodId: {
              label: '加载方法',
              fieldType: EWidgetType.CustomWidget,
              whenHidden(formData) {
                return formData.type === EPageType.新增页面;
              },
              custom(props: any) {
                return (
                  <PickMethod
                    {...props}
                    value={props?.value ? [props?.value] : undefined}
                    getLabelByValue={(methods) => {
                      return methods?.length ? methods[0].name : '请选择';
                    }}
                    checkedList={[props?.value].filter((o) => Boolean(o))}
                    onChange={(methods) => {
                      const method = methods?.length ? methods[0] : null;
                      if (method) {
                        props?.onChange?.({
                          id: method.id,
                          name: method.name,
                        });
                      }
                    }}
                  />
                );
              },
            },
            submitMethodId: {
              label: '提交方法',
              fieldType: EWidgetType.CustomWidget,
              whenHidden(formData) {
                return formData.type === EPageType.列表页面 || formData.type === EPageType.查看页面;
              },
              custom(props: any) {
                return (
                  <PickMethod
                    {...props}
                    value={props?.value ? [props?.value] : undefined}
                    getLabelByValue={(methods) => {
                      return methods?.length ? methods[0].name : '请选择';
                    }}
                    checkedList={[props?.value].filter((o) => Boolean(o))}
                    onChange={(methods) => {
                      const method = methods?.length ? methods[0] : null;
                      if (method) {
                        props?.onChange?.({
                          id: method.id,
                          name: method.name,
                        });
                      }
                    }}
                  />
                );
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default CreatePage;
