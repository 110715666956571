import { utils, writeFileXLSX, read } from 'xlsx';

interface TJson {
  [key: string]: any;
}

interface TExportJSONToExcelFileParams {
  jsonDataArray: TJson[];
  sheetName?: string;
  fileName?: string;
  suffix?: string;
}
interface TExportJSONToExcelFile {
  (params: TExportJSONToExcelFileParams): void;
}

/**
 * 将json输出为excel
 * @param jsonDataArray json数组
 * @param sheetName 工作表名称
 * @param fileName 文件名称
 */
export const exportJSONToExcelFile: TExportJSONToExcelFile = ({
  jsonDataArray,
  sheetName = 'sheet1',
  fileName = '数据',
  suffix = 'xlsx',
}) => {
  const ws = utils.json_to_sheet(jsonDataArray);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, sheetName);
  writeFileXLSX(wb, `${fileName}.${suffix}`);
};

interface TExchangeExcelFileToJSON {
  (excelDownloadUrl: string, sheetName: string): Promise<TJson[]>;
}

/**
 * 将远程的excel文件，下载后，转换成json
 * @param excelDownloadUrl excel文件下载链接
 * @param sheetName 读取的工作表
 */
export const exchangeExcelFileToJSON: TExchangeExcelFileToJSON = async (
  excelDownloadUrl,
  sheetName,
) => {
  const fileData = await (await fetch(excelDownloadUrl)).arrayBuffer();
  const wb = read(fileData);
  const readSheetName = sheetName ?? wb.SheetNames[0];
  const ws = wb.Sheets[readSheetName]; // get the first worksheet
  const data: TJson[] = utils.sheet_to_json(ws); // generate objects
  return data;
};
