import { memo } from 'react';
import { useParams } from 'react-router-dom';
import PageParser from '@/components/PageParser';
import { EModeType } from '@/store/form-generator-parse';

const PublishedPage = memo(() => {
  const params = useParams();

  return (
    <>{params.pageId && <PageParser mode={EModeType.production} pageModelId={params.pageId} />}</>
  );
});

export default PublishedPage;
