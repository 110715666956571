import create from 'zustand';

interface StoreType {
  // 加载中
  isDraft?: boolean;
  uIsDraft: (isDraft: boolean) => void;

  // 当前页面组 ID
  currentPageGroupId: string;
  uCurrentPageGroupId: (...args: any[]) => void;

  // 当前页面组信息
  currentPageGroupInfo: any;
  uCurrentPageGroupInfo: (...args: any[]) => void;

  // 模型方法列表
  methods: any[];
  uMethods: (method: any[]) => void;

  // 加载中
  loading?: boolean;
  uLoading: (loading: boolean) => void;

  // 页面列表
  pageList: any[];
  uPageList: (...args: any[]) => void;
  uDefaultPage: (id: string) => void;
  uRemovePage: (id: string) => void;

  // 当前页面信息
  currentPage: any | null;
  uCurrentPage: (...args: any[]) => void;

  // 当前聚焦组件
  currentFocusCmp: string;
  uCurrentFocusCmp: (...args: any[]) => void;

  // 可视
  editorVisible: boolean;
  uEditorVisible: (...args: any[]) => void;

  // 动态枚举方法（目前暂时只有一个）
  dyMethod: any;
  uDyMethod: (...args: any) => void;

  dyMethods: any;
  uDyMethods: (...args: any) => void;

  // 数据源信息
  dataSourceDetail: any;
  uDataSourceDetail: (...args: any) => void;

  // 挑选组件列表
  cmpListPanel: {
    visible: boolean;
    resolve?: (...args: any) => void;
  };
  uCmpListPanel: (...args: any) => void;

  // 数据重置
  reset: () => void;

  // 回滚的时候 重置
  rollback: () => void;
}

const useFormGeneratorStore = create<StoreType>((set) => ({
  isDraft: false,
  uIsDraft: (isDraft: boolean) =>
    set(() => ({
      isDraft,
    })),

  currentPageGroupId: '',
  uCurrentPageGroupId: (currentPageGroupId: string) =>
    set(() => ({
      currentPageGroupId,
    })),

  loading: false,
  uLoading: (loading) =>
    set(() => ({
      loading,
    })),

  currentPageGroupInfo: null,
  uCurrentPageGroupInfo: (info: any) =>
    set(() => ({
      currentPageGroupInfo: info,
    })),

  pageList: [],
  uPageList: (val: any[]) =>
    set(() => ({
      pageList: val,
    })),
  // 删除页面
  uRemovePage(id: string) {
    set((state) => {
      const { pageList } = state;
      return {
        pageList: pageList.filter((page) => page.id !== id),
      };
    });
  },
  // 更新首页设置
  uDefaultPage(id: string) {
    set((state) => {
      const { pageList } = state;
      const val: any[] = pageList.map((page) => {
        return {
          ...page,
          firstPage: page.id === id,
        };
      });
      return {
        pageList: val,
      };
    });
  },

  currentPage: null,
  uCurrentPage: (val: any | null) =>
    set(() => ({
      currentPage: val,
    })),

  currentFocusCmp: '',
  uCurrentFocusCmp: (val: any) =>
    set(() => ({
      currentFocusCmp: val,
    })),

  methods: [],
  uMethods: (val: any) =>
    set(() => ({
      methods: val,
    })),

  dyMethods: [],
  uDyMethods: (val: any) =>
    set(() => ({
      dyMethods: val,
    })),

  // 是否可见
  editorVisible: false,
  uEditorVisible: (val: any) =>
    set(() => ({
      editorVisible: val,
    })),

  dyMethod: null,
  uDyMethod: (val: any) =>
    set(() => ({
      dyMethod: val,
    })),

  dataSourceDetail: null,
  uDataSourceDetail: (val) =>
    set(() => ({
      dataSourceDetail: val,
    })),

  cmpListPanel: {
    visible: false,
  },
  uCmpListPanel: (cmpListPanel: any) =>
    set((state) => ({
      cmpListPanel: {
        ...(state.cmpListPanel || {}),
        ...cmpListPanel,
      },
    })),

  rollback: () =>
    set(() => ({
      isDraft: false,
      currentPageGroupInfo: null,
      pageList: [],
      currentPage: null,
      currentFocusCmp: '',
      dyMethod: null,
      dataSourceDetail: null,
    })),

  reset: () =>
    set(() => ({
      isDraft: false,
      currentPageGroupId: '',
      currentPageGroupInfo: null,
      pageList: [],
      currentPage: null,
      currentFocusCmp: '',
      dyMethod: null,
      dataSourceDetail: null,
    })),
}));

export default useFormGeneratorStore;
