import { memo } from 'react';
import { Pagination as AntPagination, Row } from 'antd';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import useFormGeneratorStore from '@/store/form-generator-list';
import { LeftFixedName } from '../help';

export const Name = 'list_pagination';

export const DEFAULT_CONFIIG = {
  used: true,
  pageSize: 10,
  version: '0.0.1',
};

const Pagination = memo(() => {
  const { bindEvents, isFocus } = useEvents(Name);
  const { listPagination } = useFormGeneratorStore((state) => state);

  return (
    <Row {...bindEvents('fg-pagination common-area')} justify="end">
      {isFocus && <LeftFixedName name="分页" />}
      <AntPagination
        showTotal={(total) => `共 ${total} 条`}
        pageSize={Number(listPagination?.pageSize)}
        showSizeChanger
        total={500}
      />
    </Row>
  );
});

export default Pagination;
