import React, { memo } from 'react';
import ConfigSearch from './ConfigSearch';

const ListSerch = memo<{}>(() => {
  return (
    <div style={{ padding: 3 }}>
      <ConfigSearch />
    </div>
  );
});
export default ListSerch;
