import type { EFieldType } from '../FieldTypeSelect/selector';
import type { IServerfield, IRowItem, IConfigFields } from './types';
import { EMode } from './types';

/**
 * 创建Row数据
 * @param _id
 * @param type
 * @param _level
 * @param _parentId
 * @param _aviableSelect
 * @returns
 */
export function createRowData(
  _id: string,
  dataType: EFieldType,
  _level: number,
  _parentId?: string,
  _aviableSelect?: boolean,
) {
  return {
    // name: '', //randomString(5),
    // title: '', //randomString(5),
    dataType,
    _aviableSelect: typeof _aviableSelect === 'undefined' ? true : _aviableSelect,
    _mode: EMode.编辑中,
    _id,
    _parentId,
    _level,
  } as IRowItem;
}

/**
 * 给行数据添加默认字段
 * @param configFields
 * @param defaultValue
 * @returns
 */
export function addConfigKeyToRowData(
  configFields: IConfigFields,
  row: IRowItem,
  defaultValue = '',
) {
  const result: Record<string, string> = {};
  for (const key in configFields) {
    result[key] = defaultValue;
  }
  return Object.assign({}, row, result);
}

/**
 * 数组转tree结构
 * @param list
 * @returns
 */
export function convertListToTree(
  list: Array<Partial<IServerfield> & { _id: string; _parentId?: string }>,
  indexMap: {
    key: string;
    plusCount?: number;
  } = {
    key: 'index',
  },
) {
  const res: any[] = [];
  const map = list.reduce((value: any, v) => {
    value[v._id] = v;
    return value;
  }, {});
  list.forEach((item: any) => {
    if (!item._parentId) {
      item[indexMap.key] = res.length + (indexMap.plusCount || 0);
      res.push(item);
    } else if (item._parentId in map) {
      const parent = (map as any)[item._parentId];
      parent.fields = parent.fields || [];
      item[indexMap.key] = parent.fields.length + (indexMap.plusCount || 0);
      parent.fields.push(item);
    }
  });
  return res;
}

/**
 * 为IConfigFields填充字段
 * @param cfMap
 * @returns
 */
export function addKeyToConfigFields(cfMap: IConfigFields) {
  for (const key in cfMap) {
    cfMap[key].key = key;
    (cfMap[key] as any).required = true;
  }
  return cfMap;
}

export function clone(obj: any[] | Record<string, any>) {
  return JSON.parse(JSON.stringify(obj));
}
