import type { ForwardRefRenderFunction } from 'react';
import React, { useCallback, useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { changeDataTypeToEditor, changeEditorTypeToServerDataType } from '@/utils/helper';
import type { IRowItem, IServerfield } from './types';
import { EMode } from './types';
import { EMethodParamPassValueType } from '@/types/index';
import { clone } from './helper';
import { EFieldType } from '@/components/FieldTypeSelect/selector';
import { message } from 'antd';
import { uuid } from '@/utils/utils';
import CustomPassValue from './CustomPassValue';
import { JSONSchemaEditor, widgets } from '@lizhife/json-schema-editor';
import JSONEditor from './JSONEditor';

import './index.less';
import '@lizhife/json-schema-editor/dist/style.css';

let changeDebounceTime: any;

interface TSextant {
  orders: string[];
  passValueType?: EMethodParamPassValueType;
  passValueConfig?: string;
}
interface TEditorValue {
  type: string;
  description?: string;
  properties?: any;
  sextant?: TSextant;
  required?: string[];
  items?: any;
}
interface IConfigFieldEditorProps {
  // formData: any;
  onChange?: (val: IRowItem[]) => void;
  // 是否有绑定全局变量功能
  showBindGlobal?: boolean;
  // 是否显示全限定类名
  isShowDeclaredClass?: boolean;
   // 是否显示泛型声明
  isShowGenericClass?: boolean;
}

export interface ConfigFieldEditorHandle {
  generateServerData: () => IServerfield[];
  restoreTreeData: (list: IServerfield[]) => void;
  checkBeforeSubmit: (isOutParams?: boolean, isSkipRcode?: boolean) => Promise<boolean>;
  jsonData2TreeData: (json: Record<string, any>) => void;
}

// 检测字段标题是否为空
function checkValuePropertyName(editorValue: any) {
  let flaw = 0;
  const children = editorValue?.properties ?? editorValue?.items?.properties ?? null;
  if (children == null) {
    return flaw;
  }
  if (['array', 'object'].includes((children as any)?.type)) {
    flaw += checkValuePropertyName(children);
    return flaw;
  }

  for (const key of Object.keys(children)) {
    const value = children[key];
    const description = (value as any)?.description ?? '';
    if (description.length < 1) {
      flaw += 1;
    }
    if (['array', 'object'].includes((value as any)?.type)) {
      flaw += checkValuePropertyName(value);
    }
  }

  return flaw;
}

function EmptyComponent() {
  return null;
}

// 自定义组件－设置值
function CustomChangeValueComponent({
  onChange,
  info,
}: {
  onChange: (key: string, value: any) => void;
  info: any;
}) {
  const onPassValueTypeChange = useCallback(
    (passValueType) => {
      onChange('passValueType', passValueType);
      onChange('passValueConfig', '');
    },
    [onChange],
  );
  const onPassValueConfigChange = useCallback(
    (passValueConfig = ['']) => {
      onChange('passValueConfig', passValueConfig[0]);
    },
    [onChange],
  );

  return (
    <CustomPassValue
      info={info}
      onPassValueTypeChange={onPassValueTypeChange}
      onPassValueConfigChange={onPassValueConfigChange}
    />
  );
}

// 转换服务端数据为参数组件数据格式
function transformData(parentData: any, list: any, isArray: boolean, listDataType: string) {
  // 当前层的数据
  const orders: string[] = [];
  const required: string[] = [];
  const properties: any = {};
  if (isArray) {
    // 当前是数组，需要特殊处理
    parentData.items = properties;
    properties.type = changeDataTypeToEditor(listDataType);
    transformData(properties, list, false, '');
    return;
  } else {
    parentData.properties = properties;
    parentData.sextant = { ...parentData.sextant, orders };
    parentData.required = required;
  }

  list.forEach((item: any) => {
    // 取出值
    const {
      name,
      title,
      declaredClass,
      genericClass,
      dataType,
      genericType,
      passValueType = EMethodParamPassValueType.直接传值,
      passValueConfig = '',
      fields = [],
    } = item;
    const type = changeDataTypeToEditor(dataType);
    orders.push(name);
    required.push(name);
    const data: any = {
      type,
      description: title,
      declaredClass,
      genericClass,
      sextant: {
        passValueType: `${passValueType}`,
        passValueConfig,
      },
    };
    properties[name] = data;

    if (fields !== null && fields.length > 0) {
      // 有下一级
      transformData(data, fields, type === 'array', genericType);
    } else if (type === 'array') {
      // 普通数组，需要设置数组的数据类型
      data.items = {
        type: changeDataTypeToEditor(genericType),
      };
    }
  });

  return parentData;
}

// 参数组件数据转换成服务端数据格式
function transformServerData(result: any[], parentData: any, parentOrders: string[]) {
  const properties = parentData?.properties ?? {};
  parentOrders.forEach((id: string, index: number) => {
    const fields: any[] = [];
    const data = properties[id];
    const name = id;
    const dataType = changeEditorTypeToServerDataType(data.type);
    const title = data?.description ?? '';
    const genericType =
      data.type === 'array' ? changeEditorTypeToServerDataType(data?.items?.type) : '';
    const orderNum = index + 1;
    const passValueType = data?.sextant?.passValueType ?? EMethodParamPassValueType.直接传值;
    const passValueConfig = data?.sextant?.passValueConfig ?? '';

    const dataOrders =
      data.type === 'array' ? data?.items?.sextant?.orders ?? [] : data?.sextant?.orders ?? [];

    if (dataOrders.length > 0) {
      // 有下一级
      transformServerData(fields, data.type === 'array' ? data.items : data, dataOrders);
    }
    const {declaredClass,genericClass} = data
    result.push({
      dataType,
      name,
      title,
      orderNum,
      declaredClass,
      genericClass,
      genericType,
      passValueType,
      passValueConfig,
      fields,
    });
  });
}

// 初始化参数组件数据
function restoreEditorData(treeData: IServerfield[]) {
  const rootData = {
    type: 'object',
  };
  transformData(rootData, treeData, false, '');
  return rootData;
}

/**
 * 对数据进行类型判断，返回类型字符串
 * @param data
 */
const typeOf = (data: any) => {
  let type = '';
  if (_.isNumber(data)) {
    if (`${data}`.includes('.')) {
      type = EFieldType.Float;
    } else {
      type = EFieldType.Long;
    }
  } else if (_.isBoolean(data)) {
    type = EFieldType.Boolean;
  } else if (_.isArray(data)) {
    if (!data?.length) {
      type = `${EFieldType.List}/String`;
    } else {
      type = `${EFieldType.List}/${typeOf(data[0])}`;
    }
  } else if (_.isObject(data)) {
    type = EFieldType.Object;
  } else {
    type = EFieldType.String;
  }
  return type;
};

/**
 * 传入对应节点参数和父节点，返回节点数据
 * @param key
 * @param value
 * @param level
 * @param parents
 */
const handleItem = (key: string, value: any, level: number, parents?: IRowItem[]) => {
  const typeResult = typeOf(value);
  const isMultiType = typeResult.split('/').length === 2;
  let dataType = typeResult;
  if (isMultiType) {
    dataType = typeResult.split('/')[0];
  }
  const item: any = {
    name: key,
    title:
      typeResult.split('/').length === 2 ||
      typeResult === EFieldType.Object ||
      typeResult !== EFieldType.String
        ? key
        : value,
    dataType,
    _mode: EMode.预览中,
    _id: uuid(),
    _parentId: parents?.length ? parents[parents.length - 1]._id : undefined,
    _level: level,
    genericType: isMultiType ? typeResult.split('/')[1] : undefined, // 如果是list类型，则看下一级item是什么类型
    fields: [],
  };
  const nowParents = parents?.length ? [...parents, item] : [item];
  if (_.isArray(value) && value?.length && _.isObject(value[0])) {
    const firstOne = value[0];
    item.fields = Object.keys(firstOne).map((sub) => {
      return handleItem(sub, (firstOne as any)[sub], level + 1, nowParents);
    });
  } else if (!_.isArray(value) && _.isObject(value) && Object.keys(value)?.length) {
    for (const sub in value) {
      item.fields.push(handleItem(sub, (value as any)[sub], level + 1, nowParents));
    }
  }
  return item;
};

const ConfigFieldEditor: ForwardRefRenderFunction<
  ConfigFieldEditorHandle,
  IConfigFieldEditorProps
> = (props, ref) => {
  const [editorValue, setEditorValue] = useState({ type: 'object' } as TEditorValue);
  const { isShowDeclaredClass,isShowGenericClass } = props;
  /**
   * 生成服务端字段
   * 保存的字段
   * - dataType 数据类型
   * - name
   * - title
   * - orderNum
   * - genericType
   * - passValueType 什么传值方式
   * - passValueConfig 绑定传值的path
   * - fields
   * @returns
   */
  const generateServerData = useCallback(() => {
    const result: any[] = [];
    transformServerData(result, editorValue, editorValue?.sextant?.orders ?? []);

    return clone(result);
  }, [editorValue]);

  /**
   * 服务端字段转换为TreeItem[]数据
   * @param treeData
   */
  const restoreTreeData = useCallback((treeData: IServerfield[]) => {
    const newResult = restoreEditorData(treeData);
    setEditorValue(newResult);
  }, []);

  /**
   * 导入的json 转换为 tree
   * @param json
   */
  const jsonData2TreeData = useCallback((json: Record<string, any>) => {
    if (!_.isObject(json)) {
      message.error('导入的 JSON 最外层必须是 Object　 类型');
      return;
    }
    const treeData: IRowItem[] = [];
    for (const attr in json) {
      treeData.push(handleItem(attr, (json as any)[attr], 0) as any);
    }

    const rootData = {
      type: 'object',
    };
    transformData(rootData, treeData, false, '');
    setEditorValue(rootData);
  }, []);

  /**
   * 检查是否所有都完成【保存】，用于提交前的校验
   * @returns
   */
  type TcheckBeforeSubmit = (isOutParams?: boolean, isSkipRcode?: boolean) => Promise<boolean>;
  const checkBeforeSubmit: TcheckBeforeSubmit = useCallback(
    async (isOutParams = false, isSkipRcode = false) => {
      const flaw = checkValuePropertyName(editorValue);

      if (flaw > 0) {
        message.error('字段标题不能为空');
        return false;
      }
      const orders = editorValue?.sextant?.orders ?? []; // 首层字段
      let hasRcodeValue = false; // 是否有rCode字段

      if (orders.length < 1) {
        return true;
      }
      // SQL方法，出参不需要rCode
      if (isSkipRcode) {
        return true;
      }

      if (isOutParams && (orders.includes('rCode') || orders.includes('code'))) {
        const codeType = editorValue.properties?.rCode?.type ?? editorValue.properties?.code?.type;
        if (codeType === 'integer') {
          hasRcodeValue = true;
        }
      }

      if (isOutParams && !hasRcodeValue) {
        message.error('出参字段必须要有 code 或 rCode 字段，且类型是 Integer');
        return false;
      }

      return true;
    },
    [editorValue],
  );

  useImperativeHandle(
    ref,
    () => ({
      generateServerData,
      restoreTreeData,
      checkBeforeSubmit,
      jsonData2TreeData,
    }),
    [generateServerData, restoreTreeData, checkBeforeSubmit, jsonData2TreeData],
  );

  useEffect(() => {
    if (changeDebounceTime) {
      clearTimeout(changeDebounceTime);
    }
    changeDebounceTime = setTimeout(() => {
      const result = generateServerData();
      if (props.onChange) {
        props.onChange(result);
      }
    }, 200);
  }, [generateServerData, props]);
  // console.log('editorValue', editorValue);

  return (
    <JSONSchemaEditor
      mockList={[{ label: 'test', value: 'test' }]}
      widgets={widgets}
      value={editorValue}
      isShowDeclaredClass={isShowDeclaredClass}
      isShowGenericClass={isShowGenericClass}
      onChange={setEditorValue}
      customChangeValueComponent={
        props.showBindGlobal ? CustomChangeValueComponent : EmptyComponent
      }
      jsonEditor={JSONEditor}
    />
  );
};

export default forwardRef(ConfigFieldEditor);
