import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import BindParam from '../common/BindParam';
import WithCollapse from '../../WithCollapse';
import { defaultTimeOptions, EDefaultTimeOptions, showOptions } from '@/types';
import { useCustom } from '../../../../hooks/useCustom';
import omit from 'lodash/omit';
import { debounce } from 'lodash';
import { isDateBindParam, newTransferOptions, transferDefault } from '../helper';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

export default function RangePickerConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const [isCustom, ifCustom] = useCustom(
    fieldInfo,
    'transferFormat',
    fieldInfo?.transferFormatCustom,
  );

  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...omit(commonConfig.base.formConfig(fieldInfo, store), [
              'placeholder',
              'defaultValue',
            ]),
            bindParam: {
              label: '绑定参数',
              tooltip: '设置当前组件关联对应方法下的参数',
              defaultValue: fieldInfo?.bindParam,
              fieldType: EWidgetType.CustomWidget,
              custom: (props: any) => (
                <>
                  <BindParam
                    {...props}
                    mixBindVarFieldName="startTime"
                    customLabel="开始时间"
                    bindedMethods={store?.bindedMethods}
                  />
                  <BindParam
                    {...props}
                    mixBindVarFieldName="endTime"
                    customLabel="结束时间"
                    bindedMethods={store?.bindedMethods}
                  />
                </>
              ),
            },
            defaultTime: {
              label: '默认时间',
              defaultValue: fieldInfo?.defaultTime ?? EDefaultTimeOptions.specifiedTime,
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: defaultTimeOptions,
              },
            },
            defaultDays: {
              label: '天数',
              defaultValue: fieldInfo?.defaultDays,
              fieldType: EWidgetType.InputNumber,
              whenHidden(formData) {
                return formData.defaultTime !== EDefaultTimeOptions.nearlyXDays;
              },
            },
            startTime: {
              label: '开始时间',
              defaultValue: fieldInfo?.startTime,
              fieldType: EWidgetType.DatePicker,
              fieldProps: {
                showTime: { format: 'HH:mm:ss' },
                format: 'YYYY-MM-DD HH:mm:ss',
              },
              whenHidden(formData) {
                return formData.defaultTime !== EDefaultTimeOptions.specifiedTime;
              },
            },
            endTime: {
              label: '结束时间',
              defaultValue: fieldInfo?.endTime,
              fieldType: EWidgetType.DatePicker,
              fieldProps: {
                showTime: { format: 'HH:mm:ss' },
                format: 'YYYY-MM-DD HH:mm:ss',
              },
              whenHidden(formData) {
                return formData.defaultTime !== EDefaultTimeOptions.specifiedTime;
              },
            },
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.({
              startTime: undefined,
              endTime: undefined,
              ...allVals,
            });
          }, 500)}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            showFormat: {
              label: '展示格式',
              defaultValue: fieldInfo?.showFormat || 'YYYY-MM-DD',
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: showOptions,
              },
            },
            transferFormat: {
              label: '传值格式',
              defaultValue: fieldInfo?.transferFormat || transferDefault(fieldInfo),
              fieldType: EWidgetType.Select,
              fieldProps: {
                value: isDateBindParam(fieldInfo) ? 'x' : fieldInfo?.transferFormat || 'YYYY-MM-DD',
                options: newTransferOptions(fieldInfo),
              },
              onChange: ifCustom,
            },
            ...isCustom,
            ...omit(commonConfig.interaction.formConfig(fieldInfo), ['rules']),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
