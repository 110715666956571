import create from 'zustand';
import type { AddEditDetailCommonStoreType } from './types';
import deepmerge from 'deepmerge';

type StoreType = AddEditDetailCommonStoreType;

const Store = create<StoreType>((set) => ({
  hasChange: false, // 标记是否修改过
  uHasChange: (hasChange: boolean) =>
    set(() => ({
      hasChange,
    })),

  ready: false,
  uReady: (bool) => set(() => ({ ready: bool })),

  title: '新建表单',
  uTitle: (title) => set((state) => ({ hasChange: !!state.ready, title })),

  bindedMethods: {},
  uBindedMethods: (val: any) =>
    set((state) => ({
      hasChange: !!state.ready,
      bindedMethods: deepmerge(state.bindedMethods, val, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    })),

  addMethod: null,
  uAddMethod: (addMethod: any) => set((state) => ({ hasChange: !!state.ready, addMethod })),

  labelColWidth: 200,
  uLabelColWidth: (labelColWidth) => set((state) => ({ hasChange: !!state.ready, labelColWidth })),

  wrapperColWidth: undefined,
  uWrapperColWidth: (wrapperColWidth) =>
    set((state) => ({ hasChange: !!state.ready, wrapperColWidth })),

  cancelButtonConfig: {
    text: '取消',
    shapeType: 'default',
    styleType: 'default',
  },
  uCancelButtonConfig: (cancelButtonConfig) =>
    set((state) => ({ hasChange: !!state.ready, cancelButtonConfig })),

  submitButtonConfig: {
    text: '保存',
    styleType: 'default',
    shapeType: 'default',
    callFunction: 'save',
  },
  uSubmitButtonConfig: (submitButtonConfig) =>
    set((state) => ({ hasChange: !!state.ready, submitButtonConfig })),

  fields: [
    /* {
      controlType: FieldCmpType.输入框,
      controlId: `${nanoid()}`,
      label: '游戏名称',
      required: true,
      used: true,
    },
    {
      controlType: FieldCmpType.数字输入框,
      controlId: `${nanoid()}`,
      label: '游戏ID',
      used: true,
    },
    {
      controlType: FieldCmpType.多图上传,
      controlId: `${nanoid()}`,
      label: '游戏图片',
      used: true,
      maxCount: 3, // 上传图片限制数量
    },
    {
      controlType: FieldCmpType.富文本,
      controlId: `${nanoid()}`,
      label: '游戏简介',
      required: true,
      used: true,
    },
    {
      controlType: FieldCmpType.视频上传,
      controlId: `${nanoid()}`,
      label: '游戏视频',
      used: true,
    },
    {
      controlType: FieldCmpType.下拉多选框,
      controlId: `${nanoid()}`,
      label: '游戏支持语言',
      required: true,
      used: true,
    },
    {
      controlType: FieldCmpType.单选框,
      controlId: `${nanoid()}`,
      label: '接入情况',
      used: true,
      defaultValue: '1',
      options: [
        {
          label: '已接入',
          value: '1',
        },
        {
          label: '待接入',
          value: '2',
        },
      ],
    },
    {
      controlType: FieldCmpType.下拉多选框,
      controlId: `${nanoid()}`,
      label: '游戏分类',
      required: true,
      options: [
        {
          label: '棋牌',
          value: '1',
        },
        {
          label: '团战',
          value: '2',
        },
      ],
      used: true,
    },
    {
      controlType: FieldCmpType.下拉框,
      controlId: `${nanoid()}`,
      label: '付费方式',
      required: true,
      options: [
        {
          label: '按月付费',
          value: '1',
        },
      ],
      used: true,
    },
    {
      controlType: FieldCmpType.单选框,
      controlId: `${nanoid()}`,
      label: '是否有低消',
      used: true,
      required: true,
      defaultValue: '1',
      options: [
        {
          label: '无',
          value: '1',
        },
        {
          label: '有',
          value: '2',
        },
      ],
    },
    {
      controlType: FieldCmpType.输入框,
      controlId: `${nanoid()}`,
      label: '低消',
      required: true,
      used: true,
    },
    {
      controlType: FieldCmpType.输入框,
      controlId: `${nanoid()}`,
      label: '计费规则',
      required: true,
      used: true,
    },
    {
      controlType: FieldCmpType.下拉框,
      controlId: `${nanoid()}`,
      label: '供应商',
      required: true,
      options: [
        {
          label: '即购',
          value: '1',
        },
        {
          label: '声网',
          value: '2',
        },
        {
          label: '度宇',
          value: '3',
        },
      ],
      used: true,
    }, */
  ],
  uFields: (fields) => set((state) => ({ hasChange: !!state.ready, fields })),

  serviceConfig: null,
  uServiceConfig: (serviceConfig: any) =>
    set((state) => ({ hasChange: !!state.ready, serviceConfig })),

  methodsValidationConfig: {},
  uMethodsValidationConfig: (methodsValidationConfig) =>
    set((state) => ({ hasChange: !!state.ready, methodsValidationConfig })),

  reset: () =>
    set(() => ({
      hasChange: false,
      fields: [],
      serviceConfig: null,
      bindedMethods: {},
      // methodsValidationConfig: {},
      submitButtonConfig: {
        text: '保存',
        styleType: 'default',
        shapeType: 'default',
        callFunction: 'save',
      },
      cancelButtonConfig: {
        text: '取消',
        shapeType: 'default',
        styleType: 'default',
      },
      wrapperColWidth: undefined,
      labelColWidth: 200,
      title: '新建表单',
      ready: false,
    })),
}));

export default Store;
