import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BaseLayout from '@/layouts/baseLayout';
import CustomPage from '@/pages/custom';
import NoFoundPage from '@/pages/404';
import TemplatePages from '@/pages/TemplatePages';
// import PlayGround from '@/pages/PlayGround'
import PublishedPage from '@/pages/PublishedPage';
import MethodConfig from '@/pages/MethodConfig';
import ExternalPage from '@/pages/ExternalPage';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/iframe/:url" element={<CustomPage />} />
        <Route path="/published/page/:pageId" element={<PublishedPage />} />
        {/* old template page enter */}
        <Route path="/pages" element={<TemplatePages />} />
        {/* new template page enter */}
        <Route path="/pages-v1/native" element={<TemplatePages />} />
        <Route path="/pages-v1/external" element={<ExternalPage />} />
        {/* 方法配置 */}
        <Route path="/method/config" element={<MethodConfig />} />
        <Route path="*" element={<NoFoundPage />} />
      </Route>
    </Routes>
  );
}
export default Router;
