import { memo, useMemo, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { List, Row, Typography } from 'antd';
import { parseString2Json } from '@/components/FormGeneractor/help';
import { EModeType } from '@/store/form-generator-parse';
import useCurPageStore from '../../useCurPageStore';
import './index.less';
import DisplayComponent from '../DisplayComponent';
import { EPageLayoutCmpType } from '@/constant';
import HelpText from '@/components/HelpText';

interface ViewProps {
  pageModeId: string;
}
export default memo<ViewProps>(({ pageModeId }) => {
  const { curPageStore } = useCurPageStore(pageModeId);
  const { addEditViewConfigInfo, mode, configForDetailInit } = curPageStore;

  const findValueByBindParam = useCallback(
    (bindParam: any[]) => {
      const { bindVar } = bindParam?.find((o) => o.type === 'loadMethod') || {};
      const paramId = bindVar?.fieldId;
      const { detailHashMap } = configForDetailInit || {};
      if (!detailHashMap) return null;
      return typeof detailHashMap[paramId] === 'undefined' ? null : detailHashMap[paramId];
    },
    [configForDetailInit],
  );

  const { labelColWidth, wrapperColWidth, list } = useMemo(() => {
    const result: any = {};
    const data: Record<string, any> = {};
    console.log('addEditViewConfigInfo', addEditViewConfigInfo);
    if (!addEditViewConfigInfo) return result;
    const pageProperties: any = parseString2Json(addEditViewConfigInfo.properties);
    if (!addEditViewConfigInfo?.components?.length) return result;
    result.labelColWidth = pageProperties.labelColWidth;
    result.wrapperColWidth = pageProperties.wrapperColWidth;
    const { components } = cloneDeep(addEditViewConfigInfo);

    const fields = (components || []).map((o: any) => {
      const properties: any = parseString2Json(o.properties);
      const value =
        mode === EModeType.production ? findValueByBindParam(properties.bindParam) : null;
      const info = Object.assign(
        {
          required: false,
          hide: false,
          mode,
          finalValue: value,
        },
        properties,
      );
      data[properties.controlName] = value;
      return info;
    });

    result.list = fields;
    return result;
  }, [addEditViewConfigInfo, configForDetailInit, findValueByBindParam, mode]);

  // log('详情字段：', list)
  // log('配置', addEditViewConfigInfo?.components)

  return (
    <>
      <List
        bordered={false}
        dataSource={list}
        rowKey="controlId"
        renderItem={(item: any) => {
          const isTitle = item.controlType === EPageLayoutCmpType.标题;
          const hasLabel = (item?.label ?? '').length > 0;
          const showLabel = !isTitle && hasLabel;

          return (
            <Row justify="start" align="middle" className="list-item">
              {showLabel && (
                <Typography.Text
                  className="fg-view-item-label"
                  style={{ flex: `0 0 ${labelColWidth || 200}px` }}
                >
                  {item.label}{' '}
                  {item.hasTip && item.tipContent && <HelpText toolTip={item.tipContent} />} ：
                </Typography.Text>
              )}

              <DisplayComponent item={item} wrapperColWidth={wrapperColWidth} values={list} />
            </Row>
          );
        }}
      />
    </>
  );
});
