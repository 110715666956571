import request from '@/utils/request';
import type {
  Page,
  TReturn,
  MethodInfo,
  EMethodType,
  ExecuteMethodParam,
  PaginationType,
  PageGroup,
  TStructFields,
} from '@/types';
import { prefixMethod, prefixPageGroup } from './common';

// 自定义方法 - 新建
export const createMethod = (data: MethodInfo) => {
  return request.post<TReturn<any>>(prefixMethod('create'), {
    data,
  });
};

// 自定义方法 - 更新
export const updateMethod = (data: MethodInfo & { id: string }) => {
  return request.post<TReturn<any>>(prefixMethod('update'), {
    data,
  });
};

// 自定义方法 - 删除
export const delMethod = (id: string) => {
  return request.post<TReturn<any>>(prefixMethod('delete'), {
    data: { id },
  });
};

// 自定义方法 - 列表
export const methodList = (
  params: {
    name?: string;
    id?: string;
    type?: EMethodType;
  } & Page = {},
) => {
  return request.get<TReturn<any>>(prefixMethod('list'), {
    params,
  });
};

// 自定义方法 - 详情
export const methodDetail = (id: string) => {
  return request.get<TReturn<MethodInfo>>(prefixMethod('get'), {
    params: { id },
  });
};

// 自定义方法 - 页面组
export const methodPageGroupIds = (params: {
  id: string;
  pageSize?: number;
  pageNumber?: number;
}) => {
  return request.get<TReturn<PaginationType<PageGroup>>>(
    prefixPageGroup('customMethodLinkPageGroup'),
    {
      params,
    },
  );
};

// 地区-业务环境
export const getRegionBusinessEnv = () => {
  return request.get<TReturn<any>>(prefixMethod('getRegionBusinessEnv'), {});
};

// 执行方法
export const executeMethod = (data: { methodId: string; paramValues?: ExecuteMethodParam[] }) => {
  return request.post<TReturn<any>>(prefixMethod('execute'), { data });
};

// 自定义方法 - 坐标推断
export interface TGetDCParamsReturn {
  protocolType: number;
  domain: number;
  op?: string;
  inputParams: any[];
  outputParams: any[];
}
export const getDCParams = (params: {
  groupId: string;
  artifactId: string;
  version: string;
  serviceName: string;
  methodName: string;
}) => {
  return request.get<TReturn<TGetDCParamsReturn>>(prefixMethod('mavenDeduce'), {
    params,
  });
};

// 自定义方法-获取业务数据表
export interface TGetBusinessTables {
  tableNames: string[];
}

export const getBusinessTables = (params: {
  regionCode: string;
  businessEnvCode: string;
  namespace: string;
}) => {
  return request.get<TReturn<TGetBusinessTables>>(prefixMethod('getBusinessTables'), { params });
};

// 自定义方法-获取业务表结构
export interface TGetTableStructs {
  tables: Array<{
    tableName: string;
    structFields: TStructFields[];
  }>;
}

export const getTableStructs = (params: {
  regionCode: string;
  businessEnvCode: string;
  namespace: string;
  tables: string[];
}) => {
  return request.get<TReturn<TGetTableStructs>>(prefixMethod('getTableStructs'), { params });
};

interface TGenerateSql {
  sql: string;
  inputParams: any[];
  outputParams: any[];
}
export const generateSql = (data: {
  tableName: string;
  methodChildType: string;
  structFields: TStructFields[];
}) => {
  return request.post<TReturn<TGenerateSql>>(prefixMethod('generateSql'), { data });
};
