import React from 'react';
import { Tooltip } from 'antd';

/* 给组件加上 toolTip */
export default function HocToolTip(
  Component: React.ComponentType<any>,
  toolMsg = '提示文案',
  block = true,
) {
  return (...props: any[]) => {
    return (
      <Tooltip title={toolMsg}>
        <div style={{ display: block ? 'block' : 'inline-block' }}>
          <Component {...props} />
        </div>
      </Tooltip>
    );
  };
}
