import { memo, useCallback, useEffect, useRef } from 'react';
import { Table, Space, Popconfirm, message, Form, Input } from 'antd';
import { EModeType } from '@/store/form-generator-parse';
import type { TableColumnType } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { dragComponents } from '@/components/SortableTable/dragable';
import update from 'immutability-helper';
import useLinkageStore from '@/store/form-generator-linkage';
import { FieldCmpType } from '@/constant';
import SingleDatePicker from '../SingleDatePicker';
import Text from '@/components/Column/Text';
import ImageView from '../../Editor/View/ImageView';

interface TConfigInfo {
  buttonLabel?: string;
  transformKeys?: Record<string, Record<string, any>>;
  limitPrompt?: string;
  bindParam?: any;
  tableConfig: Record<string, Record<string, any>>;
}
interface TableUploadProps {
  fields?: any;
  configInfo?: TConfigInfo;
  value?: any;
  mode: EModeType;
  onChange?: (data: any) => void;
  onValidate?: (errMsg: string) => void;
}

// 组合需要显示的列表数据
function useTableData({
  bindFiledId,
  fields,
  values,
  excelKeyMap,
}: {
  bindFiledId: string;
  fields: any[];
  values: any[];
  excelKeyMap: any;
}) {
  const linkageComponents = useLinkageStore((state) => state.linkageComponents); // 获取弹窗多选的数据
  const uLinkageComponents = useLinkageStore((state) => state.uLinkageComponents);

  // 获取绑定同一更新接口参数的弹窗多选组件
  const bindSamePropComponent: any[] = fields.filter(
    ({ targetParam = [] }: { targetParam: any[] }) => {
      if (targetParam.length < 1) {
        return false;
      }
      const submitInfo = targetParam.find((item: any) => item.type === 'submitMethod');
      const bindId = submitInfo?.bindVar?.fieldId ?? '';
      if (bindId.length < 1) {
        return false;
      }
      return bindId === bindFiledId;
    },
  );

  const keepOnlyMap = new Map();
  const allLineData: any[] = [];
  // 从store拿到所有绑定的弹窗多选组件的数据
  bindSamePropComponent.forEach(({ controlId }) => {
    const info = linkageComponents[controlId];
    if (typeof info === 'undefined') {
      return false;
    }

    const componentCheckData: any[] = info?.data ?? [];

    componentCheckData.forEach((item) => {
      let keepOnly = '';
      for (const key of Object.keys(item).sort()) {
        if (key !== '_controlId') {
          keepOnly += `-${key}${item[key]}`;
        }
      }
      if (keepOnlyMap.has(keepOnly)) {
        keepOnlyMap.set(keepOnly, [...keepOnlyMap.get(keepOnly), controlId]);
      } else {
        keepOnlyMap.set(keepOnly, [controlId]);
        allLineData.push({ ...item });
      }
    });
  });

  // 二次编辑，组件接收到接口返回的数据，是Array[Object]格式，需要同步
  if (allLineData.length < 1 && values !== null && values.length > 0 && !Array.isArray(values[0])) {
    // 接口取回的初始化数据
    const newValue = values.map((item) => {
      const newItem: Record<string, any> = {};
      for (const key of Object.keys(item)) {
        const excelKey = excelKeyMap[key];
        if (typeof excelKey !== 'undefined') {
          newItem[excelKey] = item[key];
        }
      }
      return newItem;
    });

    allLineData.push(...newValue);

    // 将数据存储到弹窗多选组件中
    bindSamePropComponent.forEach(({ controlId }) => {
      uLinkageComponents({
        [controlId]: {
          data: newValue,
          originalData: newValue,
        },
      });
    });
  }

  // 将所有数据加入关联的弹窗多选组件id
  allLineData.forEach((lineData: any) => {
    let keepOnly = '';
    for (const key of Object.keys(lineData).sort()) {
      if (key !== '_controlId') {
        keepOnly += `-${key}${lineData[key]}`;
      }
    }
    const controls = keepOnlyMap.get(keepOnly) ?? [];
    lineData._controlId = controls.sort();
  });

  return allLineData;
}

// 创建哈希表，用于判断两个数据是否相等
function getDataMap(data: any[]) {
  const dataMap = new Map();
  data.forEach((item: any) => {
    const { _controlId: controlIds } = item;
    const ids = controlIds.sort().join('');
    const old = dataMap.get(ids) || [];
    if (dataMap.has(ids)) {
      dataMap.set(ids, [...old, item]);
    } else {
      dataMap.set(ids, [...old, item]);
    }
  });

  return dataMap;
}

// 检测前后两个值是否相等
function checkIsEqualData(oldData: any[], newData: any[]) {
  const oldDataMap = getDataMap(oldData);
  const newDataMap = getDataMap(newData);

  // 条数不一样，则不相等
  if (oldDataMap.size !== newDataMap.size) {
    return false;
  }
  for (const key of oldDataMap.keys()) {
    const oldValuesLen = oldDataMap.get(key)?.length ?? 0;
    const newValuesLen = newDataMap.get(key)?.length ?? 0;

    if (oldValuesLen !== newValuesLen) {
      return false;
    }
  }

  return true;
}

// 通过transformKeys，获取列名和propId的映射
function getExcelKeyByPropName(transformKeys: Record<string, any>) {
  const map: Record<string, string> = {}; // 列名与实际propId的映射关系
  for (const key of Object.keys(transformKeys)) {
    const { excelKey } = transformKeys[key];
    map[key] = excelKey;
  }
  return map;
}

const TableUpload = memo<TableUploadProps>(
  ({ configInfo = {}, mode, onChange, value = [], fields }) => {
    const renderLineData = useRef<any[]>([]);
    const newTableData = useRef<any[]>([]);
    const { transformKeys = {}, bindParam = [], tableConfig = {} } = configInfo; // 获取table数据转换的配置和绑定参数
    const isPreview = mode === EModeType.preview; // 是否为预览
    const submitMethod = bindParam.find((item: any) => item.type === 'submitMethod');
    const bindFiledId = submitMethod?.bindVar?.fieldId ?? ''; // 获取table组件更新的接口参数
    const allLineData = useTableData({
      bindFiledId,
      fields,
      values: value,
      excelKeyMap: getExcelKeyByPropName(transformKeys),
    });
    const uLinkageComponents = useLinkageStore((state) => state.uLinkageComponents);
    const [form] = Form.useForm();

    const checkResult = checkIsEqualData(allLineData, renderLineData.current);
    if (checkResult === false) {
      renderLineData.current = allLineData;
    }

    const getChangeValue = useCallback(() => {
      const changeValue: any[] = [];
      renderLineData.current.forEach((item: any) => {
        const linData: any = []; // 一行数据
        for (const [apiProp, paramInfo] of Object.entries(
          transformKeys as Record<string, Record<string, any>>,
        )) {
          const newItem: any = {};
          const { excelKey } = paramInfo;
          if (typeof item[excelKey] !== 'undefined') {
            newItem.fieldId = paramInfo.id;
            newItem.dataType = paramInfo.dataType;
            newItem.propName = apiProp;
            newItem.value = item[excelKey];
          }
          linData.push(newItem);
        }
        if (linData.length > 0) {
          changeValue.push(linData);
        }
      });
      return changeValue;
    }, [transformKeys]);

    const onConfirm = useCallback(
      (record, index) => {
        if (isPreview) {
          message.warning('预览模式不支持删除');
          return;
        }

        if (newTableData.current && newTableData.current.length) {
          const { _controlId } = record;
          newTableData.current.splice(index, 1);
          const changeValue = getChangeValue();
          onChange?.(changeValue);

          _controlId.forEach((cid: string) => {
            const newData = newTableData.current.filter((item) => item._controlId.includes(cid));
            const saveData: any[] = newData.map((item) => {
              const data: any = {};
              for (const key of Object.keys(transformKeys)) {
                const { excelKey } = transformKeys[key];
                data[excelKey] = item[key];
              }
              return data;
            });

            uLinkageComponents({
              [cid]: {
                data: saveData,
                originalData: saveData,
              },
            });
          });
        }
      },
      [getChangeValue, isPreview, onChange, transformKeys, uLinkageComponents],
    );

    const getKeyByTitle: Record<string, string> = {}; // 列名与实际propId的映射关系

    // 拼装表格列
    const cols: Array<TableColumnType<any>> = [];
    for (const key of Object.keys(transformKeys)) {
      const { excelKey } = transformKeys[key];
      getKeyByTitle[excelKey] = key;
      cols.push({
        title: excelKey,
        dataIndex: key,
        render(text, record, index) {
          let node = text;
          let formNode = <Input />;
          switch (tableConfig[key]?.type) {
            case 'image':
              node = (
                <ImageView
                  configInfo={{
                    preFileLink: tableConfig[key].preFileLink,
                    finalValue: text,
                    mode: EModeType.production,
                  }}
                />
              );
              break;
            case FieldCmpType.日期选择器:
              node = (
                <Text
                  value={record[key]}
                  transferFormat={tableConfig[key].transferFormat}
                  isTime
                  tooltip={false}
                />
              );
              formNode = (
                <SingleDatePicker
                  showFormat={tableConfig[key].showFormat}
                  transferFormat={tableConfig[key].transferFormat}
                />
              );
              break;
            case FieldCmpType.输入框:
            default:
              break;
          }
          return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              <Form.Item name={['renderLineData', index, excelKey]}>
                {tableConfig[key]?.editing ? formNode : node}
              </Form.Item>
            </div>
          );
        },
      });
    }

    useEffect(() => {
      form.setFieldsValue({ renderLineData: renderLineData.current });
    }, [renderLineData.current]);

    if (checkResult === false) {
      newTableData.current = [];
      renderLineData.current.forEach((item) => {
        const newDataItem: Record<string, any> = {};
        for (const key of Object.keys(item)) {
          const newKey = getKeyByTitle[key];
          if (typeof newKey !== 'undefined') {
            newDataItem[newKey] = item[key];
          }
        }
        newDataItem._controlId = item._controlId;
        newTableData.current.push(newDataItem);
      });

      const changeValue = getChangeValue();
      onChange?.(changeValue);
    }

    const columns: Array<TableColumnType<any>> = [
      {
        title: '排序',
        dataIndex: 'sort',
        width: 50,
        render: () => <MenuOutlined />,
      },
      ...cols,
      {
        title: '操作',
        fixed: 'right',
        width: 80,
        render: (_, record, index) => (
          <Space>
            <Popconfirm
              title="确定移除？"
              cancelText="取消"
              okText="确定"
              onConfirm={() => {
                onConfirm(record, index);
              }}
            >
              <span style={{ color: '#ff4d4f', cursor: 'pointer' }}>移除</span>
            </Popconfirm>
            {/* 用于保存隐藏参数 */}
            {/* Used to save hidden parameters */}
            <Form.Item name={['renderLineData', index, '_controlId']} hidden />
          </Space>
        ),
      },
    ];

    const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        if (!newTableData.current) return;
        const dragRow = newTableData.current[dragIndex];
        newTableData.current = update(newTableData.current, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        });

        const row = renderLineData.current[dragIndex];
        renderLineData.current = update(renderLineData.current, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, row],
          ],
        });

        const changeValue = getChangeValue();
        onChange?.(changeValue);
      },
      [newTableData, onChange, getChangeValue],
    );

    return (
      <Form
        form={form}
        onValuesChange={(changedValues, values) => {
          renderLineData.current = values.renderLineData;
          const changeValue = getChangeValue();
          onChange?.(changeValue);
        }}
      >
        <Table<any>
          dataSource={newTableData.current}
          bordered
          scroll={{ x: true }}
          // rowKey="id"
          columns={columns}
          pagination={false}
          components={{ ...dragComponents() }}
          onRow={(record, index) => ({ index, moveRow } as any)}
        />
      </Form>
    );
  },
);

export default TableUpload;
