import { memo } from 'react';
import { Input, Button } from 'antd';
import pick from 'lodash/pick';
import { sandbox } from '@/utils/sandbox';

interface InputControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
  values: any;
  onFill: (data: any) => any;
}

const InputFillControl = memo<InputControlProps>(
  ({ onChange, value, configInfo, values, onFill }) => {
    const { buttonText, fillExpression } = configInfo;

    return (
      <Input.Group>
        <Input
          style={{ width: 'calc(100% - 200px)' }}
          {...pick(configInfo, ['disabled', 'placeholder'])}
          defaultValue={configInfo.defaultValue}
          value={value || configInfo.defaultValue}
          placeholder={configInfo.placeholder || '请输入'}
          onChange={onChange}
        />
        <Button
          type="primary"
          onClick={() => {
            if (fillExpression) {
              const data = sandbox(`return ${fillExpression}`, values);
              onFill(data ?? {});
            }
          }}
        >
          {buttonText}
        </Button>
      </Input.Group>
    );
  },
);

export default InputFillControl;
