import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { debounce } from 'lodash';

interface Props {
  fieldInfo: any;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

export default function InputFillConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            buttonText: {
              label: '按钮文案',
              fieldType: EWidgetType.Input,
              defaultValue: fieldInfo?.buttonText ?? '',
            },
            valueExpression: {
              label: '值转JSON',
              fieldType: EWidgetType.TextArea,
              defaultValue: fieldInfo?.valueExpression,
              extra: 'JS 代码表达式',
            },
            fillExpression: {
              label: '联动表达式',
              tooltip: '配置一个 json 值表达式，当点击按钮时，可以联动多个变化',
              fieldType: EWidgetType.TextArea,
              defaultValue: fieldInfo?.fillExpression ?? '',
            },
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
