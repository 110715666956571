import { memo } from 'react';
import { Input } from 'antd';
import pick from 'lodash/pick';

interface InputControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const InputControl = memo<InputControlProps>(({ onChange, value, configInfo }) => {
  return (
    <Input
      {...pick(configInfo, ['disabled', 'placeholder'])}
      defaultValue={configInfo.defaultValue}
      value={value || configInfo.defaultValue}
      placeholder={configInfo.placeholder || '请输入'}
      onChange={onChange}
    />
  );
});

export default InputControl;
