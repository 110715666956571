import React, { memo } from 'react';
import { Tooltip, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface IEditIconButtonProps {
  msg?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const EditIconButton = memo<IEditIconButtonProps>(({ msg = '编辑', onClick, style }) => {
  return (
    <Tooltip title={msg}>
      <Typography.Link style={style} onClick={onClick}>
        <EditOutlined style={{ color: '#1890ff' }} />
      </Typography.Link>
    </Tooltip>
  );
});
export default EditIconButton;
