import {
  ProFormText,
  ProFormDigit,
  ProFormTextArea,
  ProFormDatePicker,
  ProFormDateTimeRangePicker,
  ProFormSelect,
  ProFormCheckbox,
  ProFormRadio,
  ProFormSwitch,
  ProFormUploadButton,
} from '@ant-design/pro-form';
import { EWidgetType } from './type';

export const pickWidget = (fieldType: EWidgetType): React.ComponentType<any> | null => {
  switch (fieldType) {
    case EWidgetType.Input:
      return ProFormText;
    case EWidgetType.TextArea:
      return ProFormTextArea;
    case EWidgetType.InputNumber:
      return ProFormDigit;
    case EWidgetType.Select:
      return ProFormSelect;
    case EWidgetType.RadioGroup:
      return ProFormRadio.Group;
    case EWidgetType.CheckboxGroup:
      return ProFormCheckbox.Group;
    case EWidgetType.Checkbox:
      return ProFormCheckbox;
    case EWidgetType.Switch:
      return ProFormSwitch;
    case EWidgetType.Upload:
      return ProFormUploadButton;
    case EWidgetType.DatePicker:
      return ProFormDatePicker;
    case EWidgetType.RangePicker:
      return ProFormDateTimeRangePicker;
    default:
      return null;
  }
};

// 字符串提取正则
export const parseRegStr = (str: string) => {
  const begin = str.indexOf('/');
  const end = str.lastIndexOf('/');
  const flags = (str as any).match(/\/([igm]{0,3})$/i)[1];
  return new RegExp(str.substring(begin + 1, end), flags);
};

/**
 * 必填
 * @returns
 */
export const validateRequired = (errMsg?: string) => {
  return {
    required: true,
    message: errMsg || '请输入',
  };
};

/**
 * 校验输入最大长度
 * @param maxLength
 * @returns
 */
export const validateInputCount = (maxLength: number, message?: string) => ({
  validator(_: any, value: string) {
    return value?.length > maxLength
      ? Promise.reject(message || `限${maxLength}个字`)
      : Promise.resolve();
  },
});

/**
 * 校验输入数字最大值
 * @param maxLength
 * @returns
 */
export const validateInputMaxNum = (max: number, errMsg?: string) => ({
  validator(_: any, value: any) {
    return Number(value) > max
      ? Promise.reject(errMsg || `最大不能超过 ${max}`)
      : Promise.resolve();
  },
});

/**
 * 校验输入数字最小值
 * @param minLength
 * @returns
 */
export const validateInputMinNum = (min: number, errMsg?: string) => ({
  validator(_: any, value: any) {
    return Number(value) < min
      ? Promise.reject(errMsg || `最小不能低于 ${min}`)
      : Promise.resolve();
  },
});

/**
 * 整数校验
 * @param minLength
 * @returns
 */
export const validateInt = (errMsg?: string) => ({
  validator(_: any, value: any) {
    return value && !/^-?[1-9]+[0-9]*$/.test(value)
      ? Promise.reject(errMsg || `请输入整数`)
      : Promise.resolve();
  },
});

/**
 * 正整数校验
 * @param minLength
 * @returns
 */
export const validatePosInt = (errMsg?: string) => ({
  validator(_: any, value: any) {
    return value && !/^\+?[1-9][0-9]*$/.test(value)
      ? Promise.reject(errMsg || `请输入正整数`)
      : Promise.resolve();
  },
});

/**
 * 小数点位数校验
 * @param maxDecimal
 * @returns
 */
export const validateDecimal = (maxDecimal: number, errMsg?: string) => ({
  validator(_: any, value: any) {
    const reg = parseRegStr(`/^-?\\d+(\\.\\d{1,${maxDecimal}})?$/`);
    return value && !reg.test(value)
      ? Promise.reject(errMsg || `小数保留${maxDecimal}位`)
      : Promise.resolve();
  },
});

/**
 * 校验输入特殊符号
 * @returns
 */
export const validateInputSpecialStr = (errMsg?: string) => ({
  validator(_: any, value: string) {
    const reg =
      /[`~!@#$%^&*()+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
    return reg.test(value) ? Promise.reject(errMsg || '请输入中英文、数字') : Promise.resolve();
  },
});

/**
 * 正则校验
 * @returns
 */
export const validateReg = (reg: string, errMsg?: string) => ({
  validator(_: any, value: string) {
    const result = parseRegStr(reg);
    return !result.test(value) ? Promise.reject(errMsg || '正则校验不通过') : Promise.resolve();
  },
});

/**
 * 校验合法的 URL
 * @returns
 */
export const validateUrl = (errMsg?: string) => ({
  validator(_: any, value: string) {
    const reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
    return value && !reg.test(value)
      ? Promise.reject(errMsg || '请输入合法的url地址')
      : Promise.resolve();
  },
});

/**
 * 复选框，下拉多选，校验最多可选
 * @returns
 */
export const validateMaxSelectedCount = (maxCount: number) => ({
  validator(_: any, value: any[]) {
    return value?.length > maxCount ? Promise.reject(`最多可选 ${maxCount} 个`) : Promise.resolve();
  },
});
