import request from '@/utils/request';
import { prefixApi } from './common';

export interface Menu {
  icon: string;
  id: number;
  parentId: number;
  name: string;
  url: string;
  subMenus?: Menu[];
}

interface MenuResponse {
  code: number;
  data: {
    menus: Menu[];
    user: any;
  };
  message: string;
}

export async function queryMenu(): Promise<MenuResponse> {
  return request(prefixApi('/self/user'));
}
