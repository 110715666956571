import { memo, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import PickMethod from '@/components/PickMethod';
import PickMethodVar from '@/components/PickMethodVar';
import useMethodDetail from '@/hooks/useMethodDetail';
import useListStore from '@/store/form-generator-list';
import { EPassValueType } from '@/components/ConfigFieldEditor/types';
import _, { debounce } from 'lodash';
import { MethodParam } from '@/types';

interface ValueType {
  method?: any;
}

interface DyEnumsEditorProps {
  formData?: any;
  value?: ValueType;
  onChange?: (data: ValueType) => void;
}

function eachMethodParams(inputParams: MethodParam[], callback: (param: MethodParam) => void) {
  inputParams.forEach((n) => {
    callback(n);
    if (n.fields) {
      eachMethodParams(n.fields, callback);
    }
  });
}

let cacheMethodDetail: any = null;
// 方法入参数绑定
const BindMethodInputParam = memo<DyEnumsEditorProps>(({ value, onChange }) => {
  const { bindedMethods } = useListStore();
  const {
    load: loadMethodDetail,
    loading: loadingMethodDetail,
    data: methodDetail,
  } = useMethodDetail();

  useEffect(() => {
    if (value?.method?.id) {
      if (!cacheMethodDetail || (cacheMethodDetail && cacheMethodDetail.id !== value?.method?.id)) {
        loadMethodDetail(value?.method?.id);
      }
    }
  }, [value]);

  useEffect(() => {
    cacheMethodDetail = methodDetail;
    return () => {
      cacheMethodDetail = null;
    };
  }, [methodDetail]);

  const config = useMemo(() => {
    const result: any = {
      method: {
        label: '',
        fieldType: EWidgetType.CustomWidget,
        defaultValue: value?.method ? [value.method] : undefined,
        custom(props: any) {
          return (
            <PickMethod
              {...props}
              value={value?.method ? [value.method] : undefined}
              getLabelByValue={(methods) => {
                return methods?.length ? methods[0].name : '请选择';
              }}
              checkedList={[props?.value?.method].filter((o) => Boolean(o))}
              onChange={(methods) => {
                const method = methods?.length ? methods[0] : null;
                if (method) {
                  props?.onChange?.({
                    id: method.id,
                    name: method.name,
                  });
                }
              }}
            />
          );
        },
      },
    };
    if (methodDetail?.inputParams?.length) {
      eachMethodParams(methodDetail.inputParams, (param) => {
        if (String(param.passValueType) !== EPassValueType.直接传值) return;
        // 只取叶子节点
        if (param.fields?.length) return;
        const attr = `${param.name}_${param.id}`;
        result[attr] = {
          label: param.path,
          tooltip: '请选择当前入参绑定的字段',
          fieldType: EWidgetType.CustomWidget,
          defaultValue: _.get(value, attr, undefined),
          custom(props: any) {
            return (
              <PickMethodVar
                {...props}
                value={_.get(value, attr)}
                contextField="outputParams"
                methodId={bindedMethods?.loadMethod?.id}
                targetFieldType={`${param.dataType}${
                  param.genericType ? `/${param.genericType}` : ''
                }`}
                onSelected={(val) => {
                  props?.onChange?.(val);
                }}
              />
            );
          },
        };
      });
      // methodDetail.inputParams
      //   .filter((o: any) => String(o.passValueType) == EPassValueType.直接传值)
      //   .forEach((param: any) => {});
    }
    return result;
  }, [value, methodDetail, bindedMethods]);

  return (
    <div>
      <Spin spinning={loadingMethodDetail}>
        <FormCreator
          submitter={{ render: () => null }}
          initialValues={value}
          layout="horizontal"
          onValuesChange={debounce((changedValues: any, allValues: any) => {
            if (allValues.method?.length) {
              allValues.method = allValues.method[0];
            }
            onChange?.(allValues);
          }, 500)}
          config={config}
        />
      </Spin>
    </div>
  );
});

export default BindMethodInputParam;
