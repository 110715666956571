import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

import TransformKeys from '../common/TransformKeys';
import { FILE_TYPE } from '@/constant';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

export const ACTION = {
  uploadData: 'uploadData',
};

export const TRIGGER = {
  click: 'click',
};

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function ExcelFileUploadConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            transformKeys: {
              label: '表头字段',
              tooltip: '配置上传所需的字段，将自动生成对应的模板表格',
              defaultValue: fieldInfo?.transformKeys,
              fieldType: EWidgetType.CustomWidget,
              custom(props: any) {
                return (
                  <TransformKeys
                    {...props}
                    bindedMethods={store?.bindedMethods}
                    execlData={fieldInfo.value}
                    bindParam={fieldInfo.bindParam}
                  />
                );
              },
            },
            fileType: {
              label: '导入格式',
              defaultValue: fieldInfo?.fileType || FILE_TYPE.XLSX,
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: [{ label: 'xlsx', value: FILE_TYPE.XLSX }],
                allowClear: false,
              },
            },
            isShowTitle: {
              label: '显示标题',
              defaultValue: fieldInfo?.isShowTitle ?? true,
              fieldType: EWidgetType.Switch,
            },
            buttonLabel: {
              label: '按钮文案',
              defaultValue: fieldInfo?.buttonLabel || '上传表格',
              fieldType: EWidgetType.Input,
            },
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            trigger: {
              label: '触发条件',
              defaultValue: fieldInfo?.trigger || TRIGGER.click,
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: [{ label: '点击按钮', value: TRIGGER.click }],
                allowClear: false,
              },
            },
            action: {
              label: '执行动作',
              defaultValue: fieldInfo?.action || ACTION.uploadData,
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: [{ label: '上传数据', value: ACTION.uploadData }],
                allowClear: false,
              },
            },
            limitPrompt: {
              label: '校验文案',
              tooltip: '自动校验上传到表格表头是否匹配，若不匹配则出现文案提示。',
              defaultValue: fieldInfo?.limitPrompt ?? '',
              fieldType: EWidgetType.Input,
            },
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
