import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function VideoUploadConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            fileType: {
              label: '内部上传编码',
              defaultValue: fieldInfo?.fileType,
              fieldType: EWidgetType.InputNumber,
            },
            preFileLink: {
              label: '资源http前缀',
              defaultValue: fieldInfo?.preFileLink,
              fieldType: EWidgetType.Input,
            },
            fileTypeLimit: {
              label: '限制格式',
              defaultValue: fieldInfo?.fileTypeLimit || ['mp4', 'mkv', 'rmvb', 'avi'],
              fieldType: EWidgetType.CheckboxGroup,
              options: [
                { label: 'mp4', value: 'mp4' },
                { label: 'mkv', value: 'mkv' },
                { label: 'rmvb', value: 'rmvb' },
                { label: 'avi', value: 'avi' },
              ],
            },
            maxCount: {
              label: '限制个数',
              defaultValue: fieldInfo?.maxCount || 1,
              fieldType: EWidgetType.InputNumber,
              addonBefore: <span>最多</span>,
              addonAfter: <span>个</span>,
            },
            sizeLimit: {
              label: '限制大小',
              defaultValue: fieldInfo?.sizeLimit || false,
              fieldType: EWidgetType.Switch,
            },
            singleFileSize: {
              label: '每个大小',
              defaultValue: fieldInfo?.singleFileSize || 3,
              fieldType: EWidgetType.InputNumber,
              addonBefore: <span>限制</span>,
              addonAfter: <span>m</span>,
            },
            limitPrompt: {
              label: '校验文案',
              tooltip: '上传格式不符合时提示文案',
              defaultValue: fieldInfo?.limitPrompt ?? '',
              fieldType: EWidgetType.Input,
            },
            /*  videoPoster: {
              label: '视频封面链接',
              fieldType: EWidgetType.Input,
              extra: "请输入图片链接地址"
            }, */
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
