import { memo } from 'react';
import { Typography } from 'antd';
import {
  QueryFilter,
  ProFormText,
  ProFormDatePicker,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormDigit,
  ProFormDateTimePicker,
  ProFormDateTimeRangePicker,
  ProFormTextArea,
} from '@ant-design/pro-form';
import useEvents from '@/components/FormGeneractor/ListContent/useEvents';
import useFormGeneratorStore from '@/store/form-generator-list';
import { FieldCmpType } from '@/constant';
import deepmerge from 'deepmerge';
import { LeftFixedName } from '../help';

export const Name = 'list_search';

export const DEFAULT_CONFIIG = {
  used: true,
  defaultCollapsed: false,
  fields: [],
  labelWidth: 250,
  formItemWidth: 8,
  version: '0.0.1',
};

const Search = memo(() => {
  const { bindEvents, isFocus } = useEvents(Name);
  const fgStore = useFormGeneratorStore((state) => state);
  const { fields, defaultCollapsed, labelWidth, formItemWidth } = fgStore.listSearch;

  return (
    <div {...bindEvents('fg-search common-area')}>
      {isFocus && <LeftFixedName name="查询条件" />}
      <QueryFilter
        collapsed={defaultCollapsed}
        labelCol={{ span: 6 }}
        labelWidth={labelWidth}
        span={formItemWidth}
      >
        {fields.map((field) => {
          const commonProps = {
            key: field.cmpId,
            name: field.name,
            label: field.label,
            tooltip: field.hasTip ? field.tipContent : undefined,
            fieldProps: {
              placeholder:
                // eslint-disable-next-line no-nested-ternary
                field.controlType === FieldCmpType.日期范围选择器
                  ? field.placeholders
                    ? field.placeholders.split(',')
                    : ['开始时间', '结束时间']
                  : field.placeholder,
              // value: field.defaultValue || ''
            },
          };
          switch (field.controlType) {
            case FieldCmpType.下拉框:
              return (
                <ProFormSelect
                  {...deepmerge(commonProps, {
                    options: field.options || [],
                  })}
                />
              );
            case FieldCmpType.数字输入框:
              return <ProFormDigit {...commonProps} />;
            case FieldCmpType.下拉多选框:
              return (
                <ProFormSelect
                  {...deepmerge(commonProps, {
                    options: field.options || [],
                    fieldProps: {
                      mode: 'multiple',
                    },
                  } as any)}
                />
              );
            case FieldCmpType.日期选择器: {
              const props = deepmerge(commonProps, {});
              (props.fieldProps as any).format = field.showFormat || 'YYYY-MM-DD';
              if (field.showFormat === 'YYYY-MM-DD HH:mm:ss') {
                (props.fieldProps as any).showTime = { format: 'HH:mm:ss' };
              } else {
                (props.fieldProps as any).showTime = false;
              }
              if ((props.fieldProps as any).showTime) {
                return <ProFormDateTimePicker {...props} />;
              }
              return <ProFormDatePicker {...props} />;
            }
            case FieldCmpType.日期范围选择器: {
              const props2 = deepmerge(commonProps, {});
              (props2.fieldProps as any).format = field.showFormat || 'YYYY-MM-DD';
              if (field.showFormat === 'YYYY-MM-DD HH:mm:ss') {
                (props2.fieldProps as any).showTime = { format: 'HH:mm:ss' };
              } else {
                (props2.fieldProps as any).showTime = false;
              }
              if ((props2.fieldProps as any).showTime) {
                return <ProFormDateTimeRangePicker {...props2} />;
              }
              return <ProFormDateRangePicker {...props2} />;
            }
            case FieldCmpType.输入框:
              return <ProFormText {...commonProps} />;
            case FieldCmpType.多值文本:
              return <ProFormTextArea {...commonProps} />;
            default:
              return (
                <Typography.Text key={Date.now()} type="danger" style={{ margin: '10px 0 0 5px' }}>
                  {field.controlType} 暂不支持
                </Typography.Text>
              );
          }
        })}
      </QueryFilter>
    </div>
  );
});

export default Search;
