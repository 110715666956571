import { PlusCircleFilled } from '@ant-design/icons';
import React from 'react';
import type { GroupPageListItemType } from '@/types';
import _ from 'lodash';
import { DEFAULT_CONFIIG as DEFAULT_LIST_TITLE_CONFIIG } from './ListContent/Header';
import { DEFAULT_CONFIIG as DEFAULT_LIST_CONFIG_CONFIIG } from './ListContent/index';
import { DEFAULT_CONFIIG as DEFAULT_LIST_PAGINATION_CONFIIG } from './ListContent/Pagination';
import { DEFAULT_CONFIIG as DEFAULT_LIST_SEARCH_CONFIIG } from './ListContent/Search';
import { DEFAULT_CONFIIG as DEFAULT_LIST_TABLE_CONFIIG } from './ListContent/Table';
import type { EPageType } from '@/constant';
import { FieldControls, DataDisplayCmpTypes, PageLayoutCmpTypes, FieldCmpType } from '@/constant';
import { nanoid } from 'nanoid';
import { TMethodsParams } from '@/types';

export function getDefaultOperatorField() {
  return {
    title: '操作',
    fixed: 'right',
    width: 100,
    used: true,
    buttons: [
      {
        id: nanoid(),
        text: '编辑',
        action: 'openPage',
      },
      {
        id: nanoid(),
        text: '删除',
        action: 'callAPI',
        shapeType: 'danger',
        confirmMessage: '确认删除吗？',
      },
    ],
  };
}

// + 按钮
export const IconPlusButton = ({ position, onClick, style }: any) => {
  return (
    <PlusCircleFilled
      onClick={onClick}
      style={{
        color: '#1890ff',
        cursor: 'pointer',
        position: 'absolute',
        left: '50%',
        fontSize: 20,
        zIndex: 100,
        transform: `translate3d(-50%, 0, 0)`,
        ...(position === 'top' ? { top: -24 } : { bottom: -24 }),
        ...(style || {}),
      }}
    />
  );
};

const allCmps = [...PageLayoutCmpTypes, ...FieldControls, ...DataDisplayCmpTypes];
// 根据类型获取组件类型的中文名称
export const getDefaultLabelByCmpType = (cmpType: string) => {
  if (cmpType === FieldCmpType.列表) {
    return '已选数据';
  }
  const target = allCmps.find((o) => o.value === cmpType);
  return target ? target.label : '请配置';
};

// 组件名称
export const LeftFixedName = ({ name }: any) => {
  if (!name) return null;
  return (
    <div
      style={{
        fontSize: 10,
        position: 'absolute',
        left: -2,
        top: -16,
        backgroundColor: '#1890ff',
        color: '#fff',
        padding: '0 5px',
      }}
    >
      {name}
    </div>
  );
};

/**
 * 根据页面类型，格式化成展示所需
 * @param pages
 * @returns
 */
export const formatPageTypes = (pages: GroupPageListItemType[]) => {
  // const pageLabels = ['', '列表页面', '新建页面', '编辑页面', '查看页面']
  return pages.sort((a, b) => (a as any).type - (b as any).type);
};

/**
 * 从页面数据中获取列表类型
 * @param pages
 * @returns
 */
export const findTargetPageFromPages = (pages: any[], pageType: EPageType) => {
  const target = pages.find((page: any) => String(page.type) === pageType);
  return target;
};

/**
 * 解析 str 2 json
 * @param str
 * @returns
 */
export const parseString2Json = (str: string) => {
  let result = {};
  try {
    result = JSON.parse(str);
    return result;
  } catch (error) {
    result = {};
    return result;
  }
};

/**
 * 解析 obj 2 json string
 * @param str
 * @returns
 */
export const obj2StringJson = (obj: Record<string, any>) => {
  let result = '{}';
  try {
    result = JSON.stringify(obj);
    return result;
  } catch (error) {
    result = '{}';
    return result;
  }
};

/**
 * 格式化数据
 * @param pageInfo
 * @returns
 */
export const formatListPageInfo = async (pageInfo: any) => {
  if (!pageInfo) return null;
  const config: any = {};
  const {
    titleProperties,
    paginationProperties,
    searchProperties,
    searchParams,
    toolbarButtons,
    operationButtons,
    contentColumns,
    contentProperties,
    properties,
  } = pageInfo;

  // 全局
  if (!properties) {
    config.listConfig = _.clone(DEFAULT_LIST_CONFIG_CONFIIG);
  } else {
    config.listConfig = _.clone(
      Object.assign(DEFAULT_LIST_CONFIG_CONFIIG, parseString2Json(properties)),
    );
  }
  // 标题
  if (!titleProperties) {
    config.title = DEFAULT_LIST_TITLE_CONFIIG;
  } else {
    config.title = Object.assign(
      _.clone(DEFAULT_LIST_TITLE_CONFIIG),
      parseString2Json(titleProperties),
    );
  }

  // 分页区
  if (!paginationProperties) {
    config.listPagination = Object.assign(_.clone(DEFAULT_LIST_PAGINATION_CONFIIG), {
      pageNumberField: {},
      pageSizeField: {},
      totalField: {},
    });
  } else {
    config.listPagination = Object.assign(
      _.clone(DEFAULT_LIST_PAGINATION_CONFIIG),
      parseString2Json(paginationProperties || ''),
    );
  }
  const search: any = parseString2Json(searchProperties);
  config.listSearch = {
    ..._.clone(DEFAULT_LIST_SEARCH_CONFIIG),
    fields: (searchParams || []).map((item: any) => {
      return {
        ...parseString2Json(item.properties),
      };
    }),
    formItemWidth: search?.formItemWidth,
    labelWidth: search?.labelWidth,
  };

  const buttons = operationButtons?.map((x: any) => {
    if (typeof x.properties === 'string') {
      x.properties = parseString2Json(x.properties);
    }
    return x.properties;
  });
  const contentCofig: any = parseString2Json(contentProperties);
  const fixedRightConfig = contentCofig.fixedRightConfig || {};
  const lastField = getDefaultOperatorField();
  config.listTable = {
    ..._.clone(DEFAULT_LIST_TABLE_CONFIIG),
    ...contentCofig,
    toolbarButtons: toolbarButtons?.length
      ? toolbarButtons.map((n: any) => ({
          ...n,
          properties: parseString2Json(n.properties),
        }))
      : [DEFAULT_LIST_TABLE_CONFIIG.addButton],
    fields: [
      ...(contentColumns || []).map((item: any) => {
        return {
          ...parseString2Json(item.properties),
        };
      }),
      {
        ...lastField,
        width: fixedRightConfig.width || lastField.width,
        used: fixedRightConfig.used ?? lastField.used,
        buttons: buttons?.length ? buttons : lastField.buttons,
      },
    ],
  };
  return config;
};

/**
 * 枚举方法下拉选项
 * @param dyMethods
 * @returns
 */
export const getDyEnumOptions = (dyMethods: any[]) => {
  const dyOptions = dyMethods?.length
    ? dyMethods.map((o: any) => ({
        value: o?.tableMethod?.id,
        label: `${o?.tableMethod?.methodName}（${o?.tableMethod?.methodIdentification}）`,
      }))
    : [];
  return dyOptions;
};

/**
 * 构造保存列表数据的结构 - 保存草稿
 * @param store 表单设计数据
 * @param listStore 表单设计列表数据
 */
export const createListSubmitDataByStore = (listStore: any) => {
  const {
    title,
    listPagination,
    listSearch,
    bindedMethods,
    listTable,
    listPageServiceConfig,
    listConfig,
  } = listStore;
  const { buttons } = (listTable?.fields || []).find((o: any) => o.fixed) || {};

  // 挑选其他方法
  const searchMethods = listSearch.fields
    .filter((o: any) => o?.dyOptionsInfo?.method)
    .map((o: any) => ({
      methodId: o.dyOptionsInfo.method.id,
      type: '2',
      frontComponentId: '',
    }));
  const tableMethods = listTable.fields
    .filter((o: any) => o?.dyOptionsInfo?.method)
    .map((o: any) => ({
      methodId: o.dyOptionsInfo.method.id,
      type: '2',
      frontComponentId: '',
    }));
  // 从按钮配置映射方法引用
  const operationMethods: Array<{
    methodId: string;
    type: string;
    frontComponentId: string;
  }> = buttons
    .filter((buttonConfig: any) => buttonConfig.callMethod?.method?.id)
    .map((buttonConfig: any) => ({
      methodId: buttonConfig.callMethod?.method?.id,
      type: '2',
      frontComponentId: buttonConfig.id,
    }));
  const result: any = {
    ...listPageServiceConfig,
    properties: obj2StringJson(listConfig),
    loadMethodId: bindedMethods?.loadMethod?.id,
    titleProperties: obj2StringJson(title),
    paginationProperties: obj2StringJson(listPagination),
    contentProperties: obj2StringJson({
      ..._.omit(listTable, 'fields'),
      fixedRightConfig: _.omit(
        listTable.fields.find((field: any) => field.fixed === 'right'),
        ['buttons'],
      ),
    }),
    searchProperties: obj2StringJson(_.omit(listSearch, 'fields')),
    searchParams: listSearch.fields?.map((field: any, fieldIdx: number) => ({
      frontComponentCode: field.controlType,
      frontComponentId: field.cmpId,
      properties: obj2StringJson(field),
      orderNum: fieldIdx + 1,
    })),
    toolbarButtons: listTable.toolbarButtons.map((button: any, index: number) => {
      return {
        frontComponentCode: button.frontComponentCode ?? 'add_button',
        frontComponentId: button.frontComponentId ?? nanoid(),
        properties: obj2StringJson(button.properties),
        orderNum: button.orderNum ?? index + 1,
      };
    }),
    operationButtons: buttons.map((buttonConfig: any, index: number) => ({
      frontComponentCode: 'button',
      frontComponentId: buttonConfig.id,
      properties: obj2StringJson(buttonConfig),
      orderNum: index + 1,
    })),
    contentColumns: listTable.fields
      .filter((field: any) => !field.fixed)
      ?.map((field: any, fieldIdx: number) => {
        return {
          frontComponentCode: field.displayType,
          frontComponentId: field.controlId || nanoid(),
          properties: obj2StringJson(field),
          orderNum: fieldIdx + 1,
        };
      }),
  };
  result.methodReferences = _.unionBy(
    [
      ...searchMethods,
      ...tableMethods,
      ...operationMethods,
      ...Object.keys(bindedMethods).map((methodName) => ({
        methodId: bindedMethods[methodName]?.id,
        type: '1',
        frontComponentId: '',
      })),
    ],
    'methodId',
  );
  return result;
};

interface SavePageComponent {
  frontComponentCode: string; // 组件编码
  frontComponentId: string; // 组件id
  properties: string; // 参数配置
  rules: string; // 校验规则集配置
  actions: string; // 动作集配置
  orderNum: number; // 组件排序
}

interface SavePageMethodReference {
  methodId: string; // 方法ID
  type: number; // 引用类型:1,页面;2,组件
  frontComponentId: string; // 组件ID
}
/**
 * 构造保存新增/编辑/详情数据的结构 - 保存草稿
 * @param store
 * @param otherStore
 */
export const createOtherSubmitDataByStore = (store: any, otherStore: any) => {
  const { currentPage, currentPageGroupId } = store;
  const { id, orderNum, description } = currentPage;
  const {
    bindedMethods,
    fields,
    title,
    labelColWidth,
    wrapperColWidth,
    submitButtonConfig,
    cancelButtonConfig,
    methodsValidationConfig,
  } = otherStore;

  const components: SavePageComponent[] = [];
  const methodReferences: SavePageMethodReference[] = [];

  fields.forEach((item: any, index: number) => {
    components.push({
      frontComponentCode: item.controlType,
      frontComponentId: item.controlId,
      properties: obj2StringJson({
        ...item,
        version: '0.0.1',
      }),
      rules: obj2StringJson(item.rules || []),
      actions: '[]',
      orderNum: index + 1,
    });

    if (item.bindParam) {
      item.bindParam.forEach((bindParam: any) => {
        methodReferences.push({
          methodId: bindParam.bindMethod.id, // 方法ID
          type: 2, // 引用类型:1,页面;2,组件
          frontComponentId: item.controlId, // 组件ID
        });
      });
    }
  });

  console.log('methodsValidationConfig', methodsValidationConfig);
  const result: any = {
    id, // 表单id
    pageGroupId: currentPageGroupId,
    name: currentPage?.name, // 页面名称
    description, // 页面描述
    loadMethodId: bindedMethods?.loadMethod?.id ?? '0', // 加载方法ID, 0表示无
    submitMethodId: bindedMethods?.submitMethod?.id ?? '0', // 提交方法ID, 0表示无
    properties: obj2StringJson({
      title,
      labelColWidth,
      wrapperColWidth,
      submitButtonConfig,
      cancelButtonConfig,
      methodsValidationConfig,
    }), // 页面级配置
    orderNum, // 列表页面排序值
    methodReferences, // 方法引用列表
    components, // 表单组件列表
  };

  return result;
};

/**
 * 服务端配置信息转换为页面状态
 * @param serviceConfig
 */
export const formatAddEditDetailConfig = async ({
  serviceConfig,
  defaultCmpType = FieldCmpType.输入框,
}: any = {}) => {
  const { components, properties } = serviceConfig;
  const pageProperties: any = parseString2Json(properties);
  const result: any = {
    title: pageProperties?.title || serviceConfig?.name || '新建表单',
    labelColWidth: pageProperties?.labelColWidth || 200,
    wrapperColWidth: pageProperties?.wrapperColWidth,
    cancelButtonConfig: pageProperties.cancelButtonConfig || {
      text: '取消',
      shapeType: 'default',
      styleType: 'default',
    },
    submitButtonConfig: pageProperties.submitButtonConfig || {
      text: '保存',
      styleType: 'primary',
      shapeType: 'link',
      callFunction: 'save',
    },
    fields: [],
  };

  try {
    result.fields = (components || []).map((o: any, index: number) => {
      o.properties = parseString2Json(o.properties);
      const info = Object.assign(
        {
          controlType: o?.frontComponentCode === 'text' ? defaultCmpType : o?.frontComponentCode,
          controlId: o?.frontComponentId || `${nanoid()}`,
          orderNum: typeof o?.orderNum === 'undefined' ? index : o.orderNum,
          // required: false, // 默认是填
          hide: false,
          rules: o?.rules ? parseString2Json(o.rules) : [],
        },
        o.properties,
      );
      return info;
    });
    return result;
  } catch (error) {
    return result;
  }
};

/**
 * 是否不为空数组
 * @param array
 */

export const isNotNullArray = (array?: any[]) => {
  if (array && array.length > 0) return true;
  return false;
};

/**
 * 过滤 code 未绑定和msg错误信息未填写
 * @param methods
 */
export const filterNullMethods = (methods: TMethodsParams[]) => {
  if (!isNotNullArray(methods)) return [];
  return methods.filter((method: TMethodsParams) => {
    return method.result.fieldId && (typeof method.error === 'string' || method.error.fieldId);
  });
};
