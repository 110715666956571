import { memo, useEffect } from 'react';
import AwesomeSwiper from 'react-awesome-swiper';
import './index.less';

interface SwiperControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}
let count = 0;
const SwiperControl = memo<SwiperControlProps>(({ configInfo }) => {
  const { autoPlay, timeInterval, swiperWidth, swiperHeight } = configInfo;
  const cls = `swiper_${++count}`;
  useEffect(() => {
    setTimeout(() => {
      const wrapper: any = document.querySelector(`.${cls}`);
      if (wrapper) {
        wrapper.style.width = `${configInfo.swiperWidth || 200}px`;
      }
    }, 20);
  }, [configInfo]);

  return (
    <>
      <AwesomeSwiper
        className={`custom-awesome-swiper3 ${cls}`}
        config={{
          loop: !!autoPlay,
          autoplay: {
            delay: autoPlay ? (timeInterval || 1) * 1000 : 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          lazy: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          width: swiperWidth || 200,
          height: swiperHeight,
          autoHeight: swiperHeight ? undefined : true,
        }}
      >
        <div className="swiper-wrapper">
          {['#99CCCC', '#FFCC99', '#FFCCCC'].map((item: string) => (
            <div className="swiper-slide" key={item}>
              <div
                style={{
                  height: swiperHeight || 150,
                  backgroundColor: item,
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <span style={{ opacity: 0 }}>{item}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </AwesomeSwiper>
    </>
  );
});

export default SwiperControl;
