import type { FieldItem } from '@/store/types';
import getCommonFieldConfig from '../CommonStaticConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';

interface Props {
  fieldInfo: FieldItem;
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

let debounceTimer: any;
export default function ViewSwiperConfig({ fieldInfo, store, onChange = () => null }: Props) {
  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(fieldInfo, store),
            swiperWidth: {
              label: '轮播图宽度',
              defaultValue: fieldInfo?.swiperWidth || 200,
              fieldType: EWidgetType.InputNumber,
              extra: '默认 200 px； 设置宽度后，高度会自动适配',
            },
            swiperHeight: {
              label: '轮播图高度',
              defaultValue: fieldInfo?.swiperHeight || 150,
              fieldType: EWidgetType.InputNumber,
              extra: '默认 150 px',
            },
            autoPlay: {
              label: '自动切换',
              fieldType: EWidgetType.Switch,
              defaultValue: fieldInfo?.autoPlay || false,
            },
            timeInterval: {
              label: '间隔时间',
              fieldType: EWidgetType.InputNumber,
              whenHidden: (formData: any) => !formData.autoPlay,
              defaultValue: fieldInfo?.timeInterval || '1',
              extra: '单位: 秒',
            },
          }}
          onValuesChange={(_: any, allVals: any) => {
            // 防抖
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
              onChange?.(allVals);
            }, 500);
          }}
        />
      ),
    },
  ];
  return <WithCollapse collapseDatas={CollapseDatas} />;
}
