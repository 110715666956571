import { transferOptions } from '@/types';

const isDateBindParam = (fieldInfo: any) => {
  if (fieldInfo?.bindParam && fieldInfo?.bindParam.length !== 0) {
    const value =
      fieldInfo?.bindParam[0]?.bindVar.fieldType === 'Date' ||
      fieldInfo?.bindParam[1]?.bindVar.fieldType === 'Date';
    return value;
  }
  return false;
};
const transferDefault = (fieldInfo: any, isData?: (...arg: any) => boolean) => {
  const flag = isData ? isData() : false;
  if (flag || isDateBindParam(fieldInfo)) {
    return 'x';
  }
  return 'YYYY-MM-DD';
};
const newTransferOptions = (fieldInfo: any, isData?: (...arg: any) => boolean) => {
  const flag = isData ? isData() : false;
  if (flag || isDateBindParam(fieldInfo)) {
    return [
      {
        label: '毫秒时间戳',
        value: 'x',
      },
    ];
  }
  return transferOptions;
};

export { newTransferOptions, isDateBindParam, transferDefault };
