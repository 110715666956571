import { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import useFormGeneratorStore from '@/store/form-generator';
import cx from 'classnames';

export default function useEvents(cmpName: string) {
  const [isEnter, setIsEnter] = useState(false);
  const { currentFocusCmp, uCurrentFocusCmp } = useFormGeneratorStore((state) => state);
  const isFocus = useMemo(() => currentFocusCmp === cmpName, [currentFocusCmp, cmpName]);

  const bindEvents = useCallback(
    (className?: string) => {
      const result = {
        onMouseEnter: _.throttle((e: any) => {
          e.stopPropagation();
          if (!isEnter) {
            setIsEnter(true);
          }
        }, 500),
        onMouseLeave: _.throttle((e: any) => {
          e.stopPropagation();
          if (isEnter) {
            setIsEnter(false);
          }
        }, 500),
        onClick(event: any) {
          event.stopPropagation();
          if (currentFocusCmp !== cmpName) {
            uCurrentFocusCmp(cmpName);
          }
        },
        className: cx(className, 'fg-dashed-border', {
          hover: isEnter,
          active: isFocus,
        }),
      };
      return result;
    },
    [cmpName, isFocus, currentFocusCmp, isEnter],
  );

  return {
    bindEvents,
    isEnter,
    isFocus,
  };
}
