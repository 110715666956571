import React, { memo } from 'react';
import { Tooltip, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface ICopyIconButtonnProps {
  msg?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const CopyIconButtonn = memo<ICopyIconButtonnProps>(({ msg = '复制', onClick, style }) => {
  return (
    <Tooltip title={msg}>
      <Typography.Link style={style} onClick={onClick}>
        <CopyOutlined style={{ color: '#1890ff' }} />
      </Typography.Link>
    </Tooltip>
  );
});
export default CopyIconButtonn;
