import React, { memo } from 'react';
import omit from 'lodash/omit';
import type { FormCreatorConfigItem } from './type';
import { EWidgetType } from './type';
import SearchSelect from './widgets/SearchSelect/index';
import RichText from './widgets/RichText';

export default memo<FormCreatorConfigItem>((props) => {
  const { fieldType, ...formItemProps } = props;
  switch (fieldType) {
    case EWidgetType.SearchSelect:
      return (
        <SearchSelect {...formItemProps.fieldProps} {...omit(formItemProps, ['fieldProps'])} />
      );
    case EWidgetType.RichText:
      return <RichText {...formItemProps.fieldProps} {...omit(formItemProps, ['fieldProps'])} />;
    default:
      return null;
  }
});
