import { useState } from 'react';
import { message } from 'antd';
import { groupPageList } from '@/services/pageGroup';
import type { GroupPageListItemType } from '@/types';

// 页面组内的页面分类列表
export default function useGroupPageTypeList(published: boolean) {
  const [data, setData] = useState<GroupPageListItemType[]>([]);
  const [loading, setLoading] = useState(false);

  const load = async (id: string): Promise<GroupPageListItemType[]> => {
    try {
      if (loading) {
        return [];
      }
      setLoading(true);
      const res = await groupPageList(id, published);
      if (res.rCode === 0) {
        setData(res.data);
        return res.data;
      } else {
        message.error(res.msg);
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    load,
  };
}
