import { EFieldType } from '@/components/FieldTypeSelect/selector';
/**
 * 构造 update 接口入参
 * @param inputParams 入参
 * @param values 收集的表单数据
 * @returns
 */
export function makeParamvalues(inputParams: any[], values: Record<string, any>) {
  console.log('inputParams ----------', inputParams, 'values', values);
  const doRev = (params: any[], nowValues: Record<string, any>): any => {
    const toAdd: any[] = [];
    return params
      .map((param) => {
        if (['Object'].includes(param.dataType)) {
          return {
            paramId: param.id,
            objectValue: doRev(param.fields || [], nowValues[param.name]),
          };
        } else if (param.dataType === 'List' && param.genericType === 'Object') {
          return {
            paramId: param.id,
            listObjectValue: doRev(param.fields || [], nowValues[param.name]),
          };
        } else {
          let value =
            param.dataType === EFieldType.Long
              ? String(nowValues[param.name]) || 0
              : nowValues[param.name];

          // 布尔值
          if (param.dataType === EFieldType.Boolean) {
            if (value === 'false') {
              value = false;
            } else if (value === 'true') {
              value = true;
            } else if (typeof value === 'undefined') {
              value = false;
            } else {
              value = Boolean(value);
            }
          }
          if (param.dataType === EFieldType.Integer) {
            if (typeof value !== 'number') {
              value = Number(value || 0);
            }
          }

          //  inputNumber 组件，定义的类型却是 string
          if (param.dataType === EFieldType.String && typeof value === 'number') {
            value = String(value);
          }
          return {
            paramId: param.id,
            value: typeof value === 'undefined' ? null : value,
          };
        }
      })
      .filter((o) => Boolean(o))
      .concat(toAdd);
  };

  const result = doRev(inputParams, values);

  return result;
}
