import type {
  InputProps,
  SelectProps,
  InputNumberProps,
  CheckboxProps,
  SwitchProps,
  UploadProps,
} from 'antd';
import type { TextAreaProps } from 'antd/lib/input/TextArea';
import type { TitleProps } from 'antd/lib/typography/Title';
import type { CheckboxGroupProps } from 'antd/lib/checkbox/Group';
// import type { FormItemProps } from 'antd/lib/form/FormItem'
import type { ProFormInstance } from '@ant-design/pro-form';
import type { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { FormCreatorConfigItem } from '@/components/FormCreator/type';

// 控件类型
export enum EWidgetType {
  // 普通输入框
  Input = 'Input',
  // 文本输入框
  TextArea = 'TextArea',
  // 数字输入框
  InputNumber = 'InputNumber',
  // 下拉选择
  Select = 'Select',
  // 单选框
  RadioGroup = 'RadioGroup',
  // 复选框
  Checkbox = 'Checkbox',
  // 复选框
  CheckboxGroup = 'CheckboxGroup',
  // 开关
  Switch = 'Switch',
  // 上传
  Upload = 'Upload',
  // 日期选择器
  DatePicker = 'DatePicker',
  // 日期范围选择器
  RangePicker = 'RangePicker',
  // 自定义控件
  CustomWidget = 'CustomWidget',
  // 搜索Select
  SearchSelect = 'SearchSelect',
  // Coral挑选图标
  SelectCoralIcon = 'CoralIcon',
  // Coral挑选特效
  SelectCoralEffect = 'CoralEffect',
  // Coral制作特效
  CreateCoralEffect = 'CoralCreateEffect',
  // 标题
  Title = 'Title',
}

export interface SelectOption {
  label: string;
  value: string;
}

export type FieldItem = Partial<
  InputProps &
    SelectProps<any> &
    InputNumberProps &
    TextAreaProps &
    CheckboxProps &
    CheckboxGroupProps &
    SwitchProps &
    UploadProps &
    TitleProps &
    EditableProTableProps<any, any> &
    Record<string, any>
>;

export type requestOptionsType = (data: {
  pageNumber?: number;
  pageNo?: number;
  pageSize?: number;
  keyword?: string;
}) => Promise<
  | {
      total?: number;
      current?: number;
      list: Array<Record<string, any>>;
    }
  | undefined
>;
export type FormCreatorConfig = Record<string, FormCreatorConfigItem>; // FormCreatorConfigItem

export interface ContextStore<T = Record<string, any>> {
  form?: ProFormInstance<T>;
  setFormData: React.Dispatch<T>;
  formData: Record<string, any>;
  readonly?: boolean;
  curTabKey?: string;
  view2editMode?: boolean;
}
