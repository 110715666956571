import request, { yapiRequest } from '@/utils/request';
import type { Page, TReturn, PageGroup } from '@/types';
import type { EPageStatus } from '@/constant';
import { prefixPageGroup, prefixPageConfig, prefixSystem, prefixExternalPage } from './common';

// 页面组 - 新建
export const createPageGroup = (data: Partial<PageGroup>) => {
  return request.post<TReturn<any>>(prefixPageGroup('create'), {
    data,
  });
};

// 页面组 - 更新
export const updatePageGroup = (data: Partial<PageGroup> & { id: string }) => {
  return request.post<TReturn<any>>(prefixPageGroup('update'), {
    data,
  });
};

// 页面组 - 删除
export const delPageGroup = (id: string) => {
  return request.post<TReturn<any>>(prefixPageGroup('delete'), {
    data: { id },
  });
};

// 页面组 - 删除页面
export const removePage = (id: string) => {
  return request.post<TReturn<any>>(prefixPageGroup('deletePage'), { data: { id } });
};

// 页面组 - 设置默认页面
export const setDefaultPage = (id: string) => {
  return request.post<TReturn<any>>(prefixPageGroup('setFirstPage'), { data: { id } });
};

// 列表页面 - 保存
export const saveListPageData = (data: any) => {
  return request.post<TReturn<any>>(prefixSystem('listPage/update'), {
    data,
  });
};

// 列表页面 - 回滚到上一次发布状态
export const rollbackListPageData = (id: string) => {
  return request.post<TReturn<any>>(prefixPageGroup('revertToPublished'), {
    data: { id },
  });
};

// 页面组 - 列表
export const pageList = (
  params: {
    status?: EPageStatus;
    name?: string;
    type?: string;
    id?: string;
    styleType?: string;
  } & Page = {},
) => {
  return request.get<TReturn<any>>(prefixPageGroup('list'), {
    params,
  });
};

// 页面组 - 详情
export const pageGroupDetail = (id: string) => {
  return request.get<TReturn<any>>(prefixPageGroup('get'), {
    params: { id },
  });
};

// 页面组 - 复制页面
export const copyPage = (id: string) => {
  return request.post<TReturn<any>>(prefixSystem('page/copy'), { data: { id } });
};

// 页面-获取页面列表
export const getPageTypesByModelId = (PageGroupId: string) =>
  request.get<TReturn<any>>(prefixPageConfig('page/getPageList'), {
    params: { PageGroupId },
  });

// 页面-获取页面详情
export const getPageTypeDetailByPageId = (pageId: string) =>
  request.get<TReturn<any>>(prefixPageConfig('page/getDetail'), {
    params: { pageId },
  });

// 根据列表页面 id 加载 列表页面信息
export const getListPageInfo = (params: {
  // 列表页面ID
  id: string;
  // 请求正式发布的值
  published?: boolean;
}) =>
  request.get<TReturn<any>>(prefixSystem('listPage/load'), {
    params,
  });

/**
 * 页面组 发布
 * @param id 页面组 ID
 * @returns
 */
export const publishPageGroup = (id: string) =>
  request.post<TReturn<any>>(prefixPageGroup('/publish'), {
    data: { id },
  });

/**
 * 页面组 禁用
 * @param id 页面组 ID
 * @returns
 */
export const disablePageGroup = (id: string) =>
  request.post<TReturn<any>>(prefixPageGroup('/disable'), {
    data: { id },
  });

/**
 * 获取页面详细信息： 新增/编辑/详情
 * @param pageId 页面 ID
 * @param released true：读取正式配置，false：读取草稿配置
 * @returns
 */
export const getOtherPageInfo = (pageId: string, released = false) =>
  request.get<TReturn<any>>(prefixSystem('/page/get'), {
    params: { pageId, released },
  });

/**
 * 获取页面初始化方法参数
 * @param pageId 目标页面ID， 例如进入编辑页，即传入编辑页 ID
 * @param paramType 参数类型，0：入参，1：出参
 * @returns
 */
export const getInitMethodParams = (pageId: string, paramType: 0 | 1 = 0) =>
  request.get<TReturn<any>>(prefixSystem('/page/getInitMethodParams'), {
    params: { pageId, paramType },
  });

/**
 * 更新页面配置
 */
export const saveOtherPageInfo = (data: any) =>
  request.post<TReturn<any>>(prefixSystem('page/update'), {
    data,
  });

/**
 * 页面组内的页面list
 * @param id 页面组ID
 * @returns
 */
export const groupPageList = (id: string, published: boolean) => {
  return request.get<TReturn<any>>(prefixPageGroup('listGroupPage'), {
    params: { id, published },
  });
};

// 外部页面 - 列表
interface ExternalPageListItem {
  id: string;
  name: string;
  url: string;
  createTime: string;
  creator: string;
  modifyTime: string;
  modifier: string;
}
export interface GetExternalPageListReturnData {
  total: number;
  list: ExternalPageListItem[];
}

/**
 * 获取外部页面列表
 * @params
 *  - id {string} 外部页面id
 *  - name {string} 外部页面名称
 */
export const getExternalPageList = (params: { id?: string; name?: string } & Page = {}) => {
  return yapiRequest.get<TReturn<GetExternalPageListReturnData>>(prefixExternalPage('list'), {
    params,
  });
};

interface LoadEvent {
  name: string;
  key: string;
  inputProperties: string;
  outputProperties: string;
  orderNum: number;
}

interface MessageEvent {
  name: string;
  key: string;
  inputProperties: string;
  outputProperties: string;
  orderNum: number;
}

interface GetExternalPageDetailReturnData {
  id: string;
  name: string;
  url: string;
  createTime: string;
  creator: string;
  modifyTime: string;
  modifier: string;
  loadEvents: LoadEvent[];
  messageEvents: MessageEvent[];
}

/**
 * 获取外部页面详情
 * @params
 *    - id {string} 页面id
 */
export const getExternalPageDetail = (params: { id?: string }) => {
  return yapiRequest.get<TReturn<GetExternalPageDetailReturnData>>(prefixExternalPage('get'), {
    params,
  });
};

interface UpdateExternalPage {
  id: string;
  name: string;
  url: string;
  loadEvents: LoadEvent[];
  messageEvents: MessageEvent[];
}
interface UpdateExternalPageReturnData {
  id: string;
}
/**
 * 更新外部页面详情
 * @params
 *  - id {string} 外部页面ID
 *  - name {string} 外部页面名称
 *  - url {string} 外部页面URL
 *  - loadEvents {array[object]} 加载事件列表
 *  - messageEvents {array[object]} 消息事件列表
 */
export const updateExternalPage = (data: UpdateExternalPage) => {
  return yapiRequest.post<TReturn<UpdateExternalPageReturnData>>(prefixExternalPage('update'), {
    data,
  });
};

interface AddExternalPage {
  id: string;
  name: string;
  url: string;
  loadEvents: LoadEvent[];
  messageEvents: MessageEvent[];
}
interface AddExternalPageReturnData {
  id: string;
}

/**
 * 新建外部页面详情
 * @params
 *  - id {string} 外部页面ID
 *  - name {string} 外部页面名称
 *  - url {string} 外部页面URL
 *  - loadEvents {array[object]} 加载事件列表
 *  - messageEvents {array[object]} 消息事件列表
 */
export const addExternalPage = (data: AddExternalPage) => {
  return yapiRequest.post<TReturn<AddExternalPageReturnData>>(prefixExternalPage('create'), {
    data,
  });
};
