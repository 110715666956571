import create from 'zustand';
import get from 'lodash/get';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import type { GroupPageListItemType, MethodInfo } from '@/types';

export type DrawerServiceTarget = 'add' | 'edit' | 'view';

export interface ParseItemType {
  // 预览，还是真实渲染； 预览不请求真实数据
  mode: EModeType;
  // 当前页面组信息
  currentPageGroupInfo?: Record<string, any>;
  // 加载中
  loading?: boolean;
  // 查询列表方法
  listMethodDetail?: MethodInfo;
  // 新增/编辑/详情方法详情
  addEditViewMethodDetail?: Record<string, any>;
  // 新增/编辑详情 - 配置信息
  addEditViewConfigInfo?: Record<string, any>;
  // 页面列表
  pageList?: Array<Record<string, any>>;
  // 动态枚举方法
  dyMethods?: Array<Record<string, any>>;
  // 新增/编辑抽屉是否显示
  editorVisible?: boolean;
  // 当前抽屉组件打开的页面 ID
  currentDrawerServiceTarget: string | null;
  // 当前抽屉组件打开的页面数据
  currentDrawerServicePage: GroupPageListItemType | null;
  // 点击编辑/查看详情时，记录列表当前行数据
  rowInfo: Record<string, any>;
  // 实际返回的详情数据
  detailInfo: Record<string, any>;
  // 列表接口入参，映射
  listInputParamsMap: Record<string, any>;
  // 列表接口出参，映射
  listOutputParamsMap: Record<string, any>;
  // 用于协助初始化详情方法的配置信息
  configForDetailInit: Record<string, any>;
}

// 模式
export enum EModeType {
  production = 'production',
  preview = 'preview',
}

const mergePagesData = (state: any, pageModelId: string, key: string, value: any) => {
  return {
    ...(state.pages || {}),
    [pageModelId]: {
      ...get(state, `pages.${pageModelId}`, {}),
      [key]:
        _.isObject(value) || _.isArray(value)
          ? deepmerge(get(state, `pages.${pageModelId}.${key}`, {}), value, {
              // 数组合并，要覆盖，不要追加
              arrayMerge: (destinationArray, sourceArray) => sourceArray,
            })
          : value,
    },
  };
};

interface StoreType {
  pages: Record<string, ParseItemType>;
  uListInputParamsMap: (...args: any[]) => void;
  uListOutputParamsMap: (...args: any[]) => void;
  uCurrentPageGroupInfo: (pageModelId: string, info: any) => void;
  uLoading: (pageModelId: string, loading: boolean) => void;
  uListMethodDetail: (pageModelId: string, method: any) => void;
  uAddEditViewMethodDetail: (pageModelId: string, method: any) => void;
  uAddEditViewConfigInfo: (pageModelId: string, detail: any) => void;
  uPageList: (pageModelId: string, list: any[]) => void;
  uDyMethods: (pageModelId: string, dyMethods: any[]) => void;
  uEditorVisible: (pageModelId: string, editorVisible: boolean) => void;
  uCurrentDrawerServiceTarget: (pageModelId: string, target: string | null) => void;
  reset: (pageModelId: string) => void;
  uMode: (pageModelId: string, mode: EModeType) => void;
  uRowInfo: (pageModelId: string, info: any) => void;
  uDetailInfo: (pageModelId: string, info: any) => void;
  uConfigForDetailInit: (pageModelId: string, info: any) => void;

  listRefresh: number;
  uListRefresh: (count: number) => void;
}

const useFormGeneratorStore = create<StoreType>((set) => ({
  pages: {},

  uMode: (pageModelId, mode) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'mode', mode),
    })),

  // 列表入参映射
  uListInputParamsMap: (pageModelId, listInputParamsMap: any) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'listInputParamsMap', listInputParamsMap),
    })),

  // 列表出参映射
  uListOutputParamsMap: (pageModelId, listOutputParamsMap: any) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'listOutputParamsMap', listOutputParamsMap),
    })),

  uCurrentPageGroupInfo: (pageModelId, currentPageGroupInfo) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'currentPageGroupInfo', currentPageGroupInfo),
    })),

  uLoading: (pageModelId, loading) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'loading', loading),
    })),

  uListMethodDetail: (pageModelId, listMethodDetail) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'listMethodDetail', listMethodDetail),
    })),

  uAddEditViewMethodDetail: (pageModelId, addEditViewMethodDetail) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'addEditViewMethodDetail', addEditViewMethodDetail),
    })),

  uAddEditViewConfigInfo: (pageModelId, addEditViewConfigInfo) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'addEditViewConfigInfo', addEditViewConfigInfo),
    })),

  uPageList: (pageModelId, pageList) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'pageList', pageList),
    })),

  uDyMethods: (pageModelId, dyMethods) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'dyMethods', dyMethods),
    })),

  uEditorVisible: (pageModelId, editorVisible) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'editorVisible', editorVisible),
    })),

  uCurrentDrawerServiceTarget: (pageModelId, currentDrawerServiceTarget) =>
    set((state) => {
      let pages = mergePagesData(
        state,
        pageModelId,
        'currentDrawerServiceTarget',
        currentDrawerServiceTarget,
      );

      let page = null;
      if (currentDrawerServiceTarget !== null) {
        // 找到对应 ID 的 page 配置对象并设置为 currentDrawerServicePage
        const parseItem = state.pages[pageModelId];
        page =
          parseItem?.pageList?.find((item) => String(item.id) === currentDrawerServiceTarget) ??
          null;
        pages = mergePagesData({ ...state, pages }, pageModelId, 'currentDrawerServicePage', {
          ...page,
        });
      }

      pages = mergePagesData({ ...state, pages }, pageModelId, 'currentDrawerServicePage', {
        ...page,
      });

      return {
        pages,
      };
    }),

  uRowInfo: (pageModelId, rowInfo) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'rowInfo', rowInfo),
    })),

  uDetailInfo: (pageModelId, detailInfo) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'detailInfo', detailInfo),
    })),

  uConfigForDetailInit: (pageModelId, configForDetailInit) =>
    set((state) => ({
      pages: mergePagesData(state, pageModelId, 'configForDetailInit', configForDetailInit),
    })),

  listRefresh: 1,
  uListRefresh: (count: number) =>
    set(() => ({
      listRefresh: count,
    })),

  reset(pageModelId) {
    // alert(pageModelId);
    if (!pageModelId) return;
    set((state) => {
      const pages = _.clone(state.pages);
      delete pages[pageModelId];
      return {
        pages,
      };
    });
  },
}));

export default useFormGeneratorStore;
