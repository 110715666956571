// 前端沙箱
// from groot

/**
 * 返回可执行代码的function
 * @param code 代码段
 */
function compileCode(code: string) {
  const newCode = `with(ctx){${code}}`;
  // eslint-disable-next-line no-new-func
  return new Function('ctx', newCode);
}

/**
 * 代理上下文对象，防止访问原型链上的属性
 */
function ctxProxy(ctx: Object, config: { useWindowScope?: boolean }) {
  const { useWindowScope = false } = config;

  return new Proxy(ctx, {
    has(target: any, key: string) {
      // eslint-disable-next-line no-prototype-builtins
      if (target.hasOwnProperty(key)) {
        return true;
      }

      // window的自身属性方法，开启访问白名单
      // eslint-disable-next-line no-prototype-builtins
      if (useWindowScope && window.hasOwnProperty(key)) {
        return target[key];
      }

      throw new Error(`invalid expression - ${key}`);
    },
  });
}

/**
 * 前端运行沙箱
 * @param code 代码段
 * @param ctx 上下文
 * @param config
 *         - useWindowScope 是否使用window环境变量和方法
 */
export function sandbox(
  code: string,
  ctx: Object,
  config = {
    useWindowScope: false,
  },
) {
  try {
    const newCtx = ctxProxy(ctx, config);
    // 使用call，将当前代码段的 this 指向新的上下文
    return compileCode(code).call(newCtx, newCtx);
  } catch (e) {
    console.log('error', e);
    return null;
  }
}
