import { memo } from 'react';
import { Checkbox } from 'antd';
import pick from 'lodash/pick';

interface CheckboxGroupProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const CheckboxGroup = memo<CheckboxGroupProps>(({ onChange, value, configInfo }) => {
  return (
    <Checkbox.Group
      {...pick(configInfo, ['disabled', 'placeholder', 'options'])}
      value={value || configInfo.defaultValue}
      defaultValue={configInfo.defaultValue}
      onChange={onChange}
    />
  );
});

export default CheckboxGroup;
