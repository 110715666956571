import { memo } from 'react';
import { DatePicker } from 'antd';
import pick from 'lodash/pick';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

interface RangePickerControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const NewRangePicker = memo<RangePickerControlProps>(({ onChange, value, configInfo }) => {
  const otherProps: Record<string, any> = {};
  if (typeof configInfo.startTime !== 'undefined' && typeof configInfo.endTime !== 'undefined') {
    otherProps.defaultValue = [
      moment(configInfo.startTime, dateFormat),
      moment(configInfo.endTime, dateFormat),
    ];
  } else {
    otherProps.defaultValue = undefined;
  }
  if (configInfo.showFormat === 'YYYY-MM-DD HH:mm:ss') {
    otherProps.showTime = { format: 'HH:mm:ss' };
  }

  return (
    <RangePicker
      {...pick(configInfo, ['disabled', 'placeholder'])}
      format={configInfo.showFormat || dateFormat}
      {...otherProps}
      value={value}
      onChange={onChange}
    />
  );
});

export default NewRangePicker;
