import { useImperativeHandle, forwardRef } from 'react';
import type { ForwardRefRenderFunction } from 'react';
import type { ProColumns } from '@ant-design/pro-table';
import { default as ProTable } from '@ant-design/pro-table';
import { Row, Button } from 'antd';
import useSelection from '@/hooks/useSelection';
import Text from '@/components/Column/Text';
import { getExternalPageList } from '@/services/pageGroup';
import useExternalPageDetail from '@/hooks/useExternalPageDetail';

type TableListItem = any;
interface IMethodsProps {
  onGetModel?: (daataa: TableListItem[]) => void;
  defaultCheckeds?: TableListItem[];
  selectType?: 'radio' | 'checkbox';
}

const Methods: ForwardRefRenderFunction<any, IMethodsProps> = (props, ref) => {
  const { load: loadDetail, loading } = useExternalPageDetail();
  const { onGetModel, defaultCheckeds, selectType = 'radio' } = props;
  const { getRowSelection, checkedRows, getRowClickSelectRowKeys } = useSelection({
    type: selectType,
    idKey: 'id',
    checkedRows: defaultCheckeds,
  });

  useImperativeHandle(ref, () => ({}));

  const columns: Array<ProColumns<TableListItem>> = [
    {
      title: '页面ID',
      ellipsis: true,
      dataIndex: 'id',
      width: 200,
      render: (_, record) => <Text value={record.id} />,
    },
    {
      title: '页面名称',
      dataIndex: 'name',
      ellipsis: true,
      render: (_, record) => <Text value={record.name} />,
    },
    {
      title: '页面URL',
      dataIndex: 'url',
      ellipsis: true,
      search: false,
      render: (_, record) => <Text value={record.url} />,
    },
  ];

  return (
    <ProTable<TableListItem>
      columns={columns}
      bordered
      size="small"
      rowKey="id"
      style={{ width: 700 }}
      rowSelection={{
        ...getRowSelection(),
      }}
      onRow={(record) => getRowClickSelectRowKeys(record)}
      scroll={{
        y: 500,
      }}
      toolBarRender={false}
      request={async (params) => {
        params.pageNumber = params.current;
        delete params.current;
        const res = await getExternalPageList(params);
        return {
          data: res?.data?.list || [],
          total: res?.data?.total || 0,
          success: true,
        };
      }}
      footer={() => (
        <Row justify="end">
          <Button
            type="primary"
            disabled={!checkedRows.length}
            loading={loading}
            onClick={async () => {
              // 列表拿到的方法数据不齐全，输出时，查询详情输出
              Promise.all(
                checkedRows.map(async (row) => {
                  return await loadDetail(row.id);
                }),
              ).then((vals) => {
                onGetModel?.(vals);
              });
            }}
          >
            确定
          </Button>
        </Row>
      )}
    />
  );
};

export default forwardRef(Methods);
