import React, { memo, useRef } from 'react';
import { message, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { formatNum } from '@/utils/utils';
import { isString } from 'lodash';

interface TextProps {
  value?: string | number;
  copyable?: boolean;
  isTime?: boolean;
  isNum?: boolean;
  format?: string;
  tooltip?: boolean;
  linkStyle?: boolean;
  onClick?: () => void;
  ellipsis?: boolean;
  omitted?: number;
  isOmitted?: boolean;
  transferFormat?: string;
  style?: React.CSSProperties;
}

export default memo<TextProps>(
  ({
    value,
    copyable = false,
    format = 'YYYY-MM-DD HH:mm:ss',
    isTime = false,
    tooltip = true,
    isNum = false,
    linkStyle = false,
    ellipsis = true,
    onClick,
    omitted = 20,
    isOmitted,
    transferFormat,
    style,
  }) => {
    const copyNoderef = useRef<HTMLSpanElement | null>(null);
    if (value === null) return null;
    if (typeof value === 'undefined') return null;
    const Component = linkStyle ? Typography.Link : Typography.Text;
    let realText: string;
    if (isTime) {
      if (value === '') {
        realText = '-';
      } else if (transferFormat === 'x' || transferFormat === 'X') {
        realText = moment(Number(value), transferFormat).format(format);
      } else if (transferFormat !== undefined) {
        realText = moment(value, transferFormat).format(format);
      } else {
        realText = moment(Number(value)).format(format);
      }
    } else if (isNum) {
      realText = formatNum(String(value));
    } else if (value === 'undefined' || value === 'null') {
      realText = '-';
    } else {
      realText = value as string;
    }
    let textContent = realText;
    if (isString(textContent)) {
      if (textContent.length > omitted && isOmitted) {
        textContent = `${textContent.slice(0, omitted)}...`;
      }
    }
    const content = (
      <Component onClick={onClick} ellipsis={ellipsis}>
        <span style={style}>{textContent || '-'}</span>
      </Component>
    );
    if (!tooltip) {
      return content;
    }
    return (
      <Tooltip title={copyable ? `点击复制:${realText}` : realText}>
        {copyable ? (
          <span
            ref={copyNoderef}
            style={{ cursor: 'pointer', ...style }}
            onClick={() => {
              if (!(window.isSecureContext && navigator.clipboard)) {
                const textArea = document.createElement('textarea');
                textArea.value = realText;
                textArea.style.position = 'fixed';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                  const successful = document.execCommand('copy');
                  const msg = successful ? '复制成功' : '复制失败';
                  message[successful ? 'success' : 'error'](msg);
                } catch (err) {
                  message.error('复制失败');
                }

                document.body.removeChild(textArea);
                return;
              }
              navigator.clipboard.writeText(realText).then(
                () => {
                  message.success('复制成功');
                },
                () => {
                  message.error('复制失败');
                },
              );
            }}
          >
            {content}
          </span>
        ) : (
          content
        )}
      </Tooltip>
    );
  },
);
