import React, { useState } from 'react';
import { Select, Button, Table, Space, InputNumber, Input, Popconfirm } from 'antd';
import type { TableColumnType } from 'antd';
import { RuleTyps, ERuleType } from '@/constant';
import DeleteIconButton from '@/components/DeleteIconButton';
import { nanoid } from 'nanoid';

interface RulesConfigProps {
  // formData?: any;
  value?: any[];
  style?: React.CSSProperties;
  onChange?: (data: any[]) => void;
}
let cacheExpandedRows: string[] = [];
export default function RulesConfig({ value, onChange, style }: RulesConfigProps) {
  const [expandedRows, setExpandedRows] = useState<React.Key[]>(cacheExpandedRows);

  const columns: Array<TableColumnType<any>> = [
    {
      title: '选择校验规则类型',
      dataIndex: 'ruleType',
      render(_, record, index) {
        return (
          <Select
            value={record.ruleType || ''}
            options={RuleTyps}
            placeholder="请选择规则类型"
            allowClear
            onChange={(val) => {
              if (value && value.length) {
                value[index].ruleType = val;
                onChange?.([...value]);
              }
            }}
          />
        );
      },
    },
    {
      title: '操作',
      fixed: 'right',
      width: 60,
      render: (_, record, index) => (
        <Space>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              if (value && value.length) {
                value.splice(index, 1);
                onChange?.([...value]);
              }
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const expandedRowRender = {
    defaultExpandAllRows: true,
    expandedRows,
    onExpandedRowsChange(rows: any) {
      setExpandedRows(rows);
      cacheExpandedRows = rows;
    },
    expandedRowRender(record: any, index: number) {
      const INPUT_WIDTH = 200;

      const MessageInput = (
        <Input
          style={{ width: INPUT_WIDTH }}
          placeholder="请输入校验提示文案"
          allowClear
          value={record.message || ''}
          onChange={(e) => {
            if (value?.length) {
              value[index].message = e.target.value;
              onChange?.([...value]);
            }
          }}
        />
      );
      switch (record.ruleType) {
        case ERuleType.字符串最大长度:
          return (
            <Space direction="vertical">
              <InputNumber
                style={{ width: INPUT_WIDTH }}
                placeholder="请输入最大长度值"
                value={record.maxLength}
                onChange={(num) => {
                  if (value?.length) {
                    value[index].maxLength = num;
                    onChange?.([...value]);
                  }
                }}
              />
              {MessageInput}
            </Space>
          );
        case ERuleType.数值大小范围:
          return (
            <Space direction="vertical">
              <InputNumber
                style={{ width: INPUT_WIDTH }}
                placeholder="请输入最小值"
                value={record.min || ''}
                onChange={(num) => {
                  if (value?.length) {
                    value[index].min = num;
                    onChange?.([...value]);
                  }
                }}
              />
              <InputNumber
                style={{ width: INPUT_WIDTH }}
                placeholder="请输入最大值"
                value={record.max || ''}
                onChange={(num) => {
                  if (value?.length) {
                    value[index].max = num;
                    onChange?.([...value]);
                  }
                }}
              />
              {MessageInput}
            </Space>
          );
        case ERuleType.小数:
          return (
            <Space direction="vertical">
              <InputNumber
                style={{ width: INPUT_WIDTH }}
                placeholder="请输入保留位数字"
                min={0}
                max={3}
                value={record.precision || ''}
                onChange={(data) => {
                  if (value?.length) {
                    value[index].precision = data;
                    onChange?.([...value]);
                  }
                }}
              />
              {MessageInput}
            </Space>
          );
        case ERuleType.正则:
          return (
            <Space direction="vertical">
              <Input
                style={{ width: INPUT_WIDTH }}
                placeholder="请输入正则表达式"
                value={record.pattern || ''}
                allowClear
                onChange={(e) => {
                  if (value?.length) {
                    value[index].pattern = e.target.value;
                    onChange?.([...value]);
                  }
                }}
              />
              {MessageInput}
            </Space>
          );
        default:
          return MessageInput;
      }
    },
  };

  return (
    <div style={style}>
      <Table<any>
        dataSource={value}
        size="small"
        bordered
        showHeader={false}
        style={{ width: 400 }}
        title={() => {
          return (
            <Button
              size="small"
              type="link"
              onClick={() => {
                if (value?.length) {
                  value.push({
                    id: nanoid(),
                  });
                  onChange?.([...value]);
                } else {
                  onChange?.([{ id: nanoid() }]);
                }
              }}
            >
              添加规则
            </Button>
          );
        }}
        rowKey="id"
        columns={columns}
        pagination={false}
        expandable={expandedRowRender}
      />
    </div>
  );
}
