import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { debounce, omit } from 'lodash';
import type { FieldItem } from '@/store/types';
import useFormGeneratorStore from '@/store/form-generator';
import getCommonFieldConfig from '../CommonFieldConfig';
import FormCreator, { EWidgetType } from '@/components/FormCreator';
import WithCollapse from '../../WithCollapse';
import { EPageType } from '@/constant';
import { getListPageConfig } from '@/services/page';
import Text from '@/components/Column/Text';
import { parseString2Json } from '../../help';
import ListCheckboxConfigItem from './ConfigItem';
import BindParam from '../common/BindParam';

interface Props {
  fieldInfo: FieldItem & { [key: string]: any };
  index: number;
  store: any;
  onChange?: (val: any) => void;
}

const commonConfig = getCommonFieldConfig();

export interface ListCheckboxOptions {
  text?: string;
  page?: string;
}

export default function ListCheckboxConfig({ fieldInfo, store, onChange }: Props) {
  const pageList = useFormGeneratorStore((state) => state.pageList);
  const [listPageInfo, setPageInfo] = useState<Record<string, any> | null>();

  const pageId = fieldInfo.page;
  useEffect(() => {
    if (pageId) {
      // 5. 根据列表页面 ID, 获取相关信息
      getListPageConfig(pageId, false).then((res) => {
        if (res.rCode === 0) {
          setPageInfo(res.data);
          // uListPageInfo(pageGroupInfo.id, res.data);
        } else {
          // setErrors([...errors, res.msg]);
        }
      });
    }
    return () => {};
  }, [pageId]);

  const dataSource: any[] = listPageInfo?.contentColumns?.map((column: any) => {
    const properties: any = parseString2Json(column.properties);
    return {
      key: column.frontComponentId,
      title: properties.title,
      dataIndex: properties.dataIndex,
    };
  });
  const invalidData: string[] = [];
  const validData = dataSource?.map((item: any) => item.dataIndex);
  if (validData) {
    for (const key in fieldInfo?.listItems ?? {}) {
      if (!validData.includes(key)) {
        invalidData.push(key);
      }
    }
  }

  const CollapseDatas = [
    {
      ...commonConfig.base,
      configComponent: (
        <FormCreator
          {...(commonConfig.base.formProps as any)}
          config={{
            ...commonConfig.base.formConfig(
              fieldInfo,
              store,
              ['defaultValue', 'placeholder', 'bindParam'],
              'loadMethod',
            ),
            targetParam: {
              label: '目标回填',
              tooltip: '设置当前组件关联对应方法下的参数',
              defaultValue: fieldInfo?.targetParam,
              fieldType: EWidgetType.CustomWidget,
              custom: (props: any) => (
                <BindParam
                  {...props}
                  bindedMethods={store?.bindedMethods}
                  checkMethods={'submitMethod'}
                />
              ),
            },
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
    {
      ...commonConfig.interaction,
      configComponent: (
        <FormCreator
          {...(commonConfig.interaction.formProps as any)}
          config={{
            text: {
              label: '按钮文案',
              tooltip: '配置唤起弹窗列表的按钮名称',
              fieldType: EWidgetType.Input,
              defaultValue: fieldInfo?.text ?? '',
            },
            page: {
              label: '绑定页面',
              defaultValue: fieldInfo?.page,
              fieldType: EWidgetType.Select,
              fieldProps: {
                options: pageList
                  .filter((page) => String(page.type) === EPageType.列表页面)
                  .map((page) => ({
                    label: page.name,
                    value: page.id,
                  })),
              },
            },
            listItems: {
              // label: '列表字段',
              defaultValue: omit(fieldInfo?.listItems, invalidData) ?? {},
              fieldType: EWidgetType.CustomWidget,
              custom(props: any) {
                const { value } = props;
                return (
                  <Table
                    size="small"
                    columns={[
                      {
                        title: '列表项配置',
                        dataIndex: 'title',
                        render: (text: any, record: any) => <Text value={`${record.title} `} />,
                      },
                    ]}
                    dataSource={dataSource}
                    expandable={{
                      expandedRowRender(record, index) {
                        const { title, dataIndex } = record;
                        return (
                          <ListCheckboxConfigItem
                            key={index}
                            // currentId={fieldInfo.controlId}
                            value={value[dataIndex] ?? {}}
                            onChange={(values) => {
                              props?.onChange?.({
                                ...value,
                                [dataIndex]: {
                                  ...values,
                                  displayTitle: title,
                                },
                              });
                            }}
                          />
                        );
                      },
                    }}
                  />
                );
              },
            },
            ...commonConfig.interaction.formConfig(fieldInfo),
          }}
          onValuesChange={debounce((_: any, allVals: any) => {
            onChange?.(allVals);
          }, 500)}
        />
      ),
    },
  ];

  return <WithCollapse collapseDatas={CollapseDatas} />;
}
