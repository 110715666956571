import { memo, useCallback, useState, useEffect, useRef } from 'react';
import { Upload, Modal, message } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { token } from '@/utils/utils';
import { prefixSystem } from '@/services/common';
import { HOST } from '@/constant';

interface MultiUploadProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
  onValidate?: (errMsg: string) => void;
}

const uploadButton = (
  <div>
    <PlusOutlined />
  </div>
);

interface TPreviewInfo {
  previewImage?: string;
  previewVisible: boolean;
}

const fillFileByString = (url: string, idx: number) => ({
  thumbUrl: url,
  name: url,
  uid: url + idx,
  status: 'done',
  response: {
    rCode: 0,
    data: url,
  },
});

const MultiUpload = memo<MultiUploadProps>(({ onChange, value, configInfo, onValidate }) => {
  const {
    preFileLink = '',
    fileType,
    fileTypeLimit = ['jpeg', 'png', 'gif'],
    sizeLimit = false,
    singleFileSize = 2,
    limitPrompt = '',
    isSingle,
  } = configInfo;
  let { maxCount = 3 } = configInfo;
  if (isSingle) {
    maxCount = 1;
  }

  const fileTypeList = fileTypeLimit.map((type: string) => {
    return `image/${type}`;
  });
  const [previewInfo, setPreviewInfo] = useState<TPreviewInfo>({
    previewImage: '',
    previewVisible: false,
  });
  const [fileList, setFileList] = useState(
    (typeof value === 'string' ? [value] : value || []).map(fillFileByString),
  );
  const hasFillRef = useRef(true);
  const oldValue = useRef(JSON.stringify(value));

  useEffect(() => {
    const newValue = JSON.stringify(value);
    if (
      hasFillRef.current &&
      oldValue.current !== newValue &&
      (Array.isArray(value) || typeof value === 'string')
    ) {
      oldValue.current = newValue;
      setFileList((typeof value === 'string' ? [value] : value || []).map(fillFileByString));
    }
  }, [value]);

  useEffect(() => {
    onChange?.(fileList.map((o: any) => o?.thumbUrl || ''));
  }, [fileList]);

  // 监听图片上传
  const onChangeImg = useCallback(
    ({ fileList: newFileList, file }) => {
      // 替换base64图片，避免卡死页面
      hasFillRef.current = false;
      newFileList.forEach((imgItem: UploadFile) => {
        if (
          imgItem &&
          imgItem.status == 'done' &&
          imgItem.response &&
          imgItem.response.data &&
          preFileLink.length > 0
        ) {
          const { path } = imgItem.response.data;
          if (path.startsWith('http')) {
            imgItem.thumbUrl = `${path}`;
          } else {
            imgItem.thumbUrl = `${preFileLink}${path}`;
          }
        }
      });
      setFileList(
        newFileList.filter((item: UploadFile) => {
          if (['uploading', 'success', 'removed'].includes(item.status as string)) {
            return true;
          }
          if (item.status == 'done' && item.response.rCode == 0) {
            return true;
          }
          return false;
        }),
      ); // 阻止上传的图片，不存入fileList

      const { status, response } = file;

      switch (status) {
        case 'error':
          message.error('上传失败');
          break;
        case 'done':
          if (response.rCode !== 0) {
            message.error('上传失败');
            return;
          }
          message.success('上传成功');
          break;
      }
    },
    [preFileLink],
  );

  const handleCancel = useCallback(() => {
    setPreviewInfo({
      previewVisible: false,
    });
  }, []);

  // 预览
  const handlePreview = useCallback((file) => {
    setPreviewInfo({
      previewImage: file.url || file.thumbUrl || '',
      previewVisible: true,
    });
  }, []);

  // 限制图片 格式、size、分辨率
  const handleBeforeUpload = useCallback(
    (file) => {
      if (preFileLink.length < 1) {
        Modal.error({
          title: '请在交互设置中填写“图片资源的url前缀”',
        });
        return false;
      }

      if (typeof fileType === 'undefined') {
        Modal.error({
          title: '请在交互设置中填写“内部上传编码”',
        });
        return false;
      }
      const isSafeImage = fileTypeList.includes(file.type);

      if (!isSafeImage) {
        const msg =
          limitPrompt.length > 0 ? limitPrompt : '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~';
        /*  Modal.error({
        title: msg,
      }); */
        onValidate?.(msg);
        return false;
      }
      const isLowerLimit = file.size / 1024 / 1024 < singleFileSize; // 图片*m大小的限制
      if (sizeLimit && !isLowerLimit) {
        const msg = `超过${singleFileSize}M限制，不允许上传~`;
        /* Modal.error({
        title: msg,
      }); */
        onValidate?.(msg);
        return false;
      }
      onValidate?.('');
      return true;
    },
    [preFileLink, fileType, singleFileSize, fileTypeList, sizeLimit, limitPrompt, onValidate],
  );

  return (
    <>
      <Upload
        action={HOST + prefixSystem('upload/file')}
        listType="picture-card"
        data={{
          fileType,
        }}
        headers={{
          'X-SSO-Authorization': token as string,
        }}
        fileList={fileList}
        maxCount={maxCount}
        onChange={onChangeImg}
        onPreview={handlePreview}
        beforeUpload={handleBeforeUpload}
        withCredentials
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>
      <Modal visible={previewInfo.previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewInfo.previewImage} />
      </Modal>
    </>
  );
});

export default MultiUpload;
