/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import Request, { extend } from 'umi-request';
import Cookies from 'js-cookie';
import { token, getTokenFromSSO, getCookieDomain } from '@/utils/utils';
import { notification } from 'antd';
import { HOST } from '@/constant';

const codeMessage: Record<number, string> = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/** 异常处理程序 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;

    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

Request.interceptors.request.use((url, options: any) => {
  return {
    options: {
      ...options,
      redirect: 'manual',
      credentials: 'include',
      headers: {
        ...options.headers,
        'X-Requested-With': 'XMLHttpRequest', // 单点登录要求添加的请求头
        'X-SSO-Authorization': token,
      },
    },
  };
});

// 增加单点登录
Request.interceptors.response.use(async (response) => {
  const data = await response.clone().json();

  // first enter page, there has no token. and the request response {"code": 1001, "message": "null"}
  // second enter page, the url has "__vt_param__"，and it take the token from sso.
  const code = data.rCode || data.code;

  if (code === 1001) {
    Cookies.remove('token-v1', { path: '/', domain: getCookieDomain() });
    await getTokenFromSSO().catch((err) => {
      console.log(err);
    });
    return response;
  }
  if (code === 1002) {
    notification.error({
      description: '没有权限',
      message: '权限问题',
    });

    return response;
  }

  return response;
});

/** 配置request请求时的默认参数 */
const request = extend({
  prefix: HOST,
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie,
});

export const yapiRequest = extend({
  // prefix: `http://yapi.feoffice.lizhi.fm/mock/1819`,
  prefix: HOST,
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie,
});

export default request;
