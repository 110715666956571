import React, { useCallback } from 'react';
import { Input, Button, Table, Space, Popconfirm, Select } from 'antd';
import type { TableColumnType } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import DeleteIconButton from '@/components/DeleteIconButton';
import { dragComponents } from '@/components/SortableTable/dragable';
import update from 'immutability-helper';
import { nanoid } from 'nanoid';
import { clone } from 'lodash';

interface GiftContainerProps {
  // formData?: any;
  value?: any[];
  style?: React.CSSProperties;
  onChange?: (data: any[]) => void;
}

enum ETypeOptions {
  String = 'String',
  Boolean = 'Boolean',
  Number = 'Number',
}
type Type = keyof typeof ETypeOptions;
const typeOptions = [
  {
    label: 'String',
    value: ETypeOptions.String,
  },
  {
    label: 'Boolean',
    value: ETypeOptions.Boolean,
  },
  {
    label: 'Number',
    value: ETypeOptions.Number,
  },
];

interface OptionValueProps {
  value: string | boolean | number;
  onChange: (data: any) => void;
  type: Type;
}

function OptionValue({ value, onChange, type }: OptionValueProps) {
  if (type === ETypeOptions.Boolean) {
    return (
      // ant Select 的 value为false时，不能正常显示，故转 0，1
      <Select
        value={Number(value)}
        size="small"
        options={[
          {
            label: 'true',
            value: 1,
          },
          {
            label: 'false',
            value: 0,
          },
        ]}
        onChange={(data) => {
          onChange(Boolean(data));
        }}
      />
    );
  }
  return (
    <Input
      value={(value as string) || ''}
      size="small"
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}

export default function EnumsEditor({ value, onChange, style }: GiftContainerProps) {
  const conversionValue = (newValue: any[], index: number, type: Type, data: any) => {
    if (type === ETypeOptions.Boolean) {
      newValue[index].value = Boolean(data);
    } else {
      newValue[index].value = String(data);
    }
    return clone(newValue);
  };
  const columns: Array<TableColumnType<any>> = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 40,
      render: () => <MenuOutlined />,
    },
    {
      title: '选项名称',
      dataIndex: 'label',
      width: 80,
      render(_, record, index) {
        return (
          <Input
            value={record.label || ''}
            size="small"
            onChange={(e) => {
              if (value && value.length) {
                value[index].label = e.target.value;
                onChange?.([...value]);
              }
            }}
          />
        );
      },
    },
    {
      title: '选项值类型',
      dataIndex: 'type',
      width: 80,
      render(_, record, index) {
        return (
          <Select
            value={record.type || ETypeOptions.String}
            size="small"
            options={typeOptions}
            onChange={(data) => {
              if (value && value.length) {
                value[index].type = data;
                const newValue = conversionValue(value, index, data, value[index].value);
                onChange?.([...newValue]);
              }
            }}
          />
        );
      },
    },
    {
      title: '选项值',
      dataIndex: 'value',
      width: 80,
      render(_, record, index) {
        return (
          <OptionValue
            value={record.value || ''}
            type={record.type}
            onChange={(data) => {
              if (value && value.length) {
                value[index].value = data;
                onChange?.([...value]);
              }
            }}
          />
        );
      },
    },
    {
      title: '操作',
      fixed: 'right',
      width: 40,
      render: (_, record, index) => (
        <Space>
          <Popconfirm
            title="确定移除？"
            cancelText="取消"
            okText="确定"
            onConfirm={() => {
              if (value && value.length) {
                value.splice(index, 1);
                onChange?.([...value]);
              }
            }}
          >
            <div>
              <DeleteIconButton />
            </div>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!value) return;
      const dragRow = value[dragIndex];
      onChange?.(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [value, onChange],
  );

  return (
    <div style={style}>
      <Table<any>
        dataSource={value}
        size="small"
        bordered
        style={{ width: 400 }}
        title={() => {
          return (
            <Button
              size="small"
              type="link"
              onClick={() => {
                if (value?.length) {
                  value.push({
                    label: '',
                    value: '',
                    id: nanoid(),
                  });
                  onChange?.([...value]);
                } else {
                  onChange?.([
                    {
                      label: '',
                      value: '',
                      id: nanoid(),
                    },
                  ]);
                }
              }}
            >
              添加选项
            </Button>
          );
        }}
        rowKey="id"
        columns={columns}
        pagination={false}
        components={{ ...dragComponents() }}
        onRow={(record, index) => ({ index, moveRow } as any)}
      />
    </div>
  );
}
