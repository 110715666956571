import { TStructFields } from '@/types';
import type { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';

export interface tableDataType extends TStructFields {
  matchRelation?: number;
  functionValue?: number;
  staticValue?: string;
  _isCheck1?: boolean;
  _isCheck2?: boolean;
}

export const defaultColumns: ColumnsType<tableDataType> = [
  {
    title: '字段类型',
    dataIndex: 'columnType',
    key: 'columnType',
  },
  // {
  //   title: '字段位置',
  //   dataIndex: 'position',
  //   key: 'position',
  // },
  {
    title: '驼峰名称',
    dataIndex: 'name',
    key: 'name',
    render: (value) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>
    ),
  },
  {
    title: '字段标识',
    dataIndex: 'columnIdentification',
    key: 'columnIdentification',
    render: (value) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{value}</div>
    ),
  },
  {
    title: '字段描述',
    dataIndex: 'columnDesc',
    key: 'columnDesc',
    render(value, record) {
      if (record.columnDesc.length > 5)
        return <Tooltip title={record.columnDesc}>{`${record.columnDesc.slice(0, 5)}...`}</Tooltip>;
      return `${record.columnDesc}`;
    },
  },
];
