import React, { memo, useState, useRef, useCallback } from 'react';
import { Spin, Drawer, message } from 'antd';
import { useIframeWindow } from '@/hooks/useIframeWindow';
import { transferFile } from '@/services/file';
import { makeParamvalues } from '@/utils/tool';
import { executeMethod } from '@/services/method';
import { CloseCircleOutlined } from '@ant-design/icons';
import { transferValueFromInAndOutParams } from '@/utils/helper';
import { sandbox } from '@/utils/sandbox';

interface Tprops {
  title: string;
  src: string;
  loadData?: Record<string, any>;
  config: Record<string, any> | undefined;
  isVisible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
}

const IframeDrawer: React.FC<Tprops> = memo(
  ({
    title = '',
    src = '',
    loadData,
    config,
    isVisible = false,
    handleOk = () => {},
    handleCancel = () => {},
  }) => {
    const [loading, setLoading] = useState(false);
    const [iframeHeight, setIframeHeight] = useState(document.body.clientHeight);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const messageEvents: Record<string, any> = config?.messageEvents ?? [];

    console.log('messageEvents', messageEvents);

    const transferFileHandle = useCallback((actionKey, { data }, callback) => {
      const { effectInnerUrl = '', effectUrl = '' } = data;

      transferFile({
        sourceUrl: effectUrl,
        sourceInnerUrl: effectInnerUrl,
        targetFileType: 25,
      })
        .then((res) => {
          callback({
            actionKey,
            value: res,
          });
        })
        .catch(() => {
          message.error('转存特效文件出错！');
        });
    }, []);

    useIframeWindow({
      async listenData(eventData) {
        console.log('orca receive data: ', eventData);

        const promiseList: any[] = [];
        const { data: list = [] } = eventData;
        for (const { actionKey, value } of list) {
          if (actionKey === 'transferEffectFile') {
            promiseList.push(
              new Promise((resolve) => {
                transferFileHandle(actionKey, value, (data: any) => {
                  resolve(data);
                });
              }),
            );
          } else if (actionKey === 'cancelAndCloseWindow') {
            handleCancel();
          } else {
            for (const info of Object.values(messageEvents)) {
              const { getTransformParamType, dataFlow } = info;

              if (getTransformParamType === 'function') {
                if (dataFlow === 'flowToSystemPage') {
                  const {
                    transformInAndOutParamsWithFunction,
                  }: { transformInAndOutParamsWithFunction: Record<string, any> } = info;

                  let newTransferValue: Record<string, any> = {};
                  for (const [readKey, transferInfo] of Object.entries(
                    transformInAndOutParamsWithFunction,
                  )) {
                    const sourceValue = sandbox(`return this.${readKey}`, value);
                    const { writeKey } = transferInfo;
                    newTransferValue = transferValueFromInAndOutParams(
                      newTransferValue,
                      writeKey,
                      sourceValue,
                    );
                  }

                  console.log('coral send value===', value, 'newTransferValue', newTransferValue);

                  const methodId = info?.bindFunction?.id ?? '';
                  const inputParams = info?.bindFunction?.inputParams ?? [];
                  // const outputParams = info?.bindFunction?.outputParams ?? [];
                  const paramValues = makeParamvalues(inputParams, newTransferValue);

                  promiseList.push(
                    executeMethod({ methodId, paramValues }).then((res) => {
                      const responParamValues: Array<Record<string, any>> =
                        res?.data?.paramValues ?? [];
                      try {
                        responParamValues.forEach((item) => {
                          if (['rCode', 'code'].includes(item?.path)) {
                            if (item.value !== 0) {
                              message.error('执行绑定方法出错！');
                              throw new Error('执行绑定方法出错！');
                            } else {
                              handleOk();
                            }
                          }
                        });
                      } catch (e) {
                        console.log(e);
                      }
                      return Promise.resolve();
                    }),
                  );
                }
              }
            }
          }
        }
        const result = await Promise.all(promiseList).catch((err) => {
          console.log('err', err);
        });

        console.log('run function result', result);
        return result;
      },
      async load(data, sendMsg) {
        const height = data?.data?.windowHeight ?? document.body.clientHeight;
        setIframeHeight(height);

        console.log('drawer loadData', loadData);
        await sendMsg(loadData).catch((err) => {
          console.log(err);
        });
      },
      unload() {
        setIframeHeight(document.body.clientHeight);
      },
    });

    return (
      <Drawer
        title={title}
        visible={isVisible}
        width="100%"
        height="100%"
        destroyOnClose
        onClose={handleCancel}
        bodyStyle={{ padding: 0, position: 'relative' }}
        closeIcon={false}
        extra={<CloseCircleOutlined onClick={handleCancel} />}
      >
        <div className="h-full" style={{ position: 'relative' }}>
          {loading && <Spin spinning size="large" />}
          <iframe
            ref={iframeRef}
            style={{
              width: '100%',
              height: `${iframeHeight}px`,
              border: 'none',
            }}
            title="自定义页面"
            onLoad={() => {
              setLoading(false);
            }}
            src={src}
          />
        </div>
      </Drawer>
    );
  },
);

export default IframeDrawer;
