import { memo, useMemo } from 'react';
import type { DrawerProps } from 'antd';
import { Drawer, Row, Typography, Table, Tooltip } from 'antd';
import { FieldControls, DataDisplayCmpTypes, PageLayoutCmpTypes } from '@/constant';

import './index.less';

type Props = {
  children?: any;
  onConfirm?: (info: any) => void;
} & DrawerProps;

export default memo<Props>(({ children, onConfirm, ...rest }) => {
  const dataSource = useMemo(() => {
    const list: any[] = [
      {
        title: '数据输入',
        cmps: FieldControls,
      },
      {
        title: '数据展示',
        cmps: DataDisplayCmpTypes,
      },
      {
        title: '页面布局',
        cmps: PageLayoutCmpTypes,
      },
    ];
    return list;
  }, []);

  return (
    <>
      <Drawer
        title={
          <Row justify="start" align="middle" style={{ padding: 0, margin: 0 }}>
            <Typography.Text style={{ fontSize: 14 }}>组件库 </Typography.Text>
            {/* {checkedRows?.length ? `（当前选中组件：${checkedRows[0].label}）` : null} */}
          </Row>
        }
        destroyOnClose
        bodyStyle={{ padding: 10 }}
        placement="left"
        visible={rest.visible}
        onClose={rest.onClose}
        width={350}
        {...rest}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <Table
            pagination={false}
            showHeader={false}
            size="small"
            columns={[
              {
                dataIndex: 'title',
              },
            ]}
            rowKey="title"
            expandable={{
              defaultExpandAllRows: true,
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <Table
                  pagination={false}
                  dataSource={record.cmps as any[]}
                  size="small"
                  bordered
                  rowKey="value"
                  showHeader={false}
                  onRow={(row) => {
                    return {
                      style: { cursor: 'pointer' },
                      onDoubleClick: () => onConfirm?.(row.value),
                    };
                  }}
                  /* rowSelection={{
                    ...getRowSelection()
                  }} */
                  columns={[
                    {
                      dataIndex: 'label',
                      render(_, row) {
                        return (
                          <Tooltip title="双击组件插入页面" placement="right">
                            <span>{row.label}</span>
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                />
              ),
            }}
            dataSource={dataSource}
          />
          <div style={{ height: 140 }} />
        </div>
        {/* <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <Row justify='center' align='middle' style={{ background: '#fff' }}>
            <Divider style={{ margin: '0 0 10px' }} />
            <Button
              type="primary"
              onClick={() => onConfirm?.(checkedRows?.[0]?.value)}
              disabled={!checkedRows?.length}
              style={{ marginBottom: 10 }}
            >
              确认
            </Button>
          </Row>
        </div> */}
      </Drawer>
    </>
  );
});
