import request from '@/utils/request';
import type { Page, TReturn, FieldItem } from '@/types';
import { prefixDataSource, prefixSystem, prefixSource } from './common';
import type { EDataSourceMethod } from '@/constant';

// 数据源列表
export const dataSourceList = (
  params: {
    // 数据源ID
    id?: string;
    // 数据源名称
    name?: string;
    // 数据源类型，可选值有1：MySQL，2：Redis...
    dataType?: 1 | 2;
  } & Page = {},
) => {
  return request.get<TReturn<any>>(prefixDataSource('list'), {
    params,
  });
};

// 数据源详情
export const getDataSourceInfo = (id: string) => {
  return request.get<TReturn<any>>(prefixDataSource('getById'), {
    params: { id },
  });
};

// 更新数据源附属表信息
export const updateSubsidiaryTable = (id: string, subsidiaryTable: string) => {
  return request.post<TReturn<any>>(prefixDataSource('updateSubsidiaryTable'), {
    data: { id, subsidiaryTable },
  });
};

// 环境数据列表
export const envinfoList = () => {
  return request.get<TReturn<any>>(prefixSystem('envinfo/list'), {});
};

// 添加数据源
export const addDataSource = (data: any) => {
  return request.post<TReturn<any>>(prefixDataSource('add'), { data });
};

// 更新数据源
export const updateDataSource = (data: any) => {
  return request.post<TReturn<any>>(prefixDataSource('update'), { data });
};

// 删除数据源
export const delDataSource = (id: string) => {
  return request.post<TReturn<any>>(prefixDataSource('del'), { data: { id } });
};

// 根据dsId与表名获取表结构信息
export const getStructs = (
  params: {
    // 连接信息ID
    dsId?: string;
    // 表名称
    table?: string;
  } = {},
) => {
  return request.get<TReturn<any>>(prefixSystem('datasource/table/getStruct'), { params });
};

// 查询模型字段接口列表
export const fieldListBySourceId = (
  params: {
    // ID
    tableInformationId?: string;
  } & Page = {},
) => {
  return request.get<TReturn<any>>(prefixSource('field/list'), { params });
};

// 添加数据源字段
export const addFields = (data: { saveDataSourceFieldParam: FieldItem[] }) => {
  return request.post<TReturn<any>>(prefixSource('field/add'), { data });
};

// 删除数据源字段
export const delField = (id: string) => {
  return request.post<TReturn<any>>(prefixSource('field/del'), { data: { id } });
};

// 编辑数据源字段
export const modifyField = (data: {
  id: string;
  fieldName?: string;
  fieldIdentification?: string;
  generateStrategy?: string;
  fieldForeignKey?: string;
  description?: string;
}) => {
  return request.post<TReturn<any>>(prefixSource('field/update'), { data });
};

/**
 * 获取方法列表
 * @param tableInformationId 数据源信息表ID
 * @returns
 */
export const getDataSourceMethods = (tableInformationId: string) => {
  return request.get<
    TReturn<
      Array<{
        id: string;
        name: string;
        methodTyppe: EDataSourceMethod;
      }>
    >
  >(prefixSource('method/getMethodList'), { params: { tableInformationId } });
};

/**
 * 获取方法详情
 * @param methodId 数据源信息表ID
 * @returns
 */
export const getDataSourceMethodDetail = (methodId: string) => {
  return request.get<TReturn<any>>(prefixSource('method/getMethodDetail'), {
    params: { methodId },
  });
};

// 添加数据源方法
export const addDataSourceMethod = (data: any) => {
  return request.post<TReturn<any>>(prefixSource('method/save'), { data });
};

// 编辑数据源方法
export const updateDataSourceMethod = (data: any) => {
  return request.post<TReturn<any>>(prefixSource('method/update'), { data });
};

// 是否存在名字或者标识
export const validateDataSourceName = (params: {
  id?: string;
  name?: string;
  identification?: string;
}) => {
  return request.get<TReturn<any>>(prefixSource('info/exist'), { params });
};

// 数据源方法新增返回给客户端构建数据
export const getMethodStructure = (params: { methodId: string }) => {
  return request.get<TReturn<any>>(prefixSource('structure/getMethodStructure'), { params });
};

export interface ExecuteMethodParam {
  paramId: string;
  paramValue: string;
}
// 执行数据源的方法
export const executeMethod = (data: {
  methodId: string;
  params?: ExecuteMethodParam[];
  jsonInParam?: string;
}) => {
  return request.post<TReturn<any>>(prefixSource('execute/method'), { data });
};
