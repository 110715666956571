import React from 'react';
import { EPageLayoutCmpType, EDataDisplayCmpType } from '@/constant';
import Title from './View/Title';
import SwiperView from './View/Swiper';
import ImageView from './View/ImageView';
import PureText from './View/PureText';
import EnumView from './View/EnumView';
import VideoView from './View/VideoView';
import HTMLText from './View/HTMLText';
import TableView from './View/TableView';

interface DisplayComponentProps {
  item: any;
  values: any;
  wrapperColWidth?: number;
}

export default function DisplayComponent({ item, values, wrapperColWidth }: DisplayComponentProps) {
  let ControlCmp: any = null;
  switch (item.controlType as string) {
    case EPageLayoutCmpType.标题:
      ControlCmp = <Title configInfo={item} />;
      break;
    case EDataDisplayCmpType.轮播图:
      ControlCmp = <SwiperView configInfo={item} />;
      break;
    case EDataDisplayCmpType.图片:
      ControlCmp = <ImageView configInfo={item} />;
      break;
    case EDataDisplayCmpType.文本:
      ControlCmp = <PureText configInfo={item} values={values} />;
      break;
    case EDataDisplayCmpType.枚举:
      ControlCmp = <EnumView configInfo={item} />;
      break;
    case EDataDisplayCmpType.视频:
      ControlCmp = <VideoView configInfo={item} />;
      break;
    case EDataDisplayCmpType.HTML文本:
      ControlCmp = <HTMLText configInfo={item} />;
      break;
    case EDataDisplayCmpType.列表:
      ControlCmp = <TableView configInfo={item} value={item.finalValue} />;
      break;
    default:
      ControlCmp = null;
      break;
  }
  return <div style={{ flex: wrapperColWidth ? `0 0 ${wrapperColWidth}px` : 1 }}>{ControlCmp}</div>;
}
