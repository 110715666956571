import { memo } from 'react';
import { Select } from 'antd';
import pick from 'lodash/pick';
import { FieldCmpType } from '@/constant';

interface SelectControlProps {
  onChange?: (data: any) => void;
  value?: any;
  configInfo?: any;
}

const SelectControl = memo<SelectControlProps>(({ onChange, value, configInfo }) => {
  return (
    <Select
      {...pick(configInfo, ['disabled', 'placeholder', 'options'])}
      mode={configInfo.controlType === FieldCmpType.下拉多选框 ? 'multiple' : undefined}
      defaultValue={configInfo.defaultValue}
      value={
        configInfo.controlType === FieldCmpType.下拉多选框
          ? value || configInfo.defaultValue || []
          : value || configInfo.defaultValue
      }
      placeholder={configInfo.placeholder || '请选择'}
      onChange={onChange}
    />
  );
});

export default SelectControl;
