import { memo } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space, Alert, Input, message } from 'antd';

interface TransformKeys {
  buttonLabel?: string;
  keepOneMessage?: string;
  value?: any[];
  bindedMethods?: any;
  execlData?: any;
  bindParam?: any;
  onChange?: (data: any) => void;
}

interface FormBindKeys {
  apiKey: string | undefined;
  excelKey: string | undefined;
}

const { Option } = Select;

function getChildKeys(
  bindedMethods: any,
  methodType = 'submitMethod',
  methodParamType = 'inputParams',
  fieldId = '',
): any[] {
  if (fieldId.length < 1) {
    return [];
  }
  // 拿到当前绑定的加载方法的信息
  const inputParams = bindedMethods?.[methodType]?.[methodParamType] ?? [];

  if (inputParams.length < 1) {
    return [];
  }
  const childKeys: any[] = []; // List类型，透传其Item的字段

  for (let i = 0; i < inputParams.length; i++) {
    const treeNode = inputParams[i];
    if (treeNode.id !== fieldId) {
      continue;
    }

    // 拿出二级节点集合，找出列表数据
    const { fields } = treeNode;
    let nodeInfo: any;
    if (treeNode.dataType === 'List') {
      nodeInfo = treeNode;
    } else {
      nodeInfo = fields.find((item: any) => item.dataType === 'List');
    }

    if (typeof nodeInfo === 'undefined') {
      return [];
    }

    nodeInfo.fields.forEach(({ path, id, title, dataType }: any) => {
      childKeys.push({
        key: path,
        title,
        id,
        dataType,
      });
    });
  }

  return childKeys;
}

export default memo<TransformKeys>(
  ({
    buttonLabel = '增加表头字段',
    keepOneMessage = '必须选择1个或以上表头字段',
    value,
    bindParam = [],
    bindedMethods,
    onChange,
  }) => {
    const oldExcelKeys = [];
    const oldBindKeys = [];
    const formValue: {
      formBindKeys: FormBindKeys[];
    } = { formBindKeys: [] };

    // 初始化旧的绑定数据
    if (typeof value !== 'undefined') {
      const newFormBindKeys: FormBindKeys[] = [];
      for (const [apiKey, paramInfo] of Object.entries(value)) {
        const excelKey = paramInfo?.excelKey ?? '';
        oldExcelKeys.push(excelKey);
        oldBindKeys.push({
          propName: apiKey,
          ...paramInfo,
        });
        newFormBindKeys.push({
          apiKey,
          excelKey,
        });
      }
      formValue.formBindKeys = newFormBindKeys;
    }

    let methodParamType = 'inputParams';
    let bindInfo = bindParam.find((item: any) => item.methodParamType === 'inputParams');
    if (typeof bindInfo === 'undefined') {
      methodParamType = 'outputParams';
      bindInfo = bindParam.find((item: any) => item.methodParamType === 'outputParams');
    }
    let bindKeys: any[] = oldBindKeys; // 获取到可绑定的字段
    const childKeysMap = new Map();

    if (typeof bindInfo !== 'undefined') {
      // 当删除绑定参数时，重置数据
      if (
        typeof bindInfo.bindVar === 'object' &&
        Object.keys(bindInfo.bindVar).length < 1 &&
        typeof value !== 'undefined' &&
        typeof onChange === 'function'
      ) {
        onChange(undefined);
      }

      let keys = [];
      if (methodParamType === 'outputParams') {
        keys = bindInfo?.bindVar?.childKeys ?? [];
      } else {
        keys = getChildKeys(
          bindedMethods,
          bindInfo.type,
          methodParamType,
          bindInfo?.bindVar?.fieldId,
        );
      }
      bindKeys = [];
      keys.forEach((item: any) => {
        const propName = item.key.split('.').slice(-1)[0];
        bindKeys.push({ propName, ...item });
        childKeysMap.set(propName, { ...item });
      });

      if (formValue.formBindKeys.length < 1) {
        const newFormBindKeys: FormBindKeys[] = [];
        bindKeys.forEach((item) => {
          newFormBindKeys.push({
            apiKey: item.propName,
            excelKey: item.title,
          });
        });
        formValue.formBindKeys = newFormBindKeys;
      }
    }

    // 初次绑定，需要保存值
    if (
      typeof bindInfo !== 'undefined' &&
      typeof bindInfo.bindVar === 'object' &&
      Object.keys(bindInfo.bindVar).length >= 1 &&
      (bindInfo.bindVar?.fieldId ?? '').length > 0 &&
      (typeof value === 'undefined' || Object.keys(value).length < 1) &&
      typeof onChange === 'function'
    ) {
      const newSaveValue: any = {};
      formValue.formBindKeys.forEach(
        (item: { apiKey: string | undefined; excelKey: string | undefined }) => {
          if (typeof item === 'undefined') {
            return false;
          }
          const { apiKey = '', excelKey = '' } = item;
          if (apiKey.length < 1 || excelKey.length < 1) {
            return false;
          }
          const paramInfo = childKeysMap.get(apiKey);
          if (typeof paramInfo === 'undefined') {
            return false;
          }
          newSaveValue[apiKey] = {
            excelKey,
            ...paramInfo,
          };
        },
      );
      onChange(newSaveValue);
    }

    const [form] = Form.useForm();

    const onValuesChange = (_: any, values: any) => {
      const newSaveValue: any = {};
      values.formBindKeys.forEach(
        (item: { apiKey: string | undefined; excelKey: string | undefined }) => {
          if (typeof item === 'undefined') {
            return false;
          }
          const { apiKey = '', excelKey = '' } = item;
          if (apiKey.length < 1 || excelKey.length < 1) {
            return false;
          }
          const paramInfo = childKeysMap.get(apiKey);
          if (typeof paramInfo === 'undefined') {
            return false;
          }
          newSaveValue[apiKey] = {
            excelKey,
            ...paramInfo,
          };
        },
      );
      if (typeof onChange === 'function') {
        onChange(newSaveValue);
      }
    };

    const hasBindKeys = bindKeys.length > 0;

    return (
      <>
        {!hasBindKeys ? <Alert message="请选择绑定参数字段" type="warning" showIcon /> : null}
        {hasBindKeys ? (
          <Form
            form={form}
            initialValues={formValue}
            onValuesChange={onValuesChange}
            autoComplete="off"
          >
            <Form.List name="formBindKeys">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.apiKey !== curValues.apiKey
                        }
                      >
                        {() => (
                          <Form.Item {...field} tooltip="接口字段" name={[field.name, 'apiKey']}>
                            <Select style={{ width: 130 }} placeholder="请选择字段">
                              {bindKeys.map((item) => (
                                <Option key={item.id} value={item.propName}>
                                  {`${item.propName} (${item.title})`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.excelKey !== curValues.excelKey
                        }
                      >
                        {() => (
                          <Form.Item {...field} tooltip="excel字段" name={[field.name, 'excelKey']}>
                            <Input type="text" placeholder="请填写表头标题" />
                          </Form.Item>
                        )}
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => {
                          if (fields.length <= 1) {
                            message.warn(keepOneMessage);
                            return;
                          }
                          remove(field.name);
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {buttonLabel}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        ) : null}
      </>
    );
  },
);
